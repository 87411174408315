import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { ExtractFirstArgAsType, withApiVersion, WithoutVersion } from '../../helpers';
import { ApiError, Result, Result_1 } from '../../open-api/commands';
import { SkillService } from '../../open-api/commands/services/SkillService';

const requestSkill = withApiVersion(SkillService.requestSkill);

export type RequestSkillInput = WithoutVersion<
  ExtractFirstArgAsType<typeof SkillService.requestSkill>
>;

export const useRequestSkill = () => {
  const { t } = useTranslation();

  return useMutation<Result | Result_1, ApiError, RequestSkillInput>(requestSkill, {
    meta: {
      type: t('alert.update-types.requested'),
      requestObject: t('alert.request-objects.skill'),
    },
  });
};
