import { env } from '../../../utils/env';
import * as CommandParams from './command-params';
import { useMutation } from 'react-query';
import { ApiError, EmployeeService } from '../../open-api/commands';
import { Result } from '../../open-api/commands/models/Result';

const addOrigin = (input: CommandParams.AddOrigin) =>
  EmployeeService.addOrigin({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

type GuidResultWithId = Result & {
  id: string;
};

export const useAddOrigin = (
  requestObject: string,
  type: string,
  onSuccess:
    | ((
        data: GuidResultWithId,
        variables: CommandParams.AddOrigin,
        context: unknown
      ) => void | Promise<unknown>)
    | undefined
) => {
  return useMutation<GuidResultWithId, ApiError, CommandParams.AddOrigin>(
    async (input) => {
      return await addOrigin(input);
    },
    {
      onSuccess,
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};
