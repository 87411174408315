import { Button, Flex, Text, ComponentSlotStyle } from '@fluentui/react-northstar';
import { range } from 'ramda';
import { useTranslation } from 'react-i18next';

export interface PaginationProps {
  currentPage: number;
  pageSize: number;
  totalItems: number;
  isMinimal?: boolean;
  onPageChange: (page: number) => void;
}

const paginationRootStyle: ComponentSlotStyle = (data) => ({
  fontSize: data.theme.siteVariables.fontSizes['medium'],
});

const paginationButtonStyle: ComponentSlotStyle = () => ({
  minWidth: '2.3rem',
  boxSizing: 'border-box',
});

const paginationButtonSelectedStyle: ComponentSlotStyle = (data) => ({
  ...paginationButtonStyle(data),
  '::after': {
    content: `""`,
    position: 'absolute',
    bottom: '5px',
    display: 'block',
    width: '20px',
    height: '2px',
    backgroundColor: data.theme.siteVariables.colorScheme['brand']['background'],
  },
});

type PageNumbersProps = Pick<PaginationProps, 'currentPage' | 'onPageChange'> & {
  totalPages: number;
};

// Always render 7 page numbers at a time (unless the number of pages is less than 7)
const getRangeToRender = (currentPage: number, totalPages: number) => {
  if (totalPages <= 7) {
    return {
      from: 1,
      to: totalPages,
      includeLastItemSeparately: false,
    };
  }
  if (currentPage <= 3) {
    return {
      from: 1,
      to: 5,
      includeLastItemSeparately: true,
    };
  }
  if (currentPage >= totalPages - 4) {
    return {
      from: totalPages - 6,
      to: totalPages,
      includeLastItemSeparately: false,
    };
  }
  return {
    from: currentPage - 2,
    to: currentPage + 2,
    includeLastItemSeparately: true,
  };
};

const PageNumbers = ({ currentPage, totalPages, onPageChange }: PageNumbersProps) => {
  if (totalPages <= 0) return <></>;

  const { from, to, includeLastItemSeparately } = getRangeToRender(currentPage, totalPages);

  return (
    <>
      {range(from, to + 1).map((n) => (
        <Button
          styles={currentPage === n ? paginationButtonSelectedStyle : paginationButtonStyle}
          text
          onClick={() => onPageChange(n)}
          key={`pageSelector_${n}`}
        >
          {n}
        </Button>
      ))}
      {includeLastItemSeparately && (
        <>
          <Button styles={paginationButtonStyle} text>
            ...
          </Button>
          <Button
            styles={
              currentPage === totalPages ? paginationButtonSelectedStyle : paginationButtonStyle
            }
            text
            onClick={() => onPageChange(totalPages)}
          >
            {totalPages}
          </Button>
        </>
      )}
    </>
  );
};

export const Pagination = ({
  currentPage,
  pageSize,
  totalItems,
  isMinimal = false,
  onPageChange,
}: PaginationProps) => {
  const { t } = useTranslation();

  const firstShownItem = totalItems > 0 ? (currentPage - 1) * pageSize + 1 : 0;
  const totalPages = Math.ceil(totalItems / pageSize);

  return (
    <Flex gap="gap.large" vAlign="center" hAlign="center" styles={paginationRootStyle}>
      <Text truncated={true} styles={{ minWidth: '7rem' }}>
        {firstShownItem}-
        {firstShownItem + pageSize < totalItems ? firstShownItem + (pageSize - 1) : totalItems}{' '}
        {t('pagination.of')} {totalItems}
      </Text>
      <Flex gap="gap.small">
        {!isMinimal && (
          <Button
            styles={paginationButtonStyle}
            text
            onClick={() => onPageChange(1)}
            disabled={totalItems === 0 || currentPage === 1}
          >
            {'|<'}
          </Button>
        )}
        <Button
          styles={paginationButtonStyle}
          text
          onClick={() => onPageChange(currentPage - 1)}
          disabled={totalItems === 0 || currentPage === 1}
        >
          {'<'}
        </Button>
        <PageNumbers
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
        <Button
          styles={paginationButtonStyle}
          text
          onClick={() => onPageChange(currentPage + 1)}
          disabled={totalItems === 0 || currentPage === totalPages}
        >
          {'>'}
        </Button>
        {!isMinimal && (
          <Button
            styles={paginationButtonStyle}
            text
            onClick={() => onPageChange(totalPages)}
            disabled={totalItems === 0 || currentPage === totalPages}
          >
            {'>|'}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
