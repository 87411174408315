import { delay } from '../../helpers';
import { GenericResult } from '../../open-api/queries/models/GenericResult';

type EventStartPosition = {
  id: string;
  storePosition?: number | null;
};

export const waitForEvent = async (
  { id, storePosition }: EventStartPosition,
  fetchPosition: (id: string) => Promise<GenericResult<number | null>>,
  waitForNull = false
) => {
  let payload = (await fetchPosition(id)).payload;
  const attemptInterval = 200;
  const maxAttempts = 10000 / attemptInterval; // 10 seconds
  let counter = 0;

  while (waitForNull ? payload !== null : Number(payload) < (storePosition ?? 0)) {
    await delay(attemptInterval);

    payload = (await fetchPosition(id)).payload;

    counter++;
    if (counter > maxAttempts) {
      return Promise.reject('Event position did not update in time');
    }
  }

  return Promise.resolve();
};
