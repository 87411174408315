import { Button, EditIcon, Flex, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { AddLanguageDialogForm } from './AddLanguageDialog/AddLanguageDialogForm';
import { EmployeeLanguageDto, useUpdateLanguages } from '../../data-access';
import { EditLanguageDialogForm } from './EditLanguageDialog/EditLanguageDialogForm';
import { Ti8mPill } from '../common';
import { Ti8mDialog } from '../common/Ti8mDialog';
import { AddCircleIcon } from '../../icons';

type EmployeeSkillsLanguagesProps = {
  employeeId: string | undefined;
  isEditable: boolean;
  nativeLanguages: EmployeeLanguageDto[];
  languages: EmployeeLanguageDto[];
};

export type Language = {
  level?: number;
  languageId: string;
  name: string;
};

export type NativeLanguage = {
  languageId: string;
  name: string;
};

export const EmployeeSkillsLanguages = ({
  employeeId,
  isEditable,
  nativeLanguages,
  languages,
}: EmployeeSkillsLanguagesProps) => {
  const { t } = useTranslation();
  const [isAddDialogOpen, setAddDialogVisibility] = useState<boolean>(false);
  const [isEditDialogOpen, setEditDialogVisibility] = useState<boolean>(false);
  const [isTouched, setTouched] = useState<boolean>(false);
  const defaultEmployeeLanguage: { languages: Language[]; nativeLanguages: NativeLanguage[] } = {
    languages: languages.map((l) => {
      return {
        level: l.level,
        languageId: l.id,
        name: l.name.en,
      };
    }),
    nativeLanguages: nativeLanguages.map((l) => {
      return {
        languageId: l.id,
        name: l.name.en,
      };
    }),
  };
  const [updatedLanguages, setUpdatedLanguages] = useState<{
    languages: Language[];
    nativeLanguages: NativeLanguage[];
  }>();
  const [languageToAdd, setLanguageToAdd] = useState<Language>({ languageId: '', name: '' });
  const [languageLevel, setLanguageLevel] = useState<number>(0);

  const [editedLanguages, setEditedLanguages] = useState<Language[]>([]);
  const [editedNativeLanguages, setEditedNativeLanguages] = useState<NativeLanguage[]>([]);

  const { mutate: updateLanguages } = useUpdateLanguages();

  const resetDialog = useCallback(() => {
    setAddDialogVisibility(false);
    setLanguageToAdd({ languageId: '', name: '', level: undefined });
    setLanguageLevel(0);
    setTouched(false);
  }, [setAddDialogVisibility, setLanguageToAdd, setLanguageLevel, setTouched]);

  const handleConfirmAddLanguage = useCallback(() => {
    if (languageLevel === 7) {
      if (updatedLanguages?.nativeLanguages) {
        const nativeLanguages = updatedLanguages.nativeLanguages;
        nativeLanguages.push({
          languageId: languageToAdd?.languageId ?? '',
          name: languageToAdd?.name ?? '',
        });
        setUpdatedLanguages({ ...updatedLanguages, nativeLanguages: nativeLanguages });
      }
    } else {
      if (updatedLanguages?.nativeLanguages) {
        const languages = updatedLanguages?.languages ?? [];
        languages.push({
          languageId: languageToAdd?.languageId ?? '',
          name: languageToAdd?.name ?? '',
          level: languageLevel,
        });
        setUpdatedLanguages({
          nativeLanguages: updatedLanguages?.nativeLanguages,
          languages: languages,
        });
      }
    }
    updateLanguages({
      id: employeeId ?? '',
      requestBody: {
        languages: updatedLanguages?.languages.map((language) => {
          return { languageId: language.languageId, level: language.level ?? 1 };
        }),
        nativeLanguages: updatedLanguages?.nativeLanguages.map((native) => {
          return native.languageId;
        }),
      },
    });
    resetDialog();
  }, [
    updatedLanguages,
    languageLevel,
    languageToAdd,
    employeeId,
    updateLanguages,
    setUpdatedLanguages,
    resetDialog,
  ]);

  const handleEditLanguages = () => {
    updateLanguages({
      id: employeeId ?? '',
      requestBody: {
        languages: editedLanguages.map((langage) => {
          return {
            languageId: langage.languageId,
            level: langage.level ?? 1,
          };
        }),
        nativeLanguages: editedNativeLanguages.map((native) => {
          return native.languageId;
        }),
      },
    });
  };

  return (
    <>
      <Flex space="between">
        <Text
          weight="semibold"
          content={t('employee-detail.master-data.skills.languages-header')}
          size="large"
        />
        <Flex gap="gap.small">
          <Button
            iconOnly
            text
            disabled={!isEditable}
            size="small"
            primary
            icon={<AddCircleIcon size="large" />}
            onClick={() => {
              setAddDialogVisibility(true);
              setUpdatedLanguages(defaultEmployeeLanguage);
            }}
          />
          <Ti8mDialog
            open={isAddDialogOpen}
            header={t('employee-detail.master-data.skills.add-languages-dialog.header')}
            cancelButton={{
              content: t('employee-detail.master-data.skills.add-languages-dialog.button-cancel'),
            }}
            onCancel={resetDialog}
            confirmButton={{
              content: t('employee-detail.master-data.skills.add-languages-dialog.button-add'),
              disabled: !isTouched,
            }}
            onConfirm={handleConfirmAddLanguage}
            content={
              <AddLanguageDialogForm
                languageToAdd={languageToAdd}
                setLanguageToAdd={setLanguageToAdd}
                languageLevel={languageLevel}
                setLanguageLevel={setLanguageLevel}
                setTouched={setTouched}
              />
            }
            resetOverflow
          />
          <Button
            iconOnly
            text
            disabled={(languages.length === 0 && nativeLanguages.length === 0) || !isEditable}
            primary
            size="small"
            icon={<EditIcon size="large" />}
            onClick={() => {
              setEditDialogVisibility(true);
              setEditedLanguages(defaultEmployeeLanguage.languages ?? []);
              setEditedNativeLanguages(defaultEmployeeLanguage.nativeLanguages ?? []);
            }}
          />
          <Ti8mDialog
            open={isEditDialogOpen}
            header={t('employee-detail.master-data.skills.edit-languages-dialog.header')}
            cancelButton={{
              content: t('employee-detail.master-data.skills.edit-languages-dialog.button-cancel'),
            }}
            onCancel={() => {
              setEditDialogVisibility(false);
              setTouched(false);
            }}
            confirmButton={{
              content: t('employee-detail.master-data.skills.edit-languages-dialog.button-save'),
              disabled: !isTouched,
            }}
            onConfirm={() => {
              handleEditLanguages();
              setTouched(false);
              setEditDialogVisibility(false);
            }}
            content={
              <EditLanguageDialogForm
                editedNativeLanguages={editedNativeLanguages}
                editedLanguages={editedLanguages}
                setEditedLanguages={setEditedLanguages}
                setEditedNativeLanguages={setEditedNativeLanguages}
                setTouched={setTouched}
              />
            }
            resetOverflow
          />
        </Flex>
      </Flex>
      <Flex column gap="gap.small">
        <Text content={t('employee-detail.master-data.skills.languages-native-subheader')} />
        <Flex gap="gap.small">
          {nativeLanguages?.map((item) => (
            <Ti8mPill
              content={item.name.en}
              maxTextLength={25}
              size="medium"
              appearance="filled"
              styles={{
                maxWidth: '8rem',
                minWidth: '2rem',
              }}
              key={item.name.en}
            />
          ))}
        </Flex>
        <Text content={t('employee-detail.master-data.skills.languages-additional-subheader')} />
        <Flex gap="gap.small">
          {languages?.map((item) => (
            <Ti8mPill
              content={item.name.en}
              maxTextLength={25}
              size="medium"
              appearance="filled"
              styles={{
                maxWidth: '8rem',
                minWidth: '2rem',
              }}
              key={item.name.en}
              languageLevel={t(`language-skill-type.${item.level}`)}
            />
          ))}
        </Flex>
      </Flex>
    </>
  );
};
