import { Link } from '@fluentui/react';
import {
  DropdownProps,
  Flex,
  Form,
  FormField,
  FormLabel,
  FormMessage,
  Grid,
  Text,
} from '@fluentui/react-northstar';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EmployeeCertificateDto as EmployeeCertificateQueryDto } from '../../../../../data-access';
import { useState, useCallback } from 'react';
import { EmployeeCertificateDto } from '../../../../../data-access/open-api/commands';
import { convertYearMonthDay } from '../../../../../utils/dateConverter';
import { Ti8mDatepicker, Ti8mDropdown, Ti8mFileUploader } from '../../../../common';
import { useInfiniteCertificates } from '../../../../../data-access/hooks/queries/use-infinite-certificates';

type AddCertificateDialogContentProps = {
  defaultValues?: EmployeeCertificateQueryDto;
  errors: FieldErrors<EmployeeCertificateDto>;
  register: UseFormRegister<EmployeeCertificateDto>;
  setValue: UseFormSetValue<EmployeeCertificateDto>;
  onDocumentUploadPreparation: (file: File | undefined) => void;
  onRequestCertificateClick?: () => void;
};

export const EducationDialogForm = ({
  errors,
  defaultValues,
  register,
  setValue,
  onDocumentUploadPreparation,
  onRequestCertificateClick,
}: AddCertificateDialogContentProps) => {
  const { t } = useTranslation();

  const [certificatesSearchString, setCertificatesSearchString] = useState('');
  const { certificates, hasMoreCertificates, fetchMoreCertificates, isCertificatesError } =
    useInfiniteCertificates(certificatesSearchString);

  const certificateItemChanged = (data: DropdownProps) => {
    const id = certificates?.find((d) => d.name.en === data.value)?.id;
    setValue('certificateId', id ?? '', { shouldDirty: true });
  };

  const onIssueingDateChanged = (date: Date | null) => {
    setValue('issuedOn', date ? convertYearMonthDay(date) : '', { shouldDirty: true });
  };

  const onValidityDateChanged = (date: Date | null) => {
    setValue('validUntil', date ? convertYearMonthDay(date) : null, { shouldDirty: true });
  };

  const onFileChanged = (file: File | undefined) => {
    setValue('certificateFileName', file?.name ?? '', { shouldDirty: true });
    onDocumentUploadPreparation(file);
  };

  const handleCertificateSearchQueryChange = useCallback(
    (data: DropdownProps) => {
      if (data.searchQuery !== undefined) {
        setCertificatesSearchString(data.searchQuery);
      }
    },
    [setCertificatesSearchString]
  );

  register('certificateId', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('issuedOn', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  return (
    <Form>
      <FormField>
        <FormLabel
          content={t(
            'employee-detail.master-data.certificates.add-certificate-dialog.certificate-label'
          )}
        />
        <Ti8mDropdown
          autoFocus
          items={[...new Set(certificates.map((d) => d.name.en) ?? [])]}
          placeholder={t(
            'employee-detail.master-data.certificates.add-certificate-dialog.certificate-placeholder'
          )}
          onChange={certificateItemChanged}
          onScrollLoadMoreItems={fetchMoreCertificates}
          hasDataError={isCertificatesError}
          canLoadMoreItems={hasMoreCertificates}
          search
          defaultValue={defaultValues?.certificate.name.en}
          onSearchQueryChange={handleCertificateSearchQueryChange}
        />
        {errors.certificateId && <FormMessage error content={errors.certificateId.message} />}
        {onRequestCertificateClick && (
          /* Do not render link in edit mode (= when callback is not set) */
          <Flex gap="gap.smaller" styles={{ marginTop: 10 }}>
            <Text
              content={t('employee-detail.master-data.certificates.request-dialog.did-not-find')}
              size="small"
            />
            <Link onClick={onRequestCertificateClick}>
              <Text
                content={t('employee-detail.master-data.certificates.request-dialog.title')}
                size="small"
              />
            </Link>
          </Flex>
        )}
      </FormField>

      <Grid columns="2" styles={{ gap: '1rem' }}>
        <FormField>
          <FormLabel
            content={t(
              'employee-detail.master-data.certificates.add-certificate-dialog.issueing-date-label'
            )}
          />
          <Ti8mDatepicker
            placeholder={t(
              'employee-detail.master-data.certificates.add-certificate-dialog.issueing-date-placeholder'
            )}
            defaultSelectedDate={defaultValues ? new Date(defaultValues.issuedOn) : undefined}
            onDateChange={onIssueingDateChanged}
          />
          {errors.issuedOn && <FormMessage error content={errors.issuedOn.message} />}
        </FormField>
        <FormField>
          <FormLabel
            content={t(
              'employee-detail.master-data.certificates.add-certificate-dialog.expiration-date-label'
            )}
          />
          <Ti8mDatepicker
            placeholder={t(
              'employee-detail.master-data.certificates.add-certificate-dialog.expiration-date-placeholder'
            )}
            defaultSelectedDate={
              defaultValues?.validUntil ? new Date(defaultValues.validUntil) : undefined
            }
            onDateChange={onValidityDateChanged}
          />
        </FormField>
      </Grid>
      <FormField>
        <FormLabel
          content={t(
            'employee-detail.master-data.certificates.add-certificate-dialog.certificate-label'
          )}
        />
        <Ti8mFileUploader
          onFileChanged={onFileChanged}
          placeholder={t(
            'employee-detail.master-data.certificates.add-certificate-dialog.select-certificate-button'
          )}
          selectedFile={defaultValues?.certificateFile?.displayName}
        />
      </FormField>
    </Form>
  );
};
