export enum SwissMilitaryRankDto {
  Recruit = 0,
  PrivateE1 = 1,
  PrivateE2 = 2,
  LanceCorporal = 3,
  Corporal = 4,
  Sergeant = 5,
  SergeantFirstClass = 6,
  SergeantMajor = 7,
  QuartermasterSergeant = 8,
  ChiefSergeantMajor = 9,
  WarrantOfficer = 10,
  StaffWarrantOfficer = 11,
  MasterWarrantOfficer = 12,
  ChiefWarrantOfficer = 13,
  SecondLieutenant = 14,
  FirstLieutenant = 15,
  Captain = 16,
  Major = 17,
  LieutenantColonel = 18,
  Colonel = 19,
  SpecialistOfficer = 20,
  BrigadierGeneral = 21,
  MajorGeneral = 22,
  LieutenantGeneral = 23,
  General = 24,
}
