import { useRecoilValue } from 'recoil';
import { authAtom } from '../state/atoms/authAtom';

export const useAuthInfo = () => {
  const authInfo = useRecoilValue(authAtom);

  const { userRoles, accountId, runsInTeams } = authInfo;

  const isMasterDataEditor = userRoles.includes('MasterDataEditor');
  const isGlobalProfileEditor = userRoles.includes('GlobalProfileEditor');

  const isOwnProfile = (employeeId?: string) => (employeeId ? employeeId === accountId : false);

  const canEditProfile = (employeeId?: string) => isGlobalProfileEditor || isOwnProfile(employeeId);

  const hasRole = (role: string) => userRoles.includes(role);

  return {
    runsInTeams,
    isOwnProfile,
    loginAccountId: accountId,
    canEditProfile,
    isMasterDataEditor,
    isGlobalProfileEditor,
    userRoles,
    hasRole,
  };
};
