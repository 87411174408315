import { CancelablePromise, OpenAPI, SubjectMatterExpertTopicDto } from '../../queries';
import { request as __request } from '../../queries/core/request';
import { GuidResult } from '../models/GuidResult';

export class SubjectMatterExpertService {
  public static addSubjectMatterExpertTopic({
    version,
    requestBody,
  }: {
    version: string;
    requestBody: Omit<SubjectMatterExpertTopicDto, 'id'>;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/subject-matter-expert-topics',
      path: {
        version: version,
      },
      body: requestBody,
    });
  }

  public static updateSubjectMatterExpertTopic({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody: SubjectMatterExpertTopicDto['name'];
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/subject-matter-expert-topics/name/{id}',
      path: {
        version: version,
        id: id,
      },
      body: requestBody,
    });
  }

  public static deleteSubjectMatterExpertTopic({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/subject-matter-expert-topics/{id}',
      path: {
        version: version,
        id: id,
      },
    });
  }
}
