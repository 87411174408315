import { ComponentSlotStyle, Flex, Pill, Text, Tooltip } from '@fluentui/react-northstar';
import { SkillLevelStars } from './SkillLevelStars';
import { RatingSize } from '@fluentui/react';
import { EmployeeSkillDto } from '../../data-access';

type SkillPillProps = {
  employeeSkill: EmployeeSkillDto;
  maxTextLength: number;
  color?: string;
  size?: 'small' | 'smaller' | 'medium';
  styles?: ComponentSlotStyle;
  itemId?: string;
  appearance?: 'outline' | 'filled';
};

export const SkillPill = ({
  employeeSkill,
  maxTextLength,
  size,
  styles,
  itemId,
  color,
  appearance,
}: SkillPillProps) => {
  return (
    <Tooltip
      pointing
      trigger={
        <Pill
          key={itemId}
          rectangular
          size={size}
          styles={styles}
          color={color}
          appearance={appearance}
        >
          <Flex gap="gap.small" vAlign="center">
            <Text
              content={
                employeeSkill.skill.name.length < maxTextLength
                  ? employeeSkill.skill.name
                  : employeeSkill.skill.name.substring(0, maxTextLength).concat('...')
              }
            />
            <SkillLevelStars
              max={5}
              size={RatingSize.Small}
              readonly={true}
              rating={employeeSkill.level}
            />
          </Flex>
        </Pill>
      }
      content={<Text content={employeeSkill.skill.description} />}
    />
  );
};
