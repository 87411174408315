import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Input, Button, AddIcon, Text } from '@fluentui/react-northstar';
import { ClientNewEditDialog } from '../ClientNewEditDialog/ClientNewEditDialog';
import { useClientsFilter } from './hooks/useClientsFilter';

export const SearchWithFilter = () => {
  const { t } = useTranslation();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { searchTerm, setSearchTerm } = useClientsFilter();

  return (
    <Flex>
      <Input
        autoFocus
        label={<Text content={t('clients.search.query-term.label')} weight="semibold" />}
        placeholder={t('clients.search.query-term.placeholder')}
        style={{ width: '50%' }}
        fluid
        defaultValue={searchTerm}
        onChange={(_, data) => setSearchTerm(data?.value as string)}
        clearable
      />

      <Flex.Item push>
        <Button
          primary
          style={{ alignSelf: 'end' }}
          icon={<AddIcon />}
          onClick={() => setAddDialogOpen(true)}
          content={t('clients.buttons.add')}
        />
      </Flex.Item>
      {addDialogOpen && <ClientNewEditDialog isOpen={addDialogOpen} setIsOpen={setAddDialogOpen} />}
    </Flex>
  );
};
