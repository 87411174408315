import {
  ApiError,
  GenericResult,
  ProjectTemplateDto,
  ProjectTemplateService,
} from '../../open-api/queries';
import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import { QueryKeys } from '../../query-keys';
import type * as QueryParams from './query-params';

const getProjectsByClient = (input: QueryParams.ProjectTemplatesByClientId) =>
  ProjectTemplateService.getProjectTemplatesByClientId({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useProjectTemplatesByClient = (
  queryParams: QueryParams.ProjectTemplatesByClientId = {},
  queryOptions?: UseQueryOptions<
    GenericResult<Array<ProjectTemplateDto>>,
    ApiError,
    Array<ProjectTemplateDto>,
    [QueryKeys.ProjectTemplates, QueryParams.ProjectTemplatesByClientId]
  >
) =>
  useQuery(
    [QueryKeys.ProjectTemplates, queryParams],
    ({ queryKey: [key, input] }) => getProjectsByClient(input),
    {
      select: (data) => data.payload,
      enabled: !!queryParams.clientId,
      ...queryOptions,
    }
  );
