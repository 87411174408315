import { atom } from 'recoil';

export type SortAtomState = {
  sortBy: string;
  sortDescending: boolean;
};

export const sortAtom = atom<SortAtomState>({
  key: 'sortStateAtom',
  default: {
    sortBy: 'lastName',
    sortDescending: true,
  },
});
