import { AddIcon, Button, Flex } from '@fluentui/react-northstar';
import { ProjectDto, TemplateBasedProjectDto } from '../../data-access';
import { EmployeeProjectContainer } from './EmployeeProjectContainer';
import { AddNewProjectDialog } from './Dialogs/AddNewProjectDialog';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EmployeeProjectsTab = ({
  employeeId,
  isEditable,
  projects,
  templateBasedProjects,
  allowReferenceProjectToggle,
}: {
  employeeId: string;
  isEditable: boolean;
  projects?: Array<ProjectDto>;
  templateBasedProjects?: Array<TemplateBasedProjectDto>;
  allowReferenceProjectToggle: boolean;
}) => {
  const { t } = useTranslation();
  let pinnedProjectCounter = 0;

  const allProjects = useMemo(
    () => [...(templateBasedProjects || []), ...(projects || [])],
    [projects, templateBasedProjects]
  );
  const [isAddProjectDialogOpen, setAddProjectDialogOpen] = useState(false);

  const setAddProjectDialog = useCallback(
    (value: boolean) => {
      setAddProjectDialogOpen(value);
    },
    [setAddProjectDialogOpen]
  );

  const sortedProjects = useCallback(() => {
    const ongoingProjects = [...allProjects]
      // filter ongoing projects
      .filter((project) => !project.end)
      .sort((a, b) => {
        // check if not undefined
        if (a.start && b.start) {
          // projects with newer start date first
          if (new Date(a.start) > new Date(b.start)) {
            return -1;
          } else return 1;
        } else return -1;
      });

    const terminatedProjects = [...allProjects]
      .filter((project) => project.end)
      .sort((a, b) => {
        // check if not undefined
        if (a.end && b.end && a.start && b.start) {
          // projects with newer end date first
          if (new Date(a.end) > new Date(b.end)) {
            return -1;
          } else if (new Date(a.end) < new Date(b.end)) {
            return 1;
          }
          // if projects have the same end date ...
          else if (new Date(a.end) === new Date(b.end)) {
            // ... the ones with the older start date first
            if (new Date(a.start) > new Date(b.start)) {
              return -1;
            } else {
              return 1;
            }
          } else return -1;
        } else return -1;
      });

    return [...ongoingProjects, ...terminatedProjects];
  }, [allProjects]);

  return (
    <Flex column gap="gap.large" style={{ marginTop: '24px' }}>
      <Button
        icon={<AddIcon />}
        content={t('employee-detail.add-project-button')}
        disabled={!isEditable}
        iconPosition="before"
        style={{ marginTop: '32px' }}
        primary
        flat
        onClick={() => setAddProjectDialog(true)}
      />
      <AddNewProjectDialog
        dialogOpen={isAddProjectDialogOpen}
        employeeId={employeeId}
        onClose={() => setAddProjectDialog(false)}
      />
      {sortedProjects().map((p, i) => {
        if (p.isReferenceProject) {
          pinnedProjectCounter += 1;
        }
        const key = `project_card_${i}`;
        if (isTemplateBased(p)) {
          return (
            <EmployeeProjectContainer
              key={key}
              isEditable={isEditable}
              templateBasedProjectDto={p}
              employeeId={employeeId}
              pinCount={pinnedProjectCounter}
              allowReferenceProjectToggle={allowReferenceProjectToggle}
              project={{
                id: p.id ?? '',
                clientName: p.projectTemplate.client.name,
                name: p.projectTemplate.name,
                sector: p.projectTemplate.sector,
                isReferenceProject: p.isReferenceProject,
                start: p.start,
                end: p.end,
                description: p.projectTemplate.description,
                skills: p.skills,
                role: p.role,
                tasks: p.tasks,
              }}
            />
          );
        } else {
          return (
            <EmployeeProjectContainer
              key={key}
              isEditable={isEditable}
              project={p}
              pinCount={pinnedProjectCounter}
              employeeId={employeeId}
              allowReferenceProjectToggle={allowReferenceProjectToggle}
            />
          );
        }
      })}
    </Flex>
  );
};

function isTemplateBased(p: TemplateBasedProjectDto | ProjectDto): p is TemplateBasedProjectDto {
  return (p as TemplateBasedProjectDto).projectTemplate !== undefined;
}
