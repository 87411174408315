import { atom } from 'recoil';

export type AuthState = {
  runsInTeams: boolean;
  token?: string;
  accountId?: string;
  userRoles: string[];
};

export const authAtom = atom<AuthState>({
  key: 'authState',
  default: {
    runsInTeams: false,
    userRoles: [],
  },
});
