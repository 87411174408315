import { UInt64NullableResult } from '../core';
import { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { SectorListDtoResult } from '../models/SectorListDtoResult';

export class SectorService {
  public static sectors({ version }: { version: string }): CancelablePromise<SectorListDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/sectors',
      path: {
        version: version,
      },
    });
  }

  public static getEventPosition({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<UInt64NullableResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/sectors/event-position/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }
}
