import { Flex } from '@fluentui/react-northstar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../../components/EmployeeTable/Pagination';
import { FocusTopicDto, useFocusTopics } from '../../data-access';
import { useDeleteFocusTopic } from '../../data-access/hooks/commands/use-delete-focus-topic';
import { DefaultLoader } from '../components/DefaultLoader';
import { MasterDataToolbar } from '../components/MasterDataToolbar';
import { FocusTopicsAddOrEditFormDialog } from './FocusTopicsAddOrEditFormDialog';
import { FocusTopicsMasterDataTable } from './FocusTopicsMasterDataTable';

const PAGE_SIZE = 10;

export const FocusTopicsMasterData = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<FocusTopicDto>();

  const { mutate: remove } = useDeleteFocusTopic();

  const { data: { items = [], totalCount = 0 } = {}, isLoading } = useFocusTopics({
    take: PAGE_SIZE,
    skip: currentPage - 1,
    searchString: searchTerm,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <Flex column fill>
      <MasterDataToolbar
        DialogComponent={FocusTopicsAddOrEditFormDialog}
        buttonLabel={t('admin.focus-topics.add')}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        inputPlaceholderText={t('admin.focus-topics.search')}
      />

      <DefaultLoader isLoading={isLoading}>
        <FocusTopicsMasterDataTable
          items={items}
          onEditItem={(item) => {
            setCurrentItem(item);
            setDialogOpen(true);
          }}
          onDeleteItem={(item) => remove({ id: item.id })}
        />
      </DefaultLoader>

      <Pagination
        currentPage={currentPage}
        pageSize={PAGE_SIZE}
        totalItems={totalCount}
        onPageChange={(page) => setCurrentPage(page)}
      />

      {dialogOpen && (
        <FocusTopicsAddOrEditFormDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          currentItem={currentItem}
        />
      )}
    </Flex>
  );
};
