import { Flex } from '@fluentui/react-northstar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../../components/EmployeeTable/Pagination';
import { SkillDto, useSkillsList } from '../../data-access';
import { useDeleteSkill } from '../../data-access/hooks/commands/use-delete-skill';
import { DefaultLoader } from '../components/DefaultLoader';
import { MasterDataToolbar } from '../components/MasterDataToolbar';
import { SkillsAddOrEditFormDialog } from './SkillsAddOrEditFormDialog';
import { SkillsMasterDataTable } from './SkillsMasterDataTable';

const PAGE_SIZE = 10;

export const SkillsMasterData = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<SkillDto>();

  const { mutate: remove } = useDeleteSkill();

  const {
    items,
    totalCount,
    queryResult: { isLoading },
  } = useSkillsList({ take: PAGE_SIZE, skip: currentPage - 1, searchString: searchTerm });

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <Flex column fill>
      <MasterDataToolbar
        DialogComponent={SkillsAddOrEditFormDialog}
        buttonLabel={t('admin.skills.add')}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        inputPlaceholderText={t('admin.skills.search')}
      />

      <DefaultLoader isLoading={isLoading}>
        <SkillsMasterDataTable
          items={items}
          onEditItem={(item) => {
            setCurrentItem(item);
            setDialogOpen(true);
          }}
          onDeleteItem={(item) => remove({ id: item.id })}
        />
      </DefaultLoader>

      <Pagination
        currentPage={currentPage}
        pageSize={PAGE_SIZE}
        totalItems={totalCount}
        onPageChange={(page) => setCurrentPage(page)}
      />

      {dialogOpen ? (
        <SkillsAddOrEditFormDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          currentItem={currentItem}
        />
      ) : null}
    </Flex>
  );
};
