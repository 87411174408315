import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, withApiVersion, WithoutVersion } from '../../helpers';
import { ApiError, Result, FocusTopicService } from '../../open-api/commands';
import { FocusTopicService as QueryFocusTopicService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

type GuidResultWithId = Result & {
  id: string;
};

const deleteFocusTopic = withApiVersion(FocusTopicService.deleteFocusTopic);
const getEventPosition = withApiVersion(QueryFocusTopicService.getEventPosition);

export type DeleteFocusTopicInput = WithoutVersion<ExtractFirstArgAsType<typeof deleteFocusTopic>>;

export const useDeleteFocusTopic = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, DeleteFocusTopicInput>(
    async (input) => {
      const result = await deleteFocusTopic(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, (id) => getEventPosition({ id }), true).then(() =>
          queryClient.refetchQueries(QueryKeys.FocusTopics)
        ),

      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.focus-topic'),
      },
    }
  );
};
