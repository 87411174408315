import * as React from 'react';
import { Rating, RatingSize } from '@fluentui/react';
import { Provider } from '@fluentui/react-northstar';

export type SkillLevelStarsProps = {
  max: number;
  defaultRating?: number;
  rating?: number;
  readonly?: boolean;
  size?: RatingSize;
  allowZeroStars?: boolean;
  onChangeRating?: (event: React.FormEvent<HTMLElement>, rating?: number) => void;
};

export const SkillLevelStars = ({
  max,
  defaultRating,
  rating,
  readonly,
  size,
  allowZeroStars,
  onChangeRating,
}: SkillLevelStarsProps) => {
  return (
    <Provider.Consumer
      render={(theme) => (
        <Rating
          max={max}
          size={size}
          defaultRating={defaultRating}
          rating={rating}
          readOnly={readonly}
          allowZeroStars={allowZeroStars}
          onChange={onChangeRating}
          styles={{
            ratingStarFront: {
              ...(readonly && {
                cursor: 'default',
              }),
              color: `${theme.siteVariables.ratingStarColor} !important`,
            },
            ratingStarBack: {
              ...(readonly && {
                cursor: 'default',
              }),
              color: `${theme.siteVariables.ratingStarColor} !important`,
            },
            ...(readonly && {
              ratingButton: {
                pointerEvents: 'none',
                cursor: 'default',
              },
              ratingStar: {
                pointerEvents: 'none',
              },
            }),
          }}
        />
      )}
    />
  );
};
