/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CertificateDtoPaginatedListDtoResult } from '../models/CertificateDtoPaginatedListDtoResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { UInt64NullableResult } from '../core';

export class CertificateService {
  /**
   * @returns CertificateDtoPaginatedListDtoResult Success
   * @throws ApiError
   */
  public static certificates({
    version,
    searchString,
    skip,
    take = 50,
  }: {
    version: string;
    searchString?: string;
    skip?: number;
    take?: number;
  }): CancelablePromise<CertificateDtoPaginatedListDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/certificates',
      path: {
        version: version,
      },
      query: {
        searchString: searchString,
        skip: skip,
        take: take,
      },
    });
  }

  public static getEventPosition({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<UInt64NullableResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/certificates/event-position/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }
}
