import { useTranslation } from 'react-i18next';
import { TemplateBasedProjectDto as CommandProjectDto } from '../../../data-access/open-api/commands';
import { Ti8mProjectForm } from './Ti8mProjectForm';
import { useCallback, useEffect, useState } from 'react';
import {
  TranslationDto,
  TemplateBasedProjectDto,
  useAddEmployeeTemplateBasedProject,
  useEditEmployeeTemplateBasedProject,
} from '../../../data-access';
import { Ti8mDialog } from '../../common/Ti8mDialog';

const toCommandDto = (project?: TemplateBasedProjectDto): CommandProjectDto => {
  return {
    id: project?.id,
    projectTemplateId: project?.projectTemplate.id ?? '',
    role: project?.role ?? { de: '', en: '' },
    start: project?.start ?? '',
    end: project?.end,
    tasks: Array.from(project?.tasks ?? []),
    skills: project?.skills.map((s) => s.id) ?? [],
    isReferenceProject: project?.isReferenceProject || false,
    isUnderNDA: false,
  };
};

export const Ti8mProjectEditDialog = ({
  employeeId,
  project,
  open,
  onCancel,
}: {
  employeeId: string;
  project?: TemplateBasedProjectDto;
  open: boolean;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const [newProject, setNewProject] = useState<CommandProjectDto>(toCommandDto(project));

  //make sure the newProject state is refreshed, when we get a different project
  useEffect(() => {
    setNewProject(toCommandDto(project));
  }, [project]);
  const { mutate: addNewTemplateBasedProject } = useAddEmployeeTemplateBasedProject(
    t('alert.request-objects.employee'),
    t('alert.update-types.edit')
  );

  const { mutate: updateProject } = useEditEmployeeTemplateBasedProject(
    t('alert.request-objects.employee'),
    t('alert.update-types.edit')
  );

  const projectValidState = (project: CommandProjectDto): boolean => {
    if (
      project.projectTemplateId &&
      project.start &&
      project.tasks.filter((t) => t.de !== '').length > 0 &&
      project.tasks.filter((t) => t.en !== '').length > 0 &&
      project.role.de !== '' &&
      project.role.en !== '' &&
      project.skills.length > 0
    ) {
      return true;
    }
    return false;
  };

  const [canSave, setCanSave] = useState(projectValidState(newProject));

  useEffect(() => {
    setCanSave(projectValidState(newProject));
  }, [setCanSave, newProject]);

  const handlePropertyChanged = useCallback(
    (
      value:
        | string
        | Date
        | Array<string>
        | TranslationDto
        | Array<TranslationDto>
        | boolean
        | null,
      property: string
    ) => {
      const prev = newProject;
      setNewProject({
        ...prev,
        [property]: value,
      });
    },
    [newProject, setNewProject]
  );

  const handleRoleChanged = useCallback(
    (value: string, property: keyof TranslationDto) => {
      const role = newProject.role;
      role[property] = value;
      handlePropertyChanged({ de: role.de ?? '', en: role.en ?? '' }, 'role');
    },
    [newProject, handlePropertyChanged]
  );

  const handleSave = useCallback(() => {
    if (newProject?.id) {
      updateProject({
        id: employeeId,
        requestBody: {
          ...newProject,
          tasks: newProject.tasks.filter((t) => t.de !== '' || t.en !== ''),
        },
      });
    } else {
      addNewTemplateBasedProject({
        id: employeeId,
        requestBody: {
          ...newProject,
          tasks: newProject.tasks.filter((t) => t.de !== '' || t.en !== ''),
        },
      });
    }
    onCancel();
  }, [employeeId, newProject, addNewTemplateBasedProject, updateProject, onCancel]);

  return (
    <Ti8mDialog
      open={open}
      header={project ? t('dialog.add-project.title-edit') : t('dialog.add-project.title-add')}
      styles={{ maxHeight: '90%' }}
      cancelButton={{
        content: t('dialog.button-cancel'),
        flat: true,
      }}
      onCancel={onCancel}
      confirmButton={{
        content: project ? t('dialog.button-save-changes') : t('dialog.button-save'),
        flat: true,
        disabled: !canSave,
      }}
      onConfirm={handleSave}
      content={
        <Ti8mProjectForm
          project={project}
          onProjectChange={(value: string) => handlePropertyChanged(value, 'projectTemplateId')}
          onIsUnderNdaChange={(value: boolean) => {
            handlePropertyChanged(value, 'isUnderNDA');
          }}
          onStartChange={(value: Date | null) => handlePropertyChanged(value, 'start')}
          onEndChange={(value: Date | null) => handlePropertyChanged(value, 'end')}
          onSkillsChange={(value: Array<string>) => {
            const skills = [...value];
            handlePropertyChanged(skills, 'skills');
          }}
          onTasksChange={(value: Array<TranslationDto>) => {
            const tasks = [...value];
            handlePropertyChanged(tasks, 'tasks');
          }}
          onRoleChange={handleRoleChanged}
        />
      }
    />
  );
};
