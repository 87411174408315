export const convertToMonthDayYear = (dateString: string | undefined): string => {
  // Convert to Date Format: "July 24, 2022"

  if (dateString === undefined) {
    return '';
  } else {
    const date = new Date(dateString);
    const day: string = String(date.getDate());
    const month: string = getMonth(date.getMonth());
    const year: string = date.getFullYear().toString();

    return `${month} ${day}, ${year}`;
  }
};

export const convertMonthYear = (dateString: string | undefined): string => {
  // Convert to Date Format: "July 2022"

  if (dateString === undefined) {
    return '';
  } else {
    const date = new Date(dateString);
    const month: string = getMonth(date.getMonth());
    const year: string = date.getFullYear().toString();

    return `${month} ${year}`;
  }
};

export const convertYearMonthDay = (date: Date | undefined): string => {
  // Convert to Date Format: "2022-11-24"

  if (date === undefined) {
    return '';
  } else {
    const year: string = date.getFullYear().toString();
    const month: string = String(date.getMonth() + 1).padStart(2, '0');
    const day: string = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
};

export const getMonthName = (month: number): string => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return monthNames[month];
};

function getMonth(month: number) {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return monthNames[month];
}
