import { atom } from 'recoil';
import { QueryParams } from '../../data-access';

export type ClientsFilterAtomState = Omit<QueryParams.Clients, 'skip' | 'take'>;

export const clientsFilterAtom = atom<ClientsFilterAtomState>({
  key: 'clientsFilter',
  default: {
    searchString: '',
  },
});
