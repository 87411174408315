import { Flex } from '@fluentui/react-northstar';
import { SearchFilterForm } from '../components/ProjectsSearch/SearchFilterForm';
import { ProjectsTable } from '../components/ProjectsTable/ProjectsTable';
import { BasicLayout } from '../components/Layout/Layout';

export const ProjectsPage = () => {
  return (
    <BasicLayout>
      <Flex column gap="gap.large">
        <SearchFilterForm />
        <ProjectsTable />
      </Flex>
    </BasicLayout>
  );
};
