export const DEFAULT_TAKE = 50;

/**
 * for usage in react-query/useInfiniteQuery with the common pageinated backend query response.
 */
export const getNextPageParam = (
  lastPage: { payload: { totalCount: number } } | undefined,
  pages: any[]
): number | undefined => {
  if (!lastPage) {
    return 0;
  }
  const loaded = pages.length * DEFAULT_TAKE;
  if (loaded >= lastPage.payload.totalCount) {
    return undefined;
  } else {
    return pages.length;
  }
};
