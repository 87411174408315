import { useProjectTemplatesByClient } from '../../../data-access/hooks/queries/use-project-templates-by-client';

export const useProjectTemplatesByClientData = (clientId?: string) => {
  const result = useProjectTemplatesByClient(
    {
      clientId: clientId,
    },
    { keepPreviousData: true }
  );

  return {
    data: result.data ?? [],
  };
};
