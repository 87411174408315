/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CertificateDto } from '../models/CertificateDto';
import type { GuidResult } from '../models/GuidResult';
import type { Result } from '../models/Result';
import type { Result_1 } from '../models/Result_1';
import type { TranslationDto } from '../models/TranslationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CertificateService {
  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static addCertificate({
    version,
    requestBody,
  }: {
    version: string;
    requestBody?: CertificateDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/certificates',
      path: {
        version: version,
      },
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static requestCertificate({
    version,
    requestBody,
  }: {
    version: string;
    requestBody?: CertificateDto;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/certificates/request',
      path: {
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  public static updateCertificate({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: CertificateDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/certificates/{id}',
      path: {
        id,
        version,
      },
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
      mediaType: 'application/json',
    });
  }

  public static deleteCertificate({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/certificates/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }
}
