import { LanguageListDtoResult } from '../models/LanguageListDtoResult';
import { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
import { OpenAPI } from '../core/OpenAPI';

export class LanguageService {
  public static getAllLanguages({
    version,
  }: {
    version: string;
  }): CancelablePromise<LanguageListDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/languages',
      path: {
        version: version,
      },
    });
  }
}
