import {
  Button,
  DownloadIcon,
  Flex,
  gridNestedBehavior,
  PersonIcon,
  Table,
  Text,
} from '@fluentui/react-northstar';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import {
  employeeFilterAtom,
  EmployeeFilterAtomState,
  employeePaginationAtom,
  PaginationAtomState,
  sortAtom,
  SortAtomState,
} from '../../state/atoms';

import { useHistory } from 'react-router-dom';
import { EmployeeOverviewDto } from '../../data-access';
import { CvDownloadDialog } from './CvDownload/CvDownloadDialog';
import { EmployeeAvatar } from './EmployeeAvatar';
import { useEmployeeListData } from './hooks/useEmployeeListData';
import { Pagination } from './Pagination';
import {
  avatarColumnStyle,
  downloadColumnStyle,
  focusColumnStyle,
  modifiedColumnStyle,
  nameColumnStyle,
  overflowPillStyle,
  roleColumnStyle,
  skillsColumnStyle,
  tableRowVariablesOverride,
  tableStyles,
} from './employeeTableStyles';
import { SkillPill } from '../common/SkillPill';
import { Ti8mPill } from '../common';
import { Language } from '../../app/locales';

export const EmployeeTable = ({
  selectedEmployee,
  onSelectedEmployeeChanged,
}: {
  selectedEmployee?: string;
  onSelectedEmployeeChanged?: (id: string) => void;
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.resolvedLanguage as Language;
  const navigation = useHistory();
  const [cvToDownload, setCvToDownload] = useState<EmployeeOverviewDto>();
  const [isCvDownloadDialogOpen, setCvDownloadDialogVisibility] = useState<boolean>(false);
  const [cvLanguage, setCvLanguage] = useState<'en' | 'de'>('en');

  const [paginationData, setPaginationData] =
    useRecoilState<PaginationAtomState>(employeePaginationAtom);
  const resetPagination = useResetRecoilState(employeePaginationAtom);
  const filterData = useRecoilValue<EmployeeFilterAtomState>(employeeFilterAtom);
  const [sortData] = useRecoilState<SortAtomState>(sortAtom);

  const data = useEmployeeListData({
    filteringOptions: filterData,
    sortingOptions: sortData,
    paginationOptions: paginationData,
  });

  // Jump back to page 1 whenever the filter changes
  useEffect(() => resetPagination(), [filterData, resetPagination]);

  useEffect(() => {
    if (
      data.data.length > 0 &&
      (!selectedEmployee || data.data.filter((d) => d.id === selectedEmployee).length === 0)
    ) {
      onSelectedEmployeeChanged?.(data.data[0].id);
    }
  }, [selectedEmployee, data, onSelectedEmployeeChanged]);

  const handleDownloadCvClick = (item: EmployeeOverviewDto, language: typeof cvLanguage) => {
    setCvToDownload(item);
    setCvLanguage(language);
    setCvDownloadDialogVisibility(true);
  };

  const headers = {
    items: [
      {
        key: 'avatarHeader',
        content: <PersonIcon outline />,
        styles: avatarColumnStyle,
      },
      {
        key: 'nameHeader',
        content: <Text weight="semibold" content={t('employee-search.table.headers.name')} />,
        styles: nameColumnStyle,
      },
      {
        key: 'roleHeader',
        content: <Text weight="semibold" content={t('employee-search.table.headers.role')} />,
        styles: roleColumnStyle,
      },
      {
        key: 'focusHeader',
        content: <Text weight="semibold" content={t('employee-search.table.headers.focus')} />,
        styles: focusColumnStyle,
      },
      {
        key: 'skillsHeader',
        content: <Text weight="semibold" content={t('employee-search.table.headers.skills')} />,
        styles: skillsColumnStyle,
      },
      {
        key: 'modifiedAtHeader',
        content: (
          <Text weight="semibold" content={t('employee-search.table.headers.modified-at')} />
        ),
        styles: modifiedColumnStyle,
      },
      {
        key: 'downloadCV',
        content: (
          <Text weight="semibold" content={t('employee-search.table.headers.download-cv')} />
        ),
        styles: downloadColumnStyle,
      },
    ],
  };

  const getSkillOverflow = useCallback((skillCount: number) => {
    const spaceForSkills = 20;
    const skillPillTextSize = 10;
    const numberOfSkillsShown = spaceForSkills / skillPillTextSize;
    return Math.max(skillCount - numberOfSkillsShown, 0);
  }, []);

  return (
    <Flex column gap="gap.small">
      <Table
        styles={tableStyles}
        header={headers}
        accessibility={gridNestedBehavior}
        rows={data.data.map((item) => ({
          key: item.id,
          'aria-selected': item.id === selectedEmployee,
          items: [
            {
              key: `${item.id}_avatar`,
              truncateContent: true,
              content: (
                <EmployeeAvatar
                  firstName={item.firstName}
                  lastName={item.lastName}
                  photo={item.thumbnail}
                  id={item.id}
                  size="small"
                />
              ),
              styles: avatarColumnStyle,
              onClick: (e: MouseEvent) => {
                navigation.push(`/employeeDetail/${item.id}`);
                e.stopPropagation();
              },
            },
            {
              key: `${item.id}_name`,
              truncateContent: true,
              content: `${item.firstName} ${item.lastName}`,
              styles: nameColumnStyle,
              onClick: () => navigation.push(`/employeeDetail/${item.id}`),
            },
            {
              key: `${item.id}_role`,
              truncateContent: true,
              content: item.role,
              styles: roleColumnStyle,
              onClick: () => navigation.push(`/employeeDetail/${item.id}`),
            },
            {
              key: `${item.id}_focus`,
              truncateContent: true,
              content: item.focusTopics.map((focus) => focus.name[language]).join(', '),
              styles: focusColumnStyle,
              onClick: () => navigation.push(`/employeeDetail/${item.id}`),
            },
            {
              key: `${item.id}_skills`,
              truncateContent: true,
              content: (() => {
                const overflow = getSkillOverflow(item.skills.length);
                return (
                  <>
                    {item.skills &&
                      item.skills.slice(0, item.skills.length - overflow).map((skill) => (
                        <SkillPill
                          employeeSkill={skill}
                          maxTextLength={10}
                          appearance="filled"
                          size="small"
                          styles={{
                            marginLeft: '0',
                          }}
                          key={skill.skill.id}
                        />
                      ))}
                    {overflow > 0 && (
                      <Ti8mPill
                        content={`+${overflow}`}
                        maxTextLength={4}
                        size={'smaller'}
                        styles={overflowPillStyle}
                      />
                    )}
                  </>
                );
              })(),
              styles: skillsColumnStyle,
              onClick: () => navigation.push(`/employeeDetail/${item.id}`),
            },
            {
              key: `${item.id}_modified_at`,
              truncateContent: true,
              content: new Date(item.modifiedAt).toLocaleDateString(i18n.language),
              styles: modifiedColumnStyle,
              onClick: () => navigation.push(`/employeeDetail/${item.id}`),
            },
            {
              key: `${item.id}_download_cv`,
              truncateContent: true,
              content: (
                <Flex gap="gap.medium">
                  <Flex vAlign="center">
                    <Button
                      iconOnly
                      primary
                      text
                      icon={<DownloadIcon />}
                      onClick={() => handleDownloadCvClick(item, 'en')}
                      content={
                        <Text
                          content="en"
                          size="small"
                          weight="light"
                          styles={(styles) => ({
                            color: styles.theme.siteVariables.colorScheme['default']['foreground'],
                          })}
                        />
                      }
                    />
                  </Flex>
                  <Flex vAlign="center">
                    <Button
                      iconOnly
                      primary
                      text
                      icon={<DownloadIcon />}
                      onClick={() => handleDownloadCvClick(item, 'de')}
                      content={
                        <Text
                          content="de"
                          size="small"
                          weight="light"
                          styles={(styles) => ({
                            color: styles.theme.siteVariables.colorScheme['default']['foreground'],
                          })}
                        />
                      }
                    />
                  </Flex>
                </Flex>
              ),
              styles: downloadColumnStyle,
            },
          ],
          variables: tableRowVariablesOverride,
        }))}
      />
      <Pagination
        currentPage={paginationData.currentPage}
        pageSize={paginationData.pageSize}
        totalItems={data.totalItems}
        onPageChange={(page) => setPaginationData((prev) => ({ ...prev, currentPage: page }))}
      />
      {isCvDownloadDialogOpen && cvToDownload && (
        <CvDownloadDialog
          isOpen={isCvDownloadDialogOpen}
          setIsOpen={setCvDownloadDialogVisibility}
          language={cvLanguage}
          employee={cvToDownload}
        />
      )}
    </Flex>
  );
};
