import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Input, Text } from '@fluentui/react-northstar';
import { ClientDto } from '../../data-access/open-api/queries/models/ClientDto';
import { useAddClient } from '../../data-access/hooks/commands/use-add-client';
import { useUpdateClient } from '../../data-access/hooks/commands/use-update-client';
import { Ti8mDialog } from '../common/Ti8mDialog';

type ClientNewEditDialogProps = {
  isOpen: boolean;
  setIsOpen: (action: boolean) => void;
  client?: ClientDto;
};

export const ClientNewEditDialog = ({ isOpen, setIsOpen, client }: ClientNewEditDialogProps) => {
  const { t } = useTranslation();

  const { mutate: update } = useUpdateClient();
  const { mutate: add } = useAddClient();

  const [nameGerman, setNameGerman] = useState(client?.name.de ?? '');
  const [nameEnglish, setNameEnglish] = useState(client?.name.en ?? '');

  const someNamesEmpty = useMemo(
    () => [nameGerman, nameEnglish].some((e) => e.length === 0),
    [nameGerman, nameEnglish]
  );

  const namesUntouched = useMemo(
    () => nameGerman === client?.name.de && nameEnglish === client?.name.en,
    [nameGerman, nameEnglish, client]
  );

  const handleConfirm = () => {
    if (client) {
      update({ id: client.id, requestBody: { de: nameGerman, en: nameEnglish } });
    } else {
      add({ requestBody: { name: { de: nameGerman, en: nameEnglish } } });
    }
  };

  return (
    <Ti8mDialog
      open={isOpen}
      header={client ? t('clients.titles.edit') : t('clients.titles.add')}
      cancelButton={{ content: t('shared.buttons.cancel') }}
      onCancel={() => setIsOpen(false)}
      confirmButton={{
        content: client ? t('shared.buttons.update') : t('shared.buttons.add'),
        disabled: namesUntouched || someNamesEmpty,
      }}
      onConfirm={() => {
        handleConfirm();
        setIsOpen(false);
      }}
      content={
        <Flex column gap="gap.medium">
          <Flex column gap="gap.smaller">
            <Text content={t('clients.labels.name.de')} weight="semibold" />
            <Input
              autoFocus
              fluid
              placeholder={t('clients.placeholders.name.de')}
              value={nameGerman}
              onChange={(_, data) => setNameGerman(data?.value ?? '')}
            />
          </Flex>

          <Flex column gap="gap.smaller">
            <Text content={t('clients.labels.name.en')} weight="semibold" />
            <Input
              fluid
              placeholder={t('clients.placeholders.name.en')}
              value={nameEnglish}
              onChange={(_, data) => setNameEnglish(data?.value ?? '')}
            />
          </Flex>
        </Flex>
      }
    />
  );
};
