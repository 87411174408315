import { UInt64NullableResult } from '../core';
import { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { GenericResult } from '../models';
import { SkillGroupDto } from '../models/SkillGroupDto';

export class SkillGroupService {
  public static skillGroups({
    version,
  }: {
    version: string;
  }): CancelablePromise<GenericResult<SkillGroupDto[]>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/skillgroups',
      path: {
        version: version,
      },
    });
  }

  public static getEventPosition({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<UInt64NullableResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/skillgroups/event-position/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }
}
