import {
  Button,
  ChevronStartIcon,
  Flex,
  FlexItem,
  Menu,
  tabListBehavior,
  Text,
  DownloadIcon,
  PersonIcon,
} from '@fluentui/react-northstar';
import { EmployeeMasterDataTab } from '../components/EmployeeMasterData/EmployeeMasterDataTab';
import { EmployeeProjectsTab } from '../components/EmployeeProjects/EmployeeProjectsTab';
import { EmployeeAvatar } from '../components/EmployeeTable/EmployeeAvatar';
import { useEmployee } from '../data-access';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { BasicLayout } from '../components/Layout/Layout';
import { CvDownloadDialog } from '../components/EmployeeTable/CvDownload/CvDownloadDialog';
import { maxProjectsCount } from '../components/EmployeeTable/CvDownload/CvDownloadDialogForm';
import { useAuthInfo } from '../auth/use-authinfo';
import { DiamondIcon, DocumentIcon } from '../icons';
import { EmployeeSkillsTab } from '../components/EmployeeSkills/EmployeeSkillsTab';
import { pages} from '@microsoft/teams-js';
import { runsInTeams } from '../utils';
import { deepLinkRedirectState } from '../state/deepLinkRedirectState';

export const EmployeeDetailPage = () => {
  const { t } = useTranslation();
  const { canEditProfile, isOwnProfile } = useAuthInfo();

  const navigation = useHistory();
  const [employeeTab, setEmployeeTab] = useState<'masterData' | 'skills' | 'projects'>(
    'masterData'
  );
  const [isCvDownloadDialogOpen, setCvDownloadDialogVisibility] = useState(false);
  const [cvLanguage, setCvLanauge] = useState<'en' | 'de'>('en');

  const { id } = useParams<{ id: string }>();
  const employee = useEmployee(id);


  // count all reference projects, to determine if we can add more.
  let referenceProjectCount = 0;
  if (employee.data?.projects) {
    referenceProjectCount += employee.data.projects.filter((x) => x.isReferenceProject).length;
  }
  if (employee.data?.templateBasedProjects) {
    referenceProjectCount += employee.data.templateBasedProjects.filter(
      (x) => x.isReferenceProject
    ).length;
  }

  const maxReferenceProjectsReached = referenceProjectCount >= maxProjectsCount;

  const displayTab = (activeIndex: number) => {
    switch (activeIndex) {
      case 0:
        setEmployeeTab('masterData');
        break;
      case 1:
        setEmployeeTab('skills');
        break;
      case 2:
        setEmployeeTab('projects');
        break;
      default:
        setEmployeeTab('masterData');
        break;
    }
  };

  const isEditable = canEditProfile(employee?.data?.id);
  const showSensitiveData = isOwnProfile(employee?.data?.id);

  const navigateBack = useCallback(() => {
    deepLinkRedirectState.updateShowBackLink(false);
    navigation.push(`/employees`);
  },[navigation]);

  const navigateBackToPlanner = useCallback(async () => {
    const teams  = await runsInTeams();
    if (!teams) {
      alert("Only supported within Teams");
      return;
    }

    const plannerRedirectState = deepLinkRedirectState.getRedirectInfo();

    if (plannerRedirectState.plannerAppId && plannerRedirectState.plannerPageId) {
      pages.navigateToApp({
        appId: plannerRedirectState.plannerAppId,
        pageId: plannerRedirectState.plannerPageId,
        subPageId: plannerRedirectState.plannerSubPageId
      });
    }
  }, []);

  return (
    <BasicLayout>
      <Flex column>
        {isCvDownloadDialogOpen && employee?.data && (
          <CvDownloadDialog
            isOpen={isCvDownloadDialogOpen}
            setIsOpen={setCvDownloadDialogVisibility}
            language={cvLanguage}
            employee={employee?.data}
          />
        )}
        {deepLinkRedirectState.getShowBacklink() && (
          <FlexItem align="start" styles={{ marginBottom: '2rem' }}>
            <Button
              icon={<ChevronStartIcon />}
              iconPosition="before"
              onClick={navigateBackToPlanner}
              text
              content={t('employee-detail.back-to-planner-button-text')}
              secondary
            />
          </FlexItem>
        )}
        <FlexItem align="start" styles={{ marginBottom: '2rem' }}>
          <Button
            icon={<ChevronStartIcon />}
            iconPosition="before"
            text
            content={t('employee-detail.back-button-text')}
            primary
            onClick={navigateBack}
          />
        </FlexItem>
        <Flex column gap="gap.large">
          <Flex gap="gap.medium" vAlign="center">
            <EmployeeAvatar
              firstName={employee.data?.firstName as string}
              lastName={employee.data?.lastName as string}
              id={employee.data?.id as string}
              photo={employee.data?.photo?.photoUri}
              size="largest"
            />
            <FlexItem align="center">
              <Flex column>
                <Text
                  content={`${employee.data?.firstName as string} ${
                    employee.data?.lastName as string
                  }`}
                  size="largest"
                />
                <Text content={employee.data?.role} />
              </Flex>
            </FlexItem>
          </Flex>
          <Flex>
            <Menu
              defaultActiveIndex={0}
              items={[
                {
                  key: 'master data',
                  content: (
                    <Flex gap="gap.small" hAlign="center" styles={{ minWidth: '7rem' }}>
                      <PersonIcon outline styles={{ marginTop: '0.1rem' }} />
                      <Text content={t('employee-detail.tab1')} />
                    </Flex>
                  ),
                },
                {
                  key: 'skills',
                  content: (
                    <Flex gap="gap.small" hAlign="center" styles={{ minWidth: '7rem' }}>
                      <DiamondIcon outline styles={{ marginTop: '0.1rem' }} />
                      <Text content={t('employee-detail.tab2')} />
                    </Flex>
                  ),
                },
                {
                  key: 'projects',
                  content: (
                    <Flex gap="gap.small" hAlign="center" styles={{ minWidth: '7rem' }}>
                      <DocumentIcon outline styles={{ marginTop: '0.1rem' }} />
                      <Text content={t('employee-detail.tab3')} />
                    </Flex>
                  ),
                },
              ]}
              primary
              accessibility={tabListBehavior}
              underlined
              onActiveIndexChange={(e, d) => displayTab(d?.activeIndex as number)}
            />
            <FlexItem push>
              <Flex gap="gap.small">
                <Button
                  icon={<DownloadIcon />}
                  iconPosition="before"
                  primary
                  onClick={() => {
                    setCvLanauge('en');
                    setCvDownloadDialogVisibility(true);
                  }}
                  content={t('employee-detail.button-cv-download-en')}
                />
                <Button
                  icon={<DownloadIcon />}
                  iconPosition="before"
                  primary
                  onClick={() => {
                    setCvLanauge('de');
                    setCvDownloadDialogVisibility(true);
                  }}
                  content={t('employee-detail.button-cv-download-de')}
                />
              </Flex>
            </FlexItem>
          </Flex>
        </Flex>
        {employeeTab === 'masterData' && (
          <EmployeeMasterDataTab
            employee={employee.data}
            isEditable={isEditable}
            showSensitiveData={showSensitiveData}
          />
        )}
        {employeeTab === 'skills' && (
          <EmployeeSkillsTab isEditable={isEditable} employee={employee.data} />
        )}
        {employeeTab === 'projects' && (
          <EmployeeProjectsTab
            isEditable={isEditable}
            employeeId={id}
            projects={employee.data?.projects}
            templateBasedProjects={employee.data?.templateBasedProjects}
            allowReferenceProjectToggle={!maxReferenceProjectsReached}
          />
        )}
      </Flex>
    </BasicLayout>
  );
};
