import {
  Flex,
  List,
  PersonIcon,
  Pill,
  Table,
  Text,
  Tooltip,
  gridNestedBehavior,
} from '@fluentui/react-northstar';
import { ProjectTemplateMemberDto } from '../../data-access/open-api/queries/models/ProjectTemplateMemberDto';
import { Ti8mDialog, Ti8mPill, Ti8mTruncatedList } from '../common';
import { useTranslation } from 'react-i18next';
import { EmployeeAvatar } from '../EmployeeTable/EmployeeAvatar';
import { useHistory } from 'react-router-dom';
import { overflowPillStyle } from '../EmployeeTable/employeeTableStyles';

export interface ProjectEmployeesDialogProps {
  open: boolean;
  onClose: () => void;
  members: ProjectTemplateMemberDto[];
}

export const ProjectEmployeesDialog = ({ members, open, onClose }: ProjectEmployeesDialogProps) => {
  const { t } = useTranslation();
  const navigation = useHistory();

  const headers = {
    items: [
      {
        key: 'avatarHeader',
        content: <PersonIcon outline />,
        styles: {
          maxWidth: '3rem',
          justifyContent: 'center',
        },
      },
      {
        key: 'nameHeader',
        content: <Text weight="semibold" content={t('projects-search.employees.name')} />,
        styles: {
          maxWidth: '30%',
          justifyContent: 'flex-start',
        },
      },
      {
        key: 'skillsHeader',
        content: <Text weight="semibold" content={t('projects-search.employees.skills')} />,
      },
    ],
  };

  return (
    <Ti8mDialog
      open={open}
      onCancel={onClose}
      header={t('projects-search.employees.title')}
      styles={{ width: '900px' }}
      content={
        <Table
          header={headers}
          accessibility={gridNestedBehavior}
          rows={members.map((item) => ({
            key: item.employeeId,
            styles: {
              cursor: 'pointer',
            },
            onClick: () => navigation.push(`/employeeDetail/${item.employeeId}`),
            items: [
              {
                key: `${item.employeeId}_avatar`,
                truncateContent: true,
                styles: {
                  maxWidth: '3rem',
                  justifyContent: 'center',
                },
                content: (
                  <EmployeeAvatar
                    id={item.employeeId}
                    firstName={item.firstName}
                    lastName={item.lastName}
                    photo={item.thumbnail}
                    size="small"
                  />
                ),
              },
              {
                key: `${item.employeeId}_name`,
                truncateContent: true,
                styles: {
                  maxWidth: '30%',
                },
                content: (
                  <Flex column>
                    <Text content={`${item.firstName} ${item.lastName}`} />
                    <Text content={item.role} styles={{ color: 'grey' }} />
                  </Flex>
                ),
              },
              {
                key: `${item.employeeId}_skills`,
                truncateContent: true,
                content: (
                  <Ti8mTruncatedList
                    renderTruncator={({ hiddenItemsCount }) => (
                      <Tooltip
                        content={
                          <List
                            items={item.skills
                              .slice(item.skills.length - hiddenItemsCount)
                              .map((skill) => ({
                                key: skill.id,
                                content: skill.name,
                              }))}
                            styles={{ '> li': { padding: 0 } }}
                          />
                        }
                        trigger={
                          <Pill
                            rectangular
                            content={`+${hiddenItemsCount}`}
                            size="medium"
                            styles={overflowPillStyle}
                          />
                        }
                      />
                    )}
                    styles={{
                      padding: 0,
                      margin: 0,
                      maxHeight: '50px',
                      alignItems: 'center',
                    }}
                  >
                    {item.skills.map((skill) => (
                      <Ti8mPill
                        key={skill.id}
                        content={skill.name}
                        size="medium"
                        appearance="filled"
                        styles={{ minWidth: 'fit-content' }}
                      />
                    ))}
                  </Ti8mTruncatedList>
                ),
              },
            ],
          }))}
        />
      }
    />
  );
};
