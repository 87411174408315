import { Provider } from '@fluentui/react-northstar';
import { FC } from 'react';
import { usePlatformTheme, generateTheme } from '../../app';

export const ColorSchemeProvider: FC = ({ children }) => {
  const colorScheme = usePlatformTheme();

  return (
    <Provider theme={generateTheme(colorScheme)} lang="en-US">
      {children}
    </Provider>
  );
};
