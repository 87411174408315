import { useProjectTemplates } from '../../../data-access';
import { ProjectsFilterAtomState } from '../../../state/atoms';

export interface UseProjectsListDataHookParams {
  filteringOptions?: ProjectsFilterAtomState;
  paginationOptions: { currentPage: number; pageSize: number };
}

export const useProjectsListData = ({
  filteringOptions,
  paginationOptions,
}: UseProjectsListDataHookParams) => {
  const result = useProjectTemplates(
    {
      skip: paginationOptions.currentPage - 1,
      take: paginationOptions.pageSize,
      searchString: filteringOptions?.searchString,
      clientSearchString: filteringOptions?.clientSearchString,
    },
    { keepPreviousData: true }
  );
  return {
    data: result.data?.items ?? [],
    totalItems: result.data?.totalCount ?? 0,
  };
};
