import { DropdownProps, Flex, Text } from '@fluentui/react-northstar';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteSubjectMatterExpertTopics } from '../../../data-access/hooks/queries/use-infinte-subject-matter-expert-topics';
import { SubjectMatterExpertTopicDto } from '../../../data-access/open-api/queries/models/SubjectMatterExpertTopicDto';
import { Ti8mDropdown } from '../../common';

interface AddSubjectMatterExpertDialogFormProps {
  editSubjectMatterExpertTopics: Array<SubjectMatterExpertTopicDto>;
  setEditSubjectMatterExpertTopics: (
    subjectMatterExpertTopic: Array<SubjectMatterExpertTopicDto>
  ) => void;
  setTouched: (touched: boolean) => void;
}

export const AddSubjectMatterExpertDialogForm = ({
  editSubjectMatterExpertTopics,
  setEditSubjectMatterExpertTopics,
  setTouched,
}: AddSubjectMatterExpertDialogFormProps) => {
  const { t } = useTranslation();
  const [topicsSearchString, setTopicsSearchString] = useState('');
  const {
    subjectMatterExpertTopics,
    hasMoreSubjectMatterExpertTopics,
    fetchMoreSubjectMatterExpertTopics,
    isSubjectMatterExpertError,
  } = useInfiniteSubjectMatterExpertTopics(topicsSearchString);

  const onAddTopic = (data: DropdownProps) => {
    const newExpertTopic = (data.value as { key: SubjectMatterExpertTopicDto; header: string }).key;
    setEditSubjectMatterExpertTopics([...editSubjectMatterExpertTopics, newExpertTopic]);
    setTouched(true);
  };

  const handleTopicsSearchQueryChange = useCallback(
    (data: DropdownProps) => {
      if (data.searchQuery !== undefined) {
        setTopicsSearchString(data.searchQuery);
      }
    },
    [setTopicsSearchString]
  );

  return (
    <Flex column gap="gap.small">
      <Text
        content={t(
          'employee-detail.master-data.skills.add-subject-matter-expert-topics-dialog.label'
        )}
        weight="semibold"
      />
      <Ti8mDropdown
        autoFocus
        items={subjectMatterExpertTopics.map((item) => ({
          key: item,
          header: item.name.en,
        }))}
        onScrollLoadMoreItems={fetchMoreSubjectMatterExpertTopics}
        canLoadMoreItems={hasMoreSubjectMatterExpertTopics}
        hasDataError={isSubjectMatterExpertError}
        onChange={onAddTopic}
        search
        onSearchQueryChange={handleTopicsSearchQueryChange}
        placeholder={t(
          'employee-detail.master-data.skills.add-subject-matter-expert-topics-dialog.placeholder'
        )}
      />
    </Flex>
  );
};
