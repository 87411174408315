import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, withApiVersion, WithoutVersion } from '../../helpers';
import { ApiError, ErpProjectTemplateService, GuidResult } from '../../open-api/commands';
import { ProjectTemplateService as QueryProjectTemplateService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const addErpProjectTemplate = withApiVersion(ErpProjectTemplateService.addProjectTemplate);
const getEventPosition = withApiVersion(QueryProjectTemplateService.getEventPosition);

type AddErpProjectTemplateInput = WithoutVersion<
  ExtractFirstArgAsType<typeof ErpProjectTemplateService.addProjectTemplate>
>;

export const useAddErpProjectTemplate = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, AddErpProjectTemplateInput>(addErpProjectTemplate, {
    onSuccess: async ({ payload: id, storePosition }) =>
      waitForEvent({ id, storePosition }, (id) => getEventPosition({ id })).then(() =>
        queryClient.refetchQueries(QueryKeys.ProjectTemplates)
      ),
    meta: {
      type: type,
      requestObject: requestObject,
    },
  });
};
