import { Button, Flex, List, Text, TrashCanIcon } from '@fluentui/react-northstar';
import { EmployeeSkillDto } from '../../../data-access';
import { SkillsDto } from '../../../data-access/open-api/commands';
import { SkillLevelStars } from '../../common/SkillLevelStars';

export type EditSkillsDialogFormProps = {
  updatedSkills: Array<SkillsDto>;
  skillsGroupedBySkillGroupName: [string, EmployeeSkillDto[]][];
  setUpdatedSkills: (editedSkills: Array<SkillsDto>) => void;
  setTouched: (touched: boolean) => void;
};

export const EditSkillsDialogForm = ({
  updatedSkills,
  skillsGroupedBySkillGroupName,
  setUpdatedSkills,
  setTouched,
}: EditSkillsDialogFormProps) => {
  const onChangeSkillLevel = (rating?: number, skillId?: string) => {
    const newSkills = updatedSkills.map((skill) => {
      if (skill.skillId === skillId) {
        return {
          skillId: skill.skillId,
          name: skill.name,
          level: rating ?? 1,
        };
      }
      return {
        skillId: skill.skillId,
        name: skill.name,
        level: skill.level,
      };
    });
    setUpdatedSkills(newSkills);
    setTouched(true);
  };

  const onDeleteSkill = (id: string) => {
    const newSkills = updatedSkills.filter((skill) => skill.skillId !== id);
    setUpdatedSkills(newSkills);
    setTouched(true);
  };

  return (
    <Flex column gap="gap.medium">
      {skillsGroupedBySkillGroupName.map(([groupName, filteredSkills]) => (
        <Flex column key={groupName} gap="gap.small">
          <Text weight="semibold" content={groupName} />

          <List>
            {updatedSkills
              .filter((updSkill) =>
                filteredSkills.find((filtSkill) => filtSkill.skill.id === updSkill.skillId)
              )
              .map((skill, index) => (
                <List.Item
                  styles={{ padding: 0 }}
                  key={`${skill.skillId}_${index}`}
                  index={index}
                  content={
                    <Flex space="between" vAlign="center">
                      <Text content={skill.name} size="medium" />
                      <Flex gap="gap.large" vAlign="center">
                        <SkillLevelStars
                          max={5}
                          defaultRating={skill.level}
                          onChangeRating={(event, rating) =>
                            onChangeSkillLevel(rating, skill.skillId)
                          }
                        />
                        <Button
                          iconOnly
                          circular
                          primary
                          size="small"
                          icon={<TrashCanIcon outline />}
                          onClick={() => onDeleteSkill(skill.skillId)}
                        />
                      </Flex>
                    </Flex>
                  }
                />
              ))}
          </List>
        </Flex>
      ))}
    </Flex>
  );
};
