import { Flex, Text } from '@fluentui/react-northstar';

type AdditionalPersonalInformationProps = {
  label: string;
  data: React.ReactNode;
};

export const AdditionalPersonalInformation = ({
  label,
  data,
}: AdditionalPersonalInformationProps) => {
  return (
    <Flex column gap="gap.small">
      <Text content={label} weight="bold" />
      <Text content={data} />
    </Flex>
  );
};
