/* eslint-disable */
import {
  mergeThemes,
  DialogSlotClassNames,
  teamsV2Theme,
  teamsDarkV2Theme,
} from '@fluentui/react-northstar';
// TODO check if needed or remove
// import {
//   probabilityOfExtensionTableStyles
// } from "../../components/EmployeeTable/EmployeeTableDetailPane/probabilityOfExtensionTableStyles";

export function generateTheme(theme: 'dark' | 'light' = 'light') {
  const lightTheme = {
    siteVariables: {
      colorScheme: {
        default: {
          background1: '#F7F7FB',
        },
        warningColor: '#A4262C',
        highContrastBackground: '#FCFEFF',
      },
      dividerColor: '#E1E1F1',
      indicatorPillColor: '#C8C9E4',
      ratingStarColor: '#6264A7',
      themeType: 'light',
    },
    componentStyles: {
      Alert: {
        root: {
          border: 'none',
          borderRadius: 'none',
        },
      },
      ListItem: {
        root: {
          minHeight: 0,
        },
      },
      Pill: {
        root: {
          borderColor: '#6264A7',
          background: '#FFFFFF',
          marginLeft: '0',
          paddingLeft: '2px',
          paddingRight: '2px',
          '&:hover': {
            backgroundColor: '#FFFFFF',
          },
        },
        optionalBorder: {
          borderColor: '#C8C9E4',
          borderWidth: '1pt',
          borderStyle: 'solid',
        },
      },
      Card: {
        root: {
          border: '1px solid #E1E1F1',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',
          borderRadius: '20px 20px 20px 20px',
          width: '100%',
          minWidth: '30rem',
          padding: '2rem 2rem 1rem 2rem',
          '&:hover': {
            border: '1px solid #E1E1F1',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',
          },
        },
      },
      Button: {
        root: {
          boxShadow: 0,
        },
      },
      Input: {
        root: {
          '& ::placeholder': {
            color: '#605E5C',
          },
          fontWeight: '600',
        },
      },
      Dropdown: {
        root: {
          borderRadius: `2px`,
          width: 'auto',
        },
      },
      Dialog: {
        header: {
          fontSize: '20px',
          fontWeight: 600,
          marginBottom: '2rem',
        },
      },
      FormField: {
        root: {
          '&:last-child': {
            marginTop: 'initial',
          },
        },
      },
      FormLabel: {
        root: {
          fontWeight: '600',
          marginBottom: '8px',
        },
      },
      FormMessage: {
        root: {
          paddingLeft: '0px',
        },
      },
      MenuItem: {
        root: {
          paddingBottom: '0.5rem',
          ':hover': {
            paddingBottom: '0.5rem',
          },
        },
      },
      Menu: {
        root: {
          border: 'none',
        },
      },
      TextArea: {
        root: {
          outline: 'none',
        },
      },
      Table: {
        root: {
          cursor: 'default',
        },
      },
    },
    componentVariables: {
      Card: {
        backgroundColorHover: '#F7F7FB',
      },
    },
  };

  const darkTheme = {
    siteVariables: {
      colorScheme: {
        brand: {
          background: '#9EA2FF',
        },
        default: {
          background1: '#323348',
        },
        highContrastBackground: '#020303',
        warningColor: '#A4262C',
      },
      dividerColor: '#3D3E66',
      indicatorPillColor: '#6264A7',
      ratingStarColor: '#6264A7',
      themeType: 'dark',
    },
    componentStyles: {
      Alert: {
        root: {
          border: 'none',
          borderRadius: 'none',
        },
      },
      ListItem: {
        root: {
          minHeight: 0,
        },
      },
      Pill: {
        root: {
          borderColor: '#9EA2FF',
          background: '#2D2D2D',
          marginLeft: '0',
          paddingLeft: '2px',
          paddingRight: '2px',
          '&:hover': {
            backgroundColor: '#2D2D2D',
          },
        },
        // for now: no need to render the optional border in dark mode
        optionalBorder: {},
      },
      Card: {
        root: {
          border: '1px solid #E1E1F1',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',
          borderRadius: '20px 20px 20px 20px',
          width: '100%',
          minWidth: '30rem',
          padding: '2rem 2rem 1rem 2rem',
          '&:hover': {
            border: '1px solid #E1E1F1',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',
          },
        },
      },
      Button: {
        root: {
          boxShadow: 0,
        },
      },
      Dropdown: {
        root: {
          borderRadius: `2px`,
          width: 'auto',
        },
      },
      Dialog: {
        header: {
          fontSize: '20px',
          fontWeight: 600,
          marginBottom: '2rem',
        },
      },
      FormField: {
        root: {
          '&:last-child': {
            marginTop: 'initial',
          },
        },
      },
      FormLabel: {
        root: {
          fontWeight: '600',
          marginBottom: '8px',
        },
      },
      FormMessage: {
        root: {
          paddingLeft: '0px',
        },
      },
      MenuItem: {
        root: {
          paddingBottom: '0.5rem',
          ':hover': {
            paddingBottom: '0.5rem',
          },
        },
      },
      Input: {
        root: {
          fontWeight: '600',
        },
      },
      Menu: {
        root: {
          border: 'none',
        },
      },
      TextArea: {
        root: {
          outline: 'none',
        },
      },

      Table: {
        root: {
          cursor: 'default',
        },
      },
    },
    componentVariables: {
      Card: {
        backgroundColorHover: '#323348',
      },
    },
  };

  return theme === 'dark'
    ? mergeThemes(teamsDarkV2Theme, darkTheme)
    : mergeThemes(teamsV2Theme, lightTheme);
}
