import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { ClientsFilterAtomState, clientsFilterAtom } from '../../../state/atoms';

const SEARCH_DELAY = 300;

export const useClientsFilter = () => {
  const [filter, setFilter] = useRecoilState(clientsFilterAtom);
  const [searchTerm, setSearchTerm] = useState(filter.searchString);

  // This will prevent searching on every key stroke by adding a time delay
  useEffect(() => {
    // Avoid unnecessary re-renders
    if (searchTerm === filter.searchString) return;

    const timerId = setTimeout(() => {
      setFilter((prev: ClientsFilterAtomState) => ({ ...prev, searchString: searchTerm }));
    }, SEARCH_DELAY);
    return () => clearTimeout(timerId);
  }, [searchTerm, setFilter, filter.searchString]);

  return {
    searchTerm,
    setSearchTerm,
  };
};
