import { CancelablePromise } from '../core/CancelablePromise';
import { DegreeCategoryListDtoResult } from '../models/DegreeCategoryListDtoResult';
import { request as __request } from '../core/request';
import { OpenAPI } from '../core/OpenAPI';

export class DegreeCategoryService {
  public static getAllDegreeCategories({
    version,
  }: {
    version: string;
  }): CancelablePromise<DegreeCategoryListDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/degreecategories',
      path: {
        version: version,
      },
    });
  }
}
