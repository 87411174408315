import { Card, CardBody, Divider, Flex } from '@fluentui/react-northstar';
import { useDeleteEmployeeEducation, EmployeeDto } from '../../../../data-access';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeEducation } from './EmployeeEducation';
import { EmployeeEducationDialog } from './Dialog/EducationDialog';
import { Ti8mCardHeader } from '../../../common/Ti8mCardHeader';

type EmployeeEducationCardProps = {
  employee: EmployeeDto;
  isEditable: boolean;
};

export const EmployeeEducationCard = ({ employee, isEditable }: EmployeeEducationCardProps) => {
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const { mutate: deleteEmployeeEducation } = useDeleteEmployeeEducation();
  const [isEducationCardUnfold, setIsEducationCardUnfold] = useState<boolean>(true);

  const onConfirmDeleteEducation = (educationId: string) => {
    deleteEmployeeEducation({ id: employee.id as string, educationId: educationId });
  };

  return (
    <Card>
      <Ti8mCardHeader
        cardHeaderText={t('employee-detail.master-data.education.header')}
        isEditable={isEditable}
        isCardUnfold={isEducationCardUnfold}
        setCardUnfold={setIsEducationCardUnfold}
        setDialogVisibility={setDialogOpen}
      />
      <EmployeeEducationDialog
        key={`employee-education-dialog-add_${isDialogOpen}`}
        isDialogOpen={isDialogOpen}
        isEdit={false}
        employeeId={employee.id}
        setDialogOpen={setDialogOpen}
      />
      {isEducationCardUnfold && (
        <CardBody>
          <Flex column gap="gap.medium">
            {employee.educations.map((item, i) => (
              <Flex column gap="gap.small" key={item.id}>
                {i !== 0 && <Divider />}
                <EmployeeEducation
                  employeeEducationDto={item}
                  employeeId={employee.id}
                  isEditable={isEditable}
                  onDelete={onConfirmDeleteEducation}
                />
              </Flex>
            ))}
          </Flex>
        </CardBody>
      )}
    </Card>
  );
};
