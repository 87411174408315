import { useQuery } from 'react-query';
import { env } from '../../../utils/env';
import { QueryKeys } from '../../query-keys';
import type * as QueryParams from './query-params';
import { ReleaseNotesService } from '../../open-api/queries/services/ReleaseNotesService';
import { ReleaseNotesDto } from '../../open-api/queries/models/ReleaseNotesDto';

const getReleaseNotes = (input: QueryParams.ReleaseNotes) =>
  ReleaseNotesService.releaseNotes({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useReleaseNotes = (
  lastVersion: number,
  onSuccess?: (data: ReleaseNotesDto[]) => void
) =>
  useQuery(
    QueryKeys.ReleaseNotes,
    ({ queryKey: _ }) => getReleaseNotes({ lastVersion: lastVersion }),
    {
      select: (data) =>
        data.payload.map((note) => {
          return {
            ...note,
            releaseDate: new Date(note.releaseDate),
          };
        }),
      onSuccess: onSuccess,
    }
  );
