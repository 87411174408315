import { Table, Text, gridNestedBehavior } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { SkillDto, SkillGroupDto, useSkillGroups } from '../../data-access';
import { ActionButtons } from '../components/ActionButtons';
import { baseTableStyles, editColumnStyles } from '../helpers/styles';

type SkillsMasterDataTableProps = {
  items: SkillDto[];
  onEditItem: (value: SkillDto) => void;
  onDeleteItem: (value: SkillDto) => void;
};

const getSkillGroupById = (items: SkillGroupDto[], id: string) =>
  items.find((item) => item.id === id);

export const SkillsMasterDataTable = ({
  items,
  onEditItem,
  onDeleteItem,
}: SkillsMasterDataTableProps) => {
  const { data: skillGroupItems = [] } = useSkillGroups();
  const { t } = useTranslation();

  const headers = {
    items: [
      {
        key: 'headerName',
        content: <Text weight="semibold" content={t('admin.skills.name')} />,
      },
      {
        key: 'headerDescription',
        content: <Text weight="semibold" content={t('admin.skills.description')} />,
        styles: { minWidth: '70%' },
      },
      {
        key: 'headerSkillGroup',
        content: <Text weight="semibold" content={t('admin.skills.group')} />,
      },
      {
        key: 'headerAction',
        content: '',
        styles: editColumnStyles,
      },
    ],
  };

  return (
    <Table
      header={headers}
      styles={baseTableStyles}
      accessibility={gridNestedBehavior}
      rows={items.map((item) => ({
        key: `row-${item.id}`,
        items: [
          {
            key: `${item.id}_name`,
            truncateContent: true,
            content: item.name,
          },
          {
            key: `${item.id}_description`,
            truncateContent: true,
            content: item.description,
            styles: {
              width: '80ch',
              minWidth: '70%',
            },
          },
          {
            key: `${item.id}_skill_group`,
            truncateContent: true,
            content: getSkillGroupById(skillGroupItems, item.skillGroupId)?.name.en,
          },
          {
            key: `${item.id}_edit_action`,
            truncateContent: true,
            content: (
              <ActionButtons
                item={item}
                itemName={item.name}
                onEditItem={onEditItem}
                onDeleteItem={onDeleteItem}
              />
            ),
            styles: editColumnStyles,
          },
        ],
      }))}
    />
  );
};
