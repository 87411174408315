import {
  useAddEmployeeEducation,
  useChangeEmployeeEducation,
  useUploadEmployeeDiploma,
  EmployeeEducationDto as EmployeeEducationQueryDto,
} from '../../../../../data-access';
import { EmployeeEducationDto } from '../../../../../data-access/open-api/commands';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EducationDialogForm } from './EducationDialogForm';
import { useForm } from 'react-hook-form';
import { Ti8mDialog } from '../../../../common/Ti8mDialog';

const defaultCommandEmployeeEducation: EmployeeEducationDto = {
  degreeId: '',
  degreeCategoryId: '',
  institution: { en: '', de: '' },
  fieldOfStudy: { en: '', de: '' },
  from: '',
  to: '',
  diplomaFileName: null,
  diplomaOriginalFileName: null,
};

type EmployeeEducationDialogProps = {
  isDialogOpen: boolean;
  isEdit: boolean;
  employeeId: string;
  employeeEducationDto?: EmployeeEducationQueryDto;
  setDialogOpen: (value: boolean) => void;
};

export const EmployeeEducationDialog = ({
  isDialogOpen,
  isEdit,
  employeeId,
  employeeEducationDto = undefined,
  setDialogOpen,
}: EmployeeEducationDialogProps) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isDirty },
    setValue,
    getValues,
    trigger,
  } = useForm<EmployeeEducationDto>({
    values: employeeEducationDto
      ? ({
          id: employeeEducationDto.id,
          degreeId: employeeEducationDto.degree.id,
          degreeCategoryId: employeeEducationDto.degreeCategory.id,
          institution: employeeEducationDto.institution,
          fieldOfStudy: employeeEducationDto.fieldOfStudy,
          from: employeeEducationDto.from,
          to: employeeEducationDto.to,
          diplomaFileName: employeeEducationDto.diploma?.name ?? null,
          diplomaOriginalFileName: employeeEducationDto.diploma?.displayName ?? null,
        } as EmployeeEducationDto)
      : defaultCommandEmployeeEducation,
  });
  const [educationDiploma, setEducationDiploma] = useState<File | undefined>();

  const { mutate: addEmployeeEducation } = useAddEmployeeEducation();

  const { mutate: changeEmployeeEducation } = useChangeEmployeeEducation();

  const { mutateAsync: uploadEmployeeDiploma } = useUploadEmployeeDiploma();

  const onSubmit = useCallback(
    async (data: EmployeeEducationDto) => {
      let backendDiplomaFileName = '';
      if (educationDiploma) {
        const result = await uploadEmployeeDiploma({
          employeeId: employeeId ?? '',
          formData: {
            content: educationDiploma as Blob,
            contentType: educationDiploma?.name.split('.')[1],
          },
        });
        backendDiplomaFileName = result?.payload ?? '';
      }
      if (isEdit) {
        changeEmployeeEducation({
          id: employeeId,
          requestBody: {
            ...data,
            diplomaFileName: backendDiplomaFileName || data.diplomaFileName,
            diplomaOriginalFileName: educationDiploma
              ? educationDiploma.name
              : data.diplomaOriginalFileName,
          },
        });
      } else {
        addEmployeeEducation({
          id: employeeId,
          requestBody: {
            ...data,
            diplomaFileName: backendDiplomaFileName,
            diplomaOriginalFileName: educationDiploma?.name || null,
          },
        });
      }
    },
    [
      educationDiploma,
      employeeId,
      isEdit,
      uploadEmployeeDiploma,
      addEmployeeEducation,
      changeEmployeeEducation,
    ]
  );

  const handleConfirm = useCallback(async () => {
    if (await trigger()) {
      onSubmit(getValues());
      setDialogOpen(false);
    }
  }, [onSubmit, getValues, trigger, setDialogOpen]);

  const handleDocumentUploadPreparation = useCallback(
    (file: File | undefined) => {
      setEducationDiploma(file);
    },
    [setEducationDiploma]
  );

  return (
    <Ti8mDialog
      open={isDialogOpen}
      header={
        isEdit
          ? t('employee-detail.master-data.education.add-education-dialog.header-edit')
          : t('employee-detail.master-data.education.add-education-dialog.header')
      }
      cancelButton={{
        content: t('employee-detail.master-data.education.add-education-dialog.button-cancel'),
      }}
      onCancel={() => {
        setDialogOpen(false);
      }}
      confirmButton={{
        content: isEdit
          ? t('employee-detail.master-data.education.add-education-dialog.button-update')
          : t('employee-detail.master-data.education.add-education-dialog.button-add'),
        disabled: !isDirty,
      }}
      onConfirm={() => {
        handleConfirm();
      }}
      content={
        <EducationDialogForm
          errors={errors}
          defaultValues={employeeEducationDto}
          register={register}
          setValue={setValue}
          onDocumentUploadPreparation={handleDocumentUploadPreparation}
        />
      }
    />
  );
};
