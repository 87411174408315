/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ErpProjectTemplateDto } from '../models/ErpProjectTemplateDto';
import type { GuidResult } from '../models/GuidResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ErpProjectTemplateService {
  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static addProjectTemplate({
    version,
    requestBody,
  }: {
    version: string;
    requestBody?: ErpProjectTemplateDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/erp-project-templates',
      path: {
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
