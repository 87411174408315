import { Checkbox, Flex, Text } from '@fluentui/react-northstar';
import { Ti8mDropdown, Ti8mDropdownItem } from '../../../../common';
import { GenderType, NationalityDto, useNationalities } from '../../../../../data-access';
import { useTranslation } from 'react-i18next';
import { EmployeePersonalDataDto } from '../../../../../data-access/open-api/commands';

type EditAdditionalPersonalDataDialogContentProps = {
  value: EmployeePersonalDataDto;
  onChange: (value: EmployeePersonalDataDto) => void;
};

export const EditAdditionalPersonalDataDialogContent = ({
  value,
  onChange,
}: EditAdditionalPersonalDataDialogContentProps) => {
  const { t } = useTranslation();
  const { data: nationalities, isError } = useNationalities();

  return (
    <Flex column gap="gap.medium">
      <Flex column gap="gap.small">
        <Text
          content={t('employee-detail.master-data.additional-personal-data.content.hide-address')}
          weight="bold"
        />
        <Checkbox
          toggle
          checked={value.hideAddress}
          onChange={(_, data) => onChange({ ...value, hideAddress: !!data?.checked })}
        />
      </Flex>
      <Flex column gap="gap.small">
        <Text
          content={t('employee-detail.master-data.additional-personal-data.content.nationalities')}
          weight="bold"
        />
        <Ti8mDropdown
          autoFocus
          items={
            nationalities?.map<Ti8mDropdownItem<NationalityDto>>((n) => ({
              key: n.id,
              header: n.name.en,
              value: n,
            })) ?? []
          }
          hasDataError={isError}
          multiple
          search
          placeholder={t(
            'employee-detail.master-data.additional-personal-data.edit-dialog.dropdown-placeholder'
          )}
          value={nationalities
            ?.filter((n) => value.nationalities.includes(n.id))
            .map<Ti8mDropdownItem<NationalityDto>>((n) => ({
              key: n.id,
              header: n.name.en,
              value: n,
            }))}
          onChange={(data) =>
            onChange({
              ...value,
              nationalities: (data.value as Ti8mDropdownItem<NationalityDto>[]).map(
                (n) => n.value.id
              ),
            })
          }
          position="below"
        />
      </Flex>
      <Flex column gap="gap.small">
        <Text
          content={t('employee-detail.master-data.additional-personal-data.content.gender')}
          weight="bold"
        />

        <Ti8mDropdown
          items={Object.values(GenderType)
            .filter((d): d is number => typeof d === 'number')
            .map<Ti8mDropdownItem<number>>((g) => ({
              key: g,
              header: t(`employee-detail.master-data.additional-personal-data.value.gender.${g}`),
              value: g,
            }))}
          placeholder={t(
            'employee-detail.master-data.additional-personal-data.edit-dialog.dropdown-placeholder'
          )}
          value={{
            key: value.gender,
            header: t(
              `employee-detail.master-data.additional-personal-data.value.gender.${value.gender}`
            ),
            value: value.gender,
          }}
          onChange={(data) =>
            onChange({
              ...value,
              gender: (data.value as Ti8mDropdownItem<number>).value,
            })
          }
          position="below"
        />
      </Flex>
    </Flex>
  );
};
