import { Card, CardBody } from '@fluentui/react-northstar';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeProfileText } from './EmployeeProfileText';
import { EmployeeProfileTextDialogContent } from './EmployeeProfileTextDialogContent';
import { TranslationDto, EmployeeDto, useEditEmployeeProfileText } from '../../../../data-access';
import { Ti8mDialog } from '../../../common/Ti8mDialog';
import { Ti8mCardHeader } from '../../../common/Ti8mCardHeader';

type EmployeeProfileTextCardProps = {
  employee: EmployeeDto;
  isEditable: boolean;
};

export const EmployeeProfileTextCard = ({ employee, isEditable }: EmployeeProfileTextCardProps) => {
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isTouched, setTouched] = useState<boolean>(false);

  const [profileText, setProfileText] = useState<TranslationDto>(
    employee.profileText ?? { en: '', de: '' }
  );

  const [isProfileTextCardUnfold, setIsProfileTextCardUnfold] = useState<boolean>(true);

  const { mutate: editEmployeeProfileText } = useEditEmployeeProfileText();

  const handleProfileTextChanged = useCallback((data: TranslationDto) => {
    setProfileText(data);
    setTouched(true);
  }, []);

  const handleConfirm = useCallback(() => {
    editEmployeeProfileText({ employeeId: employee.id, requestBody: profileText });
  }, [editEmployeeProfileText, employee.id, profileText]);

  return (
    <Card>
      <Ti8mCardHeader
        cardHeaderText={t('employee-detail.master-data.profile-text.header')}
        isEditable={isEditable}
        isCardUnfold={isProfileTextCardUnfold}
        setCardUnfold={setIsProfileTextCardUnfold}
        setDialogVisibility={setDialogOpen}
      />
      <Ti8mDialog
        open={isDialogOpen}
        header={t('employee-detail.master-data.profile-text.edit-dialog.header')}
        cancelButton={{
          content: t('employee-detail.master-data.profile-text.edit-dialog.button-cancel'),
        }}
        onCancel={() => {
          setProfileText(employee.profileText ?? { en: '', de: '' });
          setDialogOpen(false);
          setTouched(false);
        }}
        confirmButton={{
          content: t('employee-detail.master-data.profile-text.edit-dialog.button-confirm'),
          disabled: !isTouched,
        }}
        onConfirm={() => {
          handleConfirm();
          setDialogOpen(false);
          setTouched(false);
        }}
        content={
          <EmployeeProfileTextDialogContent
            profileText={profileText}
            onProfileTextChanged={handleProfileTextChanged}
          />
        }
      />
      {isProfileTextCardUnfold && (
        <CardBody>
          <EmployeeProfileText
            textEnglish={employee.profileText?.en || ''}
            textGerman={employee.profileText?.de || ''}
          />
        </CardBody>
      )}
    </Card>
  );
};
