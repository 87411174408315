import { Flex, Text, Tooltip } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';

export type LanguageLevelTooltipProps = {
  level: 'A1' | 'A2' | 'B1' | 'B2' | 'C1' | 'C2';
};

export const LanguageLevelTooltip = ({ level }: LanguageLevelTooltipProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      pointing
      trigger={<Text>{level}</Text>}
      content={
        <Flex column gap="gap.small">
          <Text weight="semibold">
            {t(`employee-detail.master-data.skills.levels.${level}.title`)}
          </Text>
          <Text>{t(`employee-detail.master-data.skills.levels.${level}.description`)}</Text>
        </Flex>
      }
    />
  );
};
