import { Loader } from '@fluentui/react-northstar';
import { authentication } from '@microsoft/teams-js';
import jwt from 'jwt-decode';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { authAtom } from '../../state/atoms/authAtom';
import { OpenAPI as Commands } from '../open-api/commands/core/OpenAPI';
import { OpenAPI as Queries } from '../open-api/queries/core/OpenAPI';

export const TeamsTokenProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const [authInfo, setAuthInfo] = useRecoilState(authAtom);

  useEffect(() => {
    const tryTeamsAuthentication = async () => {
      const authToken = await authentication.getAuthToken({
        silent: true,
      });

      if (authToken) {
        Queries.TOKEN = authToken;
        Commands.TOKEN = authToken;

        const { oid = '', roles = [] } = jwt<{ oid?: string; roles?: Array<string> }>(authToken);

        setAuthInfo({
          runsInTeams: true,
          token: authToken,
          accountId: oid,
          userRoles: roles,
        });
      }
    };

    tryTeamsAuthentication();
  }, [setAuthInfo]);

  return authInfo.token ? (
    <>{children}</>
  ) : (
    <Loader style={{ height: '100%' }} label={t('auth-in-progress')} />
  );
};
