import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { FocusTopicService } from '../../open-api/queries/services/FocusTopicService';
import { QueryKeys } from '../../query-keys';
import { withApiVersion, DEFAULT_TAKE, getNextPageParam, unpack } from '../../helpers';

const getFocusTopics = (context: QueryFunctionContext) => {
  const withVersion = withApiVersion(FocusTopicService.focusTopics);
  return withVersion({
    searchString: `${context.queryKey[1]}`,
    skip: context.pageParam,
    take: DEFAULT_TAKE,
  });
};

export const useInfiniteFocusTopics = (searchString?: string) => {
  const { fetchNextPage, hasNextPage, data, isError } = useInfiniteQuery({
    queryKey: [QueryKeys.FocusTopics, searchString],
    queryFn: getFocusTopics,
    getNextPageParam: getNextPageParam,
  });

  return {
    focusTopics: unpack(data),
    hasMoreFocusTopics: hasNextPage,
    fetchMoreFocusTopics: fetchNextPage,
    isFocusTopicsError: isError,
  };
};
