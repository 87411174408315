export enum QueryKeys {
  Certificates = 'Certificates',
  Employees = 'Employees',
  EmployeeDetail = 'EmployeeDetail',
  EmployeeOverview = 'EmployeeOverview',
  EmployeeProjects = 'EmployeeProjects',
  Degrees = 'Degrees',
  DegreeCategories = 'DegreeCategories',
  Projects = 'Projects',
  ProjectTemplates = 'ProjectTemplates',
  Clients = 'Clients',
  Sectors = 'Sectors',
  ResourceRequest = 'ResourceRequest',
  ResourceRequestDetailView = 'ResourceRequestDetailView',
  ResourceRequestOverview = 'ResourceRequestOverview',
  Skills = 'Skills',
  SkillGroups = 'SkillGroups',
  Roles = 'Roles',
  Departments = 'Departments',
  Nationalities = 'Nationalities',
  Countries = 'Countries',
  Languages = 'Languages',
  Origins = 'Origins',
  ReleaseNotes = 'ReleaseNotes',
  FocusTopics = 'FocusTopics',
  SubjectMatterExpertTopics = 'SubjectMatterExpertTopics',
}
