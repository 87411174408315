interface ILocation {
  name: string;
  origins: {
    lat: number;
    lng: number;
  };
}

export const timLocations: ILocation[] = [
  {
    name: 'Zürich',
    origins: {
      lat: 47.3846964,
      lng: 8.4919515,
    },
  },
  {
    name: 'Singapore',
    origins: {
      lat: 1.28811,
      lng: 103.8414,
    },
  },
  {
    name: 'Frankfurt',
    origins: {
      lat: 50.10084,
      lng: 8.66936,
    },
  },
  {
    name: 'Düsseldorf',
    origins: {
      lat: 51.21439,
      lng: 6.74607,
    },
  },
  {
    name: 'Bern',
    origins: {
      lat: 46.94094,
      lng: 7.43536,
    },
  },
  {
    name: 'Basel',
    origins: {
      lat: 47.5503669,
      lng: 7.5869402,
    },
  },
];
