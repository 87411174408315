import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Ti8mDialog } from '../../../../common/Ti8mDialog';
import { NationalSecurityDialogForm } from './EditNationalSecurityDialogForm';
import {
  NationalSecurityDto,
  useChangeNationalSecurityInfo,
  useUploadEmployeeNationalSecurityCertificate,
} from '../../../../../data-access';
import { useState } from 'react';

const defaultCommandEmployeeNationalSecurity: NationalSecurityDto = {
  interestForNationalSecurityProjects: false,
  dutyFulfilled: false,
  selectedForCyberBasicMilitaryTraining: false,
  degreedInCyberBasicMilitaryTraining: false,
  swissCivilProtectionRank: null,
  swissMilitaryRank: null,
  personalSecurityCheckLevel: null,
  personalSecurityCheckExpiryDate: null,
  personalSecurityCheckCertificateFileName: null,
  personalSecurityCheckOriginalFileName: null,
};

interface EmployeeNationalSecurityDialogProps {
  isDialogOpen: boolean;
  employeeId: string;
  data: NationalSecurityDto | undefined;
  setDialogOpen: (value: boolean) => void;
}

export const EmployeeNationalSecurityDialog = ({
  isDialogOpen,
  employeeId,
  data,
  setDialogOpen,
}: EmployeeNationalSecurityDialogProps) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isDirty },
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm<NationalSecurityDto>({
    values: data ?? { ...defaultCommandEmployeeNationalSecurity },
  });
  const [certificateFile, setCertificateFile] = useState<File | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { mutateAsync: uploadEmployeeNationalSecurityCertificate } =
    useUploadEmployeeNationalSecurityCertificate();

  const { mutateAsync: changeNationalSecurityInfo } = useChangeNationalSecurityInfo();

  return (
    <Ti8mDialog
      styles={{
        '& .ui-dialog__content': {
          overflowY: 'auto',
        },
      }}
      open={isDialogOpen}
      header={t('employee-detail.master-data.national-security.edit-dialog.header')}
      cancelButton={{
        content: t('employee-detail.master-data.national-security.edit-dialog.button-cancel'),
      }}
      onCancel={() => {
        setDialogOpen(false);
      }}
      confirmButton={{
        content: t('employee-detail.master-data.national-security.edit-dialog.button-update'),
        disabled: !isDirty || isSubmitting,
        onClick: async () => {
          if (!(await trigger())) return;
          setIsSubmitting(true);

          let nationalSecurityInfo = getValues();

          try {
            if (certificateFile) {
              const { payload: fileId } = await uploadEmployeeNationalSecurityCertificate({
                employeeId: employeeId,
                formData: {
                  content: certificateFile,
                  contentType: certificateFile.type,
                },
              });

              nationalSecurityInfo = {
                ...nationalSecurityInfo,
                personalSecurityCheckOriginalFileName: certificateFile.name,
                personalSecurityCheckCertificateFileName: fileId,
              };
            }

            await changeNationalSecurityInfo({
              employeeId,
              requestBody: nationalSecurityInfo,
            });
            setDialogOpen(false);
          } catch (error) {
            // error message is sent to display elsewhere
          } finally {
            setIsSubmitting(false);
          }
        },
        loading: isSubmitting,
      }}
      content={
        <NationalSecurityDialogForm
          errors={errors}
          setValue={setValue}
          watch={watch}
          register={register}
          certificateFile={certificateFile}
          setCertificateFile={setCertificateFile}
        />
      }
      resetOverflow
    />
  );
};
