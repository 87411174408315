import { Alert, Button, Flex, Grid, SpotlightIcon } from '@fluentui/react-northstar';
import { convertToMonthDayYear } from '../../../../utils';
import { NationalSecurityInformation } from './NationalSecurityInformation';
import { useTranslation } from 'react-i18next';
import {
  ApiError,
  NationalSecurityDto,
  useDownloadNationalSecurityInfoCertificate,
} from '../../../../data-access';
import { downloadFile } from '../../../common';

interface EmployeeNationalSecurityProps {
  employeeId: string;
  data: NationalSecurityDto;
}

export const EmployeeNationalSecurity = ({ employeeId, data }: EmployeeNationalSecurityProps) => {
  const { t } = useTranslation();
  const downloadNationalSecurityInfoCertificate =
    useDownloadNationalSecurityInfoCertificate(employeeId);

  const handleCertificateDownload = async (id: string | null) => {
    if (!id) return;
    try {
      const { payload: url } = await downloadNationalSecurityInfoCertificate();

      downloadFile(url);
    } catch (error) {
      if (error instanceof ApiError) {
        return (
          <Alert
            content={error.body?.reason ?? error.message}
            dismissible
            dismissAction={{
              'aria-label': 'close',
            }}
            variables={{
              urgent: true,
            }}
          />
        );
      }
    }
  };

  return (
    <Flex column gap="gap.large">
      <Grid columns="repeat(4,1fr)">
        <NationalSecurityInformation
          label={t('employee-detail.master-data.national-security.content.interest-in-projects')}
          data={t(`boolean.${data?.interestForNationalSecurityProjects}`)}
        />
        <NationalSecurityInformation
          label={t('employee-detail.master-data.national-security.content.swiss-military-rank')}
          data={
            data?.swissMilitaryRank
              ? t(
                  `employee-detail.master-data.national-security.value.swiss-military-rank.${data.swissMilitaryRank}`
                )
              : '—'
          }
        />
        <NationalSecurityInformation
          label={t('employee-detail.master-data.national-security.content.cyber-basic-training')}
          data={
            data?.degreedInCyberBasicMilitaryTraining
              ? t(
                  'employee-detail.master-data.national-security.content.cyber-basic-training-degreed'
                )
              : data?.selectedForCyberBasicMilitaryTraining
              ? t(
                  'employee-detail.master-data.national-security.content.cyber-basic-training-selected'
                )
              : '—'
          }
        />
        <NationalSecurityInformation
          label={t(
            'employee-detail.master-data.national-security.content.swiss-civil-protetion-rank'
          )}
          data={
            data?.swissCivilProtectionRank
              ? t(
                  `employee-detail.master-data.national-security.value.swiss-civil-protection-rank.${data.swissCivilProtectionRank}`
                )
              : '—'
          }
        />
      </Grid>
      <Grid columns="repeat(4,1fr)">
        <NationalSecurityInformation
          label={t('employee-detail.master-data.national-security.content.duty-fulfilled')}
          data={t(`boolean.${data?.dutyFulfilled}`)}
        />
        <NationalSecurityInformation
          label={t(
            'employee-detail.master-data.national-security.content.personal-security-check-level'
          )}
          data={
            data?.personalSecurityCheckLevel
              ? t(
                  `employee-detail.master-data.national-security.value.personal-security-check-level.${data.personalSecurityCheckLevel}`
                )
              : '—'
          }
        />
        <NationalSecurityInformation
          label={t(
            'employee-detail.master-data.national-security.content.personal-security-check-expiry-date'
          )}
          data={
            data?.personalSecurityCheckExpiryDate
              ? convertToMonthDayYear(data.personalSecurityCheckExpiryDate)
              : '—'
          }
        />
        <NationalSecurityInformation
          label={t(
            'employee-detail.master-data.national-security.content.personal-security-check-certificate'
          )}
          data={
            data?.personalSecurityCheckOriginalFileName ? (
              <Button
                content={data?.personalSecurityCheckOriginalFileName}
                icon={<SpotlightIcon />}
                text
                primary
                styles={{
                  textDecoration: 'underline',
                  justifyContent: 'start',
                  height: 'fit-content',
                  padding: '0',
                  border: 'none',
                  userSelect: 'text',
                }}
                onClick={() =>
                  handleCertificateDownload(data.personalSecurityCheckCertificateFileName)
                }
              />
            ) : (
              '—'
            )
          }
        />
      </Grid>
    </Flex>
  );
};
