import { Checkbox, DropdownProps, Flex, Grid, Input, Text } from '@fluentui/react-northstar';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from '../../../app/locales';
import { TemplateBasedProjectDto, TranslationDto, useInfiniteClients } from '../../../data-access';
import { Ti8mDatepicker, Ti8mDropdown } from '../../common';
import { useInfiniteSkills } from '../../../data-access/hooks/queries/use-infinite-skills';
import { useProjectTemplatesByClientData } from '../hooks/useProjectsByClientId';

export const Ti8mProjectForm = ({
  project,
  onProjectChange,
  onIsUnderNdaChange,

  onStartChange,
  onEndChange,
  onSkillsChange,
  onTasksChange,
  onRoleChange,
}: {
  project?: TemplateBasedProjectDto;
  onProjectChange: (value: string) => void;
  onIsUnderNdaChange: (value: boolean) => void;
  onStartChange: (value: Date | null) => void;
  onEndChange: (value: Date | null) => void;
  onSkillsChange: (value: Array<string>) => void;
  onTasksChange: (value: Array<TranslationDto>) => void;
  onRoleChange: (value: string, property: keyof TranslationDto) => void;
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.resolvedLanguage as Language;
  const [tasks, setTasks] = useState(
    Array.from(Array(6), (_, k) => Object.assign({}, project?.tasks[k] ?? { de: '', en: '' }))
  );
  const [tasksCharsCount, setTasksCharsCount] = useState(
    project?.tasks
      .map((val) => ({
        en: val.en.length,
        de: val.de.length,
      }))
      .reduce((sum, val) => ({
        en: sum.en + val.en,
        de: sum.de + val.de,
      })) ?? { de: 0, en: 0 }
  );
  const [selectedClientId, setSelectedClientId] = useState(
    project?.projectTemplate.client.id ?? ''
  );
  // this will be set, when the user selects a different project; is information is used only for the isUnderNDA toggle.
  const [changedProjectTemplateId, setChangedProjectTemplateId] = useState<string | undefined>(
    undefined
  );
  const [skillsCount, setSkillsCount] = useState(project?.skills.length ?? 0);
  const [clientSearchString, setClientSearchString] = useState('');
  const [skillSearchString, setSkillSearchString] = useState('');
  const { clients, fetchMoreClients, hasMoreClients, isClientsError } =
    useInfiniteClients(clientSearchString);
  const { data: projects } = useProjectTemplatesByClientData(selectedClientId);

  const { skills, hasMoreSkills, fetchMoreSkills, isSkillsError } =
    useInfiniteSkills(skillSearchString);

  const maxTaskLength = 350;
  const maxSkillCount = 15;

  // disable the isUnderNDA toggle, if we have a Project(Template), that already has isUnderNDA=true
  // (is messy, because we also want to do that, when newly choosing a project)
  let isNdaToggleDisabled = false;
  if (projects.length > 0 && changedProjectTemplateId) {
    const currentProject = projects.find((x) => x.id === changedProjectTemplateId);
    if (currentProject?.isUnderNDA) {
      isNdaToggleDisabled = true;
    }
  } else if (project?.projectTemplate?.isUnderNDA) {
    isNdaToggleDisabled = true;
  }

  const handleClientSearchQueryChanged = useCallback(
    (data: DropdownProps) => {
      if (data.searchQuery !== undefined) {
        setClientSearchString(data.searchQuery);
      }
    },
    [setClientSearchString]
  );

  const handleSkillSearchQueryChanged = useCallback(
    (data: DropdownProps) => {
      if (data.searchQuery !== undefined) {
        setSkillSearchString(data.searchQuery);
      }
    },
    [setSkillSearchString]
  );

  const getDateDefaultValue = (data?: string | null) => {
    if (data && data !== '') {
      return new Date(data);
    }
    return undefined;
  };

  const getSkillsDisabled = useCallback(() => {
    return skillsCount >= maxSkillCount;
  }, [skillsCount]);

  const handleSelectedClientChanged = useCallback(
    (value: { key: string; header: string; object: TranslationDto }) => {
      if (value) {
        setSelectedClientId(value.key);
      } else {
        setSelectedClientId('');
      }
      onProjectChange('');
    },
    [setSelectedClientId, onProjectChange]
  );

  const handleSkillsChanged = useCallback(
    (skills: Array<string>) => {
      setSkillsCount(skills.length);
      onSkillsChange(skills);
    },
    [setSkillsCount, onSkillsChange]
  );

  const onTaskInputChange = useCallback(
    (value: string, idx: number, prop: keyof TranslationDto) => {
      let newTasks = tasks;
      const difference = value.length - newTasks[idx][prop].length;
      setTasksCharsCount((prev) => ({
        ...prev,
        [prop]: prev[prop] + difference,
      }));
      newTasks[idx][prop] = value;
      setTasks(newTasks);
      onTasksChange(newTasks);
    },
    [tasks, setTasks, setTasksCharsCount, onTasksChange]
  );

  return (
    <Flex column gap="gap.medium">
      <Flex column gap="gap.smaller">
        <Text content={t('dialog.add-project.client')} weight="semibold" />
        <Ti8mDropdown
          autoFocus
          items={clients.map((client) => ({
            key: client.id,
            header: client.name?.[language],
            object: client.name,
          }))}
          onScrollLoadMoreItems={fetchMoreClients}
          canLoadMoreItems={hasMoreClients}
          hasDataError={isClientsError}
          defaultValue={
            project
              ? {
                  key: project.projectTemplate.client.id,
                  header: project.projectTemplate.client.name[language],
                  object: project.projectTemplate.client.name,
                }
              : undefined
          }
          search
          placeholder={t('dialog.add-project.client-select-placeholder')}
          onSearchQueryChange={handleClientSearchQueryChanged}
          onChange={(props) =>
            handleSelectedClientChanged(
              props.value as { key: string; header: string; object: TranslationDto }
            )
          }
        />
      </Flex>
      <Flex column gap="gap.smaller">
        <Text content={t('dialog.add-project.project')} weight="semibold" />
        <Ti8mDropdown
          key={`selected_project_${selectedClientId}`}
          items={projects.map((project) => ({
            key: project.id,
            header: project.name[language],
          }))}
          disabled={selectedClientId === ''}
          placeholder={t('dialog.add-project.project-select-placeholder')}
          defaultValue={
            project && project.projectTemplate.client.id === selectedClientId
              ? {
                  key: project.projectTemplate.id,
                  header: project.projectTemplate.name[language],
                }
              : undefined
          }
          onChange={(props) => {
            const id = (props.value as { key: string; header: string }).key;
            setChangedProjectTemplateId(id);
            onProjectChange(id);
          }}
        />
      </Flex>

      <Grid columns="1" rows="1" styles={{ gap: '1rem' }}>
        {/* TODO: handle isUnderNDA=true in the template project */}
        <Checkbox
          toggle
          defaultChecked={project?.isUnderNDA || isNdaToggleDisabled}
          // looks weird, but is useful to set it to checked, once a project wiht NDA (in the dropdown) is selected
          checked={isNdaToggleDisabled || undefined}
          disabled={isNdaToggleDisabled}
          onChange={(_, data) => {
            onIsUnderNdaChange(data?.checked || false);
          }}
          label={t('dialog.add-project.project-is-under-nda')}
        />
      </Grid>
      <Grid columns="2" rows="1" styles={{ gap: '1rem' }}>
        <Flex column gap="gap.smaller">
          <Text weight="semibold" content={t('dialog.add-project.start-date')} />
          <Ti8mDatepicker
            placeholder={t('dialog.add-project.start-date-placeholder')}
            onDateChange={onStartChange}
            defaultSelectedDate={getDateDefaultValue(project?.start)}
          />
        </Flex>
        <Flex column gap="gap.smaller">
          <Text weight="semibold" content={t('dialog.add-project.end-date')} />
          <Ti8mDatepicker
            placeholder={t('dialog.add-project.end-date-placeholder')}
            onDateChange={onEndChange}
            defaultSelectedDate={getDateDefaultValue(project?.end)}
          />
        </Flex>
      </Grid>

      <Flex column gap="gap.smaller">
        <Text content={t('dialog.add-project.role-de')} weight="semibold" />
        <Input
          placeholder={t('dialog.add-project.role-placeholder')}
          fluid
          defaultValue={project?.role.de}
          onChange={(_, data) => onRoleChange(data?.value ?? '', 'de')}
        />
      </Flex>
      <Flex column gap="gap.smaller">
        <Text content={t('dialog.add-project.role-en')} weight="semibold" />
        <Input
          placeholder={t('dialog.add-project.role-placeholder')}
          fluid
          defaultValue={project?.role.en}
          onChange={(_, data) => onRoleChange(data?.value ?? '', 'en')}
        />
      </Flex>
      <Flex column gap="gap.smaller">
        <Text content={t('dialog.add-project.skills')} weight="semibold" />
        <Ti8mDropdown
          items={skills.map((skill) => ({
            key: skill.id,
            header: skill.name,
            disabled: getSkillsDisabled(),
          }))}
          canLoadMoreItems={hasMoreSkills}
          onScrollLoadMoreItems={fetchMoreSkills}
          hasDataError={isSkillsError}
          multiple
          search
          placeholder={t('dialog.add-project.skills-placeholder')}
          onSearchQueryChange={handleSkillSearchQueryChanged}
          onChange={(props) =>
            handleSkillsChanged((props.value as Array<{ key: string }>).map((v) => v.key))
          }
          defaultValue={project?.skills.map((s) => ({
            key: s.id,
            header: s.name,
          }))}
        />
        <Text align="end" size="small">{`${skillsCount}/${maxSkillCount}`}</Text>
      </Flex>
      <Flex column gap="gap.smaller">
        <Text content={t('dialog.add-project.tasks-de')} weight="semibold" />
        {tasks.map((value, i) => (
          <Input
            key={`add_project_task_de_${i}`}
            placeholder={t('dialog.add-project.tasks-placeholder')}
            fluid
            defaultValue={value.de}
            maxLength={maxTaskLength - tasksCharsCount.de + value.de.length}
            onChange={(_, data) => onTaskInputChange(data?.value ?? '', i, 'de')}
          />
        ))}
        <Text align="end" size="small">{`${tasksCharsCount.de}/${maxTaskLength}`}</Text>
      </Flex>
      <Flex column gap="gap.smaller">
        <Text content={t('dialog.add-project.tasks-en')} weight="semibold" />
        {tasks.map((value, i) => (
          <Input
            key={`add_project_task_en_${i}`}
            placeholder={t('dialog.add-project.tasks-placeholder')}
            fluid
            defaultValue={value.en}
            maxLength={maxTaskLength - tasksCharsCount.en + value.en.length}
            onChange={(_, data) => onTaskInputChange(data?.value ?? '', i, 'en')}
          />
        ))}
        <Text align="end" size="small">{`${tasksCharsCount.en}/${maxTaskLength}`}</Text>
      </Flex>
    </Flex>
  );
};
