import { useEmployee } from '../../../data-access';

export interface UseEmployeeDataHookParams {
  employeeId?: string | null;
}

export type EmployeeData = ReturnType<typeof useEmployee>['data'];

export const useEmployeeData = ({ employeeId }: UseEmployeeDataHookParams): EmployeeData => {
  const result = useEmployee(employeeId);
  return result.data;
};
