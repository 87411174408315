import {
  DropdownProps,
  Form,
  FormField,
  FormInput,
  FormLabel,
  FormMessage,
  Grid,
} from '@fluentui/react-northstar';
import { Ti8mDatepicker, Ti8mDropdown, Ti8mFileUploader } from '../../../../common';
import {
  useDegreeCategories,
  useDegrees,
  EmployeeEducationDto as EmployeeEducationQueryDto,
} from '../../../../../data-access';
import { EmployeeEducationDto } from '../../../../../data-access/open-api/commands';
import { useTranslation } from 'react-i18next';
import { convertYearMonthDay } from '../../../../../utils/dateConverter';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';

type EducationDialogFormProps = {
  defaultValues?: EmployeeEducationQueryDto;
  errors: FieldErrors<EmployeeEducationDto>;
  register: UseFormRegister<EmployeeEducationDto>;
  setValue: UseFormSetValue<EmployeeEducationDto>;
  onDocumentUploadPreparation: (file: File | undefined) => void;
};

export const EducationDialogForm = ({
  errors,
  defaultValues,
  register,
  setValue,
  onDocumentUploadPreparation,
}: EducationDialogFormProps) => {
  const { t } = useTranslation();
  const degrees = useDegrees().data;
  const degreeCategories = useDegreeCategories().data;

  const degreeItemChanged = (data: DropdownProps) => {
    const id = degrees?.find((d) => d.name.en === data.value)?.id;
    setValue('degreeId', id ?? '', { shouldDirty: true });
  };

  const degreeCategoryItemChanged = (data: DropdownProps) => {
    const id = degreeCategories?.find((c) => c.name.en === data.value)?.id;
    setValue('degreeCategoryId', id ?? '', { shouldDirty: true });
  };

  const onStartDateChanged = (date: Date | null) => {
    setValue('from', date ? convertYearMonthDay(date) : '', { shouldDirty: true });
  };

  const onEndDateChanged = (date: Date | null) => {
    setValue('to', date ? convertYearMonthDay(date) : null, { shouldDirty: true });
  };

  const onFileChanged = (file: File | undefined) => {
    setValue('diplomaFileName', file?.name ?? '', { shouldDirty: true });
    onDocumentUploadPreparation(file);
  };

  register('degreeId', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('degreeCategoryId', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('from', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  return (
    <Form>
      <FormField>
        <FormLabel
          content={t('employee-detail.master-data.education.add-education-dialog.degree-label')}
        />
        <Ti8mDropdown
          autoFocus
          items={degrees?.map((d) => d.name.en) ?? []}
          placeholder={t(
            'employee-detail.master-data.education.add-education-dialog.degree-placeholder'
          )}
          onChange={degreeItemChanged}
          defaultValue={defaultValues?.degree.name.en}
        />
        {errors.degreeId && <FormMessage error content={errors.degreeId.message} />}
      </FormField>
      <FormField>
        <FormLabel
          content={t(
            'employee-detail.master-data.education.add-education-dialog.degree-category-label'
          )}
        />
        <Ti8mDropdown
          items={degreeCategories?.map((d) => d.name.en) ?? []}
          placeholder={t(
            'employee-detail.master-data.education.add-education-dialog.degree-category-placeholder'
          )}
          defaultValue={defaultValues?.degreeCategory.name.en}
          onChange={degreeCategoryItemChanged}
        />
        {errors.degreeCategoryId && <FormMessage error content={errors.degreeCategoryId.message} />}
      </FormField>
      <FormInput
        key={`institution-de-input_${errors.institution?.de ? 'error' : ''}`}
        {...register('institution.de', {
          required: { value: true, message: t('form-validation.messages.required') },
        })}
        label={t(
          'employee-detail.master-data.education.add-education-dialog.institution-german-label'
        )}
        fluid
        errorMessage={errors.institution?.de ? errors.institution.de.message : undefined}
        placeholder={t(
          'employee-detail.master-data.education.add-education-dialog.institution-german-input-placeholder'
        )}
        defaultValue={defaultValues?.institution.de}
      />
      <FormInput
        key={`institution-en-input_${errors.institution?.en ? 'error' : ''}`}
        {...register('institution.en', {
          required: { value: true, message: t('form-validation.messages.required') },
        })}
        label={t(
          'employee-detail.master-data.education.add-education-dialog.institution-english-label'
        )}
        fluid
        placeholder={t(
          'employee-detail.master-data.education.add-education-dialog.institution-english-input-placeholder'
        )}
        errorMessage={errors.institution?.en ? errors.institution.en.message : ''}
        defaultValue={defaultValues?.institution.en}
      />
      <FormInput
        key={`fieldOfStudy-de-input_${errors.fieldOfStudy?.de ? 'error' : ''}`}
        {...register('fieldOfStudy.de', {
          required: { value: true, message: t('form-validation.messages.required') },
        })}
        label={t(
          'employee-detail.master-data.education.add-education-dialog.degree-program-german-label'
        )}
        fluid
        placeholder={t(
          'employee-detail.master-data.education.add-education-dialog.degree-program-german-input-placeholder'
        )}
        errorMessage={errors.fieldOfStudy?.de ? errors.fieldOfStudy.de.message : ''}
        defaultValue={defaultValues?.fieldOfStudy.de}
      />
      <FormInput
        key={`fieldOfStudy-en-input_${errors.fieldOfStudy?.en ? 'error' : ''}`}
        {...register('fieldOfStudy.en', {
          required: { value: true, message: t('form-validation.messages.required') },
        })}
        label={t(
          'employee-detail.master-data.education.add-education-dialog.degree-program-english-label'
        )}
        fluid
        errorMessage={errors.fieldOfStudy?.en ? errors.fieldOfStudy.en.message : ''}
        placeholder={t(
          'employee-detail.master-data.education.add-education-dialog.degree-program-german-input-placeholder'
        )}
        defaultValue={defaultValues?.fieldOfStudy.en}
      />
      <Grid columns="2" styles={{ gap: '1rem' }}>
        <FormField>
          <FormLabel
            content={t(
              'employee-detail.master-data.education.add-education-dialog.start-date-label'
            )}
          />
          <Ti8mDatepicker
            placeholder={t(
              'employee-detail.master-data.education.add-education-dialog.start-date-placeholder'
            )}
            popupPosition="above"
            defaultSelectedDate={defaultValues ? new Date(defaultValues.from) : undefined}
            onDateChange={onStartDateChanged}
          />
          {errors.from && <FormMessage error content={errors.from.message} />}
        </FormField>
        <FormField>
          <FormLabel
            content={t('employee-detail.master-data.education.add-education-dialog.end-date-label')}
          />
          <Ti8mDatepicker
            placeholder={t(
              'employee-detail.master-data.education.add-education-dialog.end-date-placeholder'
            )}
            defaultSelectedDate={defaultValues?.to ? new Date(defaultValues.to) : undefined}
            popupPosition="above"
            onDateChange={onEndDateChanged}
          />
        </FormField>
      </Grid>
      <FormField>
        <FormLabel
          content={t('employee-detail.master-data.education.add-education-dialog.diploma-label')}
        />
        <Ti8mFileUploader
          onFileChanged={onFileChanged}
          placeholder={t(
            'employee-detail.master-data.education.add-education-dialog.select-diploma-button'
          )}
          selectedFile={defaultValues?.diploma?.displayName}
        />
      </FormField>
    </Form>
  );
};
