import { Button, EditIcon, Flex, Text } from '@fluentui/react-northstar';
import { groupBy } from 'ramda';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeSkillDto } from '../../data-access';
import { SkillsDto, TranslationDto } from '../../data-access/open-api/commands';
import { SkillPill } from '../common/SkillPill';
import { AddSkillsDialog } from './AddSkillsDialog/AddSkillsDialog';
import { EditSkillsDialog } from './EditSkillsDialog/EditSkillsDialog';
import { AddCircleIcon } from '../../icons';

type EmployeeSkillsProps = {
  employeeId: string;
  isEditable: boolean;
  skills: EmployeeSkillDto[];
};

export const EmployeeSkills = ({ employeeId, isEditable, skills }: EmployeeSkillsProps) => {
  const { t, i18n } = useTranslation();
  const [isAddDialogOpen, setAddDialogVisibility] = useState<boolean>(false);
  const [isEditDialogOpen, setEditDialogVisibility] = useState<boolean>(false);

  const existingSkills = (): Array<SkillsDto> => {
    return skills.map((skill) => ({
      skillId: skill.skill.id,
      name: skill.skill.name,
      level: skill.level,
    }));
  };

  const skillsGroupedBySkillGroupName = useMemo(() => {
    return Object.entries(
      groupBy<EmployeeSkillDto>(
        (skill) => skill.skillGroup.name[i18n.resolvedLanguage as keyof TranslationDto],
        skills
      )
    );
  }, [skills, i18n]);

  return (
    <Flex column gap="gap.small">
      <Flex space="between">
        <Text
          weight="semibold"
          content={t('employee-detail.master-data.skills.skills-header')}
          size="large"
        />
        <Flex gap="gap.small">
          <Button
            iconOnly
            text
            primary
            disabled={!isEditable}
            size="small"
            icon={<AddCircleIcon size="large" />}
            onClick={() => {
              setAddDialogVisibility(true);
            }}
          />
          {isAddDialogOpen && (
            <AddSkillsDialog
              employeeId={employeeId}
              isDialogOpen={isAddDialogOpen}
              existingSkills={skills}
              close={() => setAddDialogVisibility(false)}
            />
          )}
          <Button
            iconOnly
            text
            size="small"
            disabled={existingSkills().length === 0 || !isEditable}
            primary
            icon={<EditIcon size="large" />}
            onClick={() => {
              setEditDialogVisibility(true);
            }}
          />
          <EditSkillsDialog
            isDialogOpen={isEditDialogOpen}
            employeeId={employeeId}
            skills={skills}
            skillsGroupedBySkillGroupName={skillsGroupedBySkillGroupName}
            close={() => setEditDialogVisibility(false)}
          />
        </Flex>
      </Flex>
      <Flex column gap="gap.large">
        {skillsGroupedBySkillGroupName.map(([groupName, filteredSkills]) => (
          <Flex column key={groupName} gap="gap.smaller">
            <Text weight="semibold" content={groupName} />
            <Flex styles={{ flexWrap: 'wrap' }}>
              {filteredSkills.map((item, index) => (
                <SkillPill
                  employeeSkill={item}
                  maxTextLength={40}
                  appearance="filled"
                  size="medium"
                  styles={{
                    marginLeft: '0',
                  }}
                  key={`${item.skill.id}_${index}`}
                />
              ))}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
