import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { TranslationDto } from '../models';
import { GuidResult } from '../models/GuidResult';

export class SectorService {
  public static updateSector({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: { name: TranslationDto };
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/sectors/{id}',
      path: {
        id,
        version,
      },
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
      mediaType: 'application/json',
    });
  }

  public static addSector({
    version,
    requestBody,
  }: {
    version: string;
    requestBody?: { name: TranslationDto };
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/sectors',
      path: {
        version,
      },
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
      mediaType: 'application/json',
    });
  }
}
