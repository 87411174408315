import { useEmployees } from '../../../data-access';
import { EmployeeFilterAtomState, PaginationAtomState, SortAtomState } from '../../../state/atoms';

export interface UseEmployeeListDataHookParams {
  searchString?: string;
  filteringOptions?: EmployeeFilterAtomState;
  sortingOptions?: SortAtomState;
  paginationOptions: PaginationAtomState;
}

export const useEmployeeListData = ({
  filteringOptions,
  paginationOptions,
}: UseEmployeeListDataHookParams) => {
  const result = useEmployees(
    {
      ...filteringOptions,
      // Skip on backend side means "skip pages" not "skip items".
      skip: paginationOptions.currentPage - 1,
      // A page size equals to the number of items provided for "take"
      take: paginationOptions.pageSize,
    },
    { keepPreviousData: true }
  );
  return {
    data: result.data?.items ?? [],
    totalItems: result.data?.totalCount ?? 0,
  };
};
