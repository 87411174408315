import {
  DropdownProps,
  Checkbox,
  Flex,
  RadioGroup,
  Text,
  RadioGroupItemProps,
} from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { Ti8mDropdown } from '../../common';
import { useLanguages } from '../../../data-access';
import { useCallback, useState } from 'react';
import { Language } from '../EmployeeSkillsLanguages';
import { warningTextStyle } from '../employeeSkillsStyles';
import { LanguageLevelTooltip } from '../LanguageLevelTooltip';
import { LanguageLevelExternalLink } from '../LanguageLevelExternalLink';

export type AddLanguageDialogFormProps = {
  languageToAdd?: Language;
  setLanguageToAdd: (languageId: Language) => void;
  languageLevel: number;
  setLanguageLevel: (level: number) => void;
  setTouched: (isTouched: boolean) => void;
};

export const AddLanguageDialogForm = ({
  languageToAdd,
  setLanguageToAdd,
  languageLevel,
  setLanguageLevel,
  setTouched,
}: AddLanguageDialogFormProps) => {
  const { t } = useTranslation();
  const languages = useLanguages().data;
  const [isNativeSelected, setNativeSelected] = useState<boolean>(false);
  const [isLevelSelected, setLevelSelected] = useState<boolean>(false);
  const getItems = () => {
    return [
      {
        name: '1',
        key: '1',
        label: <LanguageLevelTooltip level="A1" />,
        value: 1,
        disabled: isNativeSelected,
      },
      {
        name: '2',
        key: '2',
        label: <LanguageLevelTooltip level="A2" />,
        value: 2,
        disabled: isNativeSelected,
      },
      {
        name: '3',
        key: '3',
        label: <LanguageLevelTooltip level="B1" />,
        value: 3,
        disabled: isNativeSelected,
      },
      {
        name: '4',
        key: '4',
        label: <LanguageLevelTooltip level="B2" />,
        value: 4,
        disabled: isNativeSelected,
      },
      {
        name: '5',
        key: '5',
        label: <LanguageLevelTooltip level="C1" />,
        value: 5,
        disabled: isNativeSelected,
      },
      {
        name: '6',
        key: '6',
        label: <LanguageLevelTooltip level="C2" />,
        value: 6,
        disabled: isNativeSelected,
      },
    ];
  };

  const onAddLanguage = useCallback(
    (data: DropdownProps) => {
      const language = data.value as { key: string; header: string };
      setLanguageToAdd({ languageId: language.key, name: language.header });
      if (languageLevel !== 0) setTouched(true);
    },
    [setLanguageToAdd, setTouched, languageLevel]
  );

  const onSelectLevel = useCallback(
    (data: RadioGroupItemProps | undefined) => {
      setLevelSelected(true);
      setLanguageLevel(data?.value as number);
      if (languageToAdd?.languageId !== '') setTouched(true);
    },
    [languageToAdd, setLevelSelected, setLanguageLevel, setTouched]
  );

  const onNativeChanged = useCallback(() => {
    if (isNativeSelected) setNativeSelected(false);
    if (!isNativeSelected) setNativeSelected(true);
    setLanguageLevel(7);
    if (languageToAdd?.languageId !== '') setTouched(true);
  }, [isNativeSelected, languageToAdd, setNativeSelected, setTouched, setLanguageLevel]);

  return (
    <Flex column gap="gap.large">
      <Flex column gap="gap.small">
        <Text
          content={t('employee-detail.master-data.skills.add-languages-dialog.language-label')}
          weight="semibold"
        />
        <Ti8mDropdown
          autoFocus
          items={
            languages?.map((language) => ({
              key: language.id,
              header: language.name.en,
            })) ?? []
          }
          onChange={onAddLanguage}
          placeholder={t('employee-detail.master-data.skills.add-languages-dialog.placeholder')}
        />
      </Flex>
      <Flex column gap="gap.small">
        <Text
          content={t(
            'employee-detail.master-data.skills.add-languages-dialog.language-level-label'
          )}
          weight="semibold"
        />
        <Flex gap="gap.medium">
          <RadioGroup items={getItems()} onCheckedValueChange={(_, data) => onSelectLevel(data)} />
          <Text content="|" size="large" styles={{ marginTop: '0.2rem' }} />
          <Checkbox label={'Native'} styles={{ marginTop: '0.15rem' }} onChange={onNativeChanged} />
        </Flex>
        {!isLevelSelected && !isNativeSelected && languageToAdd?.languageId !== '' && (
          <Text
            content={t('employee-detail.master-data.skills.add-languages-dialog.warning')}
            size="small"
            styles={warningTextStyle}
          />
        )}
        <LanguageLevelExternalLink />
      </Flex>
    </Flex>
  );
};
