import { Flex } from '@fluentui/react-northstar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SkillGroupDto, useSkillGroups } from '../../data-access';
import { DefaultLoader } from '../components/DefaultLoader';
import { MasterDataToolbar } from '../components/MasterDataToolbar';
import { SkillGroupsAddOrEditFormDialog } from './SkillGroupsAddOrEditFormDialog';
import { SkillGroupsMasterDataTable } from './SkillGroupsMasterDataTable';

export const SkillGroupsMasterData = () => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<SkillGroupDto>();

  const { data: items = [], isLoading } = useSkillGroups();

  return (
    <Flex column fill>
      <MasterDataToolbar
        DialogComponent={SkillGroupsAddOrEditFormDialog}
        buttonLabel={t('admin.skill-groups.add')}
      />

      <DefaultLoader isLoading={isLoading}>
        <SkillGroupsMasterDataTable
          items={items}
          onEditItem={(item) => {
            setCurrentItem(item);
            setDialogOpen(true);
          }}
        />
      </DefaultLoader>

      {dialogOpen ? (
        <SkillGroupsAddOrEditFormDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          currentItem={currentItem}
        />
      ) : null}
    </Flex>
  );
};
