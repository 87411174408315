import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, withApiVersion, WithoutVersion } from '../../helpers';
import { ApiError, ClientService, GuidResult } from '../../open-api/commands';
import { ClientService as QueryClientService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const addClient = withApiVersion(ClientService.addClient);
const getEventPosition = withApiVersion(QueryClientService.getEventPosition);

type AddClientInput = WithoutVersion<ExtractFirstArgAsType<typeof ClientService.addClient>>;

export const useAddClient = () => {
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, AddClientInput>(addClient, {
    onSuccess: async ({ payload: id, storePosition }) =>
      waitForEvent({ id, storePosition }, (id) => getEventPosition({ id })).then(() =>
        queryClient.refetchQueries(QueryKeys.Clients)
      ),
  });
};
