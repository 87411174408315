import { Card, CardBody, Divider, Flex } from '@fluentui/react-northstar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeDto, useDeleteEmployeeCertificate } from '../../../../data-access';
import { EmployeeCertificateDialog } from './Dialog/EmployeeCertificateDialog';
import { EmployeeCertificates } from './EmployeeCertificates';
import { RequestCertificateDialog } from './RequestCertificateDialog/RequestCertificateDialog';
import { Ti8mCardHeader } from '../../../common/Ti8mCardHeader';

type EmployeeCertificatesCardProps = {
  employee: EmployeeDto;
  isEditable: boolean;
};

export const EmployeeCertificatesCard = ({
  employee,
  isEditable,
}: EmployeeCertificatesCardProps) => {
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isRequestDialogOpen, setRequestDialogVisibility] = useState<boolean>(false);
  const [isCertificateCardUnfold, setIsCertificateCardUnfold] = useState<boolean>(true);

  const { mutate: deleteEmployeeCertificate } = useDeleteEmployeeCertificate();

  const onConfirmDeleteCertificate = (id: string) => {
    deleteEmployeeCertificate({ id: employee.id as string, certificateId: id });
  };

  return (
    <Card>
      <Ti8mCardHeader
        cardHeaderText={t('employee-detail.master-data.certificates.header')}
        isEditable={isEditable}
        isCardUnfold={isCertificateCardUnfold}
        setCardUnfold={setIsCertificateCardUnfold}
        setDialogVisibility={setDialogOpen}
      />
      <EmployeeCertificateDialog
        key={`employee-certificate-dialog-add_${isDialogOpen}`}
        isDialogOpen={isDialogOpen}
        isEdit={false}
        employeeId={employee.id}
        setDialogOpen={setDialogOpen}
        onRequestCertificateClick={() => {
          setDialogOpen(false);
          setRequestDialogVisibility(true);
        }}
      />
      {isRequestDialogOpen && (
        <RequestCertificateDialog
          isOpen={isRequestDialogOpen}
          setIsOpen={setRequestDialogVisibility}
        />
      )}
      {isCertificateCardUnfold && (
        <CardBody>
          <Flex column gap="gap.medium">
            {employee.certificates
              .sort((a, b) =>
                a.issuedOn && b.issuedOn
                  ? new Date(b.issuedOn).getTime() - new Date(a.issuedOn).getTime()
                  : 0
              )
              .map((item, i) => (
                <Flex column gap="gap.small" key={item.id}>
                  {i !== 0 && <Divider />}
                  <EmployeeCertificates
                    isEditable={isEditable}
                    employeeCertificate={item}
                    employeeId={employee.id}
                    onDelete={onConfirmDeleteCertificate}
                  />
                </Flex>
              ))}
          </Flex>
        </CardBody>
      )}
    </Card>
  );
};
