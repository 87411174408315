import { DegreeService, QueryKeys } from '../../../data-access';
import { useQuery } from 'react-query';
import { env } from '../../../utils/env';

const getDegrees = () => DegreeService.getAllDegrees({ version: env('REACT_APP_API_VERSION') });

export const useDegrees = () =>
  useQuery(QueryKeys.Degrees, ({ queryKey: _ }) => getDegrees(), {
    select: (data) => data.payload,
  });
