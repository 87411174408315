import { Flex } from '@fluentui/react-northstar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../../components/EmployeeTable/Pagination';
import { CertificateDto, useCertificates } from '../../data-access';
import { useDeleteCertificate } from '../../data-access/hooks/commands/use-delete-certificate';
import { DefaultLoader } from '../components/DefaultLoader';
import { MasterDataToolbar } from '../components/MasterDataToolbar';
import { CertificatesAddOrEditFormDialog } from './CertificatesAddOrEditFormDialog';
import { CertificatesMasterDataTable } from './CertificatesMasterDataTable';

const PAGE_SIZE = 10;

export const CertificatesMasterData = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<CertificateDto>();

  const { mutate: remove } = useDeleteCertificate();

  const { data: { items = [], totalCount = 0 } = {}, isLoading } = useCertificates({
    take: PAGE_SIZE,
    skip: currentPage - 1,
    searchString: searchTerm,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <Flex column fill>
      <MasterDataToolbar
        DialogComponent={CertificatesAddOrEditFormDialog}
        buttonLabel={t('admin.certificates.add')}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        inputPlaceholderText={t('admin.certificates.search')}
      />

      <DefaultLoader isLoading={isLoading}>
        <CertificatesMasterDataTable
          items={items}
          onEditItem={(item) => {
            setCurrentItem(item);
            setDialogOpen(true);
          }}
          onDeleteItem={(item) => remove({ id: item.id })}
        />
      </DefaultLoader>

      <Pagination
        currentPage={currentPage}
        pageSize={PAGE_SIZE}
        totalItems={totalCount}
        onPageChange={(page) => setCurrentPage(page)}
      />

      {dialogOpen ? (
        <CertificatesAddOrEditFormDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          currentItem={currentItem}
        />
      ) : null}
    </Flex>
  );
};
