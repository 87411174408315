import { Alert, DownloadIcon, Flex, List, Text } from '@fluentui/react-northstar';
import { all } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiError, GenerateCvDto, useGenerateCvDownload } from '../../../data-access';
import { downloadFile } from '../../common';
import { useEmployeeData } from '../../EmployeeMasterData/hooks/useEmployeeData';
import { CvDownloadDialogForm, GenerateCvDtoSetter } from './CvDownloadDialogForm';
import { useDropdownItems } from './hooks/useDropdownItems';
import { Ti8mDialog } from '../../common/Ti8mDialog';

type CvDownloadDialogProps = {
  isOpen: boolean;
  setIsOpen: (action: boolean) => void;
  language: 'en' | 'de';
  employee: { id: string; firstName: string; lastName: string };
};

const mapLanguageToNumber = {
  en: 0,
  de: 1,
} as const;

export const CvDownloadDialog = ({
  isOpen,
  setIsOpen,
  language,
  employee,
}: CvDownloadDialogProps) => {
  const { t } = useTranslation();
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const employeeDetail = useEmployeeData({ employeeId: employee.id });
  const {
    initialProjectItems,
    initialSectorItems,
    initialCertificateItems,
    initialEducationItems,
    initialTopSkillItems,
  } = useDropdownItems(employeeDetail);
  const generateCvDownload = useGenerateCvDownload();
  const [alerts, setAlerts] = useState<string[]>();
  const [generateCvData, setGenerateCvData] = useState<GenerateCvDto>({
    role: '',
    sectorsKnowHow: [],
    topProjects: [],
    topSkills: [],
    relevantCertificates: [],
    relevantEducations: [],
    description: '',
    type: 0,
    language: mapLanguageToNumber[language],
  });

  useEffect(() => {
    setGenerateCvData({
      role: employeeDetail?.role ?? '',
      sectorsKnowHow: initialSectorItems.map((s) => s.key),
      topProjects: initialProjectItems.map((p) => p.key),
      topSkills: initialTopSkillItems.map((s) => s.key) ?? [],
      relevantCertificates: initialCertificateItems.map((c) => c.key),
      relevantEducations: initialEducationItems.map((e) => e.key) ?? [],
      description: employeeDetail?.profileText?.[language] ?? '',
      type: 0,
      language: mapLanguageToNumber[language],
    });
  }, [
    employeeDetail,
    initialProjectItems,
    initialSectorItems,
    initialCertificateItems,
    initialEducationItems,
    initialTopSkillItems,
    language,
  ]);

  const handleSetCvData: GenerateCvDtoSetter = (key, value) => {
    setGenerateCvData({ ...generateCvData, [key]: value });
  };

  const isValidGenerateCvData = (): boolean =>
    all<string[]>(
      (array) => array.length > 0,
      [
        generateCvData.sectorsKnowHow,
        generateCvData.topProjects,
        generateCvData.topSkills,
        generateCvData.relevantEducations,
      ]
    );

  useEffect(() => {
    // This will jump to the bottom of the dialog to fully reveal the alert
    if (alerts && typeof dialogRef?.current?.scrollTop !== 'undefined')
      dialogRef.current.scrollTop = dialogRef.current.scrollHeight;
  }, [alerts]);

  return (
    <Ti8mDialog
      ref={dialogRef}
      open={isOpen}
      cancelButton={t('cv-download-dialog.cancel-button')}
      onCancel={() => setIsOpen(false)}
      confirmButton={
        <Flex gap="gap.small">
          <DownloadIcon />
          <Text content={t('cv-download-dialog.confirm-button')} />
        </Flex>
      }
      onConfirm={async () => {
        if (!employeeDetail?.id) return;

        if (!isValidGenerateCvData()) {
          setAlerts([t('cv-download-dialog.dropdown-min-selection')]);
          return;
        }

        try {
          const { payload: url } = await generateCvDownload({
            id: employeeDetail.id,
            requestBody: generateCvData,
          });

          downloadFile(url);
          setIsOpen(false);
        } catch (error: any) {
          if (error instanceof ApiError) {
            if (error.body.messages) {
              setAlerts(error.body.messages);
            } else {
              const { status, title } = error.body;
              setAlerts(status && title ? [`${status}: ${title}`] : [t('alert.unknown-error')]);
            }
          }
        }
      }}
      content={
        employeeDetail && (
          <>
            <CvDownloadDialogForm
              cvData={employeeDetail}
              language={language}
              onSetCvData={handleSetCvData}
            />

            {alerts && (
              <Alert
                content={
                  <List
                    as={Flex}
                    gap="gap.smaller"
                    column
                    styles={{ paddingTop: 10, paddingBottom: 10, display: 'flex' }}
                  >
                    {alerts?.map((alert, index) => (
                      <List.Item
                        key={`alert-${index}`}
                        index={index}
                        content={alert}
                        styles={{ padding: 0 }}
                      />
                    ))}
                  </List>
                }
                variables={{ urgent: true }}
                styles={{ marginTop: 15 }}
              />
            )}
          </>
        )
      }
      header={`${t('cv-download-dialog.title')} ${employee?.firstName} ${
        employee?.lastName
      } (${language})`}
      key={`"cvDownloadDialog" ${employee}`}
    />
  );
};
