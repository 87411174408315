import { Card, CardBody, Flex } from '@fluentui/react-northstar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeDto } from '../../../../data-access';
import { Ti8mCardHeader } from '../../../common/Ti8mCardHeader';
import { EmployeeNationalSecurity } from './EmployeNationalSecurity';
import { EmployeeNationalSecurityDialog } from './Dialog/EditNationalSecurityDataDialog';

type EmployeeNationalSecurityCardProps = {
  employee: EmployeeDto;
  isEditable: boolean;
};

export const EmployeeNationalSecurityCard = ({
  employee,
  isEditable,
}: EmployeeNationalSecurityCardProps) => {
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isUnfold, setIsUnfold] = useState<boolean>(true);

  return (
    <Card>
      <Ti8mCardHeader
        cardHeaderText={t('employee-detail.master-data.national-security.header')}
        isEditable={isEditable}
        isCardUnfold={isUnfold}
        setCardUnfold={setIsUnfold}
        setDialogVisibility={setDialogOpen}
      />
      <EmployeeNationalSecurityDialog
        key={`employee-certificate-dialog-add_${isDialogOpen}`}
        isDialogOpen={isDialogOpen}
        setDialogOpen={setDialogOpen}
        employeeId={employee.id}
        data={employee.nationalSecurityInfo}
      />
      {isUnfold && (
        <CardBody>
          <Flex column gap="gap.medium">
            <EmployeeNationalSecurity
              employeeId={employee.id}
              data={employee.nationalSecurityInfo}
            />
          </Flex>
        </CardBody>
      )}
    </Card>
  );
};
