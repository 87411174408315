import {
  ContentIcon,
  Flex,
  Grid,
  Menu,
  MenuItemProps,
  QuestionCircleIcon,
} from '@fluentui/react-northstar';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthInfo } from '../auth/use-authinfo';
import { BasicLayout } from '../components/Layout/Layout';
import { getByIndex, mapWithIndex } from '../utils/arrayIndex';
import { AdminPage } from './AdminPage';
import { HelpPage } from './Help';

type MenuItemKeys = 'help' | 'master-data';

type MenuItemWithKey = MenuItemProps & {
  key: MenuItemKeys;
};

export const MorePage = () => {
  const { t } = useTranslation();
  const { isMasterDataEditor } = useAuthInfo();

  const menu = useMemo(() => {
    let menuBuilder: MenuItemWithKey[] = [
      {
        key: 'help',
        content: t('more-tab.help'),
        icon: <QuestionCircleIcon outline />,
      },
    ];

    if (isMasterDataEditor) {
      menuBuilder.push({
        key: 'master-data',
        content: t('more-tab.master-data'),
        icon: <ContentIcon outline />,
      });
    }

    return mapWithIndex(menuBuilder);
  }, [t, isMasterDataEditor]);

  const [selected, setSelected] = useState<MenuItemWithKey | undefined>(menu[0]);

  return (
    <BasicLayout noPadding styles={{ minHeight: '100vh' }}>
      <Grid columns="200px 1fr">
        <Menu
          defaultActiveIndex={menu[0].index}
          activeIndex={selected?.index}
          vertical
          styles={({ theme: { siteVariables } }) => ({
            width: '100%',
            padding: 0,
            '> .ui-menu__itemwrapper': { border: 0 },
            borderRight: `1px solid ${siteVariables.colors.grey[200]}`,
          })}
          items={menu.map((tab) => ({
            ...tab,
            styles: ({ theme: { siteVariables } }) => {
              const grey100 = siteVariables.colors.grey[100];
              const backgroundHoverColor = siteVariables.colorScheme.default.backgroundHover;
              const brand600 = siteVariables.colors.brand[600];
              const isSelectedMenuItem = tab.index === selected?.index;
              return {
                display: 'flex',
                alignItems: 'center',
                minHeight: '3rem',
                borderBottom: `1px solid ${grey100}`,
                backgroundColor: isSelectedMenuItem ? grey100 : 'initial',
                color: isSelectedMenuItem ? brand600 : 'inherit',
                fontWeight: isSelectedMenuItem ? siteVariables.fontWeightSemibold : 'initial',
                ':hover': { backgroundColor: backgroundHoverColor },
                '> span.ui-menu__itemicon': { marginBottom: 'unset' },
              };
            },
          }))}
          onActiveIndexChange={(_event, data) =>
            setSelected(getByIndex(menu, Number(data?.activeIndex)))
          }
        />
        <Flex styles={{ marginTop: '1rem', marginLeft: '1.5rem', marginRight: '1.5rem' }}>
          {selected?.key === 'help' && <HelpPage />}
          {selected?.key === 'master-data' && <AdminPage />}
        </Flex>
      </Grid>
    </BasicLayout>
  );
};
