import { useTranslation } from 'react-i18next';
import { RadioGroup, Text } from '@fluentui/react-northstar';
import { useCallback, useState } from 'react';
import { Ti8mProjectEditDialog } from './Ti8mProjectEditDialog';
import { ProjectEditDialog } from './ProjectEditDialog';
import { Ti8mDialog } from '../../common/Ti8mDialog';

export const AddNewProjectDialog = ({
  dialogOpen,
  employeeId,
  onClose,
}: {
  dialogOpen: boolean;
  employeeId: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [createTemplateProject, setCreateTemplateProject] = useState(true);
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const handleProjectTypeChanged = useCallback(
    (value: string) => {
      if (value === 'tim_project') {
        setCreateTemplateProject(true);
      } else {
        setCreateTemplateProject(false);
      }
    },
    [setCreateTemplateProject]
  );

  const handleConfirm = useCallback(() => {
    onClose();
    setAddDialogOpen(true);
  }, [setAddDialogOpen, onClose]);

  const handleCancel = useCallback(() => {
    onClose();
    setAddDialogOpen(false);
    setCreateTemplateProject(true);
  }, [setAddDialogOpen, onClose]);

  return (
    <>
      <Ti8mDialog
        open={dialogOpen}
        header={t('dialog.add-project.title-add')}
        cancelButton={{
          content: t('dialog.button-cancel'),
          flat: true,
        }}
        onCancel={onClose}
        confirmButton={{
          content: t('dialog.add-project.title-add'),
          flat: true,
        }}
        onConfirm={handleConfirm}
        content={
          <RadioGroup
            vertical
            defaultCheckedValue="tim_project"
            items={[
              {
                name: 'tim_project',
                key: 'tim_project',
                label: (
                  <>
                    <Text weight="semibold" content={t('dialog.add-project.ti8m-project')} />
                    <br />
                    <Text content={t('dialog.add-project.ti8m-project-text')} />
                  </>
                ),
                value: 'tim_project',
              },
              {
                name: 'other_project',
                key: 'other_project',
                label: (
                  <>
                    <Text weight="semibold" content={t('dialog.add-project.other-project')} />
                    <br />
                    <Text content={t('dialog.add-project.other-project-text')} />
                  </>
                ),
                value: 'other_project',
              },
            ]}
            onCheckedValueChange={(_, data) =>
              handleProjectTypeChanged((data?.value as string) ?? '')
            }
          />
        }
      />
      {createTemplateProject ? (
        <Ti8mProjectEditDialog
          employeeId={employeeId}
          open={addDialogOpen}
          onCancel={handleCancel}
        />
      ) : (
        <ProjectEditDialog employeeId={employeeId} open={addDialogOpen} onCancel={handleCancel} />
      )}
    </>
  );
};
