import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { I18nextProvider } from 'react-i18next';
import { useHistory } from 'react-router';
import { Redirect, Route, Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ApiProvider, TokenProvider } from '../../data-access';
import { ColorSchemeProvider } from '../../data-access/providers/colorSchemeProvider';
import {
  ClientsPage,
  EmployeeDetailPage,
  EmployeePage,
  MorePage,
  OriginsPage,
  ProjectsPage,
} from '../../pages';
import { i18nInit } from '../locales';
import { AppBrowserHeader } from './Header';
import { Main } from './Main';

initializeIcons();

export function App() {
  return (
    <RecoilRoot>
      <ColorSchemeProvider>
        <I18nextProvider i18n={i18nInit}>
          <TokenProvider>
            <ApiProvider>
              <Main>
                <AppBrowserHeader />
                <Router history={useHistory()}>
                  <Route exact path="/projects" component={ProjectsPage} />
                  <Route exact path="/clients" component={ClientsPage} />
                  <Route exact path="/employees" component={EmployeePage} />
                  <Route exact path="/employeeDetail/:id" component={EmployeeDetailPage} />
                  <Route exact path="/origins" component={OriginsPage} />
                  <Route exact path="/more" component={MorePage} />
                  <Route exact path="/">
                    <Redirect to="/employees" />
                  </Route>
                </Router>
              </Main>
            </ApiProvider>
          </TokenProvider>
        </I18nextProvider>
      </ColorSchemeProvider>
    </RecoilRoot>
  );
}
