import { Flex, Text } from '@fluentui/react-northstar';
import { PropsWithChildren } from 'react';

export const TitleTextComponent = (props: PropsWithChildren<{ title: string }>) => {
  return (
    <Flex column>
      <Text content={props.title} weight="semibold" style={{ marginBottom: '4px' }} />
      {props.children}
    </Flex>
  );
};
