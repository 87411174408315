import { Flex, FlexItem, Text, TextArea } from '@fluentui/react-northstar';
import { TranslationDto } from '../../../../data-access';
import { useTranslation } from 'react-i18next';

type EmployeeProfileTextDialogContentProps = {
  profileText: TranslationDto;
  onProfileTextChanged: (data: TranslationDto) => void;
};

export const EmployeeProfileTextDialogContent = ({
  profileText,
  onProfileTextChanged,
}: EmployeeProfileTextDialogContentProps) => {
  const { t } = useTranslation();
  const maxCharLength = 350;

  const onDataGermanChanged = (input: string) => {
    onProfileTextChanged({ ...profileText, de: input });
  };

  const onDataEnglishChanged = (input: string) => {
    onProfileTextChanged({ ...profileText, en: input });
  };

  return (
    <Flex column gap="gap.small">
      <FlexItem>
        <Flex column gap="gap.smaller">
          <Text
            weight="bold"
            content={t('employee-detail.master-data.profile-text.edit-dialog.sub-header-german')}
          />
          <TextArea
            autoFocus
            maxLength={maxCharLength}
            onChange={(_t, d) => onDataGermanChanged(d?.value ?? '')}
            value={profileText.de}
            styles={{ minHeight: '8rem' }}
          />
          <FlexItem align="end">
            <Text content={profileText.de.length + '/' + maxCharLength} weight="light" />
          </FlexItem>
        </Flex>
      </FlexItem>
      <FlexItem>
        <Flex column gap="gap.smaller">
          <Text
            weight="bold"
            content={t('employee-detail.master-data.profile-text.edit-dialog.sub-header-english')}
          />
          <TextArea
            maxLength={maxCharLength}
            onChange={(_t, d) => onDataEnglishChanged(d?.value ?? '')}
            value={profileText.en}
            styles={{ minHeight: '8rem' }}
          />
          <FlexItem align="end">
            <Text content={profileText.en.length + '/' + maxCharLength} weight="light" />
          </FlexItem>
        </Flex>
      </FlexItem>
    </Flex>
  );
};
