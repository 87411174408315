import { useTranslation } from 'react-i18next';
import { Text } from '@fluentui/react-northstar';
import { useCallback } from 'react';
import {
  useDeleteEmployeeProject,
  useDeleteEmployeeTemplateBasedProject,
} from '../../../data-access/hooks/commands';
import { Ti8mDialog } from '../../common/Ti8mDialog';

export const DeleteProjectDialog = ({
  dialogOpen,
  employeeId,
  projectId,
  isTemplateBased,
  onClose,
}: {
  dialogOpen: boolean;
  employeeId: string;
  projectId: string;
  isTemplateBased: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { mutate: deleteProject } = useDeleteEmployeeProject(
    t('alert.request-objects.employee'),
    t('alert.update-types.delete')
  );
  const { mutate: deleteTemplateBasedProject } = useDeleteEmployeeTemplateBasedProject(
    t('alert.request-objects.employee'),
    t('alert.update-types.delete')
  );

  const handleConfirm = useCallback(() => {
    onClose();
    if (isTemplateBased) {
      deleteTemplateBasedProject({ id: employeeId, projectId: projectId });
    } else {
      deleteProject({ id: employeeId, projectId: projectId });
    }
  }, [employeeId, projectId, isTemplateBased, deleteTemplateBasedProject, deleteProject, onClose]);

  return (
    <Ti8mDialog
      open={dialogOpen}
      header={t('dialog.delete-project.title')}
      showCloseHeaderAction={false}
      cancelButton={{
        content: t('dialog.button-cancel'),
        flat: true,
      }}
      onCancel={onClose}
      confirmButton={{
        content: t('dialog.button-delete'),
        flat: true,
      }}
      onConfirm={handleConfirm}
      content={<Text content={t('dialog.delete-project.text')} />}
    />
  );
};
