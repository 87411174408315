/**
 * "Unpacks" the common pageinated backend query response into a simple Array<T>.
 * Useful for processing the output of react-query/useInfiniteQuery
 */
export function unpack<T>(data?: { pages: Array<{ payload: { items: Array<T> } }> }): Array<T> {
  if (!data || !data.pages) {
    return [];
  }
  const unpacked = data.pages.map((x) => x.payload.items);
  return unpacked?.reduce((acc, val) => [...acc, ...val], []); //combine page data into one array
}
