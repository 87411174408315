import {
  AcceptIcon,
  Alert,
  ExclamationTriangleIcon,
  Layout,
  LayoutProps,
  Text,
} from '@fluentui/react-northstar';
import { Flex } from '@fluentui/react-northstar/dist/es/components/Flex/Flex';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

const DEFAULT_TARGET = 'Entry';
const DEFAULT_ACTION = 'mutated';

export const BasicLayout = ({
  noPadding,
  ...props
}: PropsWithChildren<{ noPadding?: boolean } & LayoutProps>) => {
  const { t } = useTranslation();
  const [alertData, setAlertData] = useState({
    isError: false,
    isVisible: false,
    message: '',
    title: '',
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    const unsubscribeMutationCache = queryClient.getMutationCache().subscribe((event) => {
      const e = event as any;
      // check if mutation is finished
      if (e.state.data || e.state.error) {
        let displayDuration = 3000;
        if (e.state.data?.resultCode === 0) {
          setAlertData({
            isError: false,
            isVisible: true,
            message: t('alert.success.message')
              .replace('{1}', e?.options?.meta?.requestObject ?? DEFAULT_TARGET)
              .replace('{2}', e?.options?.meta?.type ?? DEFAULT_ACTION),
            title: t('alert.success.title'),
          });
        } else {
          setAlertData({
            isError: true,
            isVisible: true,
            message: t('alert.error.message')
              .replace('{1}', e?.options?.meta?.requestObject ?? DEFAULT_TARGET)
              .replace('{2}', e?.options?.meta?.type ?? DEFAULT_ACTION),
            title: t('alert.error.title'),
          });
          displayDuration = 10000;
        }
        const timer = setTimeout(() => {
          setAlertData((prev) => ({ ...prev, isVisible: false }));
          clearTimeout(timer);
        }, displayDuration);
      }
    });

    return () => unsubscribeMutationCache();
  }, [queryClient, t]);

  return (
    <Flex column>
      <Alert
        icon={
          alertData.isError ? <ExclamationTriangleIcon outline /> : <AcceptIcon circular bordered />
        }
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 900,
          paddingTop: '3px',
          paddingBottom: '3px',
        }}
        title={alertData.title}
        content={<Text size="small">{alertData.message}</Text>}
        visible={alertData.isVisible}
        onVisibleChange={() => setAlertData((prev) => ({ ...prev, isVisible: false }))}
        success={!alertData.isError}
        danger={alertData.isError}
        dismissible
        dismissAction={{
          'aria-label': 'close',
        }}
      />

      <Layout style={noPadding ? undefined : { padding: '2rem 3rem' }} {...props}>
        {props.children}
      </Layout>
    </Flex>
  );
};
