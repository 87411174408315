import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  ComponentSlotStyle,
  EditIcon,
  Flex,
  Table,
  gridNestedBehavior,
  Text,
  Button,
} from '@fluentui/react-northstar';

import { Pagination } from '../EmployeeTable/Pagination';
import { ClientNewEditDialog } from '../ClientNewEditDialog/ClientNewEditDialog';
import { PaginationAtomState, clientPaginationAtom, clientsFilterAtom } from '../../state/atoms';
import { ClientDto } from '../../data-access/open-api/queries/models/ClientDto';

import { useClientListData } from './hooks/useClientListData';

const tableStyles: ComponentSlotStyle = (data) => {
  return {
    '& .ui-table__row': {
      fontSize: data.theme.siteVariables.fontSizes['small'],
    },
  };
};

const editColumnStyles: ComponentSlotStyle = (data) => ({
  maxWidth: '100px',
  justifyContent: 'flex-end',
});

export const ClientsTable = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [client, setClient] = useState<ClientDto | undefined>();

  const filter = useRecoilValue(clientsFilterAtom);
  const resetPagination = useResetRecoilState(clientPaginationAtom);
  const [paginationData, setPaginationData] =
    useRecoilState<PaginationAtomState>(clientPaginationAtom);

  const { data: clients, totalItems } = useClientListData({
    filterOptions: filter,
    paginationOptions: paginationData,
  });

  // Jump back to page 1 whenever the filter changes
  useEffect(() => resetPagination(), [filter, resetPagination]);

  const headers = {
    items: [
      {
        key: 'nameHeaderDe',
        content: <Text weight="semibold" content={t('clients.table.headers.name-german')} />,
      },
      {
        key: 'nameHeaderEn',
        content: <Text weight="semibold" content={t('clients.table.headers.name-english')} />,
        styles: { justifyContent: 'start' },
      },
      {
        key: 'editActionColumn',
        content: '',
        styles: editColumnStyles,
      },
    ],
  };

  return (
    <Flex column gap="gap.small">
      <Table
        styles={tableStyles}
        header={headers}
        accessibility={gridNestedBehavior}
        rows={clients.map((client) => ({
          key: client.id,
          items: [
            {
              key: `${client.id}_name_de`,
              truncateContent: true,
              content: client.name.de,
            },
            {
              key: `${client.id}_name_en`,
              truncateContent: true,
              content: client.name.en,
            },
            {
              key: `${client.id}_edit_action`,
              truncateContent: true,
              // TODO Move color to theme
              content: (
                <Button
                  primary
                  iconOnly
                  text
                  icon={<EditIcon />}
                  onClick={() => {
                    setClient(client);
                    setIsOpen(true);
                  }}
                />
              ),
              styles: editColumnStyles,
            },
          ],
        }))}
      />
      <Pagination
        currentPage={paginationData.currentPage}
        pageSize={paginationData.pageSize}
        totalItems={totalItems}
        onPageChange={(page) => setPaginationData((prev) => ({ ...prev, currentPage: page }))}
      />
      {isOpen && <ClientNewEditDialog isOpen={isOpen} setIsOpen={setIsOpen} client={client} />}
    </Flex>
  );
};
