import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, withApiVersion, WithoutVersion } from '../../helpers';
import { ApiError, Result, SkillService } from '../../open-api/commands';
import { SkillService as QuerySkillService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

type GuidResultWithId = Result & {
  id: string;
};

const deleteSkill = withApiVersion(SkillService.deleteSkill);
const getEventPosition = withApiVersion(QuerySkillService.getEventPosition);

export type DeleteSkillInput = WithoutVersion<ExtractFirstArgAsType<typeof deleteSkill>>;

export const useDeleteSkill = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, DeleteSkillInput>(
    async (input) => {
      const result = await deleteSkill(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, (id) => getEventPosition({ id }), true).then(() =>
          queryClient.refetchQueries(QueryKeys.Skills)
        ),

      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.skill'),
      },
    }
  );
};
