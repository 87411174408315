import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, WithoutVersion, withApiVersion } from '../../helpers';
import { ApiError, CertificateService, Result } from '../../open-api/commands';
import { CertificateService as QueryCertificateService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const addCertificate = withApiVersion(CertificateService.addCertificate);
const getEventPosition = withApiVersion(QueryCertificateService.getEventPosition);

export type AddCertificateInput = WithoutVersion<ExtractFirstArgAsType<typeof addCertificate>>;

export const useAddCertificate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<Result, ApiError, AddCertificateInput>(addCertificate, {
    onSuccess: async ({ payload: id, storePosition }) =>
      waitForEvent({ id, storePosition }, (id) => getEventPosition({ id })).then(() =>
        queryClient.invalidateQueries(QueryKeys.Certificates)
      ),
    meta: {
      type: t('alert.update-types.edit'),
      requestObject: t('alert.request-objects.certificate'),
    },
  });
};
