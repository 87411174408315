import { ComponentSlotStyle, ICSSInJSStyle } from '@fluentui/react-northstar';

export const baseTableStyles: ComponentSlotStyle = (data) => {
  return {
    '& .ui-table__row': {
      fontSize: data.theme.siteVariables.fontSizes['small'],
    },
    marginBottom: 10,
    marginTop: 30,
  };
};

export const editColumnStyles: ICSSInJSStyle = {
  maxWidth: '150px',
  minWidth: '120px',
  justifyContent: 'flex-end',
  flexBasis: 'content',
};
