import { UseQueryOptions, useQuery } from 'react-query';
import { ExtractFirstArgAsType, WithoutVersion, withApiVersion } from '../../helpers';
import {
  ApiError,
  FocusTopicService,
  FocusTopicsListDto,
  GenericResult,
} from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';

const getSubjectMatterExpertTopics = withApiVersion(FocusTopicService.focusTopics);

export type QueryParams = WithoutVersion<
  ExtractFirstArgAsType<typeof getSubjectMatterExpertTopics>
>;

export const useFocusTopics = (
  queryParams: QueryParams,
  queryOptions?: UseQueryOptions<
    GenericResult<FocusTopicsListDto>,
    ApiError,
    FocusTopicsListDto,
    [QueryKeys.FocusTopics, QueryParams]
  >
) =>
  useQuery(
    [QueryKeys.FocusTopics, queryParams],
    ({ queryKey: [_key, input] }) => getSubjectMatterExpertTopics(input),
    {
      select: (data) => data.payload,
      ...queryOptions,
    }
  );
