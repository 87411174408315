import _ from 'lodash';
import { uniqBy } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeDto, EmployeeSkillDto, SkillDto, TranslationDto } from '../../../../data-access';
import {
  maxCertificateCount,
  maxEducationCount,
  maxProjectsCount,
  maxSectorCount,
  maxSkillCount,
  type DropdownItem,
} from '../CvDownloadDialogForm';

type SkillWithLevel = Pick<SkillDto, 'id' | 'name'> & Pick<EmployeeSkillDto, 'level'>;

export const useDropdownItems = (cvData: EmployeeDto | undefined) => {
  const { i18n, t } = useTranslation();
  const resolvedLanguage = i18n.resolvedLanguage as keyof TranslationDto;

  const allProjectItems = useMemo((): DropdownItem[] => {
    const templateBasedProjects =
      cvData?.templateBasedProjects.map((project) => ({
        key: project.projectTemplate.id,
        header: project.projectTemplate.name[resolvedLanguage],
        isReferenceProject: project.isReferenceProject,
      })) ?? [];

    const projects =
      cvData?.projects?.map((project) => ({
        key: project.id,
        header: project.name[resolvedLanguage],
        isReferenceProject: project.isReferenceProject,
      })) ?? [];

    // Template based projects should be considered before other projects
    const result = [...templateBasedProjects, ...projects];
    // Reference projects should be considered before everything
    result.sort((a, b) => {
      if (a.isReferenceProject && !b.isReferenceProject) {
        return -1;
      } else if (!a.isReferenceProject && b.isReferenceProject) {
        return 1;
      } else {
        return 0;
      }
    });
    return result;
  }, [cvData, resolvedLanguage]);

  const initialProjectItems = useMemo(() => {
    return allProjectItems.slice(0, maxProjectsCount);
  }, [allProjectItems]);

  const allSectorItems = useMemo((): DropdownItem[] => {
    const sectorsFromTemplateBasedProjects =
      cvData?.templateBasedProjects.map((project) => ({
        key: project.projectTemplate.sector.id,
        header: project.projectTemplate.sector.name[resolvedLanguage],
      })) ?? [];

    const sectorsFromProjects =
      cvData?.projects?.map((project) => ({
        key: project.sector.id,
        header: project.sector.name[resolvedLanguage],
      })) ?? [];

    // Prioritise sectors from template based projects and remove duplicates
    return uniqBy((e) => e.key, [...sectorsFromTemplateBasedProjects, ...sectorsFromProjects]);
  }, [cvData, resolvedLanguage]);

  const initialSectorItems = useMemo(
    () => allSectorItems.slice(0, maxSectorCount),
    [allSectorItems]
  );

  const allCertificateItems = useMemo(
    (): DropdownItem[] =>
      cvData?.certificates.map((certificate) => ({
        key: certificate.certificate.id,
        header: certificate.certificate.name?.[resolvedLanguage],
      })) ?? [],
    [cvData, resolvedLanguage]
  );

  const initialCertificateItems = useMemo(
    () => allCertificateItems.slice(0, maxCertificateCount),
    [allCertificateItems]
  );

  const allEducationItems = useMemo(
    (): DropdownItem[] =>
      cvData?.educations.map((education) => ({
        key: education.id,
        header: education.degree.name[resolvedLanguage],
      })) ?? [],
    [cvData, resolvedLanguage]
  );

  const initialEducationItems = useMemo(
    () => allEducationItems.slice(0, maxEducationCount),
    [allEducationItems]
  );

  const allSkills = useMemo(
    () =>
      cvData?.skills.map(
        (skill): SkillWithLevel => ({
          id: skill.skill.id,
          name: skill.skill.name,
          level: skill.level,
        })
      ) ?? [],
    [cvData]
  );

  const buildSkillDropdownItem = useCallback(
    (skill: SkillWithLevel): DropdownItem => ({
      key: skill.id,
      header: skill.name,
      content: t('employee-detail.master-data.skills.level', { value: skill.level }),
    }),
    [t]
  );

  const allSkillItems = useMemo(
    () => allSkills.map(buildSkillDropdownItem) ?? [],
    [allSkills, buildSkillDropdownItem]
  );

  const initialTopSkillItems = useMemo(
    () =>
      _(allSkills)
        .orderBy(['level', 'name'], ['desc', 'asc'])
        .map(buildSkillDropdownItem)
        .take(maxSkillCount)
        .value(),
    [allSkills, buildSkillDropdownItem]
  );

  return {
    allProjectItems,
    initialProjectItems,
    allSectorItems,
    initialSectorItems,
    allCertificateItems,
    initialCertificateItems,
    allEducationItems,
    initialEducationItems,
    allSkillItems,
    initialTopSkillItems,
  };
};
