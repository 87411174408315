import { Flex } from '@fluentui/react-northstar';
import { SearchWithFilter } from '../components/ClientsSearch/SearchWithFilter';
import { ClientsTable } from '../components/ClientsTable/ClientsTable';
import { BasicLayout } from '../components/Layout/Layout';

export const ClientsPage = () => {
  return (
    <BasicLayout>
      <Flex column gap="gap.large">
        <SearchWithFilter />
        <ClientsTable />
      </Flex>
    </BasicLayout>
  );
};
