import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, WithoutVersion, withApiVersion } from '../../helpers';
import { ApiError, Result, SkillGroupService } from '../../open-api/commands';
import { SkillGroupService as QuerySkillGroupService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const updateSkillGroupName = withApiVersion(SkillGroupService.updateSkillGroupName);
const getEventPosition = withApiVersion(QuerySkillGroupService.getEventPosition);

export type UpdateSkillGroupNameInput = WithoutVersion<
  ExtractFirstArgAsType<typeof updateSkillGroupName>
>;

type ResultWithId = Result & { id: string };

export const useUpdateSkillGroupName = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<ResultWithId, ApiError, UpdateSkillGroupNameInput>(
    async (input) => {
      const result = await updateSkillGroupName(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, (id) => getEventPosition({ id })).then(() =>
          queryClient.invalidateQueries(QueryKeys.SkillGroups)
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.skill-group'),
      },
    }
  );
};
