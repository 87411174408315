/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeCertificateDto } from '../models/EmployeeCertificateDto';
import type { EmployeeDto } from '../models/EmployeeDto';
import type { EmployeeEducationDto } from '../models/EmployeeEducationDto';
import type { EmployeePersonalDataDto } from '../models/EmployeePersonalDataDto';
import type { GuidResult } from '../models/GuidResult';

import { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { EmployeeLanguageUpdateDto } from '../models/EmployeeLanguageUpdateDto';
import { ProjectDto } from '../models/ProjectDto';
import { TemplateBasedProjectDto } from '../models/TemplateBasedProjectDto';
import { TranslationDto } from '../models/TranslationDto';
import { SkillsDto } from '../models/SkillsDto';
import { OriginsUpdateDto } from '../../queries/models/OriginsUpdateDto';
import { FocusTopicsCommandDto } from '../models/FocusTopicsCommandDto';
import { SubjectMatterExpertTopicsCommandDto } from '../models/SubjectMatterExpertTopicsCommandDto';
import { NationalSecurityDto } from '../../queries';

export class EmployeeService {
  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static addEmployee({
    version,
    requestBody,
  }: {
    version: string;
    requestBody?: EmployeeDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/employees',
      path: {
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static changeEmployee({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: EmployeeDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{id}',
      path: {
        id: id,
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static changeEmployeeContact({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: EmployeePersonalDataDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{id}/personaldata',
      path: {
        id: id,
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static addEducation({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: EmployeeEducationDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/employees/{id}/educations',
      path: {
        id: id,
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static changeEducation({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: EmployeeEducationDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{id}/educations/{employeeEducationId}',
      path: {
        id: id,
        version: version,
        employeeEducationId: requestBody?.id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static deleteEducation({
    id,
    version,
    educationId,
  }: {
    id: string;
    version: string;
    educationId: string;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/employees/{id}/educations/{employeeEducationId}',
      path: {
        id: id,
        version: version,
        employeeEducationId: educationId,
      },
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static addCertificate({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: EmployeeCertificateDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/employees/{id}/certificates/',
      path: {
        id: id,
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static changeCertificate({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: EmployeeCertificateDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{id}/certificates/{employeeCertificateId}',
      path: {
        id: id,
        version: version,
        employeeCertificateId: requestBody?.id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static deleteCertificate({
    id,
    version,
    certificateId,
  }: {
    id: string;
    version: string;
    certificateId: string;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/employees/{id}/certificates/{employeeCertificateId}',
      path: {
        id: id,
        version: version,
        employeeCertificateId: certificateId,
      },
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static changeLanguages({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: EmployeeLanguageUpdateDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{id}/languages',
      path: {
        id: id,
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static changeSkills({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: Array<SkillsDto>;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{id}/skills',
      path: {
        id: id,
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static addProject({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: ProjectDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/employees/{id}/projects',
      path: {
        id: id,
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static changeProject({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: ProjectDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{id}/projects/{employeeProjectId}',
      path: {
        id: id,
        version: version,
        employeeProjectId: requestBody?.id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static deleteProject({
    id,
    version,
    projectId,
  }: {
    id: string;
    version: string;
    projectId?: string;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/employees/{id}/projects/{employeeProjectId}',
      path: {
        id: id,
        version: version,
        employeeProjectId: projectId,
      },
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static addTemplateBasedProject({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: TemplateBasedProjectDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/employees/{id}/template-based-projects',
      path: {
        id: id,
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static changeTemplateBasedProject({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: TemplateBasedProjectDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{id}/template-based-projects/{employeeProjectId}',
      path: {
        id: id,
        version: version,
        employeeProjectId: requestBody?.id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static deleteTemplateBasedProject({
    id,
    version,
    projectId,
  }: {
    id: string;
    version: string;
    projectId?: string;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/employees/{id}/template-based-projects/{employeeProjectId}',
      path: {
        id: id,
        version: version,
        employeeProjectId: projectId,
      },
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static uploadEmployeePhotoAsync({
    employeeId,
    version,
    formData,
  }: {
    employeeId: string;
    version: string;
    formData?: {
      File: Blob;
      contentType?: string;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/employees/{employeeId}/photo',
      path: {
        employeeId: employeeId,
        version: version,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static uploadEmployeeCertificateAsync({
    employeeId,
    version,
    formData,
  }: {
    employeeId: string;
    version: string;
    formData?: {
      content: Blob;
      contentType?: string;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/employees/{employeeId}/certificates/document-upload',
      path: {
        employeeId: employeeId,
        version: version,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static uploadEmployeeEducationAsync({
    employeeId,
    version,
    formData,
  }: {
    employeeId: string;
    version: string;
    formData?: {
      content: Blob;
      contentType?: string;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/employees/{employeeId}/educations/document-upload',
      path: {
        employeeId: employeeId,
        version: version,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static deleteEmployeeCertificateAsync({
    employeeId,
    certificateName,
    version,
  }: {
    employeeId: string;
    certificateName: string;
    version: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/employees/{employeeId}/certificate/{certificateName}',
      path: {
        employeeId: employeeId,
        certificateName: certificateName,
        version: version,
      },
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static deleteEmployeeEducationAsync({
    employeeId,
    educationName,
    version,
  }: {
    employeeId: string;
    educationName: string;
    version: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/employees/{employeeId}/diploma/{educationName}',
      path: {
        employeeId: employeeId,
        educationName: educationName,
        version: version,
      },
    });
  }

  public static changeProfileText({
    employeeId,
    version,
    requestBody,
  }: {
    employeeId: string;
    version: string;
    requestBody?: TranslationDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{employeeId}/profiletext',
      path: { employeeId: employeeId, version: version },
      body: requestBody,
    });
  }

  public static addOrigin({
    version,
    requestBody,
  }: {
    version: string;
    requestBody?: OriginsUpdateDto;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/employees/origin',
      path: {
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  public static deleteOrigin({
    version,
    employeeId,
  }: {
    version: string;
    employeeId: string;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/employees/{employeeId}/origin',
      path: {
        version: version,
        employeeId: employeeId,
      },
      mediaType: 'application/json-patch+json',
    });
  }

  public static setReferenceProject({
    version,
    employeeId,
    projectId,
  }: {
    version: string;
    employeeId: string;
    projectId: string;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{employeeId}/set-reference-project/{projectId}',
      path: {
        version,
        employeeId,
        projectId,
      },
      mediaType: 'application/json-patch+json',
    });
  }

  public static unSetReferenceProject({
    version,
    employeeId,
    projectId,
  }: {
    version: string;
    employeeId: string;
    projectId: string;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{employeeId}/unset-reference-project/{projectId}',
      path: {
        version,
        employeeId,
        projectId,
      },
      mediaType: 'application/json-patch+json',
    });
  }

  public static updateFocusTopics({
    version,
    employeeId,
    requestBody,
  }: {
    version: string;
    employeeId: string;
    requestBody: FocusTopicsCommandDto;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{employeeId}/focus-topics',
      path: {
        version,
        employeeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  public static updateSubjectMatterExpertTopics({
    version,
    employeeId,
    requestBody,
  }: {
    version: string;
    employeeId: string;
    requestBody: SubjectMatterExpertTopicsCommandDto;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{employeeId}/subject-matter-expert-topics',
      path: {
        version,
        employeeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  public static changeNationalSecurityInfo({
    version,
    employeeId,
    requestBody,
  }: {
    version: string;
    employeeId: string;
    requestBody: NationalSecurityDto;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/employees/{employeeId}/national-security-info',
      path: {
        version,
        employeeId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  public static uploadEmployeeNationalSecurityCertificate({
    employeeId,
    version,
    formData,
  }: {
    employeeId: string;
    version: string;
    formData?: {
      content: Blob;
      contentType?: string;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/employees/{employeeId}/national-security-info/document-upload',
      path: {
        employeeId: employeeId,
        version: version,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
}
