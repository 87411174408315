/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientDtoPaginatedListDtoResult } from '../models/ClientDtoPaginatedListDtoResult';
import type { UInt64NullableResult } from '../core/UInt64NullableResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClientService {
  /**
   * @returns ClientDtoPaginatedListDtoResult Success
   * @throws ApiError
   */
  public static clients({
    version,
    searchString,
    skip,
    take = 50,
  }: {
    version: string;
    searchString?: string;
    skip?: number;
    take?: number;
  }): CancelablePromise<ClientDtoPaginatedListDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/clients',
      path: {
        version: version,
      },
      query: {
        searchString: searchString,
        skip: skip,
        take: take,
      },
    });
  }

  /**
   * @returns UInt64NullableResult Success
   * @throws ApiError
   */
  public static getEventPosition({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<UInt64NullableResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/clients/event-position/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }
}
