import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Ti8mDialog } from '../../../../common';
import { EmployeeDto, GenderType, useEditEmployeeContact } from '../../../../../data-access';
import { EmployeePersonalDataDto } from '../../../../../data-access/open-api/commands';
import { EditAdditionalPersonalDataDialogContent } from './EditAdditionalPersonalDataDialogContent';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

export interface EditAdditionalPersonalDataDialogProps {
  employee: EmployeeDto | undefined;
  isDialogOpen: boolean;
  setDialogOpen: (value: boolean) => void;
}

export const EditAdditionalPersonalDataDialog: React.FC<EditAdditionalPersonalDataDialogProps> = ({
  employee,
  isDialogOpen,
  setDialogOpen,
}) => {
  const { t } = useTranslation();
  const [isTouched, setTouched] = useState<boolean>(false);

  const defaultData = useMemo<EmployeePersonalDataDto>(
    () => ({
      id: employee?.id ?? '',
      gender: employee?.gender ?? GenderType.UNDEFINED,
      nationalities: employee?.nationalities?.map((n) => n.id) ?? [],
      hideAddress: employee?.address.isHidden ?? false,
    }),
    [employee]
  );

  const [employeeContact, setEmployeeContact] = useState<EmployeePersonalDataDto>(defaultData);
  useEffect(() => setEmployeeContact(defaultData), [defaultData]);

  const { mutate: editEmployeeContact } = useEditEmployeeContact(
    t('alert.request-objects.employee'),
    t('alert.update-types.edit')
  );

  const onChangeEmployeeContact = useCallback(
    (input: EmployeePersonalDataDto) => {
      setTouched(!isEqual(input, defaultData));
      setEmployeeContact((prev) => ({
        ...prev,
        ...input,
      }));
    },
    [defaultData]
  );

  const handleConfirm = useCallback(() => {
    editEmployeeContact({
      id: employee?.id ?? '',
      requestBody: employeeContact,
    });
  }, [editEmployeeContact, employee?.id, employeeContact]);

  return (
    <Ti8mDialog
      open={isDialogOpen}
      header={t('employee-detail.master-data.additional-personal-data.edit-dialog.header')}
      cancelButton={{
        content: t(
          'employee-detail.master-data.additional-personal-data.edit-dialog.button-cancel'
        ),
      }}
      onCancel={() => {
        setEmployeeContact(defaultData);
        setDialogOpen(false);
        setTouched(false);
      }}
      confirmButton={{
        content: t(
          'employee-detail.master-data.additional-personal-data.edit-dialog.button-confirm'
        ),
        disabled: !isTouched,
      }}
      onConfirm={() => {
        handleConfirm();
        setTouched(false);
        setDialogOpen(false);
      }}
      content={
        <EditAdditionalPersonalDataDialogContent
          value={employeeContact}
          onChange={onChangeEmployeeContact}
        />
      }
      resetOverflow
    />
  );
};
