import { Form } from '@fluentui/react-northstar';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAddCertificate, useUpdateCertificate } from '../../data-access';
import { CertificateDto } from '../../data-access/open-api/commands';
import { AddOrEditDialog } from '../components/AddOrEditDialog';
import { ControlledFormDatepicker } from '../components/ControlledFormDatepicker';
import { ControlledFormInput } from '../components/ControlledFormInput';
import { applyRules } from '../helpers/formRules';

type CertificatesAddOrEditFormDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  currentItem?: CertificateDto;
};

export type FormAttributes = {
  nameEN: string;
  nameDE: string;
  company: string;
  validUntil: string | null;
};

export const CertificatesAddOrEditFormDialog = ({
  open,
  setOpen,
  currentItem,
}: CertificatesAddOrEditFormDialogProps) => {
  const { t } = useTranslation();

  const {
    control,
    trigger,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormAttributes>({
    defaultValues: {
      nameEN: currentItem?.name.en,
      nameDE: currentItem?.name.de,
      company: currentItem?.company,
      validUntil: currentItem?.validUntil,
    },
  });

  const { mutate: update } = useUpdateCertificate();
  const { mutate: add } = useAddCertificate();

  const onSubmit = async (data: FormAttributes) => {
    if (await trigger(undefined, { shouldFocus: true })) {
      const requestBody: CertificateDto = {
        name: { en: data.nameEN, de: data.nameDE },
        company: data.company,
        validUntil: data.validUntil,
      };

      if (currentItem?.id) {
        update({
          id: currentItem.id,
          requestBody,
        });
      } else {
        add({ requestBody });
      }
      setOpen(false);
    }
  };

  return (
    <AddOrEditDialog
      open={open}
      setOpen={setOpen}
      title={t('admin.certificates.name_one')}
      currentItem={currentItem}
      preventSave={!isDirty}
      onConfirm={handleSubmit(onSubmit)}
      content={
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ControlledFormInput
            control={control}
            rules={applyRules(['required', 'minLength'])}
            name="nameEN"
            label={t('admin.common.name-en')}
          />

          <ControlledFormInput
            control={control}
            rules={applyRules(['required', 'minLength'])}
            name="nameDE"
            label={t('admin.common.name-de')}
          />

          <ControlledFormInput
            control={control}
            rules={applyRules(['required', 'minLength'])}
            name="company"
            label={t('admin.certificates.company')}
          />

          <ControlledFormDatepicker
            control={control}
            name="validUntil"
            label={t('admin.certificates.valid-until')}
          />

          {/* Work around to submit a form when user presses Enter */}
          <button type="submit" hidden />
        </Form>
      }
    />
  );
};
