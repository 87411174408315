import { Button, EditIcon, Flex, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Ti8mPill } from '../common';
import { Ti8mDialog } from '../common/Ti8mDialog';
import { SubjectMatterExpertTopicDto } from '../../data-access/open-api/queries/models/SubjectMatterExpertTopicDto';
import { AddSubjectMatterExpertDialogForm } from './AddSubjectMatterExpertTopicDialog/AddSubjectMatterExpertTopicDialogForm';
import { AddCircleIcon } from '../../icons';
import { useUpdateSubjectMatterExpertTopics } from '../../data-access';
import { EditSubjectMatterExpertDialogForm } from './EditSubjectMatterExpertTopicDialog/EditSubjectMatterExpertTopicDialogForm';

interface EmployeeSkillsSubjectMatterExpertTopicsProps {
  employeeId: string;
  isEditable: boolean;
  subjectMatterExpertTopics: Array<SubjectMatterExpertTopicDto>;
  isEditor: boolean;
}

export const EmployeeSkillsSubjectMatterExpertTopics = ({
  employeeId,
  isEditable,
  subjectMatterExpertTopics,
  isEditor,
}: EmployeeSkillsSubjectMatterExpertTopicsProps) => {
  const { t } = useTranslation();
  const [isAddDialogOpen, setAddDialogVisibility] = useState<boolean>(false);
  const [isEditDialogOpen, setEditDialogVisibility] = useState<boolean>(false);
  const [editSubjectMattersExpertTopics, setEditSubjectMattersExpertTopics] = useState<
    Array<SubjectMatterExpertTopicDto>
  >([]);
  const [isTouched, setTouched] = useState<boolean>(false);
  const { mutate: updateSubjectMatterExpertTopics } = useUpdateSubjectMatterExpertTopics();

  const setSubjectMatterExpertState = useCallback(() => {
    setEditSubjectMattersExpertTopics(subjectMatterExpertTopics ?? []);
  }, [subjectMatterExpertTopics]);

  useEffect(() => {
    setSubjectMatterExpertState();
  }, [setSubjectMatterExpertState]);

  const resetAddDialog = () => {
    setAddDialogVisibility(false);
    setEditDialogVisibility(false);
    setSubjectMatterExpertState();
    setTouched(false);
  };

  const handleConfirmEditTopics = useCallback(() => {
    updateSubjectMatterExpertTopics({
      employeeId: employeeId,
      requestBody: {
        subjectMatterExpertTopics: editSubjectMattersExpertTopics.map((topic) => ({
          subjectMatterExpertTopicId: topic.id,
        })),
      },
    });
    setAddDialogVisibility(false);
    setEditDialogVisibility(false);
  }, [editSubjectMattersExpertTopics, employeeId, updateSubjectMatterExpertTopics]);

  return (
    <>
      <Flex space="between">
        <Text
          weight="semibold"
          content={t('employee-detail.master-data.skills.subject-matter-expert-topics-header')}
          size="large"
        />
        <Flex gap="gap.small">
          <Button
            iconOnly
            text
            disabled={!isEditable || !isEditor}
            size="small"
            primary
            icon={<AddCircleIcon size="large" />}
            onClick={() => {
              setAddDialogVisibility(true);
            }}
          />
          <Ti8mDialog
            open={isAddDialogOpen}
            header={t('employee-detail.master-data.skills.add-subjects-matter-dialog.header')}
            cancelButton={t(
              'employee-detail.master-data.skills.add-subjects-matter-dialog.button-cancel'
            )}
            onCancel={resetAddDialog}
            confirmButton={{
              content: t(
                'employee-detail.master-data.skills.add-subjects-matter-dialog.button-save'
              ),
              disabled: !isTouched,
            }}
            onConfirm={handleConfirmEditTopics}
            content={
              <AddSubjectMatterExpertDialogForm
                editSubjectMatterExpertTopics={editSubjectMattersExpertTopics}
                setEditSubjectMatterExpertTopics={setEditSubjectMattersExpertTopics}
                setTouched={setTouched}
              />
            }
            resetOverflow
          />
          <Button
            iconOnly
            text
            disabled={subjectMatterExpertTopics.length === 0 || !isEditable || !isEditor}
            primary
            size="small"
            icon={<EditIcon size="large" />}
            onClick={() => setEditDialogVisibility(true)}
          />
          <Ti8mDialog
            open={isEditDialogOpen}
            header={t('employee-detail.master-data.skills.edit-subject-matter-dialog.header')}
            cancelButton={t(
              'employee-detail.master-data.skills.edit-subject-matter-dialog.button-cancel'
            )}
            onCancel={resetAddDialog}
            confirmButton={{
              content: t(
                'employee-detail.master-data.skills.edit-subject-matter-dialog.button-save'
              ),
              disabled: !isTouched,
            }}
            onConfirm={handleConfirmEditTopics}
            content={
              <EditSubjectMatterExpertDialogForm
                editSubjectMatterExpertTopics={editSubjectMattersExpertTopics}
                setEditSubjectMatterExpertTopics={setEditSubjectMattersExpertTopics}
                setTouched={setTouched}
              />
            }
            resetOverflow
          />
        </Flex>
      </Flex>
      <Flex gap="gap.small">
        {subjectMatterExpertTopics.map((topic) => (
          <Ti8mPill content={topic.name.en} maxTextLength={15} key={topic.id} />
        ))}
      </Flex>
    </>
  );
};
