import { DropdownProps, Flex, Form, FormField, FormInput, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { Ti8mDropdown } from '../../common';
import { useCallback, useState } from 'react';
import { useInfiniteFocusTopics } from '../../../data-access/hooks/queries/use-infinite-focus-topics';
import { Link } from '@fluentui/react';
import { Ti8mDialog } from '../../common/Ti8mDialog';
import { useForm } from 'react-hook-form';
import { useAddFocusTopic } from '../../../data-access/hooks/commands/use-focus-topics';
import { FocusTopicDto } from '../../../data-access/open-api/queries/models/FocusTopicDto';

interface AddFocusTopicDialogFormProps {
  editFocusTopics: Array<FocusTopicDto>;
  setEditFocusTopics: (focusTopics: Array<FocusTopicDto>) => void;
  setTouched: (touched: boolean) => void;
}

export const AddFocusTopicsDialogForm = ({
  editFocusTopics,
  setEditFocusTopics,
  setTouched,
}: AddFocusTopicDialogFormProps) => {
  const { t } = useTranslation();
  const [topicsSearchString, setTopicsSearchString] = useState('');
  const { focusTopics, hasMoreFocusTopics, fetchMoreFocusTopics, isFocusTopicsError } =
    useInfiniteFocusTopics(topicsSearchString);
  const [requestNewDialogVisibility, setRequestNewDialogVisibility] = useState<boolean>(false);

  const onAddFocusTopic = (data: DropdownProps) => {
    const newFocusTopic = (data.value as { header: string; key: FocusTopicDto }).key;
    setEditFocusTopics([...editFocusTopics, newFocusTopic]);
    setTouched(true);
  };

  const handleTopicsSearchQueryChange = useCallback(
    (data: DropdownProps) => {
      if (data.searchQuery !== undefined) {
        setTopicsSearchString(data.searchQuery);
      }
    },
    [setTopicsSearchString]
  );

  // Request new focus topic dialog

  const {
    register,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<{ topicNameDE: string; topicNameEN: string }>();

  register('topicNameDE', {
    required: { value: true, message: t('form-validation.messages.required') },
  });

  register('topicNameEN', {
    required: { value: true, message: t('form-validation.messages.required') },
  });

  const { mutate: addFocusTopic } = useAddFocusTopic();

  const onConfirmRequestFocusTopic = async () => {
    if (await trigger(undefined, { shouldFocus: true })) {
      addFocusTopic({
        requestBody: {
          name: {
            de: getValues().topicNameDE,
            en: getValues().topicNameEN,
          },
        },
      });
      setRequestNewDialogVisibility(false);
    }
  };

  return (
    <Flex column gap="gap.small">
      <Text
        content={t('employee-detail.master-data.skills.add-focus-topics-dialog.label')}
        weight="semibold"
      />
      <Ti8mDropdown
        autoFocus
        items={focusTopics.map((item) => ({
          key: item,
          header: item.name.en,
        }))}
        onScrollLoadMoreItems={fetchMoreFocusTopics}
        canLoadMoreItems={hasMoreFocusTopics}
        hasDataError={isFocusTopicsError}
        onChange={onAddFocusTopic}
        search
        onSearchQueryChange={handleTopicsSearchQueryChange}
        placeholder={t('employee-detail.master-data.skills.add-focus-topics-dialog.placeholder')}
      />
      <Flex gap="gap.smaller">
        <Text
          content={t('employee-detail.master-data.skills.add-focus-topics-dialog.did-not-find')}
          size="small"
        />
        <Link onClick={() => setRequestNewDialogVisibility(true)}>
          <Text
            content={t(
              'employee-detail.master-data.skills.add-focus-topics-dialog.request-focus-topic'
            )}
            size="small"
          />
        </Link>
      </Flex>
      <Ti8mDialog
        header={t('employee-detail.master-data.skills.request-new-focus-topic-dialog.header')}
        cancelButton={t(
          'employee-detail.master-data.skills.request-new-focus-topic-dialog.cancel-button'
        )}
        onCancel={() => setRequestNewDialogVisibility(false)}
        confirmButton={t(
          'employee-detail.master-data.skills.request-new-focus-topic-dialog.button-save'
        )}
        onConfirm={onConfirmRequestFocusTopic}
        showCloseHeaderAction
        open={requestNewDialogVisibility}
        content={
          <Form>
            <FormField>
              <Flex column gap="gap.medium">
                <FormInput
                  label={t(
                    'employee-detail.master-data.skills.request-new-focus-topic-dialog.label-de'
                  )}
                  fluid
                  errorMessage={errors.topicNameDE && errors.topicNameDE.message}
                  onChange={(_, data) =>
                    setValue('topicNameDE', data?.value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                />
                <FormInput
                  label={t(
                    'employee-detail.master-data.skills.request-new-focus-topic-dialog.label-en'
                  )}
                  fluid
                  errorMessage={errors.topicNameEN && errors.topicNameEN.message}
                  onChange={(_, data) =>
                    setValue('topicNameEN', data?.value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                />
              </Flex>
            </FormField>
          </Form>
        }
      />
    </Flex>
  );
};
