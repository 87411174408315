import { EmployeeSkillsCard } from './EmployeeSkillsCard';
import { Flex } from '@fluentui/react-northstar';
import { EmployeeDto } from '../../data-access';

interface EmployeeSkillsProps {
  employee?: EmployeeDto;
  isEditable: boolean;
}

export const EmployeeSkillsTab = ({ employee, isEditable }: EmployeeSkillsProps) => {
  return (
    <Flex column gap="gap.large" style={{ marginTop: '24px' }}>
      <EmployeeSkillsCard employee={employee} isEditable={isEditable} />
    </Flex>
  );
};
