import { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { RoleListDtoResult } from '../models/RoleListDtoResult';

export class RoleService {
  public static roles({ version }: { version: string }): CancelablePromise<RoleListDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/roles',
      path: {
        version: version,
      },
    });
  }
}
