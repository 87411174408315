import { app } from '@microsoft/teams-js';

/*
 * For some reason, app.isInitialized() always returns true once
 * app.initialize() has been called, even if the call throws an error.
 *
 * Because of this, we use this hook instead that uses the failure of
 * app.initialize() to determine if the app is running in Teams.
 *
 * For more info see: https://github.com/OfficeDev/microsoft-teams-library-js/issues/310
 *
 * Returns whether the app is running in Teams or not.
 */
export const runsInTeams = async () => {
  try {
    await app.initialize();
    return true;
  } catch {
    console.log('teams-js initialization failed');
    return false;
  }
};
