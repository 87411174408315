import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import { ApiError, EmployeeOriginsDto, EmployeeService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { EmployeeOriginsDtoResult } from '../../open-api/queries/models/EmployeeOriginsDtoResult';

const getOrigins = () => EmployeeService.getOrigins({ version: env('REACT_APP_API_VERSION') });

export const useOrigins = (
  queryOptions?: UseQueryOptions<
    EmployeeOriginsDtoResult,
    ApiError,
    EmployeeOriginsDto[],
    [QueryKeys.Origins]
  >
) =>
  useQuery([QueryKeys.Origins], () => getOrigins(), {
    ...queryOptions,
    select: (data) => data.payload,
  });
