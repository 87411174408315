import { Button, Flex, Input, PaperclipIcon, TrashCanIcon } from '@fluentui/react-northstar';
import { ChangeEvent, useCallback, useRef, useState } from 'react';

type Ti8mFileUploaderProps = {
  onFileChanged: (file: File | undefined) => void;
  placeholder?: string;
  selectedFile?: string;
  asField?: boolean;
};

export const Ti8mFileUploader = ({
  onFileChanged,
  placeholder,
  selectedFile = '',
  asField = false,
}: Ti8mFileUploaderProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>(selectedFile);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const onFileSelected = useCallback(
    (name: string, file?: File) => {
      setFileName(name);
      onFileChanged(file);
    },
    [setFileName, onFileChanged]
  );

  return (
    <Flex column>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        accept=".pdf,.jpg,.png"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.files) {
            onFileSelected(e.target.files[0].name, e.target.files[0]);
          }
        }}
      />
      {fileName === '' && !asField ? (
        <Flex>
          <Button content={placeholder} flat primary onClick={handleClick} />
        </Flex>
      ) : (
        <Flex column gap="gap.smaller">
          <Flex style={{ position: 'relative' }}>
            <Input
              readOnly
              styles={{
                '& input': {
                  cursor: 'pointer',
                },
                '& input::selection': {
                  background: 'transparent',
                },
              }}
              onClick={handleClick}
              icon={<PaperclipIcon />}
              iconPosition="start"
              value={fileName || placeholder}
              fluid
            />
            {fileName && (
              <Button
                style={{
                  position: 'absolute',
                  right: '0.45rem',
                  top: '0px',
                  height: '32px',
                }}
                iconOnly
                text
                size="small"
                icon={<TrashCanIcon outline size="medium" />}
                onClick={() => {
                  onFileSelected('', undefined);
                }}
              />
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
