import { LanguageService } from '../../open-api/queries/services/LanguageService';
import { QueryKeys } from '../../../data-access/query-keys';
import { useQuery } from 'react-query';
import { env } from '../../../utils/env';

const getLanguages = () =>
  LanguageService.getAllLanguages({ version: env('REACT_APP_API_VERSION') });

export const useLanguages = () =>
  useQuery(QueryKeys.Languages, ({ queryKey: _ }) => getLanguages(), {
    select: (data) => data.payload,
  });
