import { ComponentSlotStyle, Flex, Pill, Provider, Text } from '@fluentui/react-northstar';

type Ti8mPillProps = {
  content: string;
  maxTextLength?: number;
  color?: string;
  border?: boolean;
  size?: 'small' | 'smaller' | 'medium';
  styles?: ComponentSlotStyle;
  itemId?: string;
  appearance?: 'outline' | 'filled';
  languageLevel?: string;
};

export const Ti8mPill = ({
  content,
  maxTextLength,
  size,
  styles,
  itemId,
  color,
  border,
  appearance,
  languageLevel,
}: Ti8mPillProps) => {
  return (
    <Provider.Consumer
      render={(theme) => {
        return (
          <Pill
            key={itemId}
            rectangular
            size={size}
            styles={styles}
            style={border ? (theme.componentStyles as any)['Pill']['optionalBorder']() : {}}
            color={color}
            appearance={appearance}
          >
            <Flex gap="gap.small">
              <Text
                content={
                  maxTextLength && content.length > maxTextLength
                    ? content.substring(0, maxTextLength).concat('...')
                    : content
                }
              />
              {languageLevel && (
                <Text
                  style={{
                    color: theme.siteVariables.colorScheme['brand']['foreground'],
                    fontWeight: theme.siteVariables['fontWeightSemibold'],
                  }}
                  content={languageLevel}
                />
              )}
            </Flex>
          </Pill>
        );
      }}
    />
  );
};
