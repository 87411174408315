import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, WithoutVersion, withApiVersion } from '../../helpers';
import { ApiError, GuidResult, SectorService } from '../../open-api/commands';
import { SectorService as QuerySectorService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const addSector = withApiVersion(SectorService.addSector);

export type AddSectorInput = WithoutVersion<ExtractFirstArgAsType<typeof addSector>>;

const getEventPosition = withApiVersion(QuerySectorService.getEventPosition);

export const useAddSector = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, AddSectorInput>(addSector, {
    onSuccess: async ({ payload: id, storePosition }) =>
      waitForEvent({ id, storePosition }, (id) => getEventPosition({ id })).then(() =>
        queryClient.invalidateQueries(QueryKeys.Sectors)
      ),
    meta: {
      type: t('alert.update-types.requested'),
      requestObject: t('alert.request-objects.sector'),
    },
  });
};
