import { Flex } from '@fluentui/react-northstar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectorDto, useSectors } from '../../data-access';
import { DefaultLoader } from '../components/DefaultLoader';
import { MasterDataToolbar } from '../components/MasterDataToolbar';
import { SectorsAddOrEditFormDialog } from './SectorsAddOrEditFormDialog';
import { SectorsMasterDataTable } from './SectorsMasterDataTable';

export const SectorsMasterData = () => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<SectorDto>();

  const { data: items = [], isLoading } = useSectors();

  return (
    <Flex column fill>
      <MasterDataToolbar
        DialogComponent={SectorsAddOrEditFormDialog}
        buttonLabel={t('admin.sectors.add')}
      />

      <DefaultLoader isLoading={isLoading}>
        <SectorsMasterDataTable
          items={items}
          onEditItem={(item) => {
            setCurrentItem(item);
            setDialogOpen(true);
          }}
        />
      </DefaultLoader>

      {dialogOpen && (
        <SectorsAddOrEditFormDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          currentItem={currentItem}
        />
      )}
    </Flex>
  );
};
