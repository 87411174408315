import { Button, Flex, List, RadioGroup, Text, TrashCanIcon } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { RadioGroupItemProps } from '@fluentui/react-northstar/dist/es/components/RadioGroup/RadioGroupItem';
import { Language, NativeLanguage } from '../EmployeeSkillsLanguages';
import { LanguageLevelTooltip } from '../LanguageLevelTooltip';
import { LanguageLevelExternalLink } from '../LanguageLevelExternalLink';

export type EditLanguageDialogFormProps = {
  editedNativeLanguages: NativeLanguage[];
  editedLanguages: Language[];
  setEditedLanguages: (editedLanguages: Language[]) => void;
  setEditedNativeLanguages: (editedNativeLanguages: NativeLanguage[]) => void;
  setTouched: (touched: boolean) => void;
};

export const EditLanguageDialogForm = ({
  editedNativeLanguages,
  editedLanguages,
  setEditedLanguages,
  setEditedNativeLanguages,
  setTouched,
}: EditLanguageDialogFormProps) => {
  const { t } = useTranslation();

  const getItems = () => {
    return [
      {
        name: '1',
        key: '1',
        label: <LanguageLevelTooltip level="A1" />,
        value: 1,
      },
      {
        name: '2',
        key: '2',
        label: <LanguageLevelTooltip level="A2" />,
        value: 2,
      },
      {
        name: '3',
        key: '3',
        label: <LanguageLevelTooltip level="B1" />,
        value: 3,
      },
      {
        name: '4',
        key: '4',
        label: <LanguageLevelTooltip level="B2" />,
        value: 4,
      },
      {
        name: '5',
        key: '5',
        label: <LanguageLevelTooltip level="C1" />,
        value: 5,
      },
      {
        name: '6',
        key: '6',
        label: <LanguageLevelTooltip level="C2" />,
        value: 6,
      },
    ];
  };

  const onDeletedNativeLanguage = (id: string) => {
    const updatedNativeLanguages = editedNativeLanguages
      .filter((language) => language.languageId !== id)
      .map((language) => language);
    setEditedNativeLanguages(updatedNativeLanguages);
    setTouched(true);
  };

  const onEditLanguageLevel = (data: RadioGroupItemProps | undefined, id: string) => {
    const updatedLanguages = editedLanguages.map((language) => {
      if (language.languageId === id) {
        return {
          languageId: language.languageId,
          level: data?.value as number,
          name: language.name,
        };
      }
      return {
        languageId: language.languageId,
        level: language.level,
        name: language.name,
      };
    });
    setEditedLanguages(updatedLanguages);
    setTouched(true);
  };

  const onDeleteLanguage = (id: string) => {
    const updatedLanguages = editedLanguages.filter((language) => language.languageId !== id);
    setEditedLanguages(updatedLanguages);
    setTouched(true);
  };

  return (
    <Flex column gap="gap.medium">
      {editedNativeLanguages.length !== 0 && (
        <Text
          content={t('employee-detail.master-data.skills.edit-languages-dialog.native-language')}
          weight="semibold"
        />
      )}
      <List>
        {editedNativeLanguages.map((nativeLanguage, index) => (
          <List.Item
            key={nativeLanguage.languageId}
            index={index}
            styles={{ padding: 0 }}
            content={
              <Flex space="between">
                <Text size="medium" content={nativeLanguage.name} />
                <Button
                  iconOnly
                  circular
                  primary
                  size="small"
                  icon={<TrashCanIcon outline />}
                  onClick={() => onDeletedNativeLanguage(nativeLanguage.languageId)}
                />
              </Flex>
            }
          />
        ))}
      </List>
      {editedLanguages.length !== 0 && (
        <Text
          content={t(
            'employee-detail.master-data.skills.edit-languages-dialog.additional-languages'
          )}
          weight="semibold"
        />
      )}
      <List>
        {editedLanguages.map((language, index) => (
          <List.Item
            key={language.languageId}
            index={index}
            styles={{ padding: 0 }}
            content={
              <Flex space="between">
                <Text size="medium" content={language.name} />
                <Flex gap="gap.medium">
                  <RadioGroup
                    items={getItems()}
                    defaultCheckedValue={language.level}
                    onCheckedValueChange={(event, data) =>
                      onEditLanguageLevel(data, language.languageId)
                    }
                    styles={{ marginTop: '-0.4rem' }}
                  />
                  <Button
                    iconOnly
                    circular
                    primary
                    size="small"
                    icon={<TrashCanIcon outline />}
                    onClick={() => onDeleteLanguage(language.languageId)}
                  />
                </Flex>
              </Flex>
            }
          />
        ))}
      </List>
      <LanguageLevelExternalLink />
    </Flex>
  );
};
