import { Avatar, SizeValue } from '@fluentui/react-northstar';
import React from 'react';

interface EmployeeAvatarProps {
  firstName: string;
  lastName: string;
  photo?: string | null;
  id?: string;
  size?: SizeValue;
}

export const EmployeeAvatar = React.memo(
  ({ firstName, lastName, photo, size = 'small' }: EmployeeAvatarProps) => {
    const getName = () => {
      return `${firstName} ${lastName}`;
    };

    return <Avatar size={size} image={photo} name={getName()} />;
  }
);
