import { Result } from '../../open-api/commands';
import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, withApiVersion, WithoutVersion } from '../../helpers';
import { ApiError, ProjectTemplateService } from '../../open-api/commands';
import { ProjectTemplateService as QueryProjectTemplateService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { useTranslation } from 'react-i18next';
import { waitForEvent } from './wait-for-event';

const updateProjectTemplate = withApiVersion(ProjectTemplateService.updateProjectTemplate);
const getEventPosition = withApiVersion(QueryProjectTemplateService.getEventPosition);

type UpdateProjectTemplateInput = WithoutVersion<
  ExtractFirstArgAsType<typeof ProjectTemplateService.updateProjectTemplate>
>;
type GuidResultWithId = Result & {
  id: string;
};

export const useUpdateProjectTemplate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, UpdateProjectTemplateInput>(
    async (input) => {
      const result = await updateProjectTemplate(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, (id) => getEventPosition({ id })).then(() =>
          queryClient.refetchQueries(QueryKeys.ProjectTemplates)
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.project'),
      },
    }
  );
};
