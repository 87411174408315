import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, withApiVersion, WithoutVersion } from '../../helpers';
import { ApiError, Result, CertificateService } from '../../open-api/commands';
import { CertificateService as QueryCertificateService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

type GuidResultWithId = Result & {
  id: string;
};

const deleteCertificate = withApiVersion(CertificateService.deleteCertificate);
const getEventPosition = withApiVersion(QueryCertificateService.getEventPosition);

export type DeleteCertificateInput = WithoutVersion<
  ExtractFirstArgAsType<typeof deleteCertificate>
>;

export const useDeleteCertificate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, DeleteCertificateInput>(
    async (input) => {
      const result = await deleteCertificate(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, (id) => getEventPosition({ id }), false).then(() =>
          queryClient.invalidateQueries(QueryKeys.Certificates)
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.certificate'),
      },
    }
  );
};
