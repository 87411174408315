import { AddIcon, Button, Flex, Input } from '@fluentui/react-northstar';
import { debounce as _debounce } from 'lodash';
import { ComponentType, useState } from 'react';

const SEARCH_DELAY = 300;

// Delays the data filtering by 300ms, waiting until user
// stops typing to limit the request sent to the server.
const debounce = _debounce((fn: () => void) => fn(), SEARCH_DELAY);

type DialogComponent = ComponentType<{
  open: boolean;
  setOpen: (value: boolean) => void;
}>;

type CommonProps = {
  buttonLabel: string;
  DialogComponent: DialogComponent;
};

type WithSearch = {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  inputPlaceholderText: string;
};

type WithoutSearch = {
  searchTerm?: never;
  setSearchTerm?: never;
  inputPlaceholderText?: never;
};

type MasterDataToolbarProps = CommonProps & (WithSearch | WithoutSearch);

export const MasterDataToolbar = ({
  buttonLabel,
  DialogComponent,
  searchTerm,
  setSearchTerm,
  inputPlaceholderText,
}: MasterDataToolbarProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Flex>
      {searchTerm !== undefined ? (
        <Input
          autoFocus
          placeholder={inputPlaceholderText}
          style={{ width: '50%' }}
          fluid
          defaultValue={searchTerm}
          onChange={(_, data) => debounce(() => setSearchTerm?.(`${data?.value}`))}
          clearable
        />
      ) : null}

      <Flex.Item push>
        <Button
          primary
          style={{ alignSelf: 'end' }}
          icon={<AddIcon />}
          onClick={() => setDialogOpen(true)}
          content={buttonLabel}
        />
      </Flex.Item>

      {dialogOpen ? <DialogComponent open={dialogOpen} setOpen={setDialogOpen} /> : null}
    </Flex>
  );
};
