import {
  EmployeeCertificateDto as EmployeeCertificateQueryDto,
  useAddEmployeeCertificate,
  useUploadEmployeeCertificate,
  useChangeEmployeeCertificate,
} from '../../../../../data-access';
import { EmployeeCertificateDto } from '../../../../../data-access/open-api/commands';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EducationDialogForm } from './EmployeeCertificateDialogForm';
import { useForm } from 'react-hook-form';
import { Ti8mDialog } from '../../../../common/Ti8mDialog';

const defaultCommandEmployeeCertificate: EmployeeCertificateDto = {
  certificateId: '',
  issuedOn: '',
  validUntil: undefined,
  certificateFileName: null,
  originalFilename: null,
};

type EmployeeCertificateDialogProps = {
  isDialogOpen: boolean;
  isEdit: boolean;
  employeeId: string;
  employeeCertificateDto?: EmployeeCertificateQueryDto;
  setDialogOpen: (value: boolean) => void;
  onRequestCertificateClick?: () => void;
};

export const EmployeeCertificateDialog = ({
  isDialogOpen,
  isEdit,
  employeeId,
  employeeCertificateDto = undefined,
  setDialogOpen,
  onRequestCertificateClick,
}: EmployeeCertificateDialogProps) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isDirty },
    setValue,
    getValues,
    trigger,
  } = useForm<EmployeeCertificateDto>({
    values: employeeCertificateDto
      ? ({
          id: employeeCertificateDto.id,
          certificateId: employeeCertificateDto.certificate.id,
          issuedOn: employeeCertificateDto.issuedOn,
          validUntil: employeeCertificateDto.validUntil,
          certificateFileName: employeeCertificateDto.certificateFile?.name || null,
          originalFilename: employeeCertificateDto.certificateFile?.displayName || null,
        } satisfies EmployeeCertificateDto)
      : defaultCommandEmployeeCertificate,
  });
  const [certificateFile, setCertificateFile] = useState<File | undefined>();

  const { mutate: addEmployeeCertificate } = useAddEmployeeCertificate();

  const { mutate: changeEmployeeCertificate } = useChangeEmployeeCertificate();

  const { mutateAsync: uploadEmployeeCertificate } = useUploadEmployeeCertificate();

  const onSubmit = useCallback(
    async (data: EmployeeCertificateDto) => {
      let certificateFileName = '';
      if (certificateFile) {
        let result = await uploadEmployeeCertificate({
          employeeId: employeeId ?? '',
          formData: { content: certificateFile as Blob, contentType: certificateFile?.type },
        });
        certificateFileName = result?.payload ?? '';
      }
      if (isEdit) {
        changeEmployeeCertificate({
          id: employeeId,
          requestBody: {
            ...data,
            certificateFileName: certificateFileName || data.certificateFileName,
            originalFilename: certificateFile?.name ?? data.originalFilename,
          },
        });
      } else {
        addEmployeeCertificate({
          id: employeeId,
          requestBody: {
            ...data,
            certificateFileName: certificateFileName,
            originalFilename: certificateFile?.name ?? null,
          },
        });
      }
    },
    [
      certificateFile,
      employeeId,
      isEdit,
      uploadEmployeeCertificate,
      addEmployeeCertificate,
      changeEmployeeCertificate,
    ]
  );

  const handleConfirm = useCallback(async () => {
    if (await trigger()) {
      onSubmit(getValues());
      setDialogOpen(false);
    }
  }, [onSubmit, getValues, trigger, setDialogOpen]);

  const handleDocumentUploadPreparation = useCallback(
    (file: File | undefined) => {
      setCertificateFile(file);
    },
    [setCertificateFile]
  );

  return (
    <Ti8mDialog
      open={isDialogOpen}
      header={
        isEdit
          ? t('employee-detail.master-data.certificates.add-certificate-dialog.header-edit')
          : t('employee-detail.master-data.certificates.add-certificate-dialog.header')
      }
      cancelButton={{
        content: t('employee-detail.master-data.certificates.add-certificate-dialog.button-cancel'),
      }}
      onCancel={() => {
        setDialogOpen(false);
      }}
      confirmButton={{
        content: isEdit
          ? t('employee-detail.master-data.certificates.add-certificate-dialog.button-update')
          : t('employee-detail.master-data.certificates.add-certificate-dialog.button-add'),
        disabled: !isDirty,
      }}
      onConfirm={() => {
        handleConfirm();
      }}
      content={
        <EducationDialogForm
          errors={errors}
          defaultValues={employeeCertificateDto}
          register={register}
          setValue={setValue}
          onDocumentUploadPreparation={handleDocumentUploadPreparation}
          onRequestCertificateClick={onRequestCertificateClick}
        />
      }
      resetOverflow
    />
  );
};
