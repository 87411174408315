import { FC, useEffect, useState } from 'react';
import { runsInTeams } from '../../utils/platform';
import { MsalTokenProviderWrapper } from './msalTokenProvider';
import { TeamsTokenProvider } from './teamsTokenProvider';

export const TokenProvider: FC = ({ children }) => {
  const [inTeams, setInTeams] = useState<Boolean>();

  useEffect(() => {
    const checkIfInsideTeams = async () => {
      const runsInsideTeams = await runsInTeams();
      setInTeams(runsInsideTeams);
    };

    checkIfInsideTeams();
  }, []);

  switch (inTeams) {
    case true:
      console.log('Running inside MS Teams');
      return <TeamsTokenProvider>{children}</TeamsTokenProvider>;
    case false:
      console.log('Running outside of MS Teams');
      return <MsalTokenProviderWrapper>{children}</MsalTokenProviderWrapper>;
    default:
      return null;
  }
};
