import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { CancelablePromise } from '../core/CancelablePromise';
import { GenerateCvDto } from '../models/GenerateCvDto';
import { GenericResult } from '../models/GenericResult';

export class CurriculumVitaeService {
  public static generateCv({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody: GenerateCvDto;
  }): CancelablePromise<GenericResult<string>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/q/api/v{version}/cvs/generate/{id}',
      path: {
        version: version,
        id: id,
      },
      body: requestBody,
    });
  }
}
