import { useTranslation } from 'react-i18next';
import { Ti8mDialog, Ti8mDialogProps } from '../../components/common/Ti8mDialog';

type AddOrEditDialogProps = Omit<Ti8mDialogProps, 'onCancel'> & {
  setOpen: (action: boolean) => void;
  preventSave: boolean;
  currentItem?: unknown;
  title: string;
} & Partial<Pick<Ti8mDialogProps, 'onCancel'>>;

export const AddOrEditDialog = ({
  currentItem,
  title,
  setOpen,
  preventSave,
  ...otherProps
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();

  return (
    <Ti8mDialog
      {...otherProps}
      header={
        currentItem
          ? t('admin.actions.edit', { value: title })
          : t('admin.actions.add', { value: title })
      }
      cancelButton={{ content: t('shared.buttons.cancel') }}
      onCancel={() => setOpen(false)}
      confirmButton={{
        content: currentItem ? t('shared.buttons.update') : t('shared.buttons.add'),
        disabled: preventSave,
      }}
    />
  );
};
