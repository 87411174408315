import { Flex, Grid } from '@fluentui/react-northstar';
import { EmployeeDto } from '../../../../data-access';
import { useTranslation } from 'react-i18next';
import { convertToMonthDayYear } from '../../../../utils/dateConverter';
import { PersonalInformation } from './PersonalInformation';

type EmployeePersonalDataProps = {
  employee?: EmployeeDto;
};

export const EmployeePersonalData = ({ employee }: EmployeePersonalDataProps) => {
  const { t } = useTranslation();
  return (
    <Flex column gap="gap.large">
      <Grid columns="repeat(5,1fr)">
        <PersonalInformation
          label={t('employee-detail.master-data.personal-data.content.email-label')}
          data={employee?.eMail ?? '—'}
        />
        <PersonalInformation
          label={t('employee-detail.master-data.personal-data.content.department')}
          data={employee?.organization?.name ?? '—'}
        />
        <PersonalInformation
          label={t('employee-detail.master-data.personal-data.content.pensum')}
          data={employee?.pensum?.toString().concat(' %') ?? '—'}
        />
        <PersonalInformation
          label={t('employee-detail.master-data.personal-data.content.start-date')}
          data={employee?.startDate ? convertToMonthDayYear(employee?.startDate) : '—'}
        />

        <PersonalInformation
          label={t('employee-detail.master-data.personal-data.content.year-of-birth')}
          data={employee?.birthYear ? employee.birthYear : '—'}
        />
      </Grid>
    </Flex>
  );
};
