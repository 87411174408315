import { EmployeeOverviewDto, useEmployees } from '../../../data-access';

export const useEmployeesData = (searchString: string): EmployeeOverviewDto[] => {
  const result = useEmployees({
    searchString: searchString,
    roles: undefined,
    certificates: undefined,
    skills: undefined,
    skip: 0,
    take: 50,
  });
  return result.data?.items ?? [];
};
