import { Card, CardBody, Divider, Flex } from '@fluentui/react-northstar';
import { EmployeeDto } from '../../data-access';
import { useTranslation } from 'react-i18next';
import { EmployeeSkills } from './EmployeeSkills';
import { EmployeeSkillsLanguages } from './EmployeeSkillsLanguages';
import { useState } from 'react';
import { Ti8mCardHeader } from '../common/Ti8mCardHeader';
import { EmployeeSkillsFocusTopics } from './EmployeeSkillsFocusTopics';
import { EmployeeSkillsSubjectMatterExpertTopics } from './EmployeeSkillsSubjectMatterExpertTopics';
import { useAuthInfo } from '../../auth/use-authinfo';

type EmployeeSkillsCardProps = {
  employee: EmployeeDto | undefined;
  isEditable: boolean;
};

export const EmployeeSkillsCard = ({ employee, isEditable }: EmployeeSkillsCardProps) => {
  const { t } = useTranslation();
  const [isSkillsCardUnfold, setIsSkillsCardUnfold] = useState<boolean>(true);
  const { hasRole } = useAuthInfo();

  return (
    <Card>
      <Ti8mCardHeader
        cardHeaderText={t('employee-detail.master-data.skills.header')}
        isEditable={false}
        isCardUnfold={isSkillsCardUnfold}
        setCardUnfold={setIsSkillsCardUnfold}
      />
      {isSkillsCardUnfold && (
        <CardBody>
          <Flex column gap="gap.small">
            <EmployeeSkillsLanguages
              employeeId={employee?.id}
              isEditable={isEditable}
              nativeLanguages={employee?.nativeLanguages ?? []}
              languages={employee?.languages ?? []}
            />
            <Divider />
            <EmployeeSkills
              employeeId={employee?.id ?? ''}
              isEditable={isEditable}
              skills={employee?.skills ?? []}
            />
            <Divider />
            <EmployeeSkillsFocusTopics
              employeeId={employee?.id ?? ''}
              isEditable={isEditable}
              focusTopics={employee?.focusTopics ?? []}
            />
            <Divider />
            <EmployeeSkillsSubjectMatterExpertTopics
              employeeId={employee?.id ?? ''}
              isEditable={isEditable}
              subjectMatterExpertTopics={employee?.subjectMatterExpertTopics ?? []}
              isEditor={hasRole('SME-Editor')}
            />
          </Flex>
        </CardBody>
      )}
    </Card>
  );
};
