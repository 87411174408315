/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Result } from '../models/Result';
import type { Result_1 } from '../models/Result_1';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { GuidResult } from '../models/GuidResult';
import { SkillCommandDto } from '../models';

export class SkillService {
  /**
   * @returns any Success
   * @throws ApiError
   */
  public static requestSkill({
    version,
    requestBody,
  }: {
    version: string;
    requestBody?: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/skills/request',
      path: {
        version: version,
      },
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
      mediaType: 'application/json',
    });
  }

  public static updateSkill({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: SkillCommandDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/skills/{id}',
      path: {
        id,
        version,
      },
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
      mediaType: 'application/json',
    });
  }

  public static addSkill({
    version,
    requestBody,
  }: {
    version: string;
    requestBody: SkillCommandDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/skills',
      path: {
        version: version,
      },
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
      mediaType: 'application/json',
    });
  }

  public static deleteSkill({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/skills/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }
}
