import { Flex, Text } from '@fluentui/react-northstar';
import React from 'react';

type NationalSecurityInformationProps = {
  label: string;
  data: React.ReactNode | null | undefined;
};

export const NationalSecurityInformation = ({ label, data }: NationalSecurityInformationProps) => {
  return (
    <Flex column gap="gap.small">
      <Text content={label} weight="bold" />
      <Text content={data} />
    </Flex>
  );
};
