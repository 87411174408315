import { useTranslation } from 'react-i18next';
import {
  Flex,
  Text,
  Input,
  Grid,
  DropdownProps,
  FormInput,
  FormField,
  FormLabel,
  FormMessage,
  FormTextArea,
  FlexItem,
} from '@fluentui/react-northstar';
import { Ti8mDropdown, Ti8mDatepicker } from '../../common';
import { useCallback, useState } from 'react';
import { Language } from '../../../app/locales';
import { TranslationDto } from '../../../data-access';
import { useSectorsData } from '../hooks/useSectorsData';
import { useInfiniteSkills } from '../../../data-access/hooks/queries/use-infinite-skills';
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import dayjs from 'dayjs';
import { ProjectCommandModel } from './FormModel/ProjectCommandModel';

import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const ProjectForm = ({
  errors,
  register,
  setValue,
  getValues,
}: {
  errors: FieldErrors<ProjectCommandModel>;
  register: UseFormRegister<ProjectCommandModel>;
  setValue: UseFormSetValue<ProjectCommandModel>;
  getValues: UseFormGetValues<ProjectCommandModel>;
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.resolvedLanguage as Language;
  const [tasks, setTasks] = useState(
    Array.from(Array(6), (_, k) => Object.assign({}, getValues('tasks')[k] ?? { de: '', en: '' }))
  );
  const [tasksCharsCount, setTasksCharsCount] = useState(
    (getValues('tasks').length > 0
      ? getValues('tasks').map((val) => ({
          en: val.en.length,
          de: val.de.length,
        }))
      : [{ de: 0, en: 0 }]
    ).reduce((sum, val) => ({
      en: sum.en + val.en,
      de: sum.de + val.de,
    }))
  );
  const [skillSearchString, setSkillSearchString] = useState('');
  const { skills, hasMoreSkills, fetchMoreSkills, isSkillsError } =
    useInfiniteSkills(skillSearchString);
  const sectors = useSectorsData();

  const maxTaskLength = 350;
  const maxSkillCount = 15;
  const maxProjectLength = 55;
  const maxDescriptionLength = 600;

  const handleSkillSearchQueryChanged = useCallback(
    (data: DropdownProps) => {
      if (data.searchQuery !== undefined) {
        setSkillSearchString(data.searchQuery);
      }
    },
    [setSkillSearchString]
  );

  const getDateDefaultValue = (data?: string | null) => {
    if (data && data !== '') {
      return new Date(data);
    }
    return undefined;
  };

  const getSkillsDisabled = useCallback(() => {
    return getValues('skills').length >= maxSkillCount;
  }, [getValues]);

  const handleSkillsChanged = useCallback(
    (skills: Array<{ key: string; header: string }>) => {
      setValue(
        'skills',
        skills.map((s) => ({ skillId: s.key, skillName: s.header })),
        { shouldDirty: true, shouldValidate: true }
      );
    },
    [setValue]
  );

  const handleProjectNameChange = useCallback(
    (value: string, prop: keyof TranslationDto) => {
      setValue(`projectName.${prop}`, value, { shouldDirty: true, shouldValidate: true });
    },
    [setValue]
  );

  const onTaskInputChange = useCallback(
    (value: string, idx: number, prop: keyof TranslationDto) => {
      const newTasks = tasks;
      const difference = value.length - newTasks[idx][prop].length;
      setTasksCharsCount((prev) => ({
        ...prev,
        [prop]: prev[prop] + difference,
      }));
      newTasks[idx][prop] = value;
      setTasks(newTasks);
      setValue('tasks', newTasks, { shouldDirty: true, shouldValidate: true });
    },
    [tasks, setValue]
  );

  register('clientName.de', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('clientName.en', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('projectName.de', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('projectName.en', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('role.de', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('role.en', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('description.de', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('description.en', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('sector', {
    required: { value: true, message: t('form-validation.messages.required') },
    validate: (value, _) => value.sectorId !== '' || t('form-validation.messages.required'),
  });
  register('start', {
    required: { value: true, message: t('form-validation.messages.required') },
    validate: (value, formValues) =>
      !formValues.end ||
      dayjs(value).toDate().getTime() <= dayjs(formValues.end).toDate().getTime() ||
      t('form-validation.messages.start-before-end'),
  });
  register('skills', {
    validate: (value, _) => value.length > 0 || t('form-validation.messages.skill-min-required'),
  });
  register('tasks', {
    validate: (value, _) => {
      if (
        value.filter((t) => t.de !== '').length === 0 &&
        value.filter((t) => t.en !== '').length === 0
      ) {
        return 'both';
      } else if (value.filter((t) => t.de !== '').length === 0) {
        return 'de';
      } else if (value.filter((t) => t.en !== '').length === 0) {
        return 'en';
      } else {
        return true;
      }
    },
  });

  return (
    <Flex column gap="gap.small">
      <FormInput
        autoFocus
        label={t('dialog.add-project.client-de')}
        placeholder={t('dialog.add-project.client-placeholder')}
        fluid
        errorMessage={errors.clientName?.de}
        onChange={(_, data) =>
          setValue('clientName.de', data?.value ?? '', { shouldDirty: true, shouldValidate: true })
        }
        defaultValue={getValues('clientName.de')}
      />
      <FormInput
        label={t('dialog.add-project.client-en')}
        placeholder={t('dialog.add-project.client-placeholder')}
        fluid
        errorMessage={errors.clientName?.en}
        onChange={(_, data) =>
          setValue('clientName.en', data?.value ?? '', { shouldDirty: true, shouldValidate: true })
        }
        defaultValue={getValues('clientName.en')}
      />
      <FormInput
        label={t('dialog.add-project.project-de')}
        placeholder={t('dialog.add-project.project-placeholder')}
        fluid
        errorMessage={errors.projectName?.de}
        maxLength={maxProjectLength}
        onChange={(_, data) => handleProjectNameChange(data?.value ?? '', 'de')}
        defaultValue={getValues('projectName.de')}
      />
      <Text
        align="end"
        size="small"
        content={`${getValues('projectName').de.length}/${maxProjectLength}`}
      />
      <FormInput
        label={t('dialog.add-project.project-en')}
        placeholder={t('dialog.add-project.project-placeholder')}
        fluid
        errorMessage={errors.projectName?.en}
        maxLength={maxProjectLength}
        onChange={(_, data) => handleProjectNameChange(data?.value ?? '', 'en')}
        defaultValue={getValues('projectName.en')}
      />
      <Text
        align="end"
        size="small"
        content={`${getValues('projectName.en').length}/${maxProjectLength}`}
      />
      <FormField>
        <FormLabel content={t('dialog.add-project.sectors')} />
        <Ti8mDropdown
          items={sectors?.map((s) => ({ key: s.id, header: s.name[language] })) ?? []}
          onChange={(props) => {
            const item = props.value as { key: string; header: string };
            setValue(
              'sector',
              { sectorId: item.key, sectorName: item.header },
              { shouldDirty: true, shouldValidate: true }
            );
          }}
          placeholder={t('dialog.add-project.sectors-placeholder')}
          defaultValue={
            getValues('sector').sectorId
              ? {
                  key: getValues('sector').sectorId,
                  header: getValues('sector').sectorName,
                }
              : undefined
          }
        />
        {errors.sector && <FormMessage error content={errors.sector.message} />}
      </FormField>
      <Grid columns="2" rows="1" styles={{ gap: '1rem' }}>
        <FormField>
          <FormLabel content={t('dialog.add-project.start-date')} />
          <Ti8mDatepicker
            placeholder={t('dialog.add-project.start-date-placeholder')}
            onDateChange={(date) =>
              setValue('start', date?.toISOString() ?? '', {
                shouldDirty: true,
                shouldValidate: true,
              })
            }
            defaultSelectedDate={getDateDefaultValue(getValues('start'))}
          />
          {errors.start && <FormMessage error content={errors.start.message} />}
        </FormField>
        <FormField>
          <FormLabel content={t('dialog.add-project.end-date')} />
          <Ti8mDatepicker
            placeholder={t('dialog.add-project.end-date-placeholder')}
            onDateChange={(date) =>
              setValue('end', date?.toISOString() ?? '', {
                shouldDirty: true,
                shouldValidate: true,
              })
            }
            defaultSelectedDate={getDateDefaultValue(getValues('end'))}
          />
        </FormField>
      </Grid>
      <Flex column gap="gap.small">
        <FormTextArea
          label={t('dialog.add-project.project-goal-de')}
          placeholder={t('dialog.add-project.project-goal-placeholder')}
          maxLength={maxDescriptionLength}
          rows={4}
          fluid
          defaultValue={getValues('description.de')}
          onChange={(_, d) =>
            setValue('description.de', d?.value ?? '', { shouldDirty: true, shouldValidate: true })
          }
        />
        <Flex style={{ width: '100%' }}>
          {errors.description?.de && <FormMessage error content={errors.description.de.message} />}
          <FlexItem push>
            <Text
              align="end"
              size="small"
              content={`${getValues('description.de').length}/${maxDescriptionLength}`}
            />
          </FlexItem>
        </Flex>
      </Flex>
      <Flex column gap="gap.small">
        <FormTextArea
          label={t('dialog.add-project.project-goal-en')}
          placeholder={t('dialog.add-project.project-goal-placeholder')}
          maxLength={maxDescriptionLength}
          rows={4}
          fluid
          defaultValue={getValues('description.en')}
          onChange={(_, d) =>
            setValue('description.en', d?.value ?? '', { shouldDirty: true, shouldValidate: true })
          }
        />
        <Flex style={{ width: '100%' }}>
          {errors.description?.en && <FormMessage error content={errors.description.en.message} />}
          <FlexItem push>
            <Text
              align="end"
              size="small"
              content={`${getValues('description.en').length}/${maxDescriptionLength}`}
            />
          </FlexItem>
        </Flex>
      </Flex>
      <FormInput
        label={t('dialog.add-project.role-de')}
        placeholder={t('dialog.add-project.role-placeholder')}
        fluid
        errorMessage={errors.role?.de}
        onChange={(_, data) =>
          setValue('role.de', data?.value ?? '', { shouldDirty: true, shouldValidate: true })
        }
        defaultValue={getValues('role.de')}
      />
      <FormInput
        label={t('dialog.add-project.role-en')}
        placeholder={t('dialog.add-project.role-placeholder')}
        fluid
        errorMessage={errors.role?.en}
        onChange={(_, data) =>
          setValue('role.en', data?.value ?? '', { shouldDirty: true, shouldValidate: true })
        }
        defaultValue={getValues('role.en')}
      />
      <FormField>
        <FormLabel content={t('dialog.add-project.skills')} />
        <Ti8mDropdown
          items={skills.map((skill) => ({
            key: skill.id,
            header: skill.name,
            disabled: getSkillsDisabled(),
          }))}
          canLoadMoreItems={hasMoreSkills}
          onScrollLoadMoreItems={fetchMoreSkills}
          hasDataError={isSkillsError}
          multiple
          search
          placeholder={t('dialog.add-project.skills-placeholder')}
          onSearchQueryChange={handleSkillSearchQueryChanged}
          onChange={(props) =>
            handleSkillsChanged(props.value as Array<{ key: string; header: string }>)
          }
          defaultValue={getValues('skills').map((s) => ({
            key: s.skillId,
            header: s.skillName,
          }))}
        />
        <Flex style={{ width: '100%' }}>
          {errors.skills && <FormMessage error content={errors.skills.message} />}
          <FlexItem push>
            <Text align="end" size="small">{`${getValues('skills').length}/${maxSkillCount}`}</Text>
          </FlexItem>
        </Flex>
      </FormField>
      <Text content={t('dialog.add-project.tasks-de')} weight="semibold" />
      {tasks.map((value, i) => (
        <Input
          key={`add_project_task_de_${i}`}
          placeholder={t('dialog.add-project.tasks-placeholder')}
          fluid
          defaultValue={value.de}
          maxLength={maxTaskLength - tasksCharsCount.de + (value.de ?? '').length}
          onChange={(_, data) => onTaskInputChange(data?.value ?? '', i, 'de')}
        />
      ))}
      <Flex style={{ width: '100%' }}>
        {errors.tasks && (errors.tasks.message === 'de' || errors.tasks.message === 'both') && (
          <FormMessage error content={t('form-validation.messages.tasks-min-required')} />
        )}
        <FlexItem push>
          <Text align="end" size="small">{`${tasksCharsCount.de}/${maxTaskLength}`}</Text>
        </FlexItem>
      </Flex>
      <Text content={t('dialog.add-project.tasks-en')} weight="semibold" />
      {tasks.map((value, i) => (
        <Input
          key={`add_project_task_en_${i}`}
          placeholder={t('dialog.add-project.tasks-placeholder')}
          fluid
          defaultValue={value.en}
          maxLength={maxTaskLength - tasksCharsCount.en + (value.en ?? '').length}
          onChange={(_, data) => onTaskInputChange(data?.value ?? '', i, 'en')}
        />
      ))}
      <Flex style={{ width: '100%' }}>
        {errors.tasks && (errors.tasks.message === 'en' || errors.tasks.message === 'both') && (
          <FormMessage error content={t('form-validation.messages.tasks-min-required')} />
        )}
        <FlexItem push>
          <Text align="end" size="small">{`${tasksCharsCount.en}/${maxTaskLength}`}</Text>
        </FlexItem>
      </Flex>
    </Flex>
  );
};
