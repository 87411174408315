import { CertificateService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';

import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { withApiVersion, DEFAULT_TAKE, getNextPageParam, unpack } from '../../helpers';

const getCertificates = (context: QueryFunctionContext) => {
  const withVersion = withApiVersion(CertificateService.certificates);
  return withVersion({
    searchString: `${context.queryKey[1]}`,
    skip: context.pageParam,
    take: DEFAULT_TAKE,
  });
};

export const useInfiniteCertificates = (searchString?: string) => {
  const { fetchNextPage, hasNextPage, data, isError } = useInfiniteQuery({
    queryKey: [QueryKeys.Certificates, searchString],
    queryFn: getCertificates,
    getNextPageParam: getNextPageParam,
  });

  return {
    certificates: unpack(data),
    hasMoreCertificates: hasNextPage,
    fetchMoreCertificates: fetchNextPage,
    isCertificatesError: isError,
  };
};
