import { Loader } from '@fluentui/react-northstar';
import { PropsWithChildren } from 'react';

export const DefaultLoader = ({
  isLoading,
  children,
}: PropsWithChildren<{ isLoading: boolean }>) => {
  return isLoading ? (
    <Loader styles={{ paddingTop: '2rem' }} label="Loading ..." />
  ) : (
    <>{children}</>
  );
};
