import { Flex, Menu, MenuItemProps, tabListBehavior } from '@fluentui/react-northstar';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CertificatesMasterData } from '../admin/certificates/CertificatesMasterData';
import { FocusTopicsMasterData } from '../admin/focus-topics/FocusTopicsMasterData';
import { SectorsMasterData } from '../admin/sectors/SectorsMasterData';
import { SkillsMasterData } from '../admin/skills/SkillsMasterData';
import { SubjectMatterExpertTopicsMasterData } from '../admin/subject-matter-expert-topics/SubjectMatterExpertTopicsMasterData';
import { getByIndex, mapWithIndex } from '../utils/arrayIndex';
import { SkillGroupsMasterData } from '../admin/skill-groups/SkillGroupsMasterData';

type MenuItemKeys =
  | 'skills'
  | 'skillGroups'
  | 'certificates'
  | 'subject-matter-expert-topics'
  | 'focus-topics'
  | 'sectors';

type MenuItemWithKey = MenuItemProps & {
  key: MenuItemKeys;
};

export const AdminPage = () => {
  const { t } = useTranslation();

  const menu = useMemo(
    () =>
      mapWithIndex<MenuItemWithKey>([
        {
          key: 'skills',
          content: t('admin.skills.name_other'),
        },
        {
          key: 'skillGroups',
          content: t('admin.skill-groups.name_other'),
        },
        {
          key: 'certificates',
          content: t('admin.certificates.name_other'),
        },
        {
          key: 'subject-matter-expert-topics',
          content: t('admin.subject-matter-expert-topics.name_other'),
        },
        {
          key: 'focus-topics',
          content: t('admin.focus-topics.name_other'),
        },
        {
          key: 'sectors',
          content: t('admin.sectors.name_other'),
        },
      ]),
    [t]
  );

  const [selected, setSelected] = useState<MenuItemWithKey | undefined>(menu[0]);

  return (
    <Flex column fill>
      <Menu
        defaultActiveIndex={0}
        items={menu}
        primary
        accessibility={tabListBehavior}
        underlined
        onActiveIndexChange={(_event, data) =>
          setSelected(getByIndex(menu, Number(data?.activeIndex)))
        }
        styles={{
          gap: '8px',
          '> .ui-menu__itemwrapper': {
            padding: 0,
          },
          marginBottom: '2rem',
        }}
      />

      <>
        {selected?.key === 'skills' && <SkillsMasterData />}
        {selected?.key === 'skillGroups' && <SkillGroupsMasterData />}
        {selected?.key === 'certificates' && <CertificatesMasterData />}
        {selected?.key === 'subject-matter-expert-topics' && (
          <SubjectMatterExpertTopicsMasterData />
        )}
        {selected?.key === 'focus-topics' && <FocusTopicsMasterData />}
        {selected?.key === 'sectors' && <SectorsMasterData />}
      </>
    </Flex>
  );
};
