import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  EditIcon,
  Flex,
  FlexItem,
  TrashCanIcon,
  Provider,
} from '@fluentui/react-northstar';
import {
  ProjectDto,
  TemplateBasedProjectDto,
  useSetReferenceProject,
  useUnSetReferenceProject,
} from '../../data-access';
import { useCallback, useState } from 'react';
import { ProjectEditDialog } from './Dialogs/ProjectEditDialog';
import { DeleteProjectDialog } from './Dialogs/DeleteProjectDialog';
import { Ti8mProjectEditDialog } from './Dialogs/Ti8mProjectEditDialog';
import { maxProjectsCount } from '../EmployeeTable/CvDownload/CvDownloadDialogForm';
import { EmployeeProjectCard } from './EmployeeProjectCard';

export const EmployeeProjectContainer = ({
  employeeId,
  isEditable,
  project,
  pinCount,
  allowReferenceProjectToggle,
  templateBasedProjectDto,
}: {
  employeeId: string;
  isEditable: boolean;
  project: ProjectDto;
  pinCount: number;
  allowReferenceProjectToggle: boolean;
  templateBasedProjectDto?: TemplateBasedProjectDto;
}) => {
  const { t } = useTranslation();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const changeDeleteDialogOpen = useCallback(
    (value: boolean) => {
      setDeleteDialogOpen(value);
    },
    [setDeleteDialogOpen]
  );

  const changeEditDialogOpen = useCallback(
    (value: boolean) => {
      setEditDialogOpen(value);
    },
    [setEditDialogOpen]
  );

  const { mutate: setReferenceProject, isLoading: setRefProjectIsBusy } = useSetReferenceProject();
  const { mutate: unSetReferenceProject, isLoading: unSetRefProjectIsBusy } =
    useUnSetReferenceProject();

  const isBusyMutating = setRefProjectIsBusy || unSetRefProjectIsBusy;

  const toggleReferenceProject = useCallback(
    (_: React.SyntheticEvent, data?: { checked: boolean }) => {
      if (project.isReferenceProject) {
        unSetReferenceProject({ employeeId: employeeId, projectId: project.id! });
      } else {
        setReferenceProject({ employeeId: employeeId, projectId: project.id! });
      }
    },
    [setReferenceProject, unSetReferenceProject, project.isReferenceProject, project.id, employeeId]
  );

  const pinToCoverToggleLabel = project.isReferenceProject
    ? t('employee-detail.added-project-to-cover-page') + ` (${pinCount}/${maxProjectsCount})`
    : t('employee-detail.add-project-to-cover-page');

  return (
    <Provider.Consumer
      render={(theme) => (
        <Flex
          column
          style={{
            backgroundColor: theme.siteVariables.colorScheme.default.background1,
            padding: '1.675em', //~ 24px
            gap: '1.675em',
          }}
        >
          {templateBasedProjectDto && editDialogOpen ? (
            <Ti8mProjectEditDialog
              employeeId={employeeId}
              open={editDialogOpen}
              project={templateBasedProjectDto}
              onCancel={() => changeEditDialogOpen(false)}
            />
          ) : (
            <ProjectEditDialog
              employeeId={employeeId}
              open={editDialogOpen}
              project={project}
              onCancel={() => changeEditDialogOpen(false)}
            />
          )}
          <DeleteProjectDialog
            dialogOpen={deleteDialogOpen}
            employeeId={employeeId}
            projectId={project.id}
            isTemplateBased={templateBasedProjectDto ? true : false}
            onClose={() => changeDeleteDialogOpen(false)}
          />
          <Flex>
            <FlexItem push>
              <Flex vAlign="center">
                <Checkbox
                  disabled={
                    (!allowReferenceProjectToggle && !project.isReferenceProject) ||
                    isBusyMutating ||
                    !isEditable
                  }
                  label={pinToCoverToggleLabel}
                  toggle
                  checked={project.isReferenceProject}
                  labelPosition="start"
                  onChange={toggleReferenceProject}
                  style={{ marginRight: '34px' }}
                />
                <Button
                  text
                  iconOnly
                  primary
                  disabled={isBusyMutating || !isEditable}
                  content={<EditIcon size="large" />}
                  onClick={() => changeEditDialogOpen(true)}
                />
                <Button
                  text
                  iconOnly
                  disabled={isBusyMutating || !isEditable}
                  icon={<TrashCanIcon size="large" />}
                  primary
                  onClick={() => changeDeleteDialogOpen(true)}
                />
              </Flex>
            </FlexItem>
          </Flex>
          <Flex gap="gap.medium">
            <EmployeeProjectCard project={project} language="de" />
            <EmployeeProjectCard project={project} language="en" />
          </Flex>
        </Flex>
      )}
    />
  );
};
