import { CancelablePromise, FocusTopicDto, OpenAPI } from '../../queries';
import { request as __request } from '../../queries/core/request';
import { GuidResult } from '../models/GuidResult';

export class FocusTopicService {
  public static addFocusTopic({
    version,
    requestBody,
  }: {
    version: string;
    requestBody: Omit<FocusTopicDto, 'id'>;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/focus-topics',
      path: {
        version: version,
      },
      body: requestBody,
    });
  }

  public static updateFocusTopic({
    id,
    version,
    name,
  }: {
    id: string;
    version: string;
    name: FocusTopicDto['name'];
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/focus-topics/name/{id}',
      path: {
        version: version,
        id: id,
      },
      body: name,
    });
  }

  public static deleteFocusTopic({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/focus-topics/{id}',
      path: {
        version: version,
        id: id,
      },
    });
  }
}
