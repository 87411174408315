import { UseQueryOptions, useQuery } from 'react-query';
import { ExtractFirstArgAsType, WithoutVersion, withApiVersion } from '../../helpers';
import {
  ApiError,
  GenericResult,
  SubjectMatterExpertListDto,
  SubjectMatterExpertTopicService,
} from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';

const getSubjectMatterExpertTopics = withApiVersion(
  SubjectMatterExpertTopicService.subjectMatterExpertTopics
);

export type QueryParams = WithoutVersion<
  ExtractFirstArgAsType<typeof getSubjectMatterExpertTopics>
>;

export const useSubjectMatterExpertTopics = (
  queryParams: QueryParams,
  queryOptions?: UseQueryOptions<
    GenericResult<SubjectMatterExpertListDto>,
    ApiError,
    SubjectMatterExpertListDto,
    [QueryKeys.SubjectMatterExpertTopics, QueryParams]
  >
) =>
  useQuery(
    [QueryKeys.SubjectMatterExpertTopics, queryParams],
    ({ queryKey: [_key, input] }) => getSubjectMatterExpertTopics(input),
    {
      select: (data) => data.payload,
      ...queryOptions,
    }
  );
