import { FormField, FormLabel } from '@fluentui/react-northstar';
import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import { Ti8mDatepicker } from '../../components/common';

import { isDate, isString } from 'lodash';

type ControlledFormDatepickerProps<TForm extends FieldValues> = {
  control: Control<TForm, any>;
  name: Path<TForm>;
  rules?: RegisterOptions;
  label: string;
  placeholder?: string;
};

export const ControlledFormDatepicker = <TForm extends FieldValues>({
  control,
  name,
  rules,
  label,
  placeholder,
}: ControlledFormDatepickerProps<TForm>) => {
  return (
    <FormField>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const parsedDate = () => {
            if (isString(value)) return new Date(value);
            if (isDate(value)) return value;
          };
          return (
            <>
              <FormLabel content={label} />

              <Ti8mDatepicker
                placeholder={placeholder || ''}
                defaultSelectedDate={parsedDate()}
                onDateChange={(date) => onChange(date?.toISOString())}
                error={!!error}
              />
            </>
          );
        }}
      />
    </FormField>
  );
};
