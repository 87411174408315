import { UseQueryOptions, useQuery } from 'react-query';
import { env } from '../../../utils/env';
import { ApiError, GenericResult, SkillGroupService } from '../../open-api/queries';
import { SkillGroupDto } from '../../open-api/queries/models/SkillGroupDto';
import { QueryKeys } from '../../query-keys';

const getSkillGroups = () =>
  SkillGroupService.skillGroups({ version: env('REACT_APP_API_VERSION') });

export const useSkillGroups = (
  queryOptions?: UseQueryOptions<
    GenericResult<SkillGroupDto[]>,
    ApiError,
    SkillGroupDto[],
    QueryKeys.SkillGroups
  >
) =>
  useQuery(QueryKeys.SkillGroups, ({ queryKey: [_] }) => getSkillGroups(), {
    select: (data) => data.payload,
    ...queryOptions,
  });
