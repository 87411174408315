import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@fluentui/react-northstar';
import { BasicLayout } from '../components/Layout/Layout';
import { OriginsForm } from '../components/OriginsForm/OriginsForm';
import { OriginsMap } from '../components/OriginsMap/OriginsMap';
import { useOrigins } from '../data-access/hooks/queries/use-origins';

export const OriginsPage: FC = () => {
  const { t } = useTranslation();
  const queryResult = useOrigins();

  let content: ReactNode;
  if (queryResult.isLoading) {
    content = null;
  }
  if (queryResult.isError) {
    content = (
      <Alert
        content={t('origins.page.error')}
        dismissible
        dismissAction={{
          'aria-label': 'close',
        }}
        variables={{
          urgent: true,
        }}
      />
    );
  }
  if (queryResult.data && queryResult.data.length > 0) {
    content = <OriginsMap />;
  } else {
    // If the result of the call is empty, it means the user did not insert their origin yet.
    content = <OriginsForm />;
  }
  return <BasicLayout noPadding={true}>{content}</BasicLayout>;
};
