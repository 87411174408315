import { Form } from '@fluentui/react-northstar';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  SubjectMatterExpertTopicDto,
  TranslationDto,
  useAddSubjectMatterExpertTopic,
  useUpdateSubjectMatterExpertTopic,
} from '../../data-access';
import { AddOrEditDialog } from '../components/AddOrEditDialog';
import { ControlledFormInput } from '../components/ControlledFormInput';
import { applyRules } from '../helpers/formRules';

type SubjectMatterExpertTopicsAddOrEditFormDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  currentItem?: SubjectMatterExpertTopicDto;
};

export type FormAttributes = {
  nameEN: string;
  nameDE: string;
};

export const SubjectMatterExpertTopicsAddOrEditFormDialog = ({
  open,
  setOpen,
  currentItem,
}: SubjectMatterExpertTopicsAddOrEditFormDialogProps) => {
  const { t } = useTranslation();

  const {
    control,
    trigger,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormAttributes>({
    defaultValues: {
      nameEN: currentItem?.name.en,
      nameDE: currentItem?.name.de,
    },
  });

  const { mutate: update } = useUpdateSubjectMatterExpertTopic();
  const { mutate: add } = useAddSubjectMatterExpertTopic();

  const onSubmit = async (data: FormAttributes) => {
    if (await trigger(undefined, { shouldFocus: true })) {
      const names: TranslationDto = {
        en: data.nameEN,
        de: data.nameDE,
      };

      if (currentItem?.id) {
        update({
          id: currentItem.id,
          requestBody: names,
        });
      } else {
        add({
          requestBody: {
            name: names,
          },
        });
      }
      setOpen(false);
    }
  };

  return (
    <AddOrEditDialog
      open={open}
      setOpen={setOpen}
      title={t('admin.subject-matter-expert-topics.name_one')}
      currentItem={currentItem}
      preventSave={!isDirty}
      onConfirm={handleSubmit(onSubmit)}
      content={
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ControlledFormInput
            control={control}
            rules={applyRules(['required', 'minLength'])}
            name="nameEN"
            label={t('admin.common.name-en')}
          />

          <ControlledFormInput
            control={control}
            rules={applyRules(['required', 'minLength'])}
            name="nameDE"
            label={t('admin.common.name-de')}
          />

          {/* Work around to submit a form when user presses Enter */}
          <button type="submit" hidden />
        </Form>
      }
    />
  );
};
