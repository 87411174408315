import { useMutation, useQueryClient } from 'react-query';
import { env } from '../../../utils/env';
import { ApiError, EmployeeService, GenericResult, Result } from '../../open-api/commands';
import { EmployeeService as QueryEmployeeService } from '../../open-api/queries';
import * as CommandParams from './command-params';
import { QueryKeys } from '../../query-keys';
import { ChangeEmployeeLanguages } from './command-params';
import { useTranslation } from 'react-i18next';
import { waitForEvent } from './wait-for-event';

const addEmployeeProject = (input: CommandParams.AddEmployeeProjectInput) =>
  EmployeeService.addProject({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updateEmployeeProject = (input: CommandParams.ChangeEmployeeProjectInput) =>
  EmployeeService.changeProject({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const deleteEmployeeProject = (input: CommandParams.DeleteEmployeeProjectInput) =>
  EmployeeService.deleteProject({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const addEmployeeTemplateBasedProject = (
  input: CommandParams.AddEmployeeTemplateBasedProjectInput
) =>
  EmployeeService.addTemplateBasedProject({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updateEmployeeTemplateBasedProject = (
  input: CommandParams.ChangeEmployeeTemplateBasedProjectInput
) =>
  EmployeeService.changeTemplateBasedProject({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const deleteEmployeeTemplateBasedProject = (
  input: CommandParams.DeleteEmployeeTemplateBasedProjectInput
) =>
  EmployeeService.deleteTemplateBasedProject({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const getEventPosition = (id: string) =>
  QueryEmployeeService.getEventPosition({
    version: env('REACT_APP_API_VERSION'),
    id,
  });

const uploadEmployeeDiploma = (input: CommandParams.UploadEmployeeDiplomaInput) =>
  EmployeeService.uploadEmployeeEducationAsync({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const uploadEmployeeCertificate = (input: CommandParams.UploadEmployeeCertificateInput) =>
  EmployeeService.uploadEmployeeCertificateAsync({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updateEmployeeProfileText = (input: CommandParams.ChangeEmployeeProfileTextInput) =>
  EmployeeService.changeProfileText({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updateEmployeeContact = (input: CommandParams.ChangeEmployeeContactInput) =>
  EmployeeService.changeEmployeeContact({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updateLanguages = (input: ChangeEmployeeLanguages) =>
  EmployeeService.changeLanguages({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updateSkills = (input: CommandParams.ChangeEmployeeSkills) =>
  EmployeeService.changeSkills({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const setReferenceProject = (input: CommandParams.SetReferenceProject) =>
  EmployeeService.setReferenceProject({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const unSetReferenceProject = (input: CommandParams.UnSetReferenceProject) =>
  EmployeeService.unSetReferenceProject({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const addEmployeeEducation = (input: CommandParams.AddEmployeeEducationInput) =>
  EmployeeService.addEducation({ version: env('REACT_APP_API_VERSION'), ...input });

const changeEmployeeEducation = (input: CommandParams.ChangeEmployeeEducationInput) =>
  EmployeeService.changeEducation({ version: env('REACT_APP_API_VERSION'), ...input });

const deleteEmployeeEducation = (input: CommandParams.DeleteEmployeeEducationInput) =>
  EmployeeService.deleteEducation({ version: env('REACT_APP_API_VERSION'), ...input });

const addEmployeeCertificate = (input: CommandParams.AddEmployeeCertificateInput) =>
  EmployeeService.addCertificate({ version: env('REACT_APP_API_VERSION'), ...input });

const changeEmployeeCertificate = (input: CommandParams.ChangeEmployeeCertificateInput) =>
  EmployeeService.changeCertificate({ version: env('REACT_APP_API_VERSION'), ...input });

const updateEmployeeCertificate = (input: CommandParams.ChangeEmployeeCertificateInput) =>
  EmployeeService.changeCertificate({ version: env('REACT_APP_API_VERSION'), ...input });

const deleteEmployeeCertificate = (input: CommandParams.DeleteEmployeeCertificateInput) =>
  EmployeeService.deleteCertificate({ version: env('REACT_APP_API_VERSION'), ...input });

const updateFocusTopics = (input: CommandParams.UpdateFocusTopics) =>
  EmployeeService.updateFocusTopics({ version: env('REACT_APP_API_VERSION'), ...input });

const updateSubjectMatterExpertTopics = (input: CommandParams.UpdateSubjectMatterExpertTopics) =>
  EmployeeService.updateSubjectMatterExpertTopics({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const changeNationalSecurityInfo = (input: CommandParams.ChangeNationalSecurityInfo) =>
  EmployeeService.changeNationalSecurityInfo({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const uploadEmployeeNationalSecurityCertificate = (
  input: CommandParams.UploadEmployeeNationalSecurityCertificate
) =>
  EmployeeService.uploadEmployeeNationalSecurityCertificate({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

type GuidResultWithId = Result & {
  id: string;
};

export const useAddEmployeeProject = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.AddEmployeeProjectInput>(
    async (input) => {
      const result = await addEmployeeProject(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useEditEmployeeProject = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.ChangeEmployeeProjectInput>(
    async (input) => {
      const result = await updateEmployeeProject(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useDeleteEmployeeProject = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.DeleteEmployeeProjectInput>(
    async (input) => {
      const result = await deleteEmployeeProject(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useAddEmployeeTemplateBasedProject = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    GuidResultWithId,
    ApiError,
    CommandParams.AddEmployeeTemplateBasedProjectInput
  >(
    async (input) => {
      const result = await addEmployeeTemplateBasedProject(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useEditEmployeeTemplateBasedProject = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    GuidResultWithId,
    ApiError,
    CommandParams.ChangeEmployeeTemplateBasedProjectInput
  >(
    async (input) => {
      const result = await updateEmployeeTemplateBasedProject(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useDeleteEmployeeTemplateBasedProject = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    GuidResultWithId,
    ApiError,
    CommandParams.DeleteEmployeeTemplateBasedProjectInput
  >(
    async (input) => {
      const result = await deleteEmployeeTemplateBasedProject(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useEditEmployeeProfileText = () => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.ChangeEmployeeProfileTextInput>(
    async (input) => {
      const result = await updateEmployeeProfileText(input);

      return { ...result, id: input.employeeId };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useEditEmployeeContact = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.ChangeEmployeeContactInput>(
    async (input) => {
      const result = await updateEmployeeContact(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useAddEmployeeEducation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation<GuidResultWithId, ApiError, CommandParams.AddEmployeeEducationInput>(
    async (input) => {
      const result = await addEmployeeEducation(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useChangeEmployeeEducation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.ChangeEmployeeEducationInput>(
    async (input) => {
      const result = await changeEmployeeEducation(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useDeleteEmployeeEducation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.DeleteEmployeeEducationInput>(
    async (input) => {
      const result = await deleteEmployeeEducation(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useAddEmployeeCertificate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.AddEmployeeCertificateInput>(
    async (input) => {
      const result = await addEmployeeCertificate(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useChangeEmployeeCertificate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.ChangeEmployeeCertificateInput>(
    async (input) => {
      const result = await changeEmployeeCertificate(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useEditEmployeeCertificate = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.ChangeEmployeeCertificateInput>(
    async (input) => {
      const result = await updateEmployeeCertificate(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useDeleteEmployeeCertificate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.DeleteEmployeeCertificateInput>(
    async (input) => {
      const result = await deleteEmployeeCertificate(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useUploadEmployeeDiploma = () => {
  const { t } = useTranslation();

  return useMutation<GenericResult<string>, ApiError, CommandParams.UploadEmployeeDiplomaInput>(
    async (input) => await uploadEmployeeDiploma(input),
    {
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useUploadEmployeeCertificate = () => {
  const { t } = useTranslation();

  return useMutation<GenericResult<string>, ApiError, CommandParams.UploadEmployeeCertificateInput>(
    async (input) => await uploadEmployeeCertificate(input),
    {
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useUpdateLanguages = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.ChangeEmployeeLanguages>(
    async (input) => {
      const result = await updateLanguages(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useUpdateSkills = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.ChangeEmployeeSkills>(
    async (input) => {
      const result = await updateSkills(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useSetReferenceProject = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.SetReferenceProject>(
    async (input) => {
      const result = await setReferenceProject(input);

      return { ...result, id: input.employeeId };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useUnSetReferenceProject = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.SetReferenceProject>(
    async (input) => {
      const result = await unSetReferenceProject(input);

      return { ...result, id: input.employeeId };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useUpdateFocusTopics = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.UpdateFocusTopics>(
    async (input) => {
      const result = await updateFocusTopics(input);

      return { ...result, id: input.employeeId };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useUpdateSubjectMatterExpertTopics = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.UpdateSubjectMatterExpertTopics>(
    async (input) => {
      const result = await updateSubjectMatterExpertTopics(input);

      return { ...result, id: input.employeeId };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useChangeNationalSecurityInfo = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.ChangeNationalSecurityInfo>(
    async (input) => {
      const result = await changeNationalSecurityInfo(input);

      return { ...result, id: input.employeeId };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useUploadEmployeeNationalSecurityCertificate = () => {
  const { t } = useTranslation();

  return useMutation<
    GenericResult<string>,
    ApiError,
    CommandParams.UploadEmployeeNationalSecurityCertificate
  >(async (input) => await uploadEmployeeNationalSecurityCertificate(input), {
    meta: {
      type: t('alert.update-types.edit'),
      requestObject: t('alert.request-objects.employee'),
    },
  });
};
