export enum SwissCivilProtectionRankDto {
  PrivateE1 = 1,
  PrivateE2 = 2,
  Corporal = 4,
  Sergeant = 5,
  SergeantMajor = 7,
  QuartermasterSergeant = 8,
  SecondLieutenant = 14,
  FirstLieutenant = 15,
  Captain = 16,
  Major = 17,
  LieutenantColonel = 18,
  Colonel = 19,
}
