import { Link } from '@fluentui/react';
import { DropdownProps, Flex, InfoIcon, Text, Tooltip } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { Ti8mDropdown } from '../../common';
import { SkillLevelStars } from '../../common/SkillLevelStars';
import { warningTextStyle } from '../employeeSkillsStyles';
import { useState, useCallback, useMemo } from 'react';
import { EmployeeSkillDto, useInfiniteSkills } from '../../../data-access';

export type AddSkillsDialogFormProps = {
  skill: { skillId: string; name: string };
  existingSkills: EmployeeSkillDto[];
  setSkill: (skill: { skillId: string; name: string }) => void;
  level: number;
  setLevel: (level: number) => void;
  setTouched: (isTouched: boolean) => void;
  onRequestSkillClick?: () => void;
};

export const AddSkillsDialogForm = ({
  skill,
  existingSkills,
  setSkill,
  level,
  setLevel,
  setTouched,
  onRequestSkillClick,
}: AddSkillsDialogFormProps) => {
  const { t } = useTranslation();
  const [skillsSearchString, setSkillsSearchString] = useState('');
  const { skills, hasMoreSkills, fetchMoreSkills, isSkillsError } =
    useInfiniteSkills(skillsSearchString);

  const onAddSkill = (data: DropdownProps) => {
    const newSkill = data.value as { key: string; header: string };
    if (newSkill !== null) setSkill({ skillId: newSkill.key, name: newSkill.header });
    if (level !== 0) setTouched(true);
  };

  const existingSkillIds = useMemo(() => {
    return new Set(existingSkills.map((skill) => skill.skill.id));
  }, [existingSkills]);

  const dropdownSkillItemList = useMemo(
    () =>
      skills.map((item) => ({
        key: item.id,
        header: item.name,
        ...(existingSkillIds.has(item.id)
          ? {
              content: t('employee-detail.master-data.skills.add-skills-dialog.already-added'),
              disabled: true,
            }
          : {}),
      })),
    [skills, existingSkillIds, t]
  );

  const onChangeSkillLevel = (rating?: number) => {
    setLevel(rating ?? 0);
    if (skill.skillId !== '') setTouched(true);
  };

  const handleSkillsSearchQueryChange = useCallback(
    (data: DropdownProps) => {
      if (data.searchQuery !== undefined) {
        setSkillsSearchString(data.searchQuery);
      }
    },
    [setSkillsSearchString]
  );

  return (
    <Flex column gap="gap.large">
      <Flex column gap="gap.small">
        <Text
          content={t('employee-detail.master-data.skills.add-skills-dialog.skill-label')}
          weight="semibold"
        />
        <Ti8mDropdown
          autoFocus
          items={dropdownSkillItemList}
          onScrollLoadMoreItems={fetchMoreSkills}
          canLoadMoreItems={hasMoreSkills}
          hasDataError={isSkillsError}
          onChange={onAddSkill}
          search
          onSearchQueryChange={handleSkillsSearchQueryChange}
          placeholder={t('employee-detail.master-data.skills.add-skills-dialog.placeholder')}
        />
        <Flex gap="gap.smaller">
          <Text
            content={t('employee-detail.master-data.skills.add-skills-dialog.did-not-find')}
            size="small"
          />
          <Link onClick={onRequestSkillClick}>
            <Text
              content={t('employee-detail.master-data.skills.add-skills-dialog.request-skill')}
              size="small"
            />
          </Link>
        </Flex>
      </Flex>
      <Flex column gap="gap.small">
        <Flex gap="gap.smaller">
          <Text
            content={t('employee-detail.master-data.skills.add-skills-dialog.skill-level-label')}
            weight="semibold"
          />
          <Tooltip
            trigger={<InfoIcon outline />}
            content={
              <Flex column>
                <Flex gap="gap.smaller">
                  <Text
                    weight="semibold"
                    content={t('employee-detail.master-data.skills.add-skills-dialog.one-star')}
                  />
                  <Text
                    content={t(
                      'employee-detail.master-data.skills.add-skills-dialog.skill-level-novice'
                    )}
                  />
                </Flex>
                <Flex gap="gap.smaller">
                  <Text
                    weight="semibold"
                    content={t('employee-detail.master-data.skills.add-skills-dialog.n-stars', {
                      value: 2,
                    })}
                  />
                  <Text
                    content={t(
                      'employee-detail.master-data.skills.add-skills-dialog.skill-level-adv-beginner'
                    )}
                  />
                </Flex>
                <Flex gap="gap.smaller">
                  <Text
                    weight="semibold"
                    content={t('employee-detail.master-data.skills.add-skills-dialog.n-stars', {
                      value: 3,
                    })}
                  />
                  <Text
                    content={t(
                      'employee-detail.master-data.skills.add-skills-dialog.skill-level-intermediate'
                    )}
                  />
                </Flex>
                <Flex gap="gap.smaller">
                  <Text
                    weight="semibold"
                    content={t('employee-detail.master-data.skills.add-skills-dialog.n-stars', {
                      value: 4,
                    })}
                  />
                  <Text
                    content={t(
                      'employee-detail.master-data.skills.add-skills-dialog.skill-level-advanced'
                    )}
                  />
                </Flex>
                <Flex gap="gap.smaller">
                  <Text
                    weight="semibold"
                    content={t('employee-detail.master-data.skills.add-skills-dialog.n-stars', {
                      value: 5,
                    })}
                  />
                  <Text
                    content={t(
                      'employee-detail.master-data.skills.add-skills-dialog.skill-level-expert'
                    )}
                  />
                </Flex>
              </Flex>
            }
          />
        </Flex>
        <SkillLevelStars
          max={5}
          allowZeroStars={true}
          onChangeRating={(_, rating) => onChangeSkillLevel(rating)}
        />
        {level === 0 && skill.skillId !== '' && (
          <Text
            content={t('employee-detail.master-data.skills.add-skills-dialog.warning')}
            size="small"
            styles={warningTextStyle}
          />
        )}
      </Flex>
    </Flex>
  );
};
