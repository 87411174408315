import {
  ComponentSlotStyleFunction,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  DropdownSelectedItemProps,
  Loader,
  ShorthandCollection,
  ShorthandRenderFunction,
  ShorthandValue,
  Tooltip,
} from '@fluentui/react-northstar';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface Ti8mDropdownItem<T> extends DropdownItemProps {
  key: string | number;
  value: T;
}

export interface Ti8mDropdownProps extends Omit<DropdownProps, 'onChange' | 'onSearchQueryChange'> {
  items: ShorthandCollection<DropdownItemProps>;
  value?: ShorthandValue<DropdownItemProps> | ShorthandCollection<DropdownItemProps>;
  defaultValue?: ShorthandValue<DropdownItemProps> | ShorthandCollection<DropdownItemProps>;
  autoFocus?: boolean;
  position?: 'above' | 'below' | 'before' | 'after';
  onChange: (data: DropdownProps) => void;
  onSearchQueryChange?: (data: DropdownProps) => void;
  renderSelectedItem?: ShorthandRenderFunction<DropdownSelectedItemProps>;
  /** Defaults to true; will show Tooltips on the selected items in Multi-Selects, if they are longer than (x=13) characters */
  showTooltipsOnSelectedItems?: boolean;
  /**
   * A callback function that triggers the loading of more items, when the user scrolls to the end of the popup list.
   * This is expected to be a stable function reference (=> useCallback).
   */
  onScrollLoadMoreItems?: () => void;
  /** true, if we can load more items via 'onScrollLoadMoreItems' */
  canLoadMoreItems?: boolean;
  /** use true, if an error should be displayed (e.g. when fetching data failed) */
  hasDataError?: boolean;
}

const dropDownStyles: ComponentSlotStyleFunction = (data) => ({
  '& .ui-button__content': {
    fontWeight: data.theme.siteVariables['fontWeightLight'],
  },
  '& .ui-dropdown__searchinput__input': {
    border: 'none',
  },
  '& .ui-dropdown__selected-items': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .ui-dropdown__item': {
    cursor: 'pointer',
  },
  '& .ui-dropdown__selected-items .ui-box span.ui-icon': {
    scale: 0.8,
    transform: 'translateY(8%)',
  },
});

const dropDownAboveStyles: ComponentSlotStyleFunction = () => ({
  '& .ui-dropdown__items-list': {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px -0.2rem 1rem 0px',
  },
});

const selectedItemStyle: ComponentSlotStyleFunction = (data) => {
  return {
    backgroundColor: data.theme.siteVariables.colors['skillTagBackground'],
    borderRadius: 0,
    marginTop: '0.2rem',
    fontWeight: data.theme.siteVariables['fontWeightLight'],
    color: data.theme.siteVariables.colors['bodyColor'],
  };
};

const popupMenuSelector = '.ui-list';
const textLengthToTriggerTooltip = 13;

export const Ti8mDropdown: React.FC<Ti8mDropdownProps> = ({
  onScrollLoadMoreItems,
  canLoadMoreItems,
  hasDataError,
  ...props
}) => {
  const {
    items,
    autoFocus,
    value,
    multiple,
    showTooltipsOnSelectedItems,
    position,
    search,
    onChange,
    renderSelectedItem,
    onSearchQueryChange,
  } = props;

  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const [loadingMoreItems, setLoadingMoreItems] = useState(false);

  // the IntersectionObserver to check, if we should load more items
  const observerRef = useRef<IntersectionObserver | null>(null);

  const scrollPositionRef = useRef<number | undefined>(0);

  const shouldRegisterScrollObserver = useMemo(() => {
    return onScrollLoadMoreItems && canLoadMoreItems !== undefined && canLoadMoreItems;
  }, [onScrollLoadMoreItems, canLoadMoreItems]);

  // the idea here is: when the last item comes into view: trigger the 'onScrollLoadMoreItems' function
  // for this to work use an IntersectionObserver on the last element in the popup.
  const registerLoadOnScrollObserver = useCallback(() => {
    const target = scrollObserverTarget(ref, loadingMoreItems);
    if (ref.current && target) {
      const callback: IntersectionObserverCallback = (entries) => {
        if (entries.length === 0) {
          return;
        }
        if (entries[0].isIntersecting && onScrollLoadMoreItems) {
          const scrollTop = ref.current?.querySelectorAll(popupMenuSelector)[0].scrollTop;
          if (scrollTop) {
            scrollPositionRef.current = scrollTop;
          }

          setLoadingMoreItems(true);
          // introduce an artifical delay, as this makes it all less jerky in case loading more items is fast
          setTimeout(() => {
            onScrollLoadMoreItems();
          }, 300);
        }
      };

      const observer = new IntersectionObserver(callback, {
        root: ref.current.querySelectorAll(popupMenuSelector)[0],
        rootMargin: '0px',
        threshold: 0.7, // empirical value that works okay for scrolling via mouse and via keyboard
      });

      observer.observe(target);
      return observer;
    } else {
      return null;
    }
  }, [ref, onScrollLoadMoreItems, loadingMoreItems]);

  useEffect(() => {
    setLoadingMoreItems(false);
  }, [items.length]);

  // trigger the registering of the new observer target here, when we get more items.
  // This is expected to run, while the popup is open and we get new data (so the new last-item is observed)
  useEffect(() => {
    if (scrollPositionRef.current && loadingMoreItems === false) {
      if (ref.current) {
        ref.current.querySelectorAll(popupMenuSelector)[0].scrollTop = scrollPositionRef.current;
      }
    }

    if (observerRef.current) {
      observerRef.current.disconnect();
      const target = scrollObserverTarget(ref, loadingMoreItems);
      if (target && canLoadMoreItems) {
        observerRef.current.observe(target);
      }
    }
  }, [items.length, canLoadMoreItems, registerLoadOnScrollObserver, loadingMoreItems]);

  // make sure any possible observer is cleaned up on "unmount"
  useEffect(() => {
    return () => {
      cleanupObserver(observerRef);
    };
  }, []);

  // implement autofocus
  useEffect(() => {
    if (autoFocus && ref.current) {
      setTimeout(() => {
        if (multiple || search) {
          ref.current?.querySelector('input')?.focus();
        } else {
          ref.current?.querySelector('button')?.focus();
        }
      }, 0);
    }
  }, [autoFocus, multiple, search]);

  // callback to register/unregister the observer for the loadMoreItems functionality
  const handleOnOpenChange = useCallback(
    (_, props: DropdownProps) => {
      setLoadingMoreItems(false);
      if (props.open === false && observerRef.current) {
        cleanupObserver(observerRef);
      } else if (props.open && ref.current) {
        // hack; we don't know when the popup is actually rendered. so check, until we find it.
        const handle = setInterval(() => {
          const popupMenuQuery = ref.current?.querySelectorAll(popupMenuSelector);
          if (popupMenuQuery && popupMenuQuery.length > 0) {
            clearInterval(handle);
            observerRef.current = registerLoadOnScrollObserver();
          }
        }, 30);
      }
    },
    [registerLoadOnScrollObserver]
  );

  let itemsForRender = items;
  if (hasDataError) {
    itemsForRender = [
      ...items,
      {
        key: 'error',
        header: t('dropdown.error-loading-items'),
        content: <div />,
        disabled: true,
      },
    ];
  } else if (loadingMoreItems) {
    itemsForRender = [
      ...items,
      {
        key: 'loading',
        header: '',
        content: <Loader label={t('dropdown.loading-more-items')} />,
        disabled: true,
      },
    ];
  }

  const defaultSearchQuery = useMemo(() => {
    if (typeof value === 'string') {
      return value;
    }
    if (
      value &&
      typeof value === 'object' &&
      'header' in value &&
      typeof value.header === 'string'
    ) {
      return value.header;
    }
    return undefined;
  }, [value]);

  return (
    <Dropdown
      {...props}
      ref={ref}
      fluid
      items={itemsForRender}
      onChange={(_, data) => onChange(data)}
      onSearchQueryChange={onSearchQueryChange ? (_, data) => onSearchQueryChange(data) : undefined}
      onOpenChange={shouldRegisterScrollObserver ? handleOnOpenChange : undefined}
      styles={(data) => ({
        ...dropDownStyles(data),
        ...(position === 'above' && dropDownAboveStyles(data)),
      })}
      renderItem={(Item, props) => (
        <Item {...props} isFromKeyboard={false} header={`${props.header}`} />
      )}
      renderSelectedItem={
        renderSelectedItem ||
        ((SelectedItem, props) => {
          if (
            props.header &&
            props.header.toString().length > textLengthToTriggerTooltip &&
            showTooltipsOnSelectedItems !== false
          ) {
            return (
              <Tooltip
                key={props.header.toString()}
                content={props.header}
                trigger={<SelectedItem {...props} styles={selectedItemStyle} />}
              />
            );
          } else {
            return <SelectedItem {...props} styles={selectedItemStyle} />;
          }
        })
      }
      defaultSearchQuery={defaultSearchQuery}
    />
  );
};

/**
 * Cleanup of any IntersectionObserver, that may exist within the ref.
 */
const cleanupObserver = (observerRef: MutableRefObject<IntersectionObserver | null>) => {
  if (observerRef.current) {
    observerRef.current.disconnect();
    observerRef.current = null;
  }
};

/**
 * Returns the last item of the open selection popup.
 * @param ref The ref of the Dropdown
 * @returns the last real item in the popup, or undefinied
 */
const scrollObserverTarget = (
  ref: MutableRefObject<HTMLDivElement | null>,
  isLoadingMoreItems: boolean
) => {
  if (!ref.current || isLoadingMoreItems) {
    return undefined;
  }

  const itemsQuery = ref.current.querySelectorAll(`${popupMenuSelector} > [role="option"]`);
  if (itemsQuery.length > 0) {
    return itemsQuery[itemsQuery.length - 1];
  } else {
    return undefined;
  }
};
