import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import {
  ProjectTemplateDto,
  useAddProjectTemplate,
  useUpdateProjectTemplate,
} from '../../data-access';
import { TemplateProjectForm } from './TemplateProjectForm';
import { ProjectTemplateDto as CommandProjectTemplateDto } from '../../data-access/open-api/commands';
import { useForm } from 'react-hook-form';
import { Ti8mDialog } from '../common/Ti8mDialog';

const defautlCommandProjectTemplate: CommandProjectTemplateDto = {
  id: undefined,
  clientId: '',
  name: { de: '', en: '' },
  sectorId: '',
  description: { de: '', en: '' },
  start: '',
  end: undefined,
  isUnderNDA: false,
};

type TemplateProjectDialogProps = {
  open: boolean;
  project?: ProjectTemplateDto;
  onClose: () => void;
};

export const TemplateProjectDialog = ({ project, open, onClose }: TemplateProjectDialogProps) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isDirty },
    setValue,
    getValues,
    trigger,
  } = useForm<CommandProjectTemplateDto>({
    values: project
      ? {
          id: project.id,
          clientId: project.client.id,
          name: project.name,
          sectorId: project.sector.id,
          description: project.description,
          start: project.start,
          end: project.end,
          isUnderNDA: project.isUnderNDA,
        }
      : defautlCommandProjectTemplate,
  });

  const { mutate: addNewTemplateBasedProject } = useAddProjectTemplate();

  const { mutate: updateProject } = useUpdateProjectTemplate();

  const onSubmit = useCallback(
    (data: CommandProjectTemplateDto) => {
      if (data.id) {
        updateProject({ id: data.id, requestBody: data });
      } else {
        addNewTemplateBasedProject({ requestBody: data });
      }
    },
    [updateProject, addNewTemplateBasedProject]
  );

  const handleConfirm = useCallback(async () => {
    if (await trigger()) {
      onSubmit(getValues());
      onClose();
    }
  }, [onSubmit, getValues, trigger, onClose]);

  return (
    <Ti8mDialog
      open={open}
      header={project ? t('dialog.add-project.title-edit') : t('dialog.add-project.title-add')}
      cancelButton={{
        content: t('dialog.button-cancel'),
      }}
      onCancel={() => onClose()}
      confirmButton={{
        content: project ? t('dialog.button-save-changes') : t('dialog.button-save'),
        disabled: !isDirty,
      }}
      onConfirm={handleConfirm}
      content={
        <TemplateProjectForm
          isEdit={project !== undefined}
          defaultValues={project}
          errors={errors}
          register={register}
          setValue={setValue}
        />
      }
    />
  );
};
