import { FC, useMemo, useState } from 'react';
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindow,
  MarkerClusterer,
} from '@react-google-maps/api';
import { Button, Text } from '@fluentui/react-northstar';
import './styles/index.css';
import mapstylesLight from './styles/mapstyles-light';
import mapstylesDark from './styles/mapstyles-dark';
import markerImage from './assets/person.png';
import markerImageTim from './assets/ti8m_location_shadow.png';
import { timLocations } from './ti8m_locations';
import { useTranslation } from 'react-i18next';
import { Flex, Input, Provider } from '@fluentui/react-northstar';
import { EmployeeOriginsDto } from '../../data-access';
import { useOrigins } from '../../data-access/hooks/queries/use-origins';
import { env } from '../../utils/env';
import { useDeleteOrigin } from '../../data-access/hooks/commands/use-delete-origin';
import { useAuthInfo } from '../../auth/use-authinfo';

const GOOGLE_API_KEY = env('REACT_APP_GOOGLE_API_KEY');
const DEFAULT_COORDINATES = { lat: 47.3846964, lng: 8.4919515 };

const colorSchemeToMapstylesMapping: Record<'dark' | 'light', any> = {
  light: mapstylesLight,
  dark: mapstylesDark,
};
const colorSchemeToFontColorMapping: Record<'dark' | 'light', string> = {
  light: '#004d9f',
  dark: '#ffffff',
};

const clustererOptions = {
  imagePath:
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
};

export const OriginsMap: FC = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY!,
  });
  const queryResult = useOrigins();

  if (!isLoaded || queryResult.isLoading || queryResult.isError) return null;

  return (
    <Provider.Consumer
      render={(theme) => (
        <MapDisplay
          employeeOrigins={queryResult.data!}
          colorScheme={theme.siteVariables.themeType}
        />
      )}
    />
  );
};

interface MapDisplayProps {
  employeeOrigins: EmployeeOriginsDto[];
  colorScheme: 'dark' | 'light';
}

const MapDisplay: React.FC<MapDisplayProps> = ({ employeeOrigins, colorScheme }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');
  const { mutate: deleteOrigin, isLoading: isBusyDeleting } = useDeleteOrigin(
    t('alert.request-objects.origin'),
    t('alert.update-types.delete')
  );

  const { loginAccountId } = useAuthInfo();

  const filteredMarkers = useMemo(
    () =>
      employeeOrigins.filter((origin) =>
        `${origin.firstName} ${origin.lastName}`.toLowerCase().includes(query.toLowerCase())
      ),
    [employeeOrigins, query]
  );

  const fontColor = colorSchemeToFontColorMapping[colorScheme];

  const [selectedOrigin, setSelectedOrigin] = useState<EmployeeOriginsDto | undefined>(undefined);

  const options: google.maps.MapOptions = useMemo(
    () => ({
      styles: colorSchemeToMapstylesMapping[colorScheme],
      disableDefaultUI: true,
      zoomControl: true,
    }),
    [colorScheme]
  );
  const markerScaledSize = useMemo(() => new window.google.maps.Size(40, 40), []);
  const markerLabelOrigin = useMemo(() => new window.google.maps.Point(20, 50), []);
  let centerCoords = DEFAULT_COORDINATES;

  if (filteredMarkers.length !== 0 && query) {
    centerCoords = filteredMarkers[0].origins;
  }

  return (
    <div style={{ position: 'relative' }}>
      <Flex className="origins__search-bar">
        <Input
          fluid
          defaultValue=""
          placeholder={t('origins.map.search-employees')}
          clearable
          onChange={(_, data) => {
            if (data) setQuery(data.value);
          }}
        />
      </Flex>
      <GoogleMap
        zoom={10}
        center={centerCoords}
        mapContainerStyle={{
          height: 'calc(100vh - 60px)',
          width: '100%',
          zIndex: 3,
        }}
        options={options}
      >
        <MarkerClusterer options={clustererOptions}>
          {(clusterer) => (
            <>
              {filteredMarkers.map((origin) => (
                <MarkerF
                  // We put the colorScheme in the key, because if the colorScheme is changed at runtime, the marker can be rerendered
                  key={`${origin.id}-${colorScheme}`}
                  position={{
                    lat: origin.origins.lat,
                    lng: origin.origins.lng,
                  }}
                  icon={{
                    url: markerImage,
                    scaledSize: markerScaledSize,
                    labelOrigin: markerLabelOrigin,
                  }}
                  clickable={true}
                  onClick={() => {
                    setSelectedOrigin(origin);
                  }}
                  label={{
                    text: `${origin.firstName} ${origin.lastName}`,
                    color: fontColor,
                    fontSize: '1rem',
                    fontWeight: '500',
                  }}
                  clusterer={clusterer}
                />
              ))}
            </>
          )}
        </MarkerClusterer>

        {selectedOrigin?.origins && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedOrigin(undefined);
            }}
            position={selectedOrigin.origins}
          >
            <div style={{ marginBottom: 5 }}>
              {loginAccountId === selectedOrigin?.id ? (
                <>
                  <Text
                    size="large"
                    weight="semibold"
                    content={t('origins.map.delete-window-header')}
                  />
                  <Text
                    style={{
                      display: 'block',
                      marginTop: '1.0rem',
                      marginBottom: '0.5rem',
                      maxWidth: '16rem',
                    }}
                    content={t('origins.map.delete-description')}
                  />
                  <Button
                    content={t('origins.map.delete-button')}
                    disabled={isBusyDeleting}
                    onClick={() => {
                      deleteOrigin({ employeeId: selectedOrigin.id });
                    }}
                  />
                </>
              ) : (
                <>
                  <Text
                    size="large"
                    weight="semibold"
                    content={`${selectedOrigin?.firstName} ${selectedOrigin?.lastName}`}
                  />
                  <Text
                    style={{
                      display: 'block',
                      marginTop: '1.0rem',
                      marginBottom: '0.5rem',
                    }}
                    content={t('origins.map.can-delete-description')}
                  />
                </>
              )}
            </div>
          </InfoWindow>
        )}

        {timLocations.map((timloc) => (
          <MarkerF
            key={`${timloc.name}-${timloc.origins.lng}-${timloc.origins.lat}-${colorScheme}`}
            position={{ lat: timloc.origins.lat, lng: timloc.origins.lng }}
            clickable={false}
            icon={{
              url: markerImageTim,
              scaledSize: markerScaledSize,
              labelOrigin: markerLabelOrigin,
            }}
            label={{
              text: timloc.name,
              color: fontColor,
              fontSize: '1rem',
              fontWeight: '500',
            }}
          />
        ))}
      </GoogleMap>
    </div>
  );
};
