import { useTranslation } from 'react-i18next';
import { ProjectDto as CommandProjectDto } from '../../../data-access/open-api/commands';
import { useCallback } from 'react';
import { ProjectDto, useEditEmployeeProject, useAddEmployeeProject } from '../../../data-access';
import { ProjectForm } from './ProjectForm';
import { useForm } from 'react-hook-form';
import { ProjectCommandModel } from './FormModel/ProjectCommandModel';
import { Language } from '../../../app/locales';
import { Ti8mDialog } from '../../common/Ti8mDialog';

const defaultCommandProject: ProjectCommandModel = {
  id: undefined,
  clientName: { de: '', en: '' },
  projectName: { de: '', en: '' },
  sector: { sectorName: '', sectorId: '' },
  description: { de: '', en: '' },
  role: { de: '', en: '' },
  start: '',
  end: undefined,
  tasks: [],
  skills: [],
  isReferenceProject: false,
};

export const ProjectEditDialog = ({
  employeeId,
  project,
  open,
  onCancel,
}: {
  employeeId: string;
  project?: ProjectDto;
  open: boolean;
  onCancel: () => void;
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.resolvedLanguage as Language;
  const {
    register,
    formState: { errors, isDirty },
    setValue,
    getValues,
    trigger,
  } = useForm<ProjectCommandModel>({
    values: project
      ? ({
          id: project.id,
          clientName: project.clientName,
          projectName: project.name,
          sector: { sectorId: project.sector.id, sectorName: project.sector.name[language] },
          description: project.description,
          role: project.role,
          start: project.start,
          end: project.end,
          tasks: Array.from(project.tasks),
          skills: project.skills.map((s) => ({ skillId: s.id, skillName: s.name })),
          isReferenceProject: project.isReferenceProject,
        } as ProjectCommandModel)
      : defaultCommandProject,
  });
  const { mutate: updateProject } = useEditEmployeeProject(
    t('alert.request-objects.employee'),
    t('alert.update-types.edit')
  );

  const { mutate: addNewProject } = useAddEmployeeProject(
    t('alert.request-objects.employee'),
    t('alert.update-types.edit')
  );

  const onSubmit = useCallback(
    (data: ProjectCommandModel) => {
      const projectCommandDto: CommandProjectDto = {
        id: data.id,
        clientName: data.clientName,
        sectorId: data.sector.sectorId,
        projectName: data.projectName,
        description: data.description,
        role: data.role,
        start: data.start,
        end: data.end,
        tasks: data.tasks,
        skills: data.skills.map((s) => s.skillId),
        isReferenceProject: data.isReferenceProject,
      };
      if (projectCommandDto.id) {
        updateProject({
          id: employeeId,
          requestBody: {
            ...projectCommandDto,
            tasks: projectCommandDto.tasks.filter((t) => t.de !== '' || t.en !== ''),
          },
        });
      } else {
        addNewProject({
          id: employeeId,
          requestBody: {
            ...projectCommandDto,
            tasks: projectCommandDto.tasks.filter((t) => t.de !== '' || t.en !== ''),
          },
        });
      }
    },
    [employeeId, updateProject, addNewProject]
  );

  const handleConfirm = useCallback(async () => {
    if (await trigger()) {
      onSubmit(getValues());
      onCancel();
    }
  }, [trigger, onSubmit, getValues, onCancel]);

  return (
    <Ti8mDialog
      open={open}
      header={project ? t('dialog.add-project.title-edit') : t('dialog.add-project.title-add')}
      cancelButton={{
        content: t('dialog.button-cancel'),
        flat: true,
      }}
      onCancel={onCancel}
      confirmButton={{
        content: project ? t('dialog.button-save-changes') : t('dialog.button-save'),
        flat: true,
        disabled: !isDirty,
      }}
      onConfirm={handleConfirm}
      content={
        <ProjectForm
          errors={errors}
          register={register}
          setValue={setValue}
          getValues={getValues}
        />
      }
    />
  );
};
