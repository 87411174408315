/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { ReleaseNotesListDtoResult } from '../models/ReleaseNotesListDtoResult';

export class ReleaseNotesService {
  /**
   * @throws ApiError
   */
  public static releaseNotes({
    version,
    lastVersion,
  }: {
    version: string;
    lastVersion: number;
  }): CancelablePromise<ReleaseNotesListDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/releaseNotes',
      path: { version: version },
      query: {
        lastVersion: lastVersion,
      },
    });
  }
}
