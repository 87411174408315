import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Text, Checkbox, Flex, Form, FormField, FormLabel } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { Ti8mDatepicker, Ti8mDropdown, Ti8mFileUploader } from '../../../../common';
import {
  NationalSecurityDto,
  SwissMilitaryRankDto,
  SwissCivilProtectionRankDto,
  PersonalSecurityCheckLevelDto,
} from '../../../../../data-access';
import { convertYearMonthDay } from '../../../../../utils';

type DropdownItem<T> = {
  header: React.ReactNode;
  key: T | null;
};

interface NationalSecurityDialogFormProps {
  errors: FieldErrors<NationalSecurityDto>;
  setValue: UseFormSetValue<NationalSecurityDto>;
  watch: UseFormWatch<NationalSecurityDto>;
  register: UseFormRegister<NationalSecurityDto>;
  certificateFile: File | undefined;
  setCertificateFile: (file: File | undefined) => void;
}

export const NationalSecurityDialogForm = ({
  setValue,
  register,
  watch,
  errors,
  setCertificateFile,
}: NationalSecurityDialogFormProps) => {
  const { t } = useTranslation();

  return (
    <Form>
      <FormField
        {...register('interestForNationalSecurityProjects')}
        errorMessage={errors.interestForNationalSecurityProjects?.message}
      >
        <FormLabel
          content={t(
            'employee-detail.master-data.national-security.edit-dialog.interest-in-projects'
          )}
        />
        <Checkbox
          toggle
          label={t(`boolean.${watch('interestForNationalSecurityProjects')}`)}
          checked={watch('interestForNationalSecurityProjects')}
          onChange={(_, data) =>
            setValue('interestForNationalSecurityProjects', data?.checked ?? false, {
              shouldDirty: true,
            })
          }
        />
      </FormField>
      <Text
        size="large"
        weight="semibold"
        content={t('employee-detail.master-data.national-security.edit-dialog.education')}
      />
      <FormField
        {...register('swissMilitaryRank')}
        errorMessage={errors.swissMilitaryRank?.message}
      >
        <FormLabel
          content={t(
            'employee-detail.master-data.national-security.edit-dialog.swiss-military-rank'
          )}
        />
        <Ti8mDropdown
          items={Object.values(SwissMilitaryRankDto)
            .filter((d) => typeof d === 'number')
            .map((d) => ({
              header: t(
                `employee-detail.master-data.national-security.value.swiss-military-rank.${d}`
              ),
              key: d,
              selected: d === watch('swissMilitaryRank'),
            }))}
          clearable
          placeholder={t(
            'employee-detail.master-data.national-security.edit-dialog.swiss-military-rank-placeholder'
          )}
          value={
            watch('swissMilitaryRank')
              ? t(
                  `employee-detail.master-data.national-security.value.swiss-military-rank.${watch(
                    'swissMilitaryRank'
                  )}`
                )
              : undefined
          }
          onChange={(data) =>
            setValue(
              'swissMilitaryRank',
              (data.value as DropdownItem<SwissMilitaryRankDto> | undefined)?.key ?? null,
              {
                shouldDirty: true,
              }
            )
          }
        />
      </FormField>
      <Flex column>
        <FormLabel
          content={t(
            'employee-detail.master-data.national-security.edit-dialog.cyber-basic-training'
          )}
        />
        <FormField
          {...register('selectedForCyberBasicMilitaryTraining')}
          errorMessage={errors.selectedForCyberBasicMilitaryTraining?.message}
        >
          <Checkbox
            {...register('selectedForCyberBasicMilitaryTraining')}
            label={t(
              `employee-detail.master-data.national-security.edit-dialog.cyber-basic-training-selected`
            )}
            checked={watch('selectedForCyberBasicMilitaryTraining')}
            onChange={(_, data) =>
              setValue('selectedForCyberBasicMilitaryTraining', data?.checked ?? false, {
                shouldDirty: true,
              })
            }
          />
        </FormField>
        <FormField
          {...register('degreedInCyberBasicMilitaryTraining')}
          errorMessage={errors.degreedInCyberBasicMilitaryTraining?.message}
        >
          <Checkbox
            {...register('degreedInCyberBasicMilitaryTraining')}
            label={t(
              'employee-detail.master-data.national-security.edit-dialog.cyber-basic-training-degreed'
            )}
            checked={watch('degreedInCyberBasicMilitaryTraining')}
            onChange={(_, data) =>
              setValue('degreedInCyberBasicMilitaryTraining', data?.checked ?? false, {
                shouldDirty: true,
              })
            }
          />
        </FormField>
      </Flex>
      <FormField
        {...register('swissCivilProtectionRank')}
        errorMessage={errors.swissCivilProtectionRank?.message}
      >
        <FormLabel
          content={t(
            'employee-detail.master-data.national-security.edit-dialog.swiss-civil-protetion-rank'
          )}
        />
        <Ti8mDropdown
          items={Object.values(SwissCivilProtectionRankDto)
            .filter((d) => typeof d === 'number')
            .map((d) => ({
              header: t(
                `employee-detail.master-data.national-security.value.swiss-civil-protection-rank.${d}`
              ),
              key: d,
              selected: d === watch('swissCivilProtectionRank'),
            }))}
          clearable
          placeholder={t(
            'employee-detail.master-data.national-security.edit-dialog.swiss-civil-protetion-rank-placeholder'
          )}
          value={
            watch('swissCivilProtectionRank')
              ? t(
                  `employee-detail.master-data.national-security.value.swiss-civil-protection-rank.${watch(
                    'swissCivilProtectionRank'
                  )}`
                )
              : undefined
          }
          onChange={(data) =>
            setValue(
              'swissCivilProtectionRank',
              (data.value as DropdownItem<SwissCivilProtectionRankDto> | undefined)?.key ?? null,
              { shouldDirty: true }
            )
          }
        />
      </FormField>
      <FormField {...register('dutyFulfilled')} errorMessage={errors.dutyFulfilled?.message}>
        <FormLabel
          content={t('employee-detail.master-data.national-security.edit-dialog.duty-fulfilled')}
        />
        <Checkbox
          toggle
          label={t(`boolean.${watch('dutyFulfilled')}`)}
          checked={watch('dutyFulfilled')}
          onChange={(_, data) =>
            setValue('dutyFulfilled', data?.checked ?? false, { shouldDirty: true })
          }
        />
      </FormField>
      <Text
        size="large"
        weight="semibold"
        content={t(
          'employee-detail.master-data.national-security.edit-dialog.personal-security-check'
        )}
      />
      <FormField
        {...register('personalSecurityCheckLevel')}
        errorMessage={errors.personalSecurityCheckLevel?.message}
      >
        <FormLabel
          content={t(
            'employee-detail.master-data.national-security.edit-dialog.personal-security-check-level'
          )}
        />
        <Ti8mDropdown
          items={Object.values(PersonalSecurityCheckLevelDto)
            .filter((d) => typeof d === 'number')
            .map((d) => ({
              header: t(
                `employee-detail.master-data.national-security.value.personal-security-check-level.${d}`
              ),
              key: d,
              selected: d === watch('personalSecurityCheckLevel'),
            }))}
          clearable
          placeholder={t(
            'employee-detail.master-data.national-security.edit-dialog.personal-security-check-level-placeholder'
          )}
          value={
            watch('personalSecurityCheckLevel')
              ? t(
                  `employee-detail.master-data.national-security.value.personal-security-check-level.${watch(
                    'personalSecurityCheckLevel'
                  )}`
                )
              : undefined
          }
          onChange={(data) =>
            setValue(
              'personalSecurityCheckLevel',
              (data.value as DropdownItem<PersonalSecurityCheckLevelDto> | undefined)?.key ?? null,
              {
                shouldDirty: true,
              }
            )
          }
        />
      </FormField>
      <FormField
        {...register('personalSecurityCheckExpiryDate')}
        errorMessage={errors.personalSecurityCheckExpiryDate?.message}
      >
        <FormLabel
          content={t(
            'employee-detail.master-data.national-security.edit-dialog.personal-security-check-expiry-date'
          )}
        />
        <Ti8mDatepicker
          placeholder={t(
            'employee-detail.master-data.national-security.edit-dialog.personal-security-check-expiry-date-placeholder'
          )}
          defaultSelectedDate={
            watch('personalSecurityCheckExpiryDate')
              ? new Date(watch('personalSecurityCheckExpiryDate')!)
              : undefined
          }
          onDateChange={(date) =>
            setValue('personalSecurityCheckExpiryDate', date ? convertYearMonthDay(date) : null, {
              shouldDirty: true,
            })
          }
          popupPosition="above"
        />
      </FormField>
      <FormField
        {...register('personalSecurityCheckOriginalFileName')}
        errorMessage={errors.personalSecurityCheckOriginalFileName?.message}
      >
        <FormLabel
          content={t(
            'employee-detail.master-data.national-security.edit-dialog.personal-security-check-certificate'
          )}
        />
        <Ti8mFileUploader
          asField
          selectedFile={watch('personalSecurityCheckOriginalFileName') ?? undefined}
          onFileChanged={(file) => {
            setValue('personalSecurityCheckOriginalFileName', file?.name ?? null, {
              shouldDirty: true,
            });
            setValue('personalSecurityCheckCertificateFileName', file ? 'UPLOAD_PENDING' : null);
            setCertificateFile(file);
          }}
          placeholder={t(
            'employee-detail.master-data.national-security.edit-dialog.personal-security-check-certificate-placeholder'
          )}
        />
      </FormField>
    </Form>
  );
};
