import { Button, Flex, List, Text, TrashCanIcon } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { SubjectMatterExpertTopicDto } from '../../../data-access/open-api/queries/models/SubjectMatterExpertTopicDto';
import { Language } from '../../../app/locales';

interface EditSubjectMatterExpertDialogFormProps {
  editSubjectMatterExpertTopics: Array<SubjectMatterExpertTopicDto>;
  setEditSubjectMatterExpertTopics: (
    subjectMatterExpertTopic: Array<SubjectMatterExpertTopicDto>
  ) => void;
  setTouched: (touched: boolean) => void;
}

export const EditSubjectMatterExpertDialogForm = ({
  editSubjectMatterExpertTopics,
  setEditSubjectMatterExpertTopics,
  setTouched,
}: EditSubjectMatterExpertDialogFormProps) => {
  const { i18n } = useTranslation();
  const language = i18n.resolvedLanguage as Language;

  const onDeleteSubjectMatterExpertTopic = (id: string) => {
    const updatedFocusTopics = editSubjectMatterExpertTopics.filter((topic) => topic.id !== id);
    setEditSubjectMatterExpertTopics(updatedFocusTopics);
    setTouched(true);
  };

  return (
    <Flex column gap="gap.medium">
      <List>
        {editSubjectMatterExpertTopics.map((topic, index) => (
          <List.Item
            key={topic.id}
            index={index}
            styles={{ padding: 0 }}
            content={
              <Flex space="between" vAlign="center">
                <Text size="medium" content={topic.name[language]} />
                <Button
                  iconOnly
                  circular
                  primary
                  size="small"
                  icon={<TrashCanIcon outline />}
                  onClick={() => onDeleteSubjectMatterExpertTopic(topic.id)}
                />
              </Flex>
            }
          />
        ))}
      </List>
    </Flex>
  );
};
