import { Link } from '@fluentui/react';
import { Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { OpenOutsideIconOutlined } from '../../icons';

export const LanguageLevelExternalLink = () => {
  const { t } = useTranslation();

  return (
    <Text
      as={Link}
      target="_blank"
      rel="noreferrer"
      href="https://www.coe.int/en/web/common-european-framework-reference-languages/table-1-cefr-3.3-common-reference-levels-global-scale"
      size="small"
    >
      <OpenOutsideIconOutlined size="small" />
      <Text
        style={{ marginLeft: '4px' }}
        content={t('employee-detail.master-data.skills.levels-more-info')}
      />
    </Text>
  );
};
