import { Button, EditIcon, Flex, Popup, TrashCanIcon } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';

type ActionButtonsProps<T> = {
  item: T;
  onEditItem: (item: T) => void;
  onDeleteItem?: (item: T) => void;
  itemName?: string;
};

export const ActionButtons = <T,>(props: ActionButtonsProps<T>) => {
  const { onEditItem, item } = props;
  return (
    <Flex>
      <Button primary iconOnly text icon={<EditIcon />} onClick={() => onEditItem(item)} />
      <DeleteButton {...props} />
    </Flex>
  );
};

const DeleteButton = <T,>({
  item,
  itemName,
  onDeleteItem,
}: Pick<ActionButtonsProps<T>, 'item' | 'onDeleteItem' | 'itemName'>) => {
  const { t } = useTranslation();

  if (onDeleteItem === undefined) {
    return null;
  } else {
    return (
      <Popup
        trigger={
          <Button
            primary
            iconOnly
            text
            icon={<TrashCanIcon />}
            title={t('shared.buttons.remove')}
          />
        }
        content={{
          content: (
            <Button
              primary
              iconOnly
              text
              content={
                itemName ? `${t('shared.buttons.remove')} ${itemName}` : t('shared.buttons.remove')
              }
              icon={<TrashCanIcon />}
              onClick={() => onDeleteItem(item)}
            />
          ),
          styles: { '> .ui-popup__content__content': { padding: '0.5rem 1rem' } },
        }}
      />
    );
  }
};
