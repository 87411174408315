import { Form, FormField, FormInput } from '@fluentui/react-northstar';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRequestSkill } from '../../../data-access/hooks/commands/use-request-skill';
import { Ti8mDialog } from '../../common/Ti8mDialog';

type RequestSkillDialogProps = {
  isOpen: boolean;
  setIsOpen: (action: boolean) => void;
};

export const RequestSkillDialog = ({ isOpen, setIsOpen }: RequestSkillDialogProps) => {
  const { t } = useTranslation();
  const {
    register,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<{ skillName: string }>();

  const { mutate: requestSkill } = useRequestSkill();

  return (
    <Ti8mDialog
      open={isOpen}
      header={t('request-skill-dialog.title')}
      cancelButton={{ content: t('shared.buttons.cancel') }}
      onCancel={() => setIsOpen(false)}
      confirmButton={{ content: t('request-skill-dialog.confirm-button') }}
      onConfirm={async () => {
        if (await trigger(undefined, { shouldFocus: true })) {
          requestSkill({ requestBody: getValues().skillName });
          setIsOpen(false);
        }
      }}
      content={
        <Form>
          <FormField>
            <FormInput
              {...register('skillName', {
                required: { value: true, message: t('form-validation.messages.required') },
                minLength: {
                  value: 2,
                  message: t('form-validation.messages.min-length', { value: 2 }),
                },
              })}
              label={t('request-skill-dialog.label')}
              fluid
              errorMessage={errors.skillName && errors.skillName.message}
            />
          </FormField>
        </Form>
      }
    />
  );
};
