import { ComponentSlotStyle, SiteVariablesPrepared } from '@fluentui/react-northstar';

const colPointerStyle: ComponentSlotStyle = {
  cursor: 'pointer',
};

export const avatarColumnStyle: ComponentSlotStyle = {
  maxWidth: '5%',
  justifyContent: 'center',
  ...colPointerStyle,
};

export const nameColumnStyle = {
  maxWidth: '15%',
  ...colPointerStyle,
};

export const roleColumnStyle = {
  maxWidth: '15%',
  ...colPointerStyle,
};

export const focusColumnStyle = {
  maxWidth: '15%',
  ...colPointerStyle,
};

export const skillsColumnStyle = {
  maxWidth: '30%',
  ...colPointerStyle,
};

export const modifiedColumnStyle = {
  maxWidth: '10%',
  ...colPointerStyle,
};

export const downloadColumnStyle = {
  maxWidth: '10%',
  ...colPointerStyle,
};

export const tableRowVariablesOverride = (data: SiteVariablesPrepared) => {
  return {
    backgroundColorHover: data.colorScheme['default']['background6'],
  };
};

export const tableStyles: ComponentSlotStyle = (data) => {
  return {
    '& .ui-table__row': {
      fontSize: data.theme.siteVariables.fontSizes['small'],
    },
  };
};

export const overflowPillStyle: ComponentSlotStyle = {
  background: '#989AC9',
  minWidth: '2',
  color: 'white',
  ':hover': {
    background: '#989AC9',
  },
};
