import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { DEFAULT_TAKE, getNextPageParam, unpack, withApiVersion } from '../../helpers';
import { QueryKeys } from '../../query-keys';
import { SubjectMatterExpertTopicService } from '../../open-api/queries/services/SubjectMatterExpertTopicService';

const getSubjectMatterExpertTopics = (context: QueryFunctionContext) => {
  const withVersion = withApiVersion(SubjectMatterExpertTopicService.subjectMatterExpertTopics);
  return withVersion({
    searchString: `${context.queryKey[1]}`,
    skip: context.pageParam,
    take: DEFAULT_TAKE,
  });
};

export const useInfiniteSubjectMatterExpertTopics = (searchString?: string) => {
  const { fetchNextPage, hasNextPage, data, isError } = useInfiniteQuery({
    queryKey: [QueryKeys.SubjectMatterExpertTopics, searchString],
    queryFn: getSubjectMatterExpertTopics,
    getNextPageParam: getNextPageParam,
  });

  return {
    subjectMatterExpertTopics: unpack(data),
    hasMoreSubjectMatterExpertTopics: hasNextPage,
    fetchMoreSubjectMatterExpertTopics: fetchNextPage,
    isSubjectMatterExpertError: isError,
  };
};
