import { atom } from 'recoil';
import { QueryParams } from '../../data-access';

export type EmployeeFilterAtomState = QueryParams.Employees;

export const employeeFilterAtom = atom<EmployeeFilterAtomState>({
  key: 'employeeFilter',
  default: {
    searchString: '',
  },
});
