import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, WithoutVersion, withApiVersion } from '../../helpers';
import { ApiError, Result, SectorService } from '../../open-api/commands';
import { SectorService as QuerySectorService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const updateSector = withApiVersion(SectorService.updateSector);
const getEventPosition = withApiVersion(QuerySectorService.getEventPosition);

export type UpdateSectorInput = WithoutVersion<ExtractFirstArgAsType<typeof updateSector>>;

type ResultWithId = Result & { id: string };

export const useUpdateSector = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<ResultWithId, ApiError, UpdateSectorInput>(
    async (input) => {
      const result = await updateSector(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, (id) => getEventPosition({ id })).then(() =>
          queryClient.invalidateQueries(QueryKeys.Sectors)
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.sector'),
      },
    }
  );
};
