import { ExtractFirstArgAsType, withApiVersion, WithoutVersion } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ApiError, FocusTopicService, GuidResult } from '../../open-api/commands';
import { FocusTopicService as QueryFocusTopicService } from '../../open-api/queries/services/FocusTopicService';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const addFocusTopic = withApiVersion(FocusTopicService.addFocusTopic);
const getEventPosition = withApiVersion(QueryFocusTopicService.getEventPosition);

export type AddFocusTopicInput = WithoutVersion<
  ExtractFirstArgAsType<typeof FocusTopicService.addFocusTopic>
>;

export const useAddFocusTopic = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, AddFocusTopicInput>(addFocusTopic, {
    onSuccess: async ({ payload: id, storePosition }) =>
      waitForEvent({ id, storePosition }, (id) => getEventPosition({ id })).then(() =>
        queryClient.refetchQueries(QueryKeys.FocusTopics)
      ),
    meta: {
      type: t('alert.update-types.requested'),
      requestObject: t('alert.request-objects.focus-topic'),
    },
  });
};
