import {
  Checkbox,
  DropdownProps,
  Flex,
  Form,
  FormField,
  FormInput,
  FormLabel,
  FormMessage,
  FormTextArea,
  Grid,
  Text,
} from '@fluentui/react-northstar';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Language } from '../../app/locales';
import {
  ProjectTemplateDto as ProjectTemplateQueryDto,
  TranslationDto,
  useEmployee,
  useInfiniteClients,
} from '../../data-access';
import { ProjectTemplateDto } from '../../data-access/open-api/commands';
import { convertYearMonthDay } from '../../utils/dateConverter';
import { Ti8mDatepicker, Ti8mDropdown } from '../common';
import { useEmployeesData } from './hooks/useEmployeesData';
import { useSectorsData } from './hooks/useSectorsData';

type TemplateProjectFormProps = {
  isEdit: boolean;
  defaultValues?: ProjectTemplateQueryDto;
  errors: FieldErrors<ProjectTemplateDto>;
  register: UseFormRegister<ProjectTemplateDto>;
  setValue: UseFormSetValue<ProjectTemplateDto>;
};

export const TemplateProjectForm = ({
  defaultValues,
  errors,
  register,
  setValue,
  isEdit = false,
}: TemplateProjectFormProps) => {
  const { t, i18n } = useTranslation();

  const language = i18n.resolvedLanguage as Language;
  //const language = "en" as Language
  //const language = "de" as Language

  const [projectCharsCount, setProjectCharsCount] = useState({
    de: defaultValues?.name.de.length ?? 0,
    en: defaultValues?.name.en.length ?? 0,
  });
  const [projectDescriptionCharsCount, setProjectDescriptionCharsCount] = useState({
    de: defaultValues?.description.de.length ?? 0,
    en: defaultValues?.description.en.length ?? 0,
  });

  const [clientSearchString, setClientSearchString] = useState('');
  const { clients, fetchMoreClients, hasMoreClients, isClientsError } =
    useInfiniteClients(clientSearchString);
  const [employeeSearchString, setEmployeeSearchString] = useState('');
  const employees = useEmployeesData(employeeSearchString);
  const employee = useEmployee(defaultValues?.referenceContactPerson);
  const sectors = useSectorsData();

  const maxProjectLength = 55;
  const maxDescriptionLength = 600;

  const getDateDefaultValue = (data?: string | null) => {
    if (data && data !== '') {
      return new Date(data);
    }
    return undefined;
  };

  const handleClientSearchQueryChanged = useCallback(
    (data: DropdownProps) => {
      if (data.searchQuery !== undefined) {
        setClientSearchString(data.searchQuery);
      }
    },
    [setClientSearchString]
  );

  const handleEmployeeSearchQueryChanged = useCallback(
    (data: DropdownProps) => {
      if (data.searchQuery !== undefined) {
        setEmployeeSearchString(data.searchQuery);
      }
    },
    [setEmployeeSearchString]
  );

  const handleProjectNameChanged = useCallback(
    (value: string, prop: keyof TranslationDto) => {
      setProjectCharsCount((prev) => ({
        ...prev,
        [prop]: value.length,
      }));
      setValue(`name.${prop}`, value, { shouldDirty: true });
    },
    [setProjectCharsCount, setValue]
  );

  const handleIsUnderNdaChanged = useCallback(
    (_: any, data?: { checked: boolean }) => {
      setValue('isUnderNDA', data?.checked || false, { shouldDirty: true });
    },
    [setValue]
  );

  const handleProjectDescriptionChanged = useCallback(
    (value: string, prop: keyof TranslationDto) => {
      setProjectDescriptionCharsCount((prev) => ({
        ...prev,
        [prop]: value.length,
      }));
      setValue(`description.${prop}`, value, { shouldDirty: true });
    },
    [setProjectDescriptionCharsCount, setValue]
  );

  const handleReferenceContactPersonChanged = useCallback(
    (data: DropdownProps) => {
      setValue('referenceContactPerson', (data.value as { key: string })?.key ?? '', {
        shouldDirty: true,
      });
    },
    [setValue]
  );

  const handleReferenceUrlChanged = useCallback(
    (value: string) => {
      setValue('referenceUrl', value, { shouldDirty: true });
    },
    [setValue]
  );

  const clientItemChanged = (data: DropdownProps) => {
    setValue('clientId', (data.value as { key: string })?.key ?? '', { shouldDirty: true });
  };

  const sectorItemChanged = (data: DropdownProps) => {
    setValue('sectorId', (data.value as { key: string })?.key ?? '', { shouldDirty: true });
  };

  const onStartDateChanged = (date: Date | null) => {
    setValue('start', date ? convertYearMonthDay(date) : '', { shouldDirty: true });
  };

  const onEndDateChanged = (date: Date | null) => {
    setValue('end', date ? convertYearMonthDay(date) : null, { shouldDirty: true });
  };

  register('name.de', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('name.en', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('clientId', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('sectorId', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('description.de', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('description.en', {
    required: { value: true, message: t('form-validation.messages.required') },
  });
  register('start', {
    required: { value: true, message: t('form-validation.messages.required') },
    validate: (value, formValue) =>
      !formValue.end ||
      dayjs(value) <= dayjs(formValue.end) ||
      t('form-validation.messages.start-before-end'),
  });
  return (
    <div>
      <Flex
        column
        gap="gap.small"
        style={{ maxHeight: '772', overflowY: 'scroll', paddingRight: '10px' }}
      >
        <Form>
          <Flex column>
            <FormInput
              autoFocus
              key={`project-de-input_${errors.name?.de ? 'error' : ''}`}
              label={t('dialog.add-project.project-de')}
              fluid
              errorMessage={errors.name?.de?.message}
              maxLength={maxProjectLength}
              placeholder={t('dialog.add-project.project-placeholder')}
              defaultValue={defaultValues?.name.de}
              onChange={(_, data) => {
                handleProjectNameChanged(data?.value ?? '', 'de');
              }}
            />
            <Text
              align="end"
              size="small"
              content={`${projectCharsCount.de}/${maxProjectLength}`}
            />
          </Flex>
          <Flex column>
            <FormInput
              key={`project-en-input_${errors.name?.en ? 'error' : ''}`}
              label={t('dialog.add-project.project-en')}
              fluid
              errorMessage={errors.name?.en?.message}
              maxLength={maxProjectLength}
              placeholder={t('dialog.add-project.project-placeholder')}
              defaultValue={defaultValues?.name.en}
              onChange={(_, data) => {
                handleProjectNameChanged(data?.value ?? '', 'en');
              }}
            />
            <Text
              align="end"
              size="small"
              content={`${projectCharsCount.en}/${maxProjectLength}`}
            />
          </Flex>
          <FormField>
            <FormLabel content={t('dialog.add-project.client')} />
            <Ti8mDropdown
              items={clients.map((client) => ({
                key: client.id,
                header: client.name[language],
              }))}
              onScrollLoadMoreItems={fetchMoreClients}
              canLoadMoreItems={hasMoreClients}
              hasDataError={isClientsError}
              defaultValue={
                defaultValues
                  ? {
                      key: defaultValues.client.id,
                      header: defaultValues.client.name[language],
                    }
                  : undefined
              }
              search
              placeholder={t('dialog.add-project.client-select-placeholder')}
              onSearchQueryChange={handleClientSearchQueryChanged}
              onChange={clientItemChanged}
            />
            {errors.clientId && <FormMessage error content={errors.clientId.message} />}
          </FormField>
          <Checkbox
            toggle
            defaultChecked={defaultValues?.isUnderNDA}
            onChange={handleIsUnderNdaChanged}
            label={t('dialog.add-project.project-is-under-nda')}
          />

          <FormField>
            <FormLabel content={t('dialog.add-project.sectors')} />
            <Ti8mDropdown
              items={sectors?.map((s) => ({ key: s.id, header: s.name[language] })) ?? []}
              onChange={sectorItemChanged}
              placeholder={t('dialog.add-project.sectors-placeholder')}
              defaultValue={
                defaultValues
                  ? {
                      key: defaultValues.sector.id,
                      header: defaultValues.sector.name[language],
                    }
                  : undefined
              }
            />
            {errors.sectorId && <FormMessage error content={errors.sectorId.message} />}
          </FormField>
          <Grid columns="2" rows="1" styles={{ gap: '1rem' }}>
            <FormField>
              <FormLabel content={t('dialog.add-project.start-date')} />
              <Ti8mDatepicker
                placeholder={t('dialog.add-project.start-date-placeholder')}
                onDateChange={onStartDateChanged}
                defaultSelectedDate={getDateDefaultValue(defaultValues?.start)}
              />
              {errors.start && <FormMessage error content={errors.start.message} />}
            </FormField>
            <FormField>
              <FormLabel content={t('dialog.add-project.end-date')} />
              <Ti8mDatepicker
                placeholder={t('dialog.add-project.end-date-placeholder')}
                onDateChange={onEndDateChanged}
                defaultSelectedDate={getDateDefaultValue(defaultValues?.end)}
              />
            </FormField>
          </Grid>
          <Flex column>
            <FormTextArea
              label={t('dialog.add-project.project-goal-de')}
              rows={4}
              errorMessage={errors.description?.de?.message}
              defaultValue={defaultValues?.description.de}
              placeholder={t('dialog.add-project.project-goal-placeholder')}
              maxLength={maxDescriptionLength}
              fluid
              onChange={(_, data) => {
                handleProjectDescriptionChanged(data?.value ?? '', 'de');
              }}
            />
            <Text
              align="end"
              size="small"
              content={`${projectDescriptionCharsCount.de}/${maxDescriptionLength}`}
            />
          </Flex>
          <Flex column>
            <FormTextArea
              label={t('dialog.add-project.project-goal-en')}
              rows={4}
              errorMessage={errors.description?.en?.message}
              defaultValue={defaultValues?.description.en}
              fluid
              placeholder={t('dialog.add-project.project-goal-placeholder')}
              maxLength={maxDescriptionLength}
              onChange={(_, data) => {
                handleProjectDescriptionChanged(data?.value ?? '', 'en');
              }}
            />
            <Text
              align="end"
              size="small"
              content={`${projectDescriptionCharsCount.en}/${maxDescriptionLength}`}
            />
          </Flex>
          {isEdit && (
            <>
              <FormInput
                label={t('dialog.add-project.reference-url')}
                fluid
                placeholder={t('dialog.add-project.reference-url-placeholder')}
                defaultValue={defaultValues?.referenceUrl}
                onChange={(_, data) => {
                  handleReferenceUrlChanged(data?.value ?? '');
                }}
              />
              <FormField>
                <FormLabel content={t('dialog.add-project.reference-contact-person')} />
                <Ti8mDropdown
                  key={`project-template.reference-contact-person.${employee?.data?.id}`}
                  items={
                    employees.map((employee) => ({
                      header: `${employee.firstName} ${employee.lastName}`,
                      image: employee.thumbnail,
                      content: employee.role,
                      key: employee.id,
                    })) ?? []
                  }
                  defaultValue={
                    employee.data
                      ? {
                          header: `${employee.data.firstName} ${employee.data.lastName}`,
                          content: employee.data.role,
                          key: employee.data.id,
                        }
                      : undefined
                  }
                  search
                  placeholder={t('dialog.add-project.reference-contact-person-placeholder')}
                  onSearchQueryChange={handleEmployeeSearchQueryChanged}
                  onChange={handleReferenceContactPersonChanged}
                />
              </FormField>
            </>
          )}
        </Form>
      </Flex>
    </div>
  );
};
