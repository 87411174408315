import { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { NationalityListDtoResult } from '../models/NationalityListDtoResult';

export class NationalityService {
  public static nationalities({
    version,
  }: {
    version: string;
  }): CancelablePromise<NationalityListDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/nationalities',
      path: { version: version },
    });
  }
}
