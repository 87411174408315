import { env } from '../../../utils/env';
import { EmployeeService } from '../../open-api/queries';
import { QueryParams } from '.';

const downloadNationalSecurityInfoCertificate = async (
  input: QueryParams.DownloadNationalSecurityInfoCertificate
) =>
  EmployeeService.downloadNationalSecurityInfoCertificate({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useDownloadNationalSecurityInfoCertificate = (id: string) => () =>
  downloadNationalSecurityInfoCertificate({ id });
