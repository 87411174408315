import { AddIcon, Button, DropdownProps, Flex, Input, Text } from '@fluentui/react-northstar';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { QueryKeys, useCountries } from '../../data-access';
import { useAddOrigin } from '../../data-access/hooks/commands/use-add-origin';
import { Ti8mDropdown } from '../common';

export const OriginsForm: FC = () => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const countries = useCountries().data;

  const [zipCode, setZipCode] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');

  const { mutate: addOrigin, isLoading } = useAddOrigin(
    t('alert.request-objects.origin'),
    t('alert.update-types.edit'),
    () => {
      // We want to invalidate the origins query cache, so that it gets re-fetched.
      queryClient.invalidateQueries([QueryKeys.Origins]);
    }
  );

  const handleCountryChange = useCallback(
    (data: DropdownProps) => {
      const selectedCountry = countries?.find((country) => country.name.en === data.value);
      setCountryCode(selectedCountry?.code ?? '');
    },
    [countries]
  );

  function submitForm(formValues: { zipCode: string; countryCode: string }) {
    addOrigin({
      requestBody: formValues,
    });
  }

  const isFormValid = countryCode && zipCode;

  return (
    <Flex column gap="gap.small" style={{ padding: '2rem 3rem' }}>
      <Text content={t('origins.form.explanation')} weight="semibold" />
      <Text content={t('origins.form.zip-code')} weight="semibold" />
      <Input
        defaultValue=""
        onChange={(_, data) => setZipCode(data?.value ?? '')}
        placeholder={t('origins.form.zip-code-placeholder')}
        fluid
      />
      {!zipCode && (
        <Text
          content={t('origins.form.zip-code-warning')}
          size="small"
          styles={{ color: '#A4262C' }}
        />
      )}
      <Text content={t('origins.form.country')} weight="semibold" />
      <Ti8mDropdown
        search
        items={countries?.map((country) => country.name.en).filter((x) => !!x) ?? []}
        placeholder={t('origins.form.country-placeholder')}
        onChange={handleCountryChange}
        position="below"
      />

      {!countryCode && (
        <Text
          content={t('origins.form.country-warning')}
          size="small"
          styles={{ color: '#A4262C' }}
        />
      )}
      <Flex.Item push>
        <Button
          primary
          style={{ alignSelf: 'end' }}
          flat
          icon={<AddIcon />}
          onClick={() =>
            submitForm({
              zipCode,
              countryCode,
            })
          }
          content={t('origins.form.add-origin')}
          disabled={isLoading || !isFormValid}
        />
      </Flex.Item>
    </Flex>
  );
};
