import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeSkillDto, useUpdateSkills } from '../../../data-access';
import { RequestSkillDialog } from '../RequestSkillDialog/RequestSkillDialog';
import { AddSkillsDialogForm } from './AddSkillsDialogForm';
import { Ti8mDialog } from '../../common/Ti8mDialog';

type AddSkillsDialogProps = {
  employeeId: string;
  isDialogOpen: boolean;
  existingSkills: EmployeeSkillDto[];
  close: () => void;
};

export const AddSkillsDialog = ({
  employeeId,
  isDialogOpen,
  existingSkills,
  close,
}: AddSkillsDialogProps) => {
  const { t } = useTranslation();
  const [isTouched, setTouched] = useState(false);
  const [isRequestDialogOpen, setRequestDialogVisibility] = useState(false);
  const [skill, setSkill] = useState<{ skillId: string; name: string }>({ skillId: '', name: '' });
  const [level, setLevel] = useState<number>(0);

  const { mutate: updateSkills } = useUpdateSkills();

  const resetDialog = useCallback(() => {
    setSkill({ skillId: '', name: '' });
    setLevel(0);
    setTouched(false);
    close();
  }, [close, setSkill, setTouched, setLevel]);

  const openRequestSkillDialog = useCallback(() => {
    resetDialog();
    setRequestDialogVisibility(true);
  }, [resetDialog, setRequestDialogVisibility]);

  const handleConfirmAddSkill = useCallback(() => {
    const newSkills = existingSkills.map((skill) => ({
      skillId: skill.skill.id,
      name: skill.skill.name,
      level: skill.level,
    }));
    const newSkill = { skillId: skill.skillId, name: skill.name, level: level };
    const duplicate = newSkills.find((skill) => {
      return skill.skillId === newSkill.skillId;
    });
    if (!duplicate) {
      newSkills.push(newSkill);
      updateSkills({
        id: employeeId ?? '',
        requestBody: newSkills,
      });
    }
    resetDialog();
  }, [employeeId, existingSkills, level, skill, updateSkills, resetDialog]);

  return (
    <>
      <Ti8mDialog
        open={isDialogOpen}
        header={t('employee-detail.master-data.skills.add-skills-dialog.header')}
        cancelButton={{
          content: t('employee-detail.master-data.skills.add-skills-dialog.button-cancel'),
        }}
        onCancel={resetDialog}
        confirmButton={{
          content: t('employee-detail.master-data.skills.add-skills-dialog.button-add'),
          disabled: !isTouched,
        }}
        onConfirm={() => {
          handleConfirmAddSkill();
        }}
        content={
          <AddSkillsDialogForm
            skill={skill}
            existingSkills={existingSkills}
            setSkill={setSkill}
            setLevel={setLevel}
            level={level}
            setTouched={setTouched}
            onRequestSkillClick={openRequestSkillDialog}
          />
        }
        resetOverflow
      />
      {isRequestDialogOpen && (
        <RequestSkillDialog isOpen={isRequestDialogOpen} setIsOpen={setRequestDialogVisibility} />
      )}
    </>
  );
};
