/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GuidResult } from '../models/GuidResult';
import type { ProjectTemplateDto } from '../models/ProjectTemplateDto';
import type { Result } from '../models/Result';
import type { Result_1 } from '../models/Result_1';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectTemplateService {
  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static addProjectTemplate({
    version,
    requestBody,
  }: {
    version: string;
    requestBody?: ProjectTemplateDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/project-templates',
      path: {
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static updateProjectTemplate({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: ProjectTemplateDto;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/project-templates/{id}',
      path: {
        id: id,
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
