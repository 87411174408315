import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { ExtractFirstArgAsType, withApiVersion, WithoutVersion } from '../../helpers';
import { ApiError, Result, Result_1 } from '../../open-api/commands';
import { CertificateService } from '../../open-api/commands/services/CertificateService';

const requestCertificate = withApiVersion(CertificateService.requestCertificate);

export type RequestCertificateInput = WithoutVersion<
  ExtractFirstArgAsType<typeof CertificateService.requestCertificate>
>;

export const useRequestCertificate = () => {
  const { t } = useTranslation();

  return useMutation<Result | Result_1, ApiError, RequestCertificateInput>(requestCertificate, {
    meta: {
      type: t('alert.update-types.requested'),
      requestObject: t('alert.request-objects.certificate'),
    },
  });
};
