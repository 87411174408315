import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import { ApiError } from '../../open-api/queries';
import { ClientDtoPaginatedListDto } from '../../open-api/queries/models/ClientDtoPaginatedListDto';
import { ClientDtoPaginatedListDtoResult } from '../../open-api/queries/models/ClientDtoPaginatedListDtoResult';
import { ClientService } from '../../open-api/queries/services/ClientService';
import { QueryKeys } from '../../query-keys';
import * as QueryParams from './query-params';

const getClients = (input: QueryParams.Clients) =>
  ClientService.clients({ version: env('REACT_APP_API_VERSION'), ...input });

export const useClients = (
  queryParams: QueryParams.Clients,
  queryOptions?: UseQueryOptions<
    ClientDtoPaginatedListDtoResult,
    ApiError,
    ClientDtoPaginatedListDto,
    [QueryKeys.Clients, QueryParams.Clients]
  >
) =>
  useQuery([QueryKeys.Clients, queryParams], ({ queryKey: [_, input] }) => getClients(input), {
    select: (data) => data.payload,
    ...queryOptions,
  });
