import { QueryKeys } from '../..';
import { useQuery } from 'react-query';
import mockCountries from './mock-countries.json';
import { CountryListDtoResult } from '../../open-api/queries/models/CountryListDtoResult';

// const getCountries = () =>
//   CountryService.countries({ version: env('REACT_APP_API_VERSION') });

//  #3219: Remove mock and use our call as soon as the service is implemented.
const getCountries = () =>
  new Promise<CountryListDtoResult>((res) => {
    res({
      payload: mockCountries,
    });
  });

export const useCountries = () =>
  useQuery(QueryKeys.Countries, ({ queryKey: _ }) => getCountries(), {
    select: (data) => data.payload,
  });
