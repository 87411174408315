import { Flex, Text } from '@fluentui/react-northstar';

type PersonalInformationProps = {
  label: string;
  data: string | number | undefined;
};

export const PersonalInformation = ({ label, data }: PersonalInformationProps) => {
  return (
    <Flex column gap="gap.small">
      <Text content={label} weight="bold" />
      <Text content={data} />
    </Flex>
  );
};
