import { env } from '../../utils/env';

import { WithoutVersion } from '.';

const API_VERSION = env('REACT_APP_API_VERSION');

const addApiVersion = <T>(obj: T) => ({ ...obj, version: API_VERSION });

export const withApiVersion =
  <Arg extends { version: string }, Result>(
    fn: (arg: Arg | (WithoutVersion<Arg> & { version: string })) => Result
  ) =>
  (arg: WithoutVersion<Arg>): Result =>
    fn(addApiVersion(arg));
