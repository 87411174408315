import { NationalityService, QueryKeys } from '../../../data-access';
import { useQuery } from 'react-query';
import { env } from '../../../utils/env';

const getNationalities = () =>
  NationalityService.nationalities({ version: env('REACT_APP_API_VERSION') });

export const useNationalities = () =>
  useQuery(QueryKeys.Nationalities, ({ queryKey: _ }) => getNationalities(), {
    select: (data) => data.payload,
  });
