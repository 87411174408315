import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, WithoutVersion, withApiVersion } from '../../helpers';
import { ApiError, Result, SubjectMatterExpertService } from '../../open-api/commands';
import { SubjectMatterExpertTopicService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const updateSubjectMatterExpertTopic = withApiVersion(
  SubjectMatterExpertService.updateSubjectMatterExpertTopic
);
const getEventPosition = withApiVersion(SubjectMatterExpertTopicService.getEventPosition);

export type UpdateSubjectMatterExpertTopicInput = WithoutVersion<
  ExtractFirstArgAsType<typeof updateSubjectMatterExpertTopic>
>;

type ResultWithId = Result & { id: string };

export const useUpdateSubjectMatterExpertTopic = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<ResultWithId, ApiError, UpdateSubjectMatterExpertTopicInput>(
    async (input) => {
      const result = await updateSubjectMatterExpertTopic(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, (id) => getEventPosition({ id })).then(() =>
          queryClient.invalidateQueries(QueryKeys.SubjectMatterExpertTopics)
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.subject-matter-expert-topic'),
      },
    }
  );
};
