import { FormField, FormInput } from '@fluentui/react-northstar';
import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ControlledFormInputProps<TForm extends FieldValues> = {
  control: Control<TForm, any>;
  name: Path<TForm>;
  rules?: RegisterOptions;
  label: string;
};

export const ControlledFormInput = <TForm extends FieldValues>({
  control,
  name,
  rules,
  label,
}: ControlledFormInputProps<TForm>) => {
  const { t } = useTranslation();

  return (
    <FormField>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <FormInput {...field} label={t(label)} fluid errorMessage={error?.message} />
        )}
      />
    </FormField>
  );
};
