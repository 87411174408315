import { EmployeeService } from '../../open-api/queries';
import { env } from '../../../utils/env';
import { QueryParams } from '.';

const downloadCertificate = (input: QueryParams.DownloadCertificate) =>
  EmployeeService.downloadCertificate({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useDownloadCertificate = (id: string) => (certificateName?: string) =>
  downloadCertificate({ id, certificateName });
