import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Button,
  Flex,
  Input,
  Text,
  DropdownProps,
  ChevronDownIcon,
  SearchIcon,
  Checkbox,
} from '@fluentui/react-northstar';
import { Ti8mDropdown } from '../common';
import { useEmployeeFilter } from './hooks/useEmployeeFilter';
import { useHistory } from 'react-router-dom';
import {
  useEmployee,
  useInfiniteFocusTopics,
  useInfiniteSkills,
  useInfiniteSubjectMatterExpertTopics,
  useRoles,
  TranslationDto,
  useInfiniteCertificates,
  SkillDto,
  PersonalSecurityCheckLevelDto,
} from '../../data-access';
import { useAuthInfo } from '../../auth/use-authinfo';
import { EmployeeAvatar } from '../EmployeeTable/EmployeeAvatar';

type NamedDto = {
  id: string;
  name: string | TranslationDto;
};

type KeyValueDropdownItem<T> = {
  key: string;
  header: string;
  data: T;
};

export const SearchWithFilter = () => {
  const { t } = useTranslation();

  const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false);

  const [skillsSearchString, setSkillsSearchString] = useState<string>('');
  const [certificatesSearchString, setCertificatesSearchString] = useState<string>('');
  const [focusTopicsSearchString, setFocusTopicsSearchString] = useState<string>('');
  const [subjectMattersExpertsSearchString, setSubjectMattersExpertsSearchString] =
    useState<string>('');

  const { skills, hasMoreSkills, fetchMoreSkills, isSkillsError } =
    useInfiniteSkills(skillsSearchString);
  const { certificates, hasMoreCertificates, fetchMoreCertificates, isCertificatesError } =
    useInfiniteCertificates(certificatesSearchString);
  const { focusTopics, hasMoreFocusTopics, fetchMoreFocusTopics, isFocusTopicsError } =
    useInfiniteFocusTopics(focusTopicsSearchString);
  const {
    subjectMatterExpertTopics,
    hasMoreSubjectMatterExpertTopics,
    fetchMoreSubjectMatterExpertTopics,
    isSubjectMatterExpertError,
  } = useInfiniteSubjectMatterExpertTopics(subjectMattersExpertsSearchString);

  const { data: roles = [] } = useRoles();
  const { loginAccountId } = useAuthInfo();
  const { data: loginUser } = useEmployee(loginAccountId);
  const navigation = useHistory();

  const { filter, updateFilterValue } = useEmployeeFilter();

  const convertToDropdownItems = <T extends NamedDto>(values: T[]) =>
    values.map(
      (c): KeyValueDropdownItem<T> => ({
        key: c.id,
        header: c.name instanceof Object ? c.name.en : c.name,
        data: c,
      })
    );

  const certificateItems = useMemo(() => convertToDropdownItems(certificates), [certificates]);
  const skillItems = useMemo(() => convertToDropdownItems(skills), [skills]);
  const focusTopicItems = useMemo(() => convertToDropdownItems(focusTopics), [focusTopics]);
  const expertTopicItems = useMemo(
    () => convertToDropdownItems(subjectMatterExpertTopics),
    [subjectMatterExpertTopics]
  );
  const personalSecurityCheckLevels = useMemo(
    () =>
      Object.values(PersonalSecurityCheckLevelDto)
        .filter((d) => typeof d === 'number')
        .map((d) => ({
          key: d?.toString(),
          header: t(
            `employee-detail.master-data.national-security.value.personal-security-check-level.${d}`
          ),
          data: d,
        }))
        .map((d) => ({
          ...d,
          selected: d.data === filter.personalSecurityCheckLevel,
        })),
    [filter.personalSecurityCheckLevel, t]
  );

  const selectedSkills = useMemo(
    () => skillItems.filter((e) => (filter.skills ?? []).some((s) => s.id === e.key)),
    [filter.skills, skillItems]
  );

  const onSkillsSearchChange = useCallback(
    (data: DropdownProps) => {
      if (data.searchQuery !== undefined) {
        setSkillsSearchString(data.searchQuery);
      }
    },
    [setSkillsSearchString]
  );

  const onSkillsChange = useCallback(
    (data: DropdownProps) => {
      updateFilterValue(
        'skills',
        (data.value as KeyValueDropdownItem<SkillDto>[]).map((e) => ({
          id: e.data.id,
          level: 1,
        }))
      );
    },
    [updateFilterValue]
  );

  const onRolesChange = useCallback(
    (data: DropdownProps) => {
      updateFilterValue('roles', data.value as string[]);
    },
    [updateFilterValue]
  );

  const selectedCertificates = useMemo(
    () => certificateItems.filter((e) => (filter.certificates ?? []).some((s) => s === e.key)),
    [filter.certificates, certificateItems]
  );

  const onCertificatesSearchChange = useCallback(
    (data: DropdownProps) => {
      if (data.searchQuery !== undefined) {
        setCertificatesSearchString(data.searchQuery);
      }
    },
    [setCertificatesSearchString]
  );

  const onCertificatesChange = useCallback(
    (data: DropdownProps) => {
      updateFilterValue(
        'certificates',
        (data.value as KeyValueDropdownItem<NamedDto>[]).map((e) => e.key)
      );
    },
    [updateFilterValue]
  );

  const selectedFocusTopics = useMemo(
    () => focusTopicItems.filter((e) => (filter.focusTopics ?? []).some((s) => s === e.key)),
    [filter.focusTopics, focusTopicItems]
  );

  const onFocusTopicsSearchChange = useCallback((data: DropdownProps) => {
    if (data.searchQuery !== undefined) {
      setFocusTopicsSearchString(data.searchQuery);
    }
  }, []);

  const onFocusTopicsChange = useCallback(
    (data: DropdownProps) => {
      updateFilterValue(
        'focusTopics',
        (data.value as KeyValueDropdownItem<NamedDto>[]).map((e) => e.key)
      );
    },
    [updateFilterValue]
  );

  const selectedExpertTopics = useMemo(
    () =>
      expertTopicItems.filter((e) =>
        (filter.subjectMatterExpertTopics ?? []).some((s) => s === e.key)
      ),
    [filter.subjectMatterExpertTopics, expertTopicItems]
  );

  const handleExpertTopicSearchChange = useCallback((data: DropdownProps) => {
    if (data.searchQuery !== undefined) {
      setSubjectMattersExpertsSearchString(data.searchQuery);
    }
  }, []);

  const onExpertTopicsChange = useCallback(
    (data: DropdownProps) => {
      updateFilterValue(
        'subjectMatterExpertTopics',
        (data.value as KeyValueDropdownItem<NamedDto>[]).map((e) => e.key)
      );
    },
    [updateFilterValue]
  );

  const onPersonalSecurityCheckLevelChange = useCallback(
    (data: DropdownProps) => {
      updateFilterValue(
        'personalSecurityCheckLevel',
        (data.value as KeyValueDropdownItem<PersonalSecurityCheckLevelDto> | undefined)?.data
      );
    },
    [updateFilterValue]
  );

  const onInterestForNationalSecurityProjectsChange = useCallback(
    (_, data) => {
      updateFilterValue('interestForNationalSecurityProjects', data.checked ?? false);
    },
    [updateFilterValue]
  );

  const onOpenUserProfile = useCallback(() => {
    navigation.push(`/employeeDetail/${loginAccountId}`);
  }, [loginAccountId, navigation]);

  return (
    <Flex column gap="gap.medium">
      <Flex styles={{ paddingBottom: '1.25rem' }}>
        <Button
          content={
            <Flex gap="gap.small">
              <Flex gap="gap.small">
                <EmployeeAvatar
                  firstName={loginUser?.firstName ?? ''}
                  lastName={loginUser?.lastName ?? ''}
                  photo={loginUser?.photo?.thumbnail}
                />
              </Flex>
              <Flex column>
                <Text
                  weight="semibold"
                  size="medium"
                  align="start"
                  content={`${loginUser?.firstName} ${loginUser?.lastName} (me)`}
                />
                <Text weight="regular" size="smaller" align="start">
                  {loginUser?.role}
                </Text>
              </Flex>
            </Flex>
          }
          style={{ marginLeft: 0, paddingLeft: 0 }}
          onClick={onOpenUserProfile}
          text
        />
      </Flex>

      <Grid columns="repeat(2,1fr)" styles={{ gap: '1rem' }}>
        <Flex column gap="gap.small">
          <Text
            id="name-label"
            weight="semibold"
            content={t('employee-search.filter.search-term.label')}
          />
          <Input
            autoFocus
            fluid
            icon={<SearchIcon />}
            iconPosition="start"
            defaultValue={filter.searchString}
            placeholder={t('employee-search.filter.search-term.label')}
            onChange={(_, data) => updateFilterValue('searchString', data?.value)}
            clearable
          />
        </Flex>
        <Flex column gap="gap.small">
          <Text
            id="skills-label"
            weight="semibold"
            content={t('employee-search.filter.skills.label')}
          />
          <Ti8mDropdown
            items={skillItems}
            onScrollLoadMoreItems={fetchMoreSkills}
            canLoadMoreItems={hasMoreSkills}
            hasDataError={isSkillsError}
            aria-label="skills-label"
            multiple
            position="below"
            search
            onSearchQueryChange={onSkillsSearchChange}
            align="top"
            placeholder={t('employee-search.filter.skills.placeholder')}
            defaultValue={selectedSkills}
            onChange={onSkillsChange}
          />
        </Flex>
      </Grid>
      <Button
        styles={{ justifyContent: 'flex-start' }}
        icon={
          <ChevronDownIcon
            styles={{
              transform: showMoreFilters ? 'rotate(-180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease-in-out',
            }}
          />
        }
        text
        content={
          showMoreFilters ? t('employee-search.less-filters') : t('employee-search.more-filters')
        }
        onClick={() => setShowMoreFilters(!showMoreFilters)}
      />
      {showMoreFilters && (
        <Grid columns="repeat(4,1fr)" styles={{ gap: '1rem' }}>
          <Flex column gap="gap.small">
            <Text
              id="role-label"
              weight="semibold"
              content={t('employee-search.filter.roles.label')}
            />
            <Ti8mDropdown
              items={roles}
              aria-label="role-label"
              multiple
              position="below"
              search
              align="top"
              placeholder={t('employee-search.filter.search-term.placeholder')}
              defaultValue={filter.roles}
              onChange={onRolesChange}
            />
          </Flex>
          <Flex column gap="gap.small">
            <Text
              id="certificates-label"
              weight="semibold"
              content={t('employee-search.filter.certificates.label')}
            />
            <Ti8mDropdown
              items={certificateItems}
              aria-label="certificates-label"
              multiple
              position="below"
              search
              onScrollLoadMoreItems={fetchMoreCertificates}
              canLoadMoreItems={hasMoreCertificates}
              hasDataError={isCertificatesError}
              onSearchQueryChange={onCertificatesSearchChange}
              align="top"
              placeholder={t('employee-search.filter.certificates.placeholder')}
              defaultValue={selectedCertificates}
              onChange={onCertificatesChange}
            />
          </Flex>
          <Flex column gap="gap.small">
            <Text
              id="focus-label"
              weight="semibold"
              content={t('employee-search.filter.focus.label')}
            />
            <Ti8mDropdown
              items={focusTopicItems}
              aria-label="focus-label"
              multiple
              position="below"
              search
              onScrollLoadMoreItems={fetchMoreFocusTopics}
              canLoadMoreItems={hasMoreFocusTopics}
              hasDataError={isFocusTopicsError}
              onSearchQueryChange={onFocusTopicsSearchChange}
              align="top"
              placeholder={t('employee-search.filter.focus.placeholder')}
              defaultValue={selectedFocusTopics}
              onChange={onFocusTopicsChange}
            />
          </Flex>
          <Flex column gap="gap.small">
            <Text
              id="certificates-label"
              weight="semibold"
              content={t('employee-search.filter.subject-matter-expert.label')}
            />
            <Ti8mDropdown
              items={expertTopicItems}
              aria-label="certificates-label"
              multiple
              position="below"
              search
              onScrollLoadMoreItems={fetchMoreSubjectMatterExpertTopics}
              canLoadMoreItems={hasMoreSubjectMatterExpertTopics}
              hasDataError={isSubjectMatterExpertError}
              onSearchQueryChange={handleExpertTopicSearchChange}
              align="top"
              placeholder={t('employee-search.filter.subject-matter-expert.placeholder')}
              defaultValue={selectedExpertTopics}
              onChange={onExpertTopicsChange}
            />
          </Flex>
          <Flex column gap="gap.small">
            <Text
              id="personalsecuritychecklevel-label"
              weight="semibold"
              content={t('employee-search.filter.personal-security-check.label')}
            />
            <Ti8mDropdown
              items={personalSecurityCheckLevels}
              aria-label="personalsecuritychecklevel-label"
              position="below"
              align="top"
              clearable
              placeholder={t('employee-search.filter.personal-security-check.placeholder')}
              defaultValue={filter.personalSecurityCheckLevel}
              onChange={onPersonalSecurityCheckLevelChange}
            />
          </Flex>
          <Flex column gap="gap.small">
            <Text
              id="personalsecuritychecklevel-label"
              weight="semibold"
              content={t('employee-search.filter.interest-in-national-security-projects.label')}
            />
            <Checkbox
              toggle
              checked={filter.interestForNationalSecurityProjects}
              onChange={onInterestForNationalSecurityProjectsChange}
            />
          </Flex>
        </Grid>
      )}
    </Flex>
  );
};
