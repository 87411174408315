import { app } from '@microsoft/teams-js';
import { useCallback, useEffect, useState } from 'react';
import { useAuthInfo } from '../../auth/use-authinfo';

/**
 * Returns the theme of the current platform (Teams or web).
 * @returns 'dark' | 'light'
 */
export const usePlatformTheme = () => {
  const { runsInTeams } = useAuthInfo();
  const [theme, setTheme] = useState<'dark' | 'light'>('light');

  const setTeamsTheme = useCallback((teamsTheme: string) => {
    setTheme(teamsTheme === 'dark' ? 'dark' : 'light');
  }, []);

  useEffect(() => {
    if (runsInTeams) {
      app.registerOnThemeChangeHandler(setTeamsTheme);
      app.getContext().then((context) => {
        setTeamsTheme(context.app.theme);
      });
    } else {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const setWebTheme = (event: MediaQueryListEvent) => {
        setTheme(event.matches ? 'dark' : 'light');
      };
      mediaQuery.addEventListener('change', setWebTheme);
      setTheme(mediaQuery.matches ? 'dark' : 'light');

      return () => {
        mediaQuery.removeEventListener('change', setWebTheme);
      };
    }
  }, [runsInTeams, setTeamsTheme]);

  return theme;
};
