import { SectorDto, SectorListDtoResult, SectorService } from '../../open-api/queries';
import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import { ApiError } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';

const getSectors = () => SectorService.sectors({ version: env('REACT_APP_API_VERSION') });

export const useSectors = (
  queryOptions?: UseQueryOptions<SectorListDtoResult, ApiError, Array<SectorDto>, QueryKeys.Sectors>
) =>
  useQuery(QueryKeys.Sectors, ({ queryKey: [_] }) => getSectors(), {
    select: (data) => data.payload,
    ...queryOptions,
  });
