import { env } from '../../../utils/env';
import * as CommandParams from './command-params';
import { useMutation, useQueryClient } from 'react-query';
import { ApiError, EmployeeService } from '../../open-api/commands';
import { EmployeeService as QueryEmployeeService } from '../../open-api/queries';
import { Result } from '../../open-api/commands/models/Result';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const deleteOrigin = (input: CommandParams.DeleteOrigin) =>
  EmployeeService.deleteOrigin({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

type GuidResultWithId = Result & {
  id: string;
};

const getEventPosition = (id: string) =>
  QueryEmployeeService.getEventPosition({
    version: env('REACT_APP_API_VERSION'),
    id,
  });

export const useDeleteOrigin = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();
  return useMutation<GuidResultWithId, ApiError, CommandParams.DeleteOrigin>(
    async (input) => {
      const result = await deleteOrigin(input);

      return { ...result, id: input.employeeId };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries(QueryKeys.Origins)
        ),
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};
