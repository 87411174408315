import { CancelablePromise } from '../core/CancelablePromise';
import { DegreeListDtoResult } from '../models/DegreeListDtoResult';
import { request as __request } from '../core/request';
import { OpenAPI } from '../core/OpenAPI';

export class DegreeService {
  public static getAllDegrees({
    version,
  }: {
    version: string;
  }): CancelablePromise<DegreeListDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/degrees',
      path: {
        version: version,
      },
    });
  }
}
