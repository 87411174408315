import { ProjectDto } from '../../data-access';
import { useTranslation } from 'react-i18next';
import { Card, Flex, Text, Pill, Provider } from '@fluentui/react-northstar';
import { Ti8mPill } from '../common';
import { TitleTextComponent } from './TitleTextComponent';

export type EmployeeProjectDescriptionProps = {
  project: ProjectDto;
  language: 'en' | 'de';
};

export const EmployeeProjectCard = ({ project, language }: EmployeeProjectDescriptionProps) => {
  const languageLocale = language === 'en' ? 'en-US' : 'de-CH';
  const { t } = useTranslation();
  return (
    <Provider.Consumer
      render={(theme) => (
        <Card
          styles={{
            backgroundColor: theme.siteVariables.colorScheme.highContrastBackground,
            ':hover': 'unset',
          }}
        >
          <Card.Body>
            <Flex column gap="gap.medium">
              <Flex gap="gap.smaller" vAlign="center">
                <Text content={project.clientName[language]} size="large" weight="semibold" />
                <Pill
                  content={language.toUpperCase()}
                  appearance="filled"
                  styles={{
                    minWidth: '2rem',
                    backgroundColor: theme.siteVariables.indicatorPillColor,
                    // hack to disabled the hover effect
                    ':hover': {
                      backgroundColor: theme.siteVariables.indicatorPillColor,
                    },
                  }}
                  rectangular
                  size="small"
                />
              </Flex>
              <Flex column gap="gap.smaller">
                <Flex>
                  <Text content={project.name[language]} size="large" />
                </Flex>
                <Ti8mPill
                  border
                  content={project.sector.name[language]}
                  size="medium"
                  appearance="filled"
                  maxTextLength={project.sector.name[language].length + 1}
                />
              </Flex>
              <Text
                content={`${new Date(project.start).toLocaleDateString(languageLocale, {
                  month: 'short',
                  year: 'numeric',
                })} - ${
                  project.end
                    ? new Date(project.end).toLocaleDateString(languageLocale, {
                        month: 'short',
                        year: 'numeric',
                      })
                    : t('employee-project.today')
                }`}
                weight="semibold"
              />
              <TitleTextComponent title={t(`employee-project.project-goal-${language}`)}>
                <Text content={project.description[language]} />
              </TitleTextComponent>
              <TitleTextComponent title={t(`employee-project.skills-${language}`)}>
                <Flex gap="gap.small" wrap>
                  {project.skills &&
                    project.skills.map((s, i) => (
                      <Ti8mPill
                        key={`project_card_skill_${i}`}
                        content={s.name}
                        border
                        size="medium"
                        appearance="filled"
                        maxTextLength={s.name.length + 1}
                      />
                    ))}
                </Flex>
              </TitleTextComponent>
              <TitleTextComponent title={t(`employee-project.role-${language}`)}>
                <Text content={project.role[language]} />
              </TitleTextComponent>
              <TitleTextComponent title={t(`employee-project.tasks-${language}`)}>
                <ul style={{ margin: 0 }}>
                  {project.tasks.map((task, i) => (
                    <li key={`project_card_task_${i}_${language}`}>{task[language]}</li>
                  ))}
                </ul>
              </TitleTextComponent>
            </Flex>
          </Card.Body>
        </Card>
      )}
    />
  );
};
