import { Form } from '@fluentui/react-northstar';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SectorDto, useAddSector, useUpdateSector } from '../../data-access';
import { AddOrEditDialog } from '../components/AddOrEditDialog';
import { ControlledFormInput } from '../components/ControlledFormInput';
import { applyRules } from '../helpers/formRules';

type SectorsAddOrEditFormDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  currentItem?: SectorDto;
};

export type FormAttributes = {
  nameEN: string;
  nameDE: string;
};

export const SectorsAddOrEditFormDialog = ({
  open,
  setOpen,
  currentItem,
}: SectorsAddOrEditFormDialogProps) => {
  const { t } = useTranslation();

  const {
    control,
    trigger,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormAttributes>({
    defaultValues: {
      nameEN: currentItem?.name.en || '',
      nameDE: currentItem?.name.de || '',
    },
  });

  const { mutate: update } = useUpdateSector();
  const { mutate: add } = useAddSector();

  const onSubmit = async (data: FormAttributes) => {
    if (await trigger(undefined, { shouldFocus: true })) {
      if (currentItem) {
        update({ id: currentItem.id, requestBody: { name: { en: data.nameEN, de: data.nameDE } } });
      } else {
        add({ requestBody: { name: { en: data.nameEN, de: data.nameDE } } });
      }
      setOpen(false);
    }
  };

  return (
    <AddOrEditDialog
      open={open}
      setOpen={setOpen}
      title={t('admin.sectors.name_one')}
      currentItem={currentItem}
      preventSave={!isDirty}
      onConfirm={handleSubmit(onSubmit)}
      content={
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ControlledFormInput
            control={control}
            rules={applyRules(['required', 'minLength'])}
            name="nameEN"
            label={t('admin.common.name-en')}
          />

          <ControlledFormInput
            control={control}
            rules={applyRules(['required', 'minLength'])}
            name="nameDE"
            label={t('admin.common.name-de')}
          />

          {/* Work around to submit a form when user presses Enter */}
          <button type="submit" hidden />
        </Form>
      }
    />
  );
};
