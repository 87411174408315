import { Table, Text, gridNestedBehavior } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { SectorDto } from '../../data-access';
import { ActionButtons } from '../components/ActionButtons';
import { baseTableStyles, editColumnStyles } from '../helpers/styles';

type SectorsMasterDataTableProps = {
  items: SectorDto[];
  onEditItem: (value: SectorDto) => void;
};

export const SectorsMasterDataTable = ({ items, onEditItem }: SectorsMasterDataTableProps) => {
  const { t } = useTranslation();

  const headers = {
    items: [
      {
        key: 'headerNameEN',
        content: <Text weight="semibold" content={t('admin.common.name-en')} />,
      },
      {
        key: 'headerNameDE',
        content: <Text weight="semibold" content={t('admin.common.name-de')} />,
      },
      {
        key: 'headerAction',
        content: '',
        styles: editColumnStyles,
      },
    ],
  };

  return (
    <Table
      header={headers}
      styles={baseTableStyles}
      accessibility={gridNestedBehavior}
      rows={items.map((item) => ({
        key: `row-${item.id}`,
        items: [
          {
            key: `${item.id}_en`,
            truncateContent: true,
            content: item.name.en,
          },
          {
            key: `${item.id}_de`,
            truncateContent: true,
            content: item.name.de,
          },
          {
            key: `${item.id}_edit_action`,
            truncateContent: true,
            content: <ActionButtons item={item} onEditItem={onEditItem} />,
            styles: editColumnStyles,
          },
        ],
      }))}
    />
  );
};
