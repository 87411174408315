import { atom } from 'recoil';
import { QueryParams } from '../../data-access';

export type ProjectsFilterAtomState = QueryParams.ProjectTemplates;

export const projectsFilterAtom = atom<ProjectsFilterAtomState>({
  key: 'projectsFilter',
  default: {
    searchString: '',
    clientSearchString: '',
  },
});
