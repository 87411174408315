import { PropsWithChildren } from 'react';
import { ReleaseNotesDialog } from '../../components/ReleaseNotes/ReleaseNotesDialog';
import { useFreshReleaseNotes } from '../../components/ReleaseNotes/hooks/useFreshReleaseNotes';

/**
 * Simply renders a <div> tag (with the provided children) and handles showing the ReleaseNotesDialog
 */
export const Main = (props: PropsWithChildren<{}>) => {
  const { releaseNotes, showDialog, setShowDialog } = useFreshReleaseNotes();

  return (
    <div style={{ height: '100%', overflow: 'scroll' }}>
      {releaseNotes && showDialog && (
        <ReleaseNotesDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          releaseNotes={releaseNotes}
        />
      )}

      {props.children}
    </div>
  );
};
