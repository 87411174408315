import {
  Alert,
  Button,
  EditIcon,
  Flex,
  SpotlightIcon,
  Text,
  TrashCanIcon,
} from '@fluentui/react-northstar';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiError, EmployeeCertificateDto, useDownloadCertificate } from '../../../../data-access';
import { convertMonthYear } from '../../../../utils/dateConverter';
import { downloadFile } from '../../../common';
import { EmployeeCertificateDialog } from './Dialog/EmployeeCertificateDialog';
import { Ti8mDialog } from '../../../common/Ti8mDialog';

type EmployeeCertificatesProps = {
  employeeCertificate: EmployeeCertificateDto;
  employeeId: string;
  isEditable: boolean;
  onDelete: (id: string) => void;
};

export const EmployeeCertificates = ({
  employeeCertificate,
  employeeId,
  isEditable,
  onDelete,
}: EmployeeCertificatesProps) => {
  const { t } = useTranslation();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const downloadCertificate = useDownloadCertificate(employeeId);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleConfirmDelete = useCallback(() => {
    onDelete(employeeCertificate.id);
    setDeleteDialogOpen(false);
  }, [employeeCertificate.id, onDelete]);

  const changeDialogVisibility = useCallback(
    (value: boolean) => {
      setDialogOpen(value);
    },
    [setDialogOpen]
  );

  const handleCertificateDownload = async (id?: string) => {
    try {
      const { payload: url } = await downloadCertificate(id);

      downloadFile(url);
    } catch (error) {
      if (error instanceof ApiError) {
        return (
          <Alert
            content={error.body?.reason ?? error.message}
            dismissible
            dismissAction={{
              'aria-label': 'close',
            }}
            variables={{
              urgent: true,
            }}
          />
        );
      }
    }
  };

  return (
    <Flex column>
      <Flex space="between" vAlign="end">
        <Text weight="semibold" content={employeeCertificate.certificate.company} />
        <Flex gap="gap.small">
          <Button
            iconOnly
            primary
            disabled={!isEditable}
            text
            size="small"
            icon={<EditIcon outline size="large" />}
            onClick={() => changeDialogVisibility(true)}
          />
          <Button
            iconOnly
            primary
            disabled={!isEditable}
            text
            size="small"
            icon={<TrashCanIcon outline size="large" />}
            onClick={() => setDeleteDialogOpen(true)}
          />
        </Flex>
      </Flex>
      {isDialogOpen && (
        <EmployeeCertificateDialog
          key={`employee-certificate-dialog_${isDialogOpen}`}
          isDialogOpen={isDialogOpen}
          isEdit={true}
          employeeId={employeeId}
          employeeCertificateDto={employeeCertificate}
          setDialogOpen={changeDialogVisibility}
        />
      )}

      <Ti8mDialog
        open={isDeleteDialogOpen}
        cancelButton={t('employee-detail.master-data.certificates.delete-dialog.cancel-button')}
        onCancel={() => setDeleteDialogOpen(false)}
        confirmButton={t('employee-detail.master-data.certificates.delete-dialog.confirm-button')}
        onConfirm={handleConfirmDelete}
        showCloseHeaderAction={false}
        content={t('employee-detail.master-data.certificates.delete-dialog.content')}
        header={t('employee-detail.master-data.certificates.delete-dialog.header')}
      />
      <Text
        content={employeeCertificate.certificate.name.en}
        size="large"
        weight="semibold"
        styles={{ marginBottom: 4 }}
      />

      <Text
        content={t('employee-detail.master-data.certificates.completion-label', {
          value: convertMonthYear(employeeCertificate.issuedOn),
        })}
      />

      {employeeCertificate.validUntil && (
        <Text
          content={t('employee-detail.master-data.certificates.expiration-label', {
            value: convertMonthYear(employeeCertificate.validUntil),
          })}
        />
      )}

      {employeeCertificate.certificateFile && (
        <Flex>
          <Button
            content={t('employee-detail.master-data.certificates.certificate')}
            icon={<SpotlightIcon />}
            text
            disabled={!isEditable}
            primary
            styles={{ textDecoration: 'underline', justifyContent: 'start', paddingLeft: '0' }}
            onClick={() => handleCertificateDownload(employeeCertificate.certificateFile?.name)}
          />
        </Flex>
      )}
    </Flex>
  );
};
