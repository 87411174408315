import { Button, EditIcon, Flex, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { FocusTopicDto } from '../../data-access/open-api/queries/models/FocusTopicDto';
import { useCallback, useEffect, useState } from 'react';
import { Ti8mPill } from '../common';
import { Ti8mDialog } from '../common/Ti8mDialog';
import { useUpdateFocusTopics } from '../../data-access';
import { AddFocusTopicsDialogForm } from './AddFocusTopicDialog/AddFocusTopicDialogForm';
import { EditFocusTopicDialogForm } from './EditFocusTopicDialog/EditFocusTopicDialogForm';
import { AddCircleIcon } from '../../icons';

interface EmployeeSkillsFocusTopicsProps {
  employeeId: string;
  isEditable: boolean;
  focusTopics?: Array<FocusTopicDto>;
}

export const EmployeeSkillsFocusTopics = ({
  employeeId,
  isEditable,
  focusTopics,
}: EmployeeSkillsFocusTopicsProps) => {
  const { t } = useTranslation();
  const [isAddDialogOpen, setAddDialogVisibility] = useState<boolean>(false);
  const [isEditDialogOpen, setEditDialogVisibility] = useState<boolean>(false);
  const [editFocusTopics, setEditFocusTopics] = useState<Array<FocusTopicDto>>([]);
  const [isTouched, setTouched] = useState<boolean>(false);
  const { mutate: updateFocusTopics } = useUpdateFocusTopics();

  const setFocusTopicsState = useCallback(() => {
    setEditFocusTopics(focusTopics ?? []);
  }, [focusTopics]);

  useEffect(() => {
    setFocusTopicsState();
  }, [setFocusTopicsState]);

  const resetDialog = () => {
    setAddDialogVisibility(false);
    setEditDialogVisibility(false);
    setFocusTopicsState();
    setTouched(false);
  };

  const handleConfirmEditTopics = useCallback(() => {
    updateFocusTopics({
      employeeId: employeeId,
      requestBody: { focusTopics: editFocusTopics.map((topic) => ({ focusTopicId: topic.id })) },
    });
    setAddDialogVisibility(false);
    setEditDialogVisibility(false);
    setTouched(false);
  }, [editFocusTopics, employeeId, updateFocusTopics]);

  return (
    <>
      <Flex space="between">
        <Text
          weight="semibold"
          content={t('employee-detail.master-data.skills.focus-topics-header')}
          size="large"
        />
        <Flex gap="gap.small">
          <Button
            iconOnly
            text
            disabled={!isEditable}
            size="small"
            primary
            icon={<AddCircleIcon size="large" />}
            onClick={() => {
              setAddDialogVisibility(true);
            }}
          />
          <Ti8mDialog
            open={isAddDialogOpen}
            header={t('employee-detail.master-data.skills.add-focus-topics-dialog.header')}
            cancelButton={t(
              'employee-detail.master-data.skills.add-focus-topics-dialog.button-cancel'
            )}
            onCancel={resetDialog}
            confirmButton={{
              content: t('employee-detail.master-data.skills.add-focus-topics-dialog.button-save'),
              disabled: !isTouched,
            }}
            onConfirm={handleConfirmEditTopics}
            content={
              <AddFocusTopicsDialogForm
                editFocusTopics={editFocusTopics}
                setEditFocusTopics={setEditFocusTopics}
                setTouched={setTouched}
              />
            }
            resetOverflow
          />
          <Button
            iconOnly
            text
            disabled={editFocusTopics.length === 0 || !isEditable}
            primary
            size="small"
            icon={<EditIcon size="large" />}
            onClick={() => setEditDialogVisibility(true)}
          />
          <Ti8mDialog
            open={isEditDialogOpen}
            header={t('employee-detail.master-data.skills.edit-focus-topics-dialog.header')}
            cancelButton={t(
              'employee-detail.master-data.skills.edit-focus-topics-dialog.button-cancel'
            )}
            onCancel={resetDialog}
            confirmButton={{
              content: t('employee-detail.master-data.skills.edit-focus-topics-dialog.button-save'),
              disabled: !isTouched,
            }}
            onConfirm={handleConfirmEditTopics}
            content={
              <EditFocusTopicDialogForm
                editFocusTopics={editFocusTopics}
                setEditFocusTopics={setEditFocusTopics}
                setTouched={setTouched}
              />
            }
            resetOverflow
          />
        </Flex>
      </Flex>
      <Flex gap="gap.small">
        {focusTopics &&
          focusTopics.map((topic) => (
            <Ti8mPill content={topic.name.en} maxTextLength={15} key={topic.id} />
          ))}
      </Flex>
    </>
  );
};
