import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, withApiVersion, WithoutVersion } from '../../helpers';
import { ApiError, ClientService, Result } from '../../open-api/commands';
import { ClientService as QueryClientService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const updateClient = withApiVersion(ClientService.updateClient);
const getEventPosition = withApiVersion(QueryClientService.getEventPosition);

type GuidResultWithId = Result & {
  id: string;
};

type UpdateClientInput = WithoutVersion<ExtractFirstArgAsType<typeof ClientService.updateClient>>;

export const useUpdateClient = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, UpdateClientInput>(
    async (input) => {
      const result = await updateClient(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, (id) => getEventPosition({ id })).then(() =>
          queryClient.invalidateQueries(QueryKeys.Clients)
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.client'),
      },
    }
  );
};
