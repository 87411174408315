import { AddIcon, Button, Flex, FlexItem, Input, Text } from '@fluentui/react-northstar';
import { debounce as _debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  PaginationAtomState,
  ProjectsFilterAtomState,
  projectPaginationAtom,
  projectsFilterAtom,
} from '../../state/atoms';
import { TemplateProjectDialog } from '../ProjectsDialog/TemplateProjectDialog';

const SEARCH_DELAY = 300;

// Delays the data filtering by 300ms, waiting until user
// stops typing to limit the request sent to the server.
const debounce = _debounce((fn: () => void) => fn(), SEARCH_DELAY);

export const SearchFilterForm = () => {
  const { t } = useTranslation();
  const [, setPaginationData] = useRecoilState<PaginationAtomState>(projectPaginationAtom);
  const [filterData, setFilterData] = useRecoilState<ProjectsFilterAtomState>(projectsFilterAtom);
  const [dialogOpen, setDialogOpen] = useState(false);

  const setDialogOpenState = useCallback(
    (value: boolean) => {
      setDialogOpen(value);
    },
    [setDialogOpen]
  );

  return (
    <Flex gap="gap.medium">
      <TemplateProjectDialog open={dialogOpen} onClose={() => setDialogOpenState(false)} />
      <FlexItem size="size.quarter">
        <Input
          autoFocus
          label={<Text content={t('projects-search.filter.search-term.label')} weight="semibold" />}
          placeholder={t('projects-search.filter.search-term.placeholder')}
          fluid
          defaultValue={filterData.searchString}
          onChange={(_, data) =>
            debounce(() => {
              setFilterData((prev) => ({ ...prev, searchString: data?.value ?? '' }));
              setPaginationData((prev) => ({
                ...prev,
                currentPage: 1,
              }));
            })
          }
          clearable
        />
      </FlexItem>
      <FlexItem size="size.quarter">
        <Input
          label={
            <Text
              content={t('projects-search.filter.client-search-term.label')}
              weight="semibold"
            />
          }
          placeholder={t('projects-search.filter.client-search-term.placeholder')}
          fluid
          defaultValue={filterData.clientSearchString}
          onChange={(_, data) =>
            debounce(() => {
              setFilterData((prev) => ({ ...prev, clientSearchString: data?.value ?? '' }));
              setPaginationData((prev) => ({
                ...prev,
                currentPage: 1,
              }));
            })
          }
          clearable
        />
      </FlexItem>
      <Flex.Item push>
        <Button
          primary
          style={{ alignSelf: 'end' }}
          flat
          icon={<AddIcon />}
          onClick={() => setDialogOpenState(true)}
          content={t('projects-search.filter.add-project')}
        />
      </Flex.Item>
    </Flex>
  );
};
