import { Avatar, Button, Flex } from '@fluentui/react-northstar';
import { useState } from 'react';
import { ProjectTemplateMemberDto } from '../../data-access/open-api/queries/models/ProjectTemplateMemberDto';
import { EmployeeAvatar } from '../EmployeeTable/EmployeeAvatar';
import { ProjectEmployeesDialog } from './ProjectEmployeesDialog';

type ProjectEmployeesProps = {
  members: ProjectTemplateMemberDto[];
};

export const ProjectEmployees = ({ members }: ProjectEmployeesProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  return (
    <>
      <Button
        text
        iconOnly
        primary
        onClick={() => setShowDialog(true)}
        style={{
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          cursor: 'pointer',
        }}
      >
        <Flex gap={'gap.small'}>
          {members.length > 0 && (
            <>
              <EmployeeAvatar
                firstName={members[0].firstName}
                lastName={members[0].lastName}
                size={'medium'}
                photo={members[0].thumbnail}
              />
              {members.slice(1, 4).map((member) => (
                <Flex
                  styles={{
                    marginLeft: '-1.2rem',
                  }}
                  key={member.employeeId}
                >
                  <EmployeeAvatar
                    firstName={member.firstName}
                    lastName={member.lastName}
                    size={'medium'}
                    photo={member.thumbnail}
                  />
                </Flex>
              ))}
            </>
          )}
          {members.length > 4 && (
            <Avatar
              icon={`+${members.length - 4}`}
              styles={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '-1rem',
                '> .ui-avatar__icon': { justifyContent: 'center', background: '#989AC9' },
              }}
            />
          )}
        </Flex>
      </Button>
      {showDialog && (
        <ProjectEmployeesDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          members={members}
        />
      )}
    </>
  );
};
