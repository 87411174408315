import {
  Button,
  Text,
  EditIcon,
  Flex,
  SiteVariablesPrepared,
  Table,
} from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Pagination } from '../EmployeeTable/Pagination';
import { Language } from '../../app/locales';
import { useProjectsListData } from './hooks/useTemplateProjectsListData';
import { TemplateProjectDialog } from '../ProjectsDialog/TemplateProjectDialog';
import { useCallback, useState } from 'react';
import { ProjectTemplateDto } from '../../data-access';
import {
  projectsFilterAtom,
  ProjectsFilterAtomState,
  PaginationAtomState,
  projectPaginationAtom,
} from '../../state/atoms';
import { ProjectEmployees } from './ProjectEmployees';

const tableRowVariablesOverride = (data: SiteVariablesPrepared) => {
  return {
    backgroundColorHover: data.colorScheme['default']['background6'],
  };
};

export const ProjectsTable = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.resolvedLanguage as Language;
  const [paginationData, setPaginationData] =
    useRecoilState<PaginationAtomState>(projectPaginationAtom);
  const filterData = useRecoilValue<ProjectsFilterAtomState>(projectsFilterAtom);
  const [projectToEdit, setProjectToEdit] = useState<ProjectTemplateDto | undefined>();
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);

  const projects = useProjectsListData({
    filteringOptions: filterData,
    paginationOptions: paginationData,
  });

  const openEditDialog = useCallback(
    (project: ProjectTemplateDto) => {
      setProjectToEdit(project);
      setEditDialogOpen(true);
    },
    [setProjectToEdit, setEditDialogOpen]
  );

  const headers = {
    items: [
      {
        key: 'nameHeader',
        content: <Text weight="semibold" content={t('projects-search.table.headers.name')} />,
      },
      {
        key: 'customerHeader',
        content: <Text weight="semibold" content={t('projects-search.table.headers.customer')} />,
      },
      {
        key: 'teamHeader',
        content: <Text weight="semibold" content={t('projects-search.table.headers.team')} />,
        style: { maxWidth: '200px' },
      },
      {
        key: 'startHeader',
        content: <Text weight="semibold" content={t('projects-search.table.headers.start')} />,
        style: { maxWidth: '100px' },
      },
      {
        key: 'endHeader',
        content: <Text weight="semibold" content={t('projects-search.table.headers.end')} />,
        style: { maxWidth: '100px' },
      },
      {
        key: 'editHeader',
        content: '',
        style: { maxWidth: '100px' },
      },
    ],
  };

  return (
    <Flex column gap="gap.small">
      <TemplateProjectDialog
        key={`edit-project-dialog-${isEditDialogOpen}`}
        open={isEditDialogOpen}
        project={projectToEdit}
        onClose={() => setEditDialogOpen(false)}
      />
      <Table
        header={headers}
        rows={projects?.data.map((item) => ({
          key: item.id,
          items: [
            {
              key: `${item.id}_name`,
              truncateContent: true,
              content: item.name[language],
            },
            {
              key: `${item.id}_customer`,
              truncateContent: true,
              content: item.client.name[language],
            },
            {
              key: `${item.id}_team`,
              truncateContent: true,
              content: <ProjectEmployees members={item.members} />,
              style: { maxWidth: '200px' },
            },
            {
              key: `${item.id}_start`,
              truncateContent: true,
              content: new Date(item.start).toLocaleDateString(i18n.language, {
                month: 'short',
                year: 'numeric',
              }),
              style: { maxWidth: '100px' },
            },
            {
              key: `${item.id}_end`,
              truncateContent: true,
              styles: {
                contentAlign: 'end',
              },
              content: item.end
                ? new Date(item.end).toLocaleDateString(i18n.language, {
                    month: 'short',
                    year: 'numeric',
                  })
                : '',
              style: { maxWidth: '100px' },
            },
            {
              key: `${item.id}_edit`,
              truncateContent: true,
              content: (
                <Button
                  primary
                  iconOnly
                  text
                  icon={<EditIcon />}
                  onClick={() => openEditDialog(item)}
                />
              ),
              style: { maxWidth: '100px', justifyContent: 'end' },
            },
          ],
          variables: tableRowVariablesOverride,
        }))}
      />
      <Pagination
        currentPage={paginationData.currentPage}
        pageSize={paginationData.pageSize}
        totalItems={projects?.totalItems ?? 0}
        onPageChange={(page) =>
          setPaginationData((prev) => {
            return {
              ...prev,
              currentPage: page,
            };
          })
        }
      />
    </Flex>
  );
};
