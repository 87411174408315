import {
  ApiError,
  ProjectTemplateDtoPaginatedListDto,
  ProjectTemplateDtoPaginatedListDtoResult,
  ProjectTemplateService,
} from '../../open-api/queries';
import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import { QueryKeys } from '../../query-keys';
import type * as QueryParams from './query-params';

const getProjects = (input: QueryParams.ProjectTemplates) =>
  ProjectTemplateService.pagedProjectTemplates({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useProjectTemplates = (
  queryParams: QueryParams.ProjectTemplates = {},
  queryOptions?:
    | UseQueryOptions<
        ProjectTemplateDtoPaginatedListDtoResult,
        ApiError,
        ProjectTemplateDtoPaginatedListDto,
        [QueryKeys.ProjectTemplates, QueryParams.ProjectTemplates]
      >
    | undefined
) =>
  useQuery(
    [QueryKeys.ProjectTemplates, queryParams],
    ({ queryKey: [key, input] }) => getProjects(input),
    {
      select: (data) => data.payload,
      ...queryOptions,
    }
  );
