import {
  AddIcon,
  Button,
  CardHeader,
  ChevronDownIcon,
  Divider,
  EditIcon,
  Flex,
  FlexItem,
  Text,
} from '@fluentui/react-northstar';

interface Ti8mCardHeaderProps {
  cardHeaderText: string;
  isEditable: boolean;
  isCardUnfold: boolean;
  setCardUnfold: (unfold: boolean) => void;
  setDialogVisibility?: (visibility: boolean) => void;
}

export const Ti8mCardHeader = ({
  cardHeaderText,
  isEditable,
  isCardUnfold,
  setCardUnfold,
  setDialogVisibility,
}: Ti8mCardHeaderProps) => {
  const onEdit = (dialogVisibility: boolean) => {
    if (setDialogVisibility) {
      setDialogVisibility(dialogVisibility);
    }
  };

  return (
    <CardHeader>
      <Flex gap="gap.smaller">
        {isCardUnfold ? (
          <Button
            icon={<ChevronDownIcon styles={{ marginBottom: '0.2rem' }} />}
            iconOnly
            text
            onClick={() => setCardUnfold(false)}
          />
        ) : (
          <Button
            icon={<ChevronDownIcon rotate={180} styles={{ marginBottom: '0.2rem' }} />}
            iconOnly
            text
            onClick={() => setCardUnfold(true)}
          />
        )}
        <Text
          content={cardHeaderText}
          size="large"
          weight="semibold"
          styles={{ marginBottom: '1rem' }}
        />
        {isEditable && (
          <FlexItem push>
            <Button
              circular
              icon={isEditable ? <EditIcon outline /> : <AddIcon outline />}
              primary
              disabled={!isEditable}
              onClick={() => onEdit(true)}
            />
          </FlexItem>
        )}
      </Flex>
      <Divider />
    </CardHeader>
  );
};
