import { Form, FormField, FormInput } from '@fluentui/react-northstar';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRequestCertificate } from '../../../../../data-access/hooks/commands/use-request-certificate';
import { CertificateDto } from '../../../../../data-access/open-api/commands/models/CertificateDto';
import { Ti8mDialog } from '../../../../common/Ti8mDialog';

type RequestCertificateDialogProps = {
  isOpen: boolean;
  setIsOpen: (action: boolean) => void;
};

// Redefine name to be string only
type UpdateCertificateDto = Pick<CertificateDto, 'name' | 'company'> & { name: string };

export const RequestCertificateDialog = ({ isOpen, setIsOpen }: RequestCertificateDialogProps) => {
  const { t } = useTranslation();
  const {
    register,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<UpdateCertificateDto>();

  const { mutate: requestCertificate } = useRequestCertificate();

  return (
    <Ti8mDialog
      open={isOpen}
      header={t('employee-detail.master-data.certificates.request-dialog.title')}
      cancelButton={{ content: t('shared.buttons.cancel') }}
      onCancel={() => setIsOpen(false)}
      confirmButton={{
        content: t('employee-detail.master-data.certificates.request-dialog.confirm-button'),
      }}
      onConfirm={async () => {
        if (await trigger(undefined, { shouldFocus: true })) {
          requestCertificate({
            requestBody: {
              company: getValues().company,
              name: { de: getValues().name, en: getValues().name },
            },
          });
          setIsOpen(false);
        }
      }}
      content={
        <Form>
          <FormField>
            <FormInput
              {...register('name', {
                required: { value: true, message: t('form-validation.messages.required') },
                minLength: {
                  value: 2,
                  message: t('form-validation.messages.min-length', { value: 2 }),
                },
              })}
              label={t('employee-detail.master-data.certificates.request-dialog.name-label')}
              fluid
              errorMessage={errors.name && errors.name.message}
            />
          </FormField>

          <FormField>
            <FormInput
              {...register('company', {
                required: { value: true, message: t('form-validation.messages.required') },
                minLength: {
                  value: 2,
                  message: t('form-validation.messages.min-length', { value: 2 }),
                },
              })}
              label={t('employee-detail.master-data.certificates.request-dialog.company-label')}
              fluid
              errorMessage={errors.company && errors.company.message}
            />
          </FormField>
        </Form>
      }
    />
  );
};
