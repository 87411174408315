import { DropdownProps, Flex, FlexItem, Input, Text, TextArea } from '@fluentui/react-northstar';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeDto, GenerateCvDto, TranslationDto } from '../../../data-access';
import { Ti8mDropdown } from '../../common';
import { useDropdownItems } from './hooks/useDropdownItems';

export type GenerateCvDtoSetter = <K extends keyof GenerateCvDto>(
  key: K,
  value: GenerateCvDto[K]
) => void;

export type DropdownItem = {
  key: string;
  header: string;
  content?: ReactNode;
  disabled?: boolean;
};

type CvDownloadDialogFormProps = {
  cvData: EmployeeDto | undefined;
  language: keyof TranslationDto;
  onSetCvData: GenerateCvDtoSetter;
};

export const maxEducationCount = 3;
export const maxProjectsCount = 2;
export const maxSectorCount = 5;
export const maxSkillCount = 5;
export const maxCertificateCount = 3;

export const CvDownloadDialogForm = ({
  cvData,
  language,
  onSetCvData,
}: CvDownloadDialogFormProps) => {
  const { t } = useTranslation();
  const [abstractCount, setAbstractCount] = useState(0);
  const [educationCount, setEducationCount] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [sectorCount, setSectorCount] = useState(0);
  const [skillCount, setSkillCount] = useState(0);
  const [certificateCount, setCertificateCount] = useState(0);

  const {
    allProjectItems,
    initialProjectItems,
    allSectorItems,
    initialSectorItems,
    allCertificateItems,
    initialCertificateItems,
    allEducationItems,
    initialEducationItems,
    allSkillItems,
    initialTopSkillItems,
  } = useDropdownItems(cvData);

  const getEducationsDisabled = useCallback(
    () => educationCount >= maxEducationCount,
    [educationCount]
  );
  const getProjectDisabled = useCallback(() => projectCount >= maxProjectsCount, [projectCount]);
  const getSectorDisabled = useCallback(() => sectorCount >= maxSectorCount, [sectorCount]);
  const getSkillsDisabled = useCallback(() => skillCount >= maxSkillCount, [skillCount]);
  const getCertificatesDisabled = useCallback(
    () => certificateCount >= maxCertificateCount,
    [certificateCount]
  );

  useEffect(() => {
    if (cvData) {
      setProjectCount(initialProjectItems.length);
      setSectorCount(initialSectorItems.length);
      setAbstractCount(cvData.profileText?.[language].length ?? 0);
      setEducationCount(initialEducationItems.length);
      setSkillCount(initialTopSkillItems.length);
      setCertificateCount(initialCertificateItems.length);
    }
  }, [
    cvData,
    initialProjectItems,
    initialSectorItems,
    initialEducationItems,
    initialCertificateItems,
    initialTopSkillItems,
    language,
  ]);

  const onRoleChanged = (role: string) => {
    onSetCvData('role', role);
  };

  const onAbstractChanged = (abstract: string) => {
    onSetCvData('description', abstract);
  };

  const onEducationChanged = (data: DropdownProps) => {
    const selectedValues = data.value as DropdownItem[];
    setEducationCount(selectedValues.length);
    onSetCvData(
      'relevantEducations',
      selectedValues.map((edu) => edu.key)
    );
  };

  const onProjectsChanged = (data: DropdownProps) => {
    const selectedValues = data.value as DropdownItem[];
    setProjectCount(selectedValues.length);
    onSetCvData(
      'topProjects',
      selectedValues.map((pro) => pro.key)
    );
  };

  const onSectorChanged = (data: DropdownProps) => {
    const selectedValues = data.value as DropdownItem[];
    setSectorCount(selectedValues.length);
    onSetCvData(
      'sectorsKnowHow',
      selectedValues.map((sec) => sec.key)
    );
  };

  const onSkillsChanged = (data: DropdownProps) => {
    const selectedValues = data.value as DropdownItem[];
    setSkillCount(selectedValues.length);
    onSetCvData(
      'topSkills',
      selectedValues.map((skill) => skill.key)
    );
  };

  const onCertificatesChanged = (data: DropdownProps) => {
    const selectedValues = data.value as DropdownItem[];
    setCertificateCount(selectedValues.length);
    onSetCvData(
      'relevantCertificates',
      selectedValues.map((cert) => cert.key)
    );
  };

  return (
    <Flex column gap="gap.medium">
      <Flex column gap="gap.smaller">
        <Text content={t('cv-download-dialog.role-header')} weight="semibold" />
        <Input
          autoFocus
          fluid
          defaultValue={cvData?.role}
          onChange={(_, data) => onRoleChanged(data?.value ?? '')}
        />
      </Flex>
      <Flex column gap="gap.smaller">
        <Text content={t('cv-download-dialog.abstract-header')} weight="semibold" />
        <TextArea
          defaultValue={cvData?.profileText?.[language]}
          styles={{ minHeight: '7rem' }}
          maxLength={350}
          onChange={(e, data) => {
            onAbstractChanged(data?.value ?? '');
            setAbstractCount(data?.value?.length ?? 0);
          }}
        />
        <FlexItem align="end">
          <Text content={`${abstractCount} / 350`} size="smaller" weight="light" />
        </FlexItem>
      </Flex>
      <Flex column gap="gap.small">
        <Text content={t('cv-download-dialog.eduction-header')} weight="semibold" />
        <Ti8mDropdown
          items={
            allEducationItems.map((item) => ({
              ...item,
              disabled: getEducationsDisabled(),
            })) ?? []
          }
          multiple
          search
          position="above"
          align="top"
          onChange={onEducationChanged}
          defaultValue={initialEducationItems}
          key={`education ${cvData?.id}`}
        />
        <FlexItem align="end">
          <Text
            content={`${educationCount} / ${maxEducationCount}`}
            size="smaller"
            weight="light"
          />
        </FlexItem>
      </Flex>
      <Flex column gap="gap.small">
        <Text content={t('cv-download-dialog.projects-header')} weight="semibold" />
        <Ti8mDropdown
          items={allProjectItems.map((project) => ({
            ...project,
            disabled: getProjectDisabled(),
          }))}
          multiple
          search
          position="above"
          align="top"
          onChange={onProjectsChanged}
          defaultValue={initialProjectItems}
          key={`project ${cvData?.id}`}
        />
        <FlexItem align="end">
          <Text content={`${projectCount} / ${maxProjectsCount}`} size="smaller" weight="light" />
        </FlexItem>
      </Flex>
      <Flex column gap="gap.small">
        <Text content={t('cv-download-dialog.sector-header')} weight="semibold" />
        <Ti8mDropdown
          items={allSectorItems.map((item) => ({
            ...item,
            disabled: getSectorDisabled(),
          }))}
          multiple
          search
          position="above"
          align="top"
          onChange={onSectorChanged}
          defaultValue={initialSectorItems}
          key={`sector ${cvData?.id}`}
        />
        <FlexItem align="end">
          <Text content={`${sectorCount} / ${maxSectorCount}`} size="smaller" weight="light" />
        </FlexItem>
      </Flex>
      <Flex column gap="gap.small">
        <Text content={t('cv-download-dialog.skills-header')} weight="semibold" />
        <Ti8mDropdown
          items={
            allSkillItems.map((item) => ({
              ...item,
              disabled: getSkillsDisabled(),
            })) ?? []
          }
          multiple
          search
          position="above"
          align="top"
          onChange={onSkillsChanged}
          defaultValue={initialTopSkillItems}
          key={`skill ${cvData?.id}`}
        />
        <FlexItem align="end">
          <Text content={`${skillCount} / ${maxSkillCount}`} size="smaller" weight="light" />
        </FlexItem>
      </Flex>
      <Flex column gap="gap.small">
        <Text content={t('cv-download-dialog.certificate-header')} weight="semibold" />
        <Ti8mDropdown
          items={allCertificateItems.map((certificate) => ({
            ...certificate,
            disabled: getCertificatesDisabled(),
          }))}
          multiple
          search
          position="above"
          align="top"
          onChange={onCertificatesChanged}
          defaultValue={initialCertificateItems}
          key={`certificate ${cvData?.id}`}
        />
        <FlexItem align="end">
          <Text
            content={`${certificateCount} / ${maxCertificateCount}`}
            size="smaller"
            weight="light"
          />
        </FlexItem>
      </Flex>
    </Flex>
  );
};
