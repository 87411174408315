import { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
import { OpenAPI } from '../core/OpenAPI';
import { SubjectMatterExpertTopicDtoResult } from '../models/SubjectMatterExpertTopicDtoResult';
import { UInt64NullableResult } from '../core';

export class SubjectMatterExpertTopicService {
  public static subjectMatterExpertTopics({
    searchString,
    skip,
    take,
    version,
  }: {
    searchString?: string;
    skip?: number;
    take?: number;
    version: string;
  }): CancelablePromise<SubjectMatterExpertTopicDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/subject-matter-expert-topics',
      path: {
        version: version,
      },
      query: {
        searchString,
        skip,
        take,
      },
    });
  }

  public static getEventPosition({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<UInt64NullableResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/subject-matter-expert-topics/event-position/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }
}
