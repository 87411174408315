import { useClients } from '../../../data-access';
import { ClientsFilterAtomState, PaginationAtomState } from '../../../state/atoms';

export type UseClientListDataProps = {
  filterOptions: ClientsFilterAtomState;
  paginationOptions: PaginationAtomState;
};

export const useClientListData = ({ paginationOptions, filterOptions }: UseClientListDataProps) => {
  const result = useClients(
    {
      searchString: filterOptions.searchString,
      // Skip on backend side means "skip pages" not "skip items".
      skip: paginationOptions.currentPage - 1,
      // A page size equals to the number of items provided for "take"
      take: paginationOptions.pageSize,
    },
    { keepPreviousData: true }
  );

  return {
    data: result.data?.items ?? [],
    totalItems: result.data?.totalCount ?? 0,
  };
};
