import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, withApiVersion, WithoutVersion } from '../../helpers';
import { ApiError, GuidResult, SkillService } from '../../open-api/commands';
import { SkillService as QuerySkillService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const addSkill = withApiVersion(SkillService.addSkill);
const getEventPosition = withApiVersion(QuerySkillService.getEventPosition);

export type AddSkillInput = WithoutVersion<ExtractFirstArgAsType<typeof addSkill>>;

export const useAddSkill = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, AddSkillInput>(addSkill, {
    onSuccess: async ({ payload: id, storePosition }) =>
      waitForEvent({ id, storePosition }, (id) => getEventPosition({ id })).then(() =>
        queryClient.invalidateQueries(QueryKeys.Skills)
      ),
    meta: {
      type: t('alert.update-types.edit'),
      requestObject: t('alert.request-objects.skill'),
    },
  });
};
