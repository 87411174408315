import { Divider, Flex, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';

type EmployeeProfileTextProps = {
  textGerman: string;
  textEnglish: string;
};

export const EmployeeProfileText = ({ textGerman, textEnglish }: EmployeeProfileTextProps) => {
  const { t } = useTranslation();
  return (
    <Flex column gap="gap.small">
      <Flex column>
        <Text weight="bold" content={t('employee-detail.master-data.profile-text.sub-header-1')} />
        <Text content={textGerman} />
      </Flex>
      <Divider />
      <Flex column>
        <Text weight="bold" content={t('employee-detail.master-data.profile-text.sub-header-2')} />
        <Text content={textEnglish} />
      </Flex>
    </Flex>
  );
};
