import {
  EyeIcon,
  EyeSlashIcon,
  Flex,
  Grid,
  List,
  Pill,
  Tooltip,
  Text,
} from '@fluentui/react-northstar';
import { Ti8mPill, Ti8mTruncatedList } from '../../../common';
import { useTranslation } from 'react-i18next';
import { GenderType } from '../../../../data-access';
import { AdditionalPersonalInformation } from './AdditionalPersonalInformation';
import { overflowPillStyle } from '../../../EmployeeTable/employeeTableStyles';
import { AddressDto } from '../../../../data-access/open-api/queries/models/AddressDto';

type EmployeeAdditionalPersonalDataProps = {
  showSensitiveData: boolean;
  address: AddressDto | undefined;
  nationalities: string[] | undefined;
  gender?: GenderType | null;
};

export const EmployeeAdditionalPersonalData = ({
  showSensitiveData,
  address,
  nationalities,
  gender,
}: EmployeeAdditionalPersonalDataProps) => {
  const { t } = useTranslation();
  return (
    <Flex column gap="gap.large">
      <Grid columns="repeat(5,1fr)">
        <AdditionalPersonalInformation
          label={t('employee-detail.master-data.additional-personal-data.content.domicile')}
          data={
            <Flex gap="gap.small">
              <Text
                content={
                  address?.domicile && address.state ? `${address.domicile}, ${address.state}` : '—'
                }
              />
              {address?.isHidden ? <EyeSlashIcon /> : <EyeIcon />}
            </Flex>
          }
        />
        <AdditionalPersonalInformation
          label={t('employee-detail.master-data.additional-personal-data.content.nationalities')}
          data={
            nationalities ? (
              <Ti8mTruncatedList
                renderTruncator={({ hiddenItemsCount }) => (
                  <Tooltip
                    content={
                      <List
                        items={nationalities
                          .slice(nationalities.length - hiddenItemsCount)
                          .map((nationality) => ({
                            key: nationality,
                            content: nationality,
                            styles: { minHeight: 'unset' },
                          }))}
                        styles={{ '> li': { padding: 0 } }}
                      />
                    }
                    trigger={
                      <Pill
                        rectangular
                        content={`+${hiddenItemsCount}`}
                        size="medium"
                        styles={overflowPillStyle}
                      />
                    }
                  />
                )}
                styles={{
                  margin: 0,
                  padding: 0,
                  maxHeight: '50px',
                  alignItems: 'center',
                }}
              >
                {nationalities?.map((item) => (
                  <Ti8mPill
                    key={item}
                    content={item}
                    maxTextLength={25}
                    size="medium"
                    appearance="filled"
                    styles={{
                      minWidth: 'fit-content',
                    }}
                  />
                ))}
              </Ti8mTruncatedList>
            ) : (
              '—'
            )
          }
        />
        {showSensitiveData && (
          <AdditionalPersonalInformation
            label={t('employee-detail.master-data.additional-personal-data.content.gender')}
            data={
              gender != null
                ? t(`employee-detail.master-data.additional-personal-data.value.gender.${gender}`)
                : '—'
            }
          />
        )}
      </Grid>
    </Flex>
  );
};
