import { GenderType } from '../data-access';

type GenderString = 'female' | 'male' | 'different identity' | 'undefined';

export const mapGenderString = (gender: GenderType | string): GenderString => {
  switch (gender) {
    case 'FEMALE':
      return 'female';
    case 'MALE':
      return 'male';
    case 'DIFFERENT_IDENTITY':
      return 'different identity';
    case 'UNDEFINED':
      return 'undefined';
    default:
      return 'undefined';
  }
};

export const mapGenderNumber = (gender: number): GenderString => {
  switch (gender) {
    case 0:
      return 'female';
    case 1:
      return 'male';
    case 2:
      return 'different identity';
    case 3:
      return 'undefined';
    default:
      return 'undefined';
  }
};

export const getGenderTypes = Object.values(GenderType).filter((type) => isNaN(Number(type)));
