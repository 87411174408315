import { Card, CardBody } from '@fluentui/react-northstar';
import { EmployeeDto } from '../../../../data-access';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeAdditionalPersonalData } from './EmployeeAdditionalPersonalData';
import { Ti8mCardHeader } from '../../../common/Ti8mCardHeader';
import { EditAdditionalPersonalDataDialog } from './Dialog/EditAdditionalPersonalDataDialog';

type EmployeeAdditionalPersonalDataCardProps = {
  employee: EmployeeDto;
  isEditable: boolean;
  showSensitiveData: boolean;
};

export const EmployeeAdditionalPersonalDataCard = ({
  employee,
  isEditable,
  showSensitiveData,
}: EmployeeAdditionalPersonalDataCardProps) => {
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isAdditionalPersonalDataCardUnfold, setIsAdditionalPersonalDataCardUnfold] =
    useState<boolean>(true);

  return (
    <Card>
      <Ti8mCardHeader
        cardHeaderText={t('employee-detail.master-data.additional-personal-data.header')}
        isEditable={isEditable && showSensitiveData}
        isCardUnfold={isAdditionalPersonalDataCardUnfold}
        setCardUnfold={setIsAdditionalPersonalDataCardUnfold}
        setDialogVisibility={setDialogOpen}
      />
      <EditAdditionalPersonalDataDialog
        employee={employee}
        isDialogOpen={isDialogOpen}
        setDialogOpen={setDialogOpen}
      />
      {isAdditionalPersonalDataCardUnfold && (
        <CardBody>
          <EmployeeAdditionalPersonalData
            showSensitiveData={showSensitiveData}
            address={employee.address}
            nationalities={employee.nationalities.map((n) => n.name.en)}
            gender={employee.gender}
          />
        </CardBody>
      )}
    </Card>
  );
};
