/**
 * This file provides a wrapper around state in localStorage
 * that is used to implement the 'deep link' feature from Planner to a Talents profile.
 * 
 * The reason, why this state is in localStorage (instead of e.g. an atom or other kinds of state) is this:
 * When switching between tabs the regular javascript state is lost, 
 * but the app.context.page.subPageId (which holds the information to redirect to the employee page) does *not* get reset.
 * (Note that app.context.page.subPageId is also read-only and can not be cleared.)
 * 
 * So to remember, if the redirect was already handled a more persistent state was needed.
 * Although only the 'timestamp' is necessary in localStorage the other information is also included here to avoid
 * splitting the redirect relevant information.
 */


export interface PlannerRedirectInfo {
  showBacklink?: boolean;
  timestamp?: number;
  plannerAppId?: string;
  plannerPageId?: string;
  plannerSubPageId?: string;
}

const _getRedirectInfo = (): PlannerRedirectInfo => {
  const item = localStorage.getItem('plannerRedirectInfo');
  return item ? JSON.parse(item) : {
    showBacklink: false,
    timestamp: undefined,
    plannerAppId: undefined,
    plannerPageId: undefined,
    plannerSubPageId: undefined
  };
};

const _setRedirectInfo = (info: PlannerRedirectInfo | undefined) =>
  localStorage.setItem('plannerRedirectInfo', JSON.stringify(info));

export const deepLinkRedirectState = {
  getTimestamp: () => _getRedirectInfo().timestamp,
  getShowBacklink: () => _getRedirectInfo().showBacklink,
  getRedirectInfo: () => _getRedirectInfo() ,
  setRedirectInfo: (info: PlannerRedirectInfo) => _setRedirectInfo(info),

  updateShowBackLink: (newShowBackLink: boolean) => _setRedirectInfo({ ..._getRedirectInfo(), showBacklink: newShowBackLink }),
};
