import {
  DropdownItemProps,
  FormDropdown,
  FormField,
  ShorthandCollection,
} from '@fluentui/react-northstar';
import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';

type ControlledFormDropdownProps<TForm extends FieldValues> = {
  control: Control<TForm, any>;
  name: Path<TForm>;
  rules?: RegisterOptions;
  label: string;
  dropDownItems: ShorthandCollection<DropdownItemProps>;
};

export const ControlledFormDropdown = <TForm extends FieldValues>({
  control,
  name,
  rules,
  label,
  dropDownItems,
}: ControlledFormDropdownProps<TForm>) => {
  return (
    <FormField>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormDropdown
            onChange={(_event, { value }) => onChange(value)}
            items={dropDownItems}
            value={value}
            label={label}
            fluid
            errorMessage={error}
          />
        )}
      />
    </FormField>
  );
};
