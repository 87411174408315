import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import { ApiError, SkillService, SkillsListDto, SkillsListDtoResult } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import * as QueryParams from './query-params';

const getSkills = (input: QueryParams.Skills) =>
  SkillService.skills({ version: env('REACT_APP_API_VERSION'), ...input });

export const useSkillsList = (
  queryParams: QueryParams.Skills,
  queryOptions?: UseQueryOptions<
    SkillsListDtoResult,
    ApiError,
    SkillsListDto,
    [QueryKeys.Skills, QueryParams.Skills]
  >
) => {
  const result = useQuery(
    [QueryKeys.Skills, queryParams],
    ({ queryKey: [_, input] }) => getSkills(input),
    {
      select: (data) => data.payload,
      ...queryOptions,
    }
  );

  return {
    items: result.data?.items ?? [],
    totalCount: result.data?.totalCount ?? 0,
    queryResult: result,
  };
};
