/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProjectTemplateDtoPaginatedListDtoResult } from '../models/ProjectTemplateDtoPaginatedListDtoResult';
import type { UInt64NullableResult } from '../core/UInt64NullableResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { GenericResult } from '../models/GenericResult';
import { ProjectTemplateDto } from '../models/ProjectTemplateDto';

export class ProjectTemplateService {
  /**
   * @returns ProjectTemplateDtoPaginatedListDtoResult Success
   * @throws ApiError
   */
  public static pagedProjectTemplates({
    version,
    searchString,
    clientSearchString,
    skip,
    take = 50,
  }: {
    version: string;
    searchString?: string;
    clientSearchString?: string;
    skip?: number;
    take?: number;
  }): CancelablePromise<ProjectTemplateDtoPaginatedListDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/project-templates',
      path: {
        version: version,
      },
      query: {
        searchString: searchString,
        clientSearchString: clientSearchString,
        skip: skip,
        take: take,
      },
    });
  }

  public static getProjectTemplatesByClientId({
    version,
    clientId,
  }: {
    version: string;
    clientId?: string;
  }): CancelablePromise<GenericResult<Array<ProjectTemplateDto>>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/project-templates/search-by-client',
      path: {
        version: version,
      },
      query: {
        clientId: clientId,
      },
    });
  }

  /**
   * @returns UInt64NullableResult Success
   * @throws ApiError
   */
  public static getEventPosition({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<UInt64NullableResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/project-templates/event-position/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }
}
