import { useQuery, UseQueryOptions } from 'react-query';
import { QueryParams } from '.';
import { getPayload, withApiVersion } from '../../helpers';
import {
  ApiError,
  CertificateDtoPaginatedListDto,
  CertificateDtoPaginatedListDtoResult,
  CertificateService,
} from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';

const getCertificates = withApiVersion(CertificateService.certificates);

export const useCertificates = (
  queryParams: QueryParams.Certificates,
  queryOptions?: UseQueryOptions<
    CertificateDtoPaginatedListDtoResult,
    ApiError,
    CertificateDtoPaginatedListDto,
    [QueryKeys.Certificates, QueryParams.Certificates]
  >
) =>
  useQuery(
    [QueryKeys.Certificates, queryParams],
    ({ queryKey: [key, input] }) => getCertificates(input),
    {
      select: getPayload,
      ...queryOptions,
    }
  );
