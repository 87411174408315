import { Form } from '@fluentui/react-northstar';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SkillDto, useAddSkill, useSkillGroups, useUpdateSkill } from '../../data-access';
import { AddOrEditDialog } from '../components/AddOrEditDialog';
import { ControlledFormDropdown } from '../components/ControlledFormDropdown';
import { ControlledFormInput } from '../components/ControlledFormInput';
import { ControlledFormTextArea } from '../components/ControlledFormTextArea';
import { applyRules } from '../helpers/formRules';

type SkillsAddOrEditFormDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  currentItem?: SkillDto;
};

type FormAttributes = {
  name: string;
  description: string;
  group: { key: string; header: string };
  tags: string;
};

export const SkillsAddOrEditFormDialog = ({
  open,
  setOpen,
  currentItem,
}: SkillsAddOrEditFormDialogProps) => {
  const { t } = useTranslation();

  const { mutate: update } = useUpdateSkill();
  const { mutate: add } = useAddSkill();
  const { data: skillGroups = [] } = useSkillGroups();

  const {
    control,
    trigger,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormAttributes>({
    defaultValues: {
      name: currentItem?.name,
      description: currentItem?.description,
      tags: currentItem?.skillTags.join(','),
      group: currentItem?.skillGroupId
        ? {
            key: currentItem?.skillGroupId,
            header: skillGroups.find((e) => e.id === currentItem?.skillGroupId)?.name.en,
          }
        : undefined,
    },
  });

  const onSubmit = async (data: FormAttributes) => {
    if (await trigger(undefined, { shouldFocus: true })) {
      const requestParams = {
        skillGroupId: data.group.key,
        name: data.name,
        explanation: data.description,
        skillTags: data.tags === '' || data.tags === undefined ? [] : data.tags.split(','),
      };
      if (currentItem) {
        update({
          id: currentItem.id,
          requestBody: requestParams,
        });
      } else {
        add({
          requestBody: requestParams,
        });
      }
      setOpen(false);
    }
  };

  return (
    <AddOrEditDialog
      open={open}
      setOpen={setOpen}
      title={t('admin.skill-groups.name_one')}
      currentItem={currentItem}
      preventSave={!isDirty}
      onConfirm={handleSubmit(onSubmit)}
      content={
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ControlledFormInput
            control={control}
            rules={applyRules(['required', 'minLength'])}
            name="name"
            label={t('admin.skills.name')}
          />

          <ControlledFormTextArea
            control={control}
            rules={applyRules(['required', 'minLength'])}
            name="description"
            label={t('admin.skills.description')}
            textAreaRows={3}
          />

          <ControlledFormDropdown
            control={control}
            rules={applyRules(['required'])}
            name="group"
            label={t('admin.skills.group')}
            dropDownItems={skillGroups.map(({ id, name }) => ({ key: id, header: name.de }))}
          />

          <ControlledFormTextArea
            control={control}
            name="tags"
            label={`${t('admin.skills.tags')} (${t('admin.skills.tags-hint')})`}
            textAreaRows={3}
          />

          {/* Work around to submit a form when user presses Enter */}
          <button type="submit" hidden />
        </Form>
      }
    />
  );
};
