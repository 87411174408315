import { DegreeCategoryService, QueryKeys } from '../../../data-access';
import { useQuery } from 'react-query';
import { env } from '../../../utils/env';

const getDegreeCategories = () =>
  DegreeCategoryService.getAllDegreeCategories({ version: env('REACT_APP_API_VERSION') });

export const useDegreeCategories = () =>
  useQuery(QueryKeys.DegreeCategories, ({ queryKey: _ }) => getDegreeCategories(), {
    select: (data) => data.payload,
  });
