import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeSkillDto, useUpdateSkills } from '../../../data-access';
import { SkillsDto } from '../../../data-access/open-api/commands';
import { EditSkillsDialogForm } from './EditSkillsDialogForm';
import { Ti8mDialog } from '../../common/Ti8mDialog';

type EditSkillsDialogProps = {
  employeeId: string;
  isDialogOpen: boolean;
  skills: EmployeeSkillDto[];
  skillsGroupedBySkillGroupName: [string, EmployeeSkillDto[]][];
  close: () => void;
};

export const EditSkillsDialog = ({
  employeeId,
  isDialogOpen,
  skills,
  skillsGroupedBySkillGroupName,
  close,
}: EditSkillsDialogProps) => {
  const { t } = useTranslation();
  const [isTouched, setTouched] = useState(false);
  const [updatedSkills, setUpdatedSkills] = useState<Array<SkillsDto>>(
    skills.map((skill) => ({
      skillId: skill.skill.id,
      name: skill.skill.name,
      level: skill.level,
    }))
  );

  const { mutate: updateSkills } = useUpdateSkills();

  useEffect(() => {
    if (skills) {
      setUpdatedSkills(
        skills.map((skill) => ({
          skillId: skill.skill.id,
          name: skill.skill.name,
          level: skill.level,
        }))
      );
    }
  }, [skills, setUpdatedSkills]);

  const resetDialog = useCallback(() => {
    setTouched(false);
    close();
  }, [close]);

  const handleConfirmEditSkills = useCallback(() => {
    updateSkills({
      id: employeeId ?? '',
      requestBody: filterDuplicates(updatedSkills),
    });
    resetDialog();
  }, [employeeId, updatedSkills, resetDialog, updateSkills]);

  const filterDuplicates = (skills: SkillsDto[]): SkillsDto[] => {
    const result: SkillsDto[] = [];
    skills.forEach((skill) => {
      const found = result.some((value) => value.skillId === skill.skillId);
      if (!found) {
        result.push(skill);
      }
    });
    return result;
  };

  return (
    <Ti8mDialog
      open={isDialogOpen}
      header={t('employee-detail.master-data.skills.edit-skills-dialog.header')}
      cancelButton={{
        content: t('employee-detail.master-data.skills.edit-skills-dialog.button-cancel'),
      }}
      onCancel={resetDialog}
      confirmButton={{
        content: t('employee-detail.master-data.skills.edit-skills-dialog.button-save'),
        disabled: !isTouched,
      }}
      onConfirm={handleConfirmEditSkills}
      content={
        <EditSkillsDialogForm
          updatedSkills={updatedSkills}
          skillsGroupedBySkillGroupName={skillsGroupedBySkillGroupName}
          setUpdatedSkills={setUpdatedSkills}
          setTouched={setTouched}
        />
      }
    />
  );
};
