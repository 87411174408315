import { i18nInit } from '../../app/locales';

export const allRules = {
  required: { value: true, message: i18nInit.t('form-validation.messages.required') },
  minLength: {
    value: 2,
    message: i18nInit.t('form-validation.messages.min-length', { value: 2 }),
  },
};

export const applyRules = (rules: (keyof typeof allRules)[]) =>
  rules.reduce<Record<string, unknown>>((acc, rule) => {
    if (allRules[rule]) acc[rule] = allRules[rule];
    return acc;
  }, {});
