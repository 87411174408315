import { createSvgIcon } from '@fluentui/react-northstar';

export const DiamondIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <path d="M5.5 3a.5.5 0 0 0-.429.243l-3 5a.5.5 0 0 0 .045.577l7.5 9a.5.5 0 0 0 .768 0l7.5-9a.5.5 0 0 0 .045-.577l-3-5A.5.5 0 0 0 14.5 3h-9ZM3.383 8l2.4-4h1.979l-1.6 4H3.383Zm2.775 1 2.267 5.829L3.568 9h2.59ZM10 16.12 7.231 9h5.538L10 16.12ZM7.239 8l1.6-4h2.322l1.6 4H7.24Zm6.6 0-1.6-4h1.978l2.4 4h-2.779Zm.003 1h2.59l-4.857 5.829L13.842 9Z" />
    </svg>
  ),
  displayName: 'DiamondIcon',
});
