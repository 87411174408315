import { Flex } from '@fluentui/react-northstar';
import { SearchWithFilter } from '../components/EmployeeSearch/SearchWithFilter';
import { EmployeeTable } from '../components/EmployeeTable/EmployeeTable';
import { BasicLayout } from '../components/Layout/Layout';
import { app } from '@microsoft/teams-js';
import { useEffect } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { runsInTeams } from '../utils';
import { deepLinkRedirectState } from '../state/deepLinkRedirectState';

// When called from Planner: the payload of information provided in the app.context.page.subPageId
interface PlannerPayload {
  employeeId: string,
  timestamp: number,
  plannerAppId: string,
  plannerPageId: string,
  plannerSubPageId?: string
}

export const EmployeePage = () => {
  const history = useHistory();


  useEffect(() => {
    // In Planner there is a link (via pages.navigateToApp) to an employee profile in Talents.
    // As it can only target a tab (and not a specific URL) we need to redirect to the correct page.
    // For more information see: state/deepLinkRedirectState
    const redirectFromPlanner = async () => {
      const inTeams = await runsInTeams();
      if (inTeams) {
        app.getContext().then((context) => {
          const payload = context.page.subPageId;
          if (payload) {
            const params:PlannerPayload = JSON.parse(payload);

            const redirectTimestamp  = deepLinkRedirectState.getTimestamp(); 
            if (!redirectTimestamp || redirectTimestamp !== params.timestamp) {
              deepLinkRedirectState.setRedirectInfo({
                showBacklink: true,
                timestamp: params.timestamp,
                plannerAppId: params.plannerAppId,
                plannerPageId: params.plannerPageId,
                plannerSubPageId: params.plannerSubPageId
              });
              const url = generatePath('/employeeDetail/:id', { id: params.employeeId });
              history.replace(url);
            } else {
              // make sure we don't show the Planner-Backlink any more (e.g. after switching tabs)
              deepLinkRedirectState.updateShowBackLink(false);
            }
          }
        });
      }
    };

    redirectFromPlanner();
  }, [history]);

  return (
    <BasicLayout>
      <Flex column gap="gap.large">
        <SearchWithFilter />
        <EmployeeTable />
      </Flex>
    </BasicLayout>
  );
};

