/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientDto } from '../models/ClientDto';
import type { GuidResult } from '../models/GuidResult';
import type { Result } from '../models/Result';
import type { Result_1 } from '../models/Result_1';
import type { TranslationDto } from '../models/TranslationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClientService {
  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static addClient({
    version,
    requestBody,
  }: {
    version: string;
    requestBody?: ClientDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/clients',
      path: {
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static updateClient({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: TranslationDto;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/clients/{id}',
      path: {
        id: id,
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
