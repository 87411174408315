import { Flex } from '@fluentui/react-northstar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../../components/EmployeeTable/Pagination';
import {
  SubjectMatterExpertTopicDto,
  useDeleteSubjectMatterExpertTopic,
  useSubjectMatterExpertTopics,
} from '../../data-access';
import { DefaultLoader } from '../components/DefaultLoader';
import { MasterDataToolbar } from '../components/MasterDataToolbar';
import { SubjectMatterExpertTopicsAddOrEditFormDialog } from './SubjectMatterExpertTopicsAddOrEditFormDialog';
import { SubjectMatterExpertTopicsMasterDataTable } from './SubjectMatterExpertTopicsMasterDataTable';

const PAGE_SIZE = 10;

export const SubjectMatterExpertTopicsMasterData = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<SubjectMatterExpertTopicDto>();

  const { mutate: remove } = useDeleteSubjectMatterExpertTopic();

  const { data: { items = [], totalCount = 0 } = {}, isLoading } = useSubjectMatterExpertTopics({
    take: PAGE_SIZE,
    skip: currentPage - 1,
    searchString: searchTerm,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <Flex column fill>
      <MasterDataToolbar
        DialogComponent={SubjectMatterExpertTopicsAddOrEditFormDialog}
        buttonLabel={t('admin.subject-matter-expert-topics.add')}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        inputPlaceholderText={t('admin.subject-matter-expert-topics.search')}
      />

      <DefaultLoader isLoading={isLoading}>
        <SubjectMatterExpertTopicsMasterDataTable
          items={items}
          onEditItem={(item) => {
            setCurrentItem(item);
            setDialogOpen(true);
          }}
          onDeleteItem={(item) => remove({ id: item.id })}
        />
      </DefaultLoader>

      <Pagination
        currentPage={currentPage}
        pageSize={PAGE_SIZE}
        totalItems={totalCount}
        onPageChange={(page) => setCurrentPage(page)}
      />

      {dialogOpen ? (
        <SubjectMatterExpertTopicsAddOrEditFormDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          currentItem={currentItem}
        />
      ) : null}
    </Flex>
  );
};
