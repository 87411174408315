import { Button, CalendarIcon, Datepicker, Position } from '@fluentui/react-northstar';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { debounce } from 'lodash';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';

dayjs.extend(customParseFormat);

const customParseFormats = [
  'D.M.YYYY',
  'DD.M.YYYY',
  'D.MM.YYYY',
  'DD.MM.YYYY',
  'DD.MM.YY',
  'DD.M.YY',
  'D.M.YY',
];

interface Ti8mDatepickerProps {
  placeholder: string;
  defaultSelectedDate?: Date;
  error?: boolean;
  popupPosition?: Position;
  onDateChange: (date: Date | null) => void;
  required?: boolean;
}

export const Ti8mDatepicker: FunctionComponent<Ti8mDatepickerProps> = ({
  placeholder,
  defaultSelectedDate,
  error = false,
  popupPosition,
  onDateChange,
  required = false,
}: Ti8mDatepickerProps) => {
  const [isCalendarPopupOpen, setIsCalendarPopupOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(defaultSelectedDate);

  const handleOnOpenChange = useCallback(
    (value?: boolean) => {
      // small timeouthack to handle order of execution
      setTimeout(() => {
        setIsCalendarPopupOpen(value ?? isCalendarPopupOpen);
      }, 10);
    },
    [setIsCalendarPopupOpen, isCalendarPopupOpen]
  );

  const parseDateDebounced = useMemo(
    () =>
      debounce((dateString: string) => {
        const regex = /^\d{1,2}\.\d{1,2}\.\d{2,4}$/;
        const date = dayjs(dateString, customParseFormats, true);
        if (regex.test(dateString) && date.isValid()) {
          setSelectedDate(date.toDate());
          onDateChange(date.toDate());
        }
      }, 400),
    [setSelectedDate, onDateChange]
  );

  return (
    <Datepicker
      required={required}
      inputOnly
      input={{
        icon: (
          <Button
            type="button"
            iconOnly
            text
            icon={<CalendarIcon outline />}
            onClick={() => setIsCalendarPopupOpen(!isCalendarPopupOpen)}
          />
        ),
        fluid: true,
        showSuccessIndicator: false,
        error,
        errorIndicator: (
          <Button
            type="button"
            iconOnly
            text
            icon={
              <CalendarIcon
                styles={({ theme: { siteVariables } }) => ({
                  color: siteVariables.colors.red['400'],
                })}
              />
            }
            onClick={() => setIsCalendarPopupOpen(!isCalendarPopupOpen)}
          />
        ),
      }}
      calendar={{
        calendarCellButton: {
          onClick: () => setIsCalendarPopupOpen(false),
        },
      }}
      popup={{
        onOpenChange: (_, d) => {
          handleOnOpenChange(d?.open ?? isCalendarPopupOpen);
        },
        unstable_pinned: true,
        // default to 'below' here, because providing undefined here does change the behaviour!
        position: popupPosition ?? 'below',
      }}
      calendarOpenState={isCalendarPopupOpen}
      inputPlaceholder={placeholder}
      allowManualInput
      onDateChange={(_event, data) => {
        setSelectedDate(data?.value);
        onDateChange(data?.value ?? null);
      }}
      parseDate={(dateString) => {
        // Delay the execution of parseDate by using debounce. This will
        // give the user more time to input a desired date
        parseDateDebounced.cancel();
        parseDateDebounced(dateString);

        // We can not use parseDate as intended because of the debounce
        // so we just return null.
        return null;
      }}
      formatMonthDayYear={(date) => dayjs(date).format('DD.MM.YYYY')}
      selectedDate={selectedDate}
    />
  );
};
