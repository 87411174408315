import { FormField, FormTextArea } from '@fluentui/react-northstar';
import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';

type ControlledFormTextAreaProps<TForm extends FieldValues> = {
  control: Control<TForm, any>;
  name: Path<TForm>;
  rules?: RegisterOptions;
  label: string;
  textAreaRows?: number;
};

export const ControlledFormTextArea = <TForm extends FieldValues>({
  control,
  name,
  rules,
  label,
  textAreaRows = 3,
}: ControlledFormTextAreaProps<TForm>) => {
  return (
    <FormField>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <FormTextArea {...field} rows={textAreaRows} label={label} fluid errorMessage={error} />
        )}
      />
    </FormField>
  );
};
