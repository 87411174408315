import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, WithoutVersion, withApiVersion } from '../../helpers';
import { ApiError, GuidResult } from '../../open-api/commands';
import { SubjectMatterExpertService } from '../../open-api/commands/services/SubjectMatterExpertService';
import { SubjectMatterExpertTopicService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const addSubjectMatterExpertTopic = withApiVersion(
  SubjectMatterExpertService.addSubjectMatterExpertTopic
);

export type SubjectMatterExpertTopicInput = WithoutVersion<
  ExtractFirstArgAsType<typeof SubjectMatterExpertService.addSubjectMatterExpertTopic>
>;

const getEventPosition = withApiVersion(SubjectMatterExpertTopicService.getEventPosition);

export const useAddSubjectMatterExpertTopic = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, SubjectMatterExpertTopicInput>(
    addSubjectMatterExpertTopic,

    {
      onSuccess: async ({ payload: id, storePosition }) =>
        waitForEvent({ id, storePosition }, (id) => getEventPosition({ id })).then(() =>
          queryClient.invalidateQueries(QueryKeys.SubjectMatterExpertTopics)
        ),
      meta: {
        type: t('alert.update-types.requested'),
        requestObject: t('alert.request-objects.subject-matter-expert-topic'),
      },
    }
  );
};
