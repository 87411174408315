import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ExtractFirstArgAsType, withApiVersion, WithoutVersion } from '../../helpers';
import { ApiError, GuidResult, ProjectTemplateService } from '../../open-api/commands';
import { ProjectTemplateService as QueryProjectTemplateService } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const addProjectTemplate = withApiVersion(ProjectTemplateService.addProjectTemplate);
const getEventPosition = withApiVersion(QueryProjectTemplateService.getEventPosition);

type AddProjectTemplateInput = WithoutVersion<
  ExtractFirstArgAsType<typeof ProjectTemplateService.addProjectTemplate>
>;

export const useAddProjectTemplate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, AddProjectTemplateInput>(addProjectTemplate, {
    onSuccess: async ({ payload: id, storePosition }) =>
      waitForEvent({ id, storePosition }, (id) => getEventPosition({ id })).then(() =>
        queryClient.refetchQueries(QueryKeys.ProjectTemplates)
      ),
    meta: {
      type: t('alert.update-types.edit'),
      requestObject: t('alert.request-objects.employee'),
    },
  });
};
