import { useQuery } from 'react-query';
import { env } from '../../../utils/env';
import { RoleService } from '../../open-api/queries/services/RoleService';
import { QueryKeys } from '../../query-keys';

const getRoles = () => RoleService.roles({ version: env('REACT_APP_API_VERSION') });

export const useRoles = () =>
  useQuery(QueryKeys.Roles, ({ queryKey: _ }) => getRoles(), {
    select: (data) => data.payload,
  });
