import { SvgIconProps, iconClassNames, createSvgIcon } from '@fluentui/react-northstar';
import cx from 'classnames';

export type NorthStarIconProps = React.HTMLAttributes<HTMLSpanElement> & SvgIconProps;

export type FluentIconComponent = {
  (
    props: React.SVGAttributes<SVGElement> & {
      primaryFill?: string;
      className?: string;
      filled?: boolean;
      title?: string;
    }
  ): JSX.Element;
  displayName?: string;
};

/**
 * Icons from `@fluentui/react-icons` do not automatically hook into the
 * system that `@fluentui/react-northstar` uses to outline or fill icons in buttons when hovered.
 *
 * With this method we can use the icons from `@fluentui/react-icons`
 * and create a new icon that behaves like the icons from `@fluentui/react-northstar`.
 */
export const createNorthstarIcon = (
  regular: FluentIconComponent,
  filled: FluentIconComponent | undefined
) => {
  return (props: NorthStarIconProps) => {
    const RegularComponent = regular;
    const FilledComponent = filled;

    return createSvgIcon({
      svg: ({ classes }) => (
        <>
          <RegularComponent
            className={cx(classes.svg, iconClassNames.outline, classes.outlinePart)}
          />
          {FilledComponent && (
            <FilledComponent
              className={cx(classes.svg, iconClassNames.filled, classes.filledPart)}
            />
          )}
        </>
      ),
      displayName: regular.displayName?.replace('Regular', '') ?? 'GeneratedUnknownIcon',
    })(props);
  };
};
