import { EmployeeService } from '../../open-api/queries';
import { env } from '../../../utils/env';
import { QueryParams } from '.';

const downloadEducation = (input: QueryParams.DownloadEducation) =>
  EmployeeService.downloadEducation({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useDownloadEducation = (id: string) => (educationName?: string) =>
  downloadEducation({ id, educationName });
