import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { env } from '../utils/env';

const createConfiguration = (): Configuration => {
  const clientId = env('REACT_APP_AZURE_CLIENT_ID');
  const tenantId = env('REACT_APP_AZURE_TENANT_ID');
  const redirectUri = env('REACT_APP_AZURE_REDIRECT_URI');

  return {
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
    auth: {
      clientId,
      authority: `https://login.microsoftonline.com/${tenantId}`,
      redirectUri,
      postLogoutRedirectUri: redirectUri,
      navigateToLoginRequestUrl: true,
    },
  };
};

export const auth = new PublicClientApplication(createConfiguration());
