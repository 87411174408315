import { atom } from 'recoil';

export interface PaginationAtomState {
  currentPage: number;
  pageSize: number;
}

export const employeePaginationAtom = atom<PaginationAtomState>({
  key: 'employeePaginationStateAtom',
  default: {
    currentPage: 1,
    pageSize: 12,
  },
});

export const clientPaginationAtom = atom<PaginationAtomState>({
  key: 'clientPaginationStateAtom',
  default: {
    currentPage: 1,
    pageSize: 12,
  },
});

export const projectPaginationAtom = atom<PaginationAtomState>({
  key: 'projectPaginationStateAtom',
  default: {
    currentPage: 1,
    pageSize: 12,
  },
});
